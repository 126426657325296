import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import PropTypes from 'prop-types';
import "./Devices.css";
import dayjs from 'dayjs'
// import { Chip, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getAllDevice, createDevice, getDeviceDetails, 
          editDevice,deleteDevice, bulkcreateDevice, DeviceHistory } from "./api";
import Paper from '@mui/material/Paper';          
import TextField from '@mui/material/TextField';
import { useEffect, useState, useRef} from "react";
import CustomModal from "../Modal/CusModal";
import EditModal from "../Modal/CusModal";
import { CircularProgress } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import getCookie, { setAdminCookie, userState } from "../../Atom";
import Popup from "../popup/Popup";
// import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Box from '@mui/material/Box';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from '@material-ui/icons/Delete';
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom";
import deviceCSV from'../../assets/csv/deviceCSV.csv'

import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useRecoilState } from "recoil";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: '_id',
    numeric: false,
    disablePadding: true,
    label: 'Serial Id',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Device Name',
  },
  // {
  //   id: 'fuelVolume',
  //   numeric: true,
  //   disablePadding: true,
  //   label: 'Fuel Volume',
  // },
  // {
  //   id: 'nozzleDia',
  //   numeric: true,
  //   disablePadding: true,
  //   label: 'Nozzle Diameter',
  // },
  // {
  //   id: 'pressure',
  //   numeric: true,
  //   disablePadding: true,
  //   label: 'Pressure',
  // },
  // {
  //   id: 'className',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Class',
  // },
  // {
  //   id: 'studentName',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Student',
  // },
  // {
  //   id: 'date',
  //   numeric: true,
  //   disablePadding: true,
  //   label: 'Date',
  // },
  // {
  //   id: 'time',
  //   numeric: true,
  //   disablePadding: true,
  //   label: 'Time',
  // },
  
];



function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              className="sort-header"
              sx={{ display: 'flex' }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          align={'center'}
          padding={'normal'}>
          <div className=' mb-2'>
            School District
          </div>
        </TableCell>
        <TableCell
          align={'center'}
          padding={'normal'}>
          <div className=' mb-2'>
            School
          </div>
        </TableCell>
        <TableCell
          align={'center'}
          padding={'normal'}>
          <div className=' mb-2'>
            Teacher
          </div>
        </TableCell>
        <TableCell
          align={'center'}
          padding={'normal'}>
          <div className=' mb-2'>
            Assigned on
          </div>
        </TableCell>
        <TableCell
          align={'center'}
          padding={'normal'}>
          <div className=' mb-2'>
            Device History
          </div>
        </TableCell>

        <TableCell
          align={'center'}
          padding={'normal'}>
          <div className=' mb-2 '>
            Actions
          </div>
        </TableCell>
      </TableRow>
    </TableHead>

  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    // 
    <div className='pt-10'>

    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


export default function Device() {
  // const newBulkModal = useRef()
  const history = useHistory();
    let search = window.location.search;
    let params = new URLSearchParams(search);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [editMode, seteditMode] = useState(false);
  const [deleteMode, setdeleteMode] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [name, setName] = useState("");
  const [editName, setEditName] = useState('');
  const [activeId, setactiveId] = useState("");
  const [fileValid, setFileValid] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [duplicateNameFile, setDuplicateNameFile] = useState([]);
  const [duplicateNameDataBase, setDuplicateNameDataBase] = useState([]);
  const [duplicateSerialFile, setDuplicateSerialFile] = useState([]);
  const [duplicateSerialDataBase, setDuplicateSerialDataBase] = useState([]);
  const [query, setQuery] = useState("");
  const [sortType, setSortType] = useState('ASC');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [clicked, setclicked] = useState(false);
  // const { token } = useRecoilValue(userState);
  const [allocatedDevices, setAllocatedDevices] = useState(0);
  const [unallocatedDevices, setunAllocatedDevices] = useState(0);
  const [invalidNames, setInvalidNames] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [deviceHistory, setDeviceHistory] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [paged, setPaged] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [noOfPages, setNoOfPages] = useState(0);
  const [serialId, setSerialId] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(25);
  const [snackBarShow, setSnackBarShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [ myUser , setmyUser ] = useState(userState);

  const logoutHandler=async()=>{
    setTimeout(()=>{
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })
      setAdminCookie({
        fName:null,
        lName:null,
        token:null,
        userId:null
      })
      let hostname= window.location.host;
      // console.log(hostname,window.location.protocol)
      window.location.assign(`${window.location.protocol}//${hostname}/super-admin`)   
    })  
  }

  const feedBackMsg =(snackShow,snackSeverity,snackMessage)=>{
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    
    feedBackMsg(false,'','');
  }

  const editHandler = async (e) => {
    e.preventDefault();
    let dataToSend = { name: editName }
    // console.log('edit',token)
    let res = await editDevice(activeId, dataToSend, myUser.token);
    if (res.error) {
      // alert("Could not edit Device Details. Try Again");
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }

    } else {
      feedBackMsg(true,'success',res.res.data.msg);
      setactiveId('')
      setEditName('')
      handleCloseAddModal();
      getDevice(paged,pageLimit,query, sortType)
      //showModal(true)
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteHandler = async (e) => {
    e.preventDefault();
    let dataToSend = { name: editName }
    // console.log(token)
    let res = await deleteDevice(activeId, myUser.token);
    if (res.error) {
      // alert("Could not delete Device. Try Again");
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }

    } else {
      setactiveId('')
      setEditName('')
      handleCloseAddModal();
      getDevice(paged,pageLimit,query, sortType)
      feedBackMsg(true,'success',res.res.data.msg);
    }
  };

  const fetchDeviceHistory = async(id) =>{
    // e.preventDefault();
    setLoading(true);
    let res = await DeviceHistory(id, myUser.token);
    // console.log(res)
    if (res.error) {
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        // feedBackMsg(true,'error',res.data.msg);
      
      }
      setLoading(false);
    } else {
      setLoading(false)
      // console.log(res);
      if(res.res.data.data.status=='found'){
        // console.log(res.res.data.data.devicehistory);
        setDeviceHistory(res.res.data.data.devicehistory);
        feedBackMsg(true,'success',res.res.data.msg);
        setLoading(false)
      }else{
        // alert('Device History not found');
        // setShowHistory(false);
      }
    }
  }

  const handleChangePage = (event, newPage) => {
       
    // console.log(event)
    // setPaged(newPage);
    setPaged(event.selected)
    getDevice(event.selected,pageLimit,query, sortType);
    history.push({
        pathname: '/devices',
        search: '?page=' + event.selected + '&limit='+ pageLimit +'&search=' +query + '&order=' + sortType
      })
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true)
    let res = await createDevice({ name: name.trim(),serialId:serialId.trim() }, myUser.token);
    // console.log(res)
    if (res.error) {
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      
      setLoading(false)
      }
    } else {
      setLoading(false)

      getDevice(paged,pageLimit,query, sortType);
      handleCloseAddModal();

      feedBackMsg(true,'success',res.res.data.msg);
      //showModal(true)
    }
  };

  
  const getDeviceDetail = async (activeId,type) => {
    // console.log(editMode,deleteMode,type)
    if(type==='edit')seteditMode(true); else setdeleteMode(true);
    // console.log(editMode,deleteMode)
    setactiveId(activeId)
    handleShowAddModal()
    let res = await getDeviceDetails(activeId, myUser.token)
    if (res.error) {
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
    }
    else {
      // setSnackBarShow(true);
      // setSnackBarSeverity('success')
      // setSnackBarMessage(res.res.data.msg);
      setEditName(res.res.data.data.device.name)
      // console.log(res.res.data.data)
    }
  }

  useEffect(() => {
    // getDevice();
    const _cookie = getCookie('recoil-persist')
        if(!_cookie){
          logoutHandler()
        }else{
        const user = JSON.parse(_cookie)['KT_app']
        setmyUser(user)
    let urlPage=parseInt(params.get('page')) || 0;
    let urlLimit = parseInt(params.get('limit')) || 25;
    let searchPage=params.get('search') || '';
    let urlOrder = params.get('order') || 'ASC';
        // console.log(urlPage)
        
            history.push({
                pathname: '/devices',
                search: '?page=' + urlPage + '&limit=' + urlLimit+'&search=' +searchPage + '&order=' + urlOrder
              })
              setPaged(urlPage)
              getDevice(urlPage,urlLimit,searchPage, urlOrder,user)
              setPageLimit(urlLimit)
              setQuery(searchPage);
              setSortType(urlOrder);
            }
  }, [])
  // useEffect(() => {
    
  //   if(sortType === 'ASC'){
  //     let temp_devices = [...devices].sort(function(a, b) {
  //       var keyA = a._id,
  //         keyB = b._id;
  //       // Compare the 2 dates
  //       if (keyA < keyB) return -1;
  //       if (keyA > keyB) return 1;
  //       return 0;
  //     });
  //     setDevices(temp_devices)
  //   }else{
  //     let temp_devices = [...devices].sort(function(a, b) {
  //       var keyA = a._id,
  //         keyB = b._id;
  //       // Compare the 2 dates
  //       if (keyA < keyB) return 1;
  //       if (keyA > keyB) return -1;
  //       return 0;
  //     });
  //     setDevices(temp_devices)
  //   }

  // }, [sortType])

  const createTableFormatData = (data) => {
    // ADDS SOME MORE PROPETIES TO TABLE DATA FROM SERVER -- BASICALLY NESTED OBJECTS IN RESPONSE
    return new Promise(resolve => {
      let arr = []
      for (let index = 0; index < data.length; index++) {
        
          const __exp = {...data[index], name:data[index].name || 'No Name'};
        
        arr.push(__exp)
        if(index == data.length - 1) resolve(arr)
      }
    })
  }

  const getDevice = async (pages,limits,search,order,user=myUser) => {
    let res = await getAllDevice(user?.token,pages,limits,search,order)
    if (res.error) {
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
    }
    else {
      // console.log(res.res.data.data)
      let temp_devices = res.res.data.data.devices,arr=[];
      for(let i=0;i<temp_devices.length;i++){
        let obj={...temp_devices[i],name:temp_devices[i].name};
        arr.push(obj);
      }
      // temp_devices.sort(function(a, b) {
      //   var keyA = a._id,
      //     keyB = b._id;
      //   // Compare the 2 dates
      //   if (keyA < keyB) return -1;
      //   if (keyA > keyB) return 1;
      //   return 0;
      // });
      setDevices(arr);
      // let deviceArray = temp_devices;let allocatedCount=0,unallocatedCount=0;
      // for(let i=0;i<deviceArray.length;i++){
      //   if(deviceArray[i].schoolDistrict || deviceArray[i].school || deviceArray[i].teacher.length>0){
      //     allocatedCount++; 
      //   }else{
      //     unallocatedCount++;
      //   }
      // }
      setAllocatedDevices(res.res.data.data.allocatedCount); setunAllocatedDevices(res.res.data.data.unallocatedCount);
      setTotalCount(res.res.data.data.allocatedCount+res.res.data.data.unallocatedCount)
      let divisor=parseInt(res.res.data.data.totalcount/limits);
      let remainder= parseInt((res.res.data.data.totalcount%limits==0)?0:1);
            setNoOfPages(divisor+remainder)
            setCurrPage(pages);
            if(res.res.data.data.totalcount==0){
              setPaged(0);
            }
            // history.push({
            //   pathname: '/devices',
            //   search: '?page=' + pages + '&limit=' + limits+ '&search=' +search
            // })
    }
  }
  const handleCloseAddModal = () => {
    // console.log(show)
    setShow(false);
    seteditMode(false);
    setName('');
    setSerialId('');
    setdeleteMode(false);

  };
  const handleShowAddModal = () => {
    setShow(true);
  };
  const handleCloseEditModal = () => {
    // console.log(show)
    setShow(false);
    seteditMode(false);
  };

  const handleBulk = async (e) => {
    e.preventDefault()
    if(!uploadedFile){
      return
    }
    setclicked(true)
    const formData = new FormData();
    formData.append(
      "file",
      uploadedFile,
      "file"
    );
    // console.log(formData,uploadedFile)
    const res = await bulkcreateDevice(formData, myUser.token);
    // console.log(res.res.data)
    if(res.error){
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
    }else {
      // feedBackMsg(true,'success',res.res.data.msg);
    if(res.res.data.data.duplicateEntryNameinFile.length>0 || res.res.data.data.fiveExceedlist.length>0 ||
       res.res.data.data.duplicateEntrySerialinDatabase.length>0 || res.res.data.data.duplicateEntryNameinDatabase.length>0 || 
       res.res.data.data.duplicateEntrySerialinFile.length>0){
      let duplicateIds=res.res.data.data.duplicateEntryNameinFile;
      setShowDuplicate(true)
      setDuplicateNameFile(duplicateIds);
      setDuplicateNameDataBase(res.res.data.data.duplicateEntryNameinDatabase);
      setDuplicateSerialDataBase(res.res.data.data.duplicateEntrySerialinDatabase);
      setDuplicateSerialFile(res.res.data.data.duplicateEntrySerialinFile);
      setInvalidNames(res.res.data.data.fiveExceedlist);
      }
    }
    setclicked(false)
    handleClose();
    // newBulkModal.current.close();

    getDevice(paged,pageLimit,query, sortType);
  }

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageLimit);
  const handleRequestSort = (event, property) => {
    // console.log(property)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleFile = e => {
    // console.log(e.target.files[0]);
    // const file= e.target.files;
    // setUploadedFile(e.target.files[0]);
    if(e.target.files[0].name.includes('.csv')){
      const file= e.target.files;
      setUploadedFile(e.target.files[0]);
      setFileValid(true);
    }
    else{
      alert('Please enter a csv file');
      setFileValid(false);
    }
  }

  const handleClose1 = ()=>{
    setShowDuplicate(false);
  }

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setPageLimit(parseInt(event.target.value, 10))
    setPaged(0);
    // console.log('Row change')
    getDevice(0,parseInt(event.target.value, 10),query, sortType);
    history.push({
      pathname: '/devices',
      search: '?page=0'  + '&limit=' + parseInt(event.target.value, 10) + '&search=' +query + '&order=' +sortType
    })
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;
  
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;

  // console.log(editMode,deleteMode)
  return (
    <section id="hero-school ">
     
     <Dialog open={showHistory} handleClose={()=>{setShowHistory(false);setDeviceHistory([]);}} fullWidth maxWidth="md">
      
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h6" style={{fontWeight:'600'}}>Manage Devices</Typography>
            <IconButton onClick={()=>{setShowHistory(false);setDeviceHistory([]);}} >
              <CloseIcon style={{border:'2px solid black',fontSize:'30px'}}/>
            </IconButton>
          </Grid>
        </DialogTitle>
         
        <div className="flex-container">
          
         
    {
        <TableContainer className="container table" style={{maxHeight:'500px'}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Device Id</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Category Id</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
        

          {loading && !deviceHistory?.length && <CircularProgress/>}
          {!loading && !deviceHistory?.length && <p>No Device History Found</p>}

            <TableBody>
              {deviceHistory?.length>0 && deviceHistory.map((item, index) => {
                return (
                  <TableRow hover key={index} role="checkbox" tabIndex={-1}>
                    <TableCell className="table-data">{item.device._id}</TableCell>
                    <TableCell className="table-data">
                      {item.allocatedTo}
                    </TableCell>{" "}
                    <TableCell className="table-data">
                      {item.allocationId}
                    </TableCell>{" "}
                    <TableCell className="table-data">
                      {/* { dayjs(item.updatedAt).format('DD/MM/YYYY  hh:mm:A') } */}
                      {`${new Date(item.updatedAt).toLocaleDateString()} ${new Date(item.updatedAt).toLocaleTimeString()}`}
                    </TableCell>
                    <TableCell className="table-data">
                      {item.status}
                    </TableCell>{" "}
                  </TableRow>
                );
              })}
            </TableBody>
        
          </Table>
        </TableContainer>
      }
      </div>
    </Dialog>

      <Dialog scroll="paper" open={showDuplicate} onClose={handleClose1} fullWidth>
        <DialogTitle>Duplicate LoginIds/ Device Name Exceed List</DialogTitle>
        <DialogContent>
          
          {(duplicateNameFile.length>0)?<>
            <br/>
          <h6>Duplicate Device Names in File</h6>
          <br></br></>:null}
          {duplicateNameFile.map((e,i)=>{
            return <div>{i+1}. {e}</div>
          })}

         
          {(duplicateNameDataBase.length>0)?<>
            <br/>
          <h6>These Device names are already present in Database.</h6>
          <br></br></>:null}
          {duplicateNameDataBase.map((e,i)=>{
            return <div>{i+1}. {e}</div>
          })}
          
          {(duplicateSerialFile.length>0)?<>
            <br/>
          <h6>Duplicate Serial Id in File.</h6>
          <br></br></>:null}
          {duplicateSerialFile.map((e,i)=>{
            return <div>{i+1}. {e}</div>
          })}
         
          {(duplicateSerialDataBase.length>0)?<>
            <br/>
          <h6>These Serial Ids are already present in Database.</h6>
          <br></br></>:null}
          {duplicateSerialDataBase.map((e,i)=>{
            return <div>{i+1}. {e}</div>
          })}
          
          {
            (invalidNames.length>0)?
            <>
            <br />
            <h6>These Device names either exceeded more than 15 letters or were less than 5 characters  </h6>
              <br></br>
            {invalidNames.map((e,i)=>{
            return <div>{i+1}. {e}</div>
          })}
            </>:null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Close</Button>
        </DialogActions>
      </Dialog>

       <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           
            <form onSubmit={e=>handleBulk(e)}>
            <h3 className="custom-form-heading">Add Devices from Excel</h3>
            <input type="file" className="custom-form-input" required onChange={e=>{handleFile(e)}}/>
            {!clicked && <input type="submit" className="custom-form-button" value="Upload" disabled={!fileValid} />}
            {clicked && <button className="custom-form-button" disabled>Uploading...</button>}
          </form>
          {/* <br/> */}
          {/* <div onClick={openDocs} style={{color:'blue'}}>
              Download Sample
          </div> */}
          <a href={deviceCSV} target="_blank">Download Sample File.</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
         
        </DialogActions>
      </Dialog>

      <Dialog handleClose={handleCloseAddModal} open={show} fullWidth>
        
                {!editMode && !deleteMode ? <>
                 
                  <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                      <Typography variant="h6" style={{fontWeight:'600'}}>Add Device</Typography>
                      <IconButton onClick={handleCloseAddModal} >
                        <CloseIcon style={{border:'2px solid black',fontSize:'30px'}}/>
                      </IconButton>
                    </Grid>
                  </DialogTitle>
                  <div className="col-md-9 text-left  col-9 m-auto">
                    <div className="form-group mt-4   p-20">
                      <input
                        maxLength="15"
                        type="text"
                        value={name}
                        className="form-control"
                        onBlur={(e) => setName(e.target.value.trim())}
                        placeholder="Device Name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mt-4   p-20">
                      <input
                        maxLength="32"
                        type="text"
                        value={serialId}
                        onBlur={e=>setSerialId(e.target.value.trim())}
                        className="form-control"
                        placeholder="Device Serial Id"
                        onChange={(e) => setSerialId(e.target.value)}
                      />
                    </div>
                   
                    <div className="form-group p-20">
                      <button onClick={submitHandler} type="submit" className="cta-btn" disabled={name.trim().length<1 || serialId.trim().length<1}>
                        {loading ? <CircularProgress /> : "Add"}
                      </button>
                    </div>
                  </div></> : editMode? <>
                
                  <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                      <Typography variant="h6" style={{fontWeight:'600'}}>Edit Device</Typography>
                      <IconButton onClick={handleCloseAddModal} >
                        <CloseIcon style={{border:'2px solid black',fontSize:'30px'}}/>
                      </IconButton>
                    </Grid>
                  </DialogTitle>
                  <div className="col-md-9 text-left  col-9 m-auto">
                    <div className="form-group mt-4   p-20">
                      <input
                        value={editName}
                        maxLength="15"
                        type="text"
                        onBlur={(e) => setEditName(e.target.value.trim())}
                        className="form-control"
                        placeholder="Device ID"
                        onChange={(e) => setEditName(e.target.value)}
                      />
                    </div>
                
                    <div className="form-group p-20">
                      <button onClick={editHandler} type="submit" className="cta-btn">
                        {loading ? <CircularProgress /> : "Edit"}
                      </button>
                    </div>
                  </div></>:<>
                  
                  <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                      <Typography variant="h6" style={{fontWeight:'600'}}>Remove Device</Typography>
                      <IconButton onClick={handleCloseAddModal} >
                        <CloseIcon style={{border:'2px solid black',fontSize:'30px'}}/>
                      </IconButton>
                    </Grid>
                  </DialogTitle>
                  <div className="col-md-9 text-left  col-9 m-auto">
                    <div className="form-group mt-4   p-20">
                      <h4>Are you sure to Remove this Device?</h4>
                    </div>
                    <div className="form-group mt-4   p-20">
                    <input readOnly
                        value={editName}
                        type="text"
                        className="form-control"
                        placeholder="Device ID"
                      />
                    </div>
                 
                    <div className="form-group p-20">
                      <button onClick={deleteHandler} type="submit" className="cta-btn-red">
                        {loading ? <CircularProgress /> : "Delete"}
                      </button>
                    </div>
                  </div>
                </>}
      </Dialog>



      <div >
        <NavBar />
        <div className="mt-5">
          <h2>All Devices</h2>
        </div>
        <div className="dashboard-notification-cards mt-1">
          <div className="notification-card-admin p-3">
            <h2>{totalCount}</h2>

            <h6 className="ml-2 notification-subHeading span-h4">Total</h6>
          </div>
          <div className="notification-card-admin p-3">
            <h2>{allocatedDevices}</h2>

            <h6 className="ml-2 notification-subHeading span-h4">Allocated</h6>
          </div>
          <div className="notification-card-admin p-3">
            <h2>{unallocatedDevices}</h2>

            <h6 className="ml-2 notification-subHeading span-h4">Unallocated</h6>
          </div>
        </div>
        <div
          className="dashboard-heading "
          style={{ marginRight: "20px" }}
        >
          <h2>
            Devices List
            <Link onClick={handleShowAddModal}>

              <button className="btn btn-add-device float-right ">
                <i className="fas fa-plus-circle"></i> {"  "}
                <span>Add new Device</span>
              </button>
            </Link>

          <Link onClick={()=>handleClickOpen()}>
              <button className="btn btn-add-device float-right ">
                <i className="fas fa-cloud-upload-alt"></i> {"  "}
                <span>Upload Item In Bulk</span>
              </button>
          </Link>
            
          </h2>
        </div>

        <div>
          <div className="row">
            <div className="col-lg-8 col-md-7 col-sm-12">
              {/* <input type="text" className="form-control" placeholder="Search..." onChange={(e)=>{setQuery(e.target.value)}} /> */}
              <TextField sx={{minWidth:250,width:600,background:'white'}} value={query} id="outlined-search" label="Search"  type="search"
              onBlur={(e)=>{setQuery(e.target.value.trim())}}
              onChange={(e)=>{setQuery(e.target.value);getDevice(0,pageLimit,e.target.value,sortType)}}/>
            </div>
            {/* <div className="col-lg-4 col-md-5 col-sm-12">
              <select className="form-control" value={sortType} onChange={(e)=>{setSortType(e.target.value); console.log(e.target.value)}}>
                <option value="ASC">Ascending</option>
                <option value="DESC">Descending</option>
              </select>
            </div> */}
            {/* <div className="col-lg-4 col-md-5 col-sm-12">
              <Box sx={{ minWidth: 300, background:'white' }} >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Order</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sortType}
                    label="Order"
                    onChange={(e)=>{setSortType(e.target.value);getDevice(paged,pageLimit,query,e.target.value)}}>
                    <MenuItem value='ASC'>Ascending</MenuItem>
                    <MenuItem value='DESC'>Descending</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div> */}
          </div>
          <br />
          <div className="d-flex justify-content-end align-items-end">
                
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={noOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handleChangePage}
                    containerClassName="pagination"
                    activeClassName="activePage active"
                    forcePage={currPage}/>

            </div>
            {/* <div className="d-flex justify-content-end align-items-end">

            <Box sx={{ minWidth: 100,backgroundColor:"white"}} >
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Page</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={pageLimit}
                          label="Age"
                          onChange={handleChangeRowsPerPage}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                           
                        </Select>
                      </FormControl>
                    </Box>
            </div>   */}

          {/* <table className="table">
            <thead>
              <tr>
                <th>Serial Id</th>
                <th>Device Name</th>
                <th>School District</th>
                <th>School</th>
                <th>Teacher</th>
                <th>Assigned on</th>
                <th>Device History</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {devices.map((item, index) => {

                  if(item._id.toLowerCase().includes(query.toLowerCase()) || item?.name?.includes(query.toLowerCase())){
                    return <tr hover key={index} role="checkbox" tabIndex={-1}>
                    <td className="">
                    <Link className="sec-link" to={`/experiments?serial=${item._id}`}>{item._id}</Link>
                      </td>
                    <td className="">
                      {item.name}
                    </td>{" "}
                    <td className="" style={{ textTransform: 'capitalize' }}><Link className="sec-link" to={(item?.schoolDistrict?.schoolId==undefined)?`/district/${item?.schoolDistrict?._id}`:`/district/${item?.schoolDistrict?._id}/school/${item?.school?._id}`}>{item?.schoolDistrict?.name}</Link></td>
                    <td className="" style={{ textTransform: 'capitalize' }}><Link className="sec-link" to={`/district/${item?.schoolDistrict?._id}/school/${item?.school?._id}`}>{item?.school?.name}</Link></td>
                    <td className="" style={{ textTransform: 'capitalize' }}>
                      {item?.teacher?.map(teach => {
                        return <li key={teach._id}
                          className="sec-link">
                        
                        
                          {`${teach?.fName} ${teach?.lName}`}
                        </li>
                      })}
                    </td>
                    <td className="table-data dateShow">{(item.schoolDistrict || item.school || item.teacher.length>0)?
                      (item.assignedAt==undefined)?`${new Date(item.updatedAt).toLocaleDateString()} ${new Date(item.updatedAt).toLocaleTimeString()}`
                        :`${new Date(item.assignedAt).toLocaleDateString()} ${new Date(item.assignedAt).toLocaleTimeString()}`:'-'}
                    </td>
                    <td>
                      <Link onClick={()=>{setShowHistory(true);fetchDeviceHistory(item._id)}}>Show</Link>
                    </td>
                    <td className="">
                    <Tooltip title="Edit" placement="top">
                      <Link>
                       
                        <span onClick={() => {
                          getDeviceDetail(item._id,'edit');
                        }}>
                          <i className="fas fa-edit icon-size-20" ></i>
                        </span>
                      </Link>
                      </Tooltip>
                      &nbsp;
                      <Tooltip title="Delete" placement="top">
                      <Link>
                      
                        <span onClick={() => {
                          getDeviceDetail(item._id,'delete');
                        }}>
                          <i className="fas fa-trash-alt icon-size-20" ></i>
                        </span>
                      </Link>
                      </Tooltip>
                    </td>

                  </tr>
                  }
                  
              })}
            </tbody>
          </table> */}

    <Box sx={{ width: '100%' }} >
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer
        //  sx={{ height: '80vh', overflow: 'auto' }}
         className="scrollLimits"
         >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'small'}
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={devices.length}
            />
            <TableBody>
              {stableSort(devices, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((devices, index) => {
                  const isItemSelected = isSelected(devices.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, devices.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={(devices.name || 'xyz') + index.toString()}
                      selected={isItemSelected}
                    >
                      <TableCell align="center">
                        {/* {experiments.deviceId || experiments?.device?._id} */}
                        <Link className="sec-link" to={`/experiments?serial=${devices._id}`}>{devices._id}</Link>
                      </TableCell>
                      <TableCell align="right">{(devices.name)?<>
                      <span>{devices.name}</span>
                      </>:<>
                      <span style={{backgroundColor:'#960018',color:'white'}}>No Name</span>
                      </>}
                      </TableCell>
                      <TableCell align="right">
                        {/* {experiments.deviceName || experiments?.device?.name} */}
                        <Link className="sec-link" to={(devices?.schoolDistrict?.schoolId==undefined)?`/district/${devices?.schoolDistrict?._id}`:`/district/${devices?.schoolDistrict?._id}/school/${devices?.school?._id}`}>{devices?.schoolDistrict?.name}</Link>
                      </TableCell>
                      <TableCell align="right">
                        {/* {Number.isInteger(experiments?.fuelVolume) ? experiments?.fuelVolume : experiments?.fuelVolume.toFixed(2)} ml */}
                        <Link className="sec-link" to={`/district/${devices?.schoolDistrict?._id}/school/${devices?.school?._id}`}>{devices?.school?.name}</Link>
                      </TableCell>
                      <TableCell align="right">
                        {/* {Number.isInteger(experiments?.nozzleDia) ? experiments?.nozzleDia : experiments?.nozzleDia.toFixed(2)} mm */}
                        {devices?.teacher?.map(teach => {
                        return <li key={teach._id}
                          className="sec-link">
                          {`${teach?.fName} ${teach?.lName}`}
                        </li>
                      })}
                      </TableCell>
                      <TableCell align="right">
                        {/* {Number.isInteger(experiments?.pressure) ? experiments?.pressure : experiments?.pressure.toFixed(2)} psi */}
                        {(devices.schoolDistrict || devices.school || devices.teacher.length>0)?
                          (devices.assignedAt==undefined)?`${new Date(devices.updatedAt).toLocaleDateString()} ${new Date(devices.updatedAt).toLocaleTimeString()}`
                        :`${new Date(devices.assignedAt).toLocaleDateString()} ${new Date(devices.assignedAt).toLocaleTimeString()}`:'-'}
                      </TableCell>
                      <TableCell align="right">
                        {/* {experiments.className} */}
                        <Link onClick={()=>{setShowHistory(true);fetchDeviceHistory(devices._id)}}>Show</Link>
                      </TableCell>
                      <TableCell align="right">
                          {/* {experiments.studentName} */}
                          <Tooltip title="Edit" placement="top">
                            <Link>
                       
                              <span onClick={() => {
                                getDeviceDetail(devices._id,'edit');
                                }} style={{fontSize:'25px'}}>
                                <i className="fas fa-edit icon-size-20" ></i>
                              </span>
                            </Link>
                          </Tooltip>
                          {/* &nbsp;
                          <Tooltip title="Delete" placement="top">
                            <Link>
                      
                              <span onClick={() => {
                                getDeviceDetail(devices._id,'delete');
                              }}>
                                <i className="fas fa-trash-alt icon-size-20" ></i>
                              </span>
                            </Link>
                          </Tooltip> */}
                      </TableCell>
                      {/* <TableCell align="right">{experiments.date}</TableCell>
                      <TableCell align="right">{experiments.time}</TableCell>
                      <TableCell>
                        <Link to={`/experiments/${experiments._id}`} className="btn btn-sm btn-outline-primary">Analyze</Link>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Edit" placement="left">
                          <button className="btn btn-sm btn-outline-primary mr-2" onClick={() => initiateEdit(experiments)}>
                            <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.75 5H5C4.33696 5 3.70107 5.26339 3.23223 5.73223C2.76339 6.20107 2.5 6.83696 2.5 7.5V25C2.5 25.663 2.76339 26.2989 3.23223 26.7678C3.70107 27.2366 4.33696 27.5 5 27.5H22.5C23.163 27.5 23.7989 27.2366 24.2678 26.7678C24.7366 26.2989 25 25.663 25 25V16.25" stroke="#495057" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M23.125 3.125C23.6223 2.62772 24.2967 2.34835 25 2.34835C25.7033 2.34835 26.3777 2.62772 26.875 3.125C27.3723 3.62228 27.6517 4.29674 27.6517 5C27.6517 5.70326 27.3723 6.37772 26.875 6.875L15 18.75L10 20L11.25 15L23.125 3.125Z" stroke="#495057" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </button>
                        </Tooltip>

                        <Tooltip title="Delete" placement="right">
                          <button className="btn btn-sm btn-outline-danger " onClick={() => handleDelete(experiments._id)}>
                            <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="#495057" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M18.75 11.25L11.25 18.75" stroke="#495057" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.25 11.25L18.75 18.75" stroke="#495057" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                          </button>
                        </Tooltip>
                      </TableCell> */}

                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
                </Box>
        </div>
      </div>

                  <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                    <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                  </Snackbar>

    </section>
  );
}
