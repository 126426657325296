import axios from "axios";
import { baseUrl } from "../Base_url";

export const getAllExperimentsForStudent = async (token, page,limit,data) => {
    try {
        const res = await axios.post(`${baseUrl}/student/student/experiment/my?page=${page}&&limit=${limit}`,data, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};
export const fetchStudentsDetail = async (token) => {
    try {
        const res = await axios.get(`${baseUrl}/student/student`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};

export const addTeachersId = async (token) => {
    try {
        const res = await axios.get(`${baseUrl}/student/student/addTeacherId`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};