import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'



const { persistAtom } = recoilPersist()

const studentState = atom({
  key: "student",
  default: {
    fName: null,
    userId: null,
    token: null,
    lName: null,
  },
  
  effects_UNSTABLE: [persistAtom]
});






export {studentState};
