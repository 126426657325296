import axios from "axios";
import { baseUrl } from "../Base_url";

export const getAllDevice = async (token,page,limit,search,order) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/device/all?page=${page}&limit=${limit}&search=${search}&order=${order}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const createDevice = async (data, token) => {
    try {
      const res = await axios.post(`${baseUrl}/admin/device/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const bulkcreateDevice = async (data, token) => {
    try {
      const res = await axios.post(`${baseUrl}/admin/device/create/bulk/xlsx`,data,{
        headers: { Authorization: `Bearer ${token}`,'Content-Type': 'multipart/form-data' },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  
  export const editDevice = async (id,data, token) => {
    try {
      const res = await axios.put(`${baseUrl}/admin/device/${id}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const getDeviceDetails = async (id, token) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/device/${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const deleteDevice = async (id,token) => {
    try {
      const res = await axios.delete(`${baseUrl}/admin/device/${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const DeviceHistory = async (id,token) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/device/device-history/${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  
  
  
  