import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./DashBoard.css";
import getCookie, {districtAdminState, setDistrictCookie} from '../../Atom'
import { useRecoilState } from "recoil";
import { dashboardStats, destrictAdminOrgDetail } from "./api";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FeedBack from "../FeedBack";

import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
// import { useRecoilState } from "recoil";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function DashBoard() {
  const [ myUser , setmyUser ] = useState()
  const [schoolName, setSchoolName] = useState("")
  const [stats, setstats] = useState({})
  const [snackBarShow, setSnackBarShow] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState('');

    const logoutHandler=async()=>{
      setTimeout(()=>{
        // setmyUser({
        //   fName: null,
        //   userId: null,
        //   token: null,
        //   lName: null,
        // })
        setDistrictCookie({
          fName:null,
          lName:null,
          token:null,
          userId:null
        })
        let hostname= window.location.host;
        // console.log(hostname,window.location.protocol)
        window.location.assign(`${window.location.protocol}//${hostname}/school-district`)   
      })  
    }


  useEffect(() => {
    const _cookie = getCookie('recoil-persist')
    if(!_cookie){
      logoutHandler()
    }else{
    const user = JSON.parse(_cookie)['district_admin_v1']
    setmyUser(user)
    getMySelf(user)
    }
  }, [0])
  const getMySelf = async (user) => {
    // console.log(myUser)
    let res = await destrictAdminOrgDetail(user?.token);
    let res1 = await dashboardStats(user?.token);
    // console.log(res)
    if (res.error) {
      // alert('Failed to get Stats. Try Again')
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
    }
    else {
      // feedBackMsg(true,'success',res.res.data.msg);
      setSchoolName(res.res.data.data.schoolDistrict.name)
      // console.log("Dashboard =>", res1.res.data.data)
      setstats(res1.res.data.data)
    }
  }

  const feedBackMsg =(snackShow,snackSeverity,snackMessage)=>{
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    
    feedBackMsg(false,'','');
  }

  return (
    <section className="district-dashboard" >
      
        <div >
          <NavBar />
          <div className="dashboard-heading">
            <h1 className="span-h1" style={{textTransform:'capitalize'}}>Good Morning, {schoolName} {(myUser?.type=='individual')?'School':'District'} Admin</h1>
            <span>Manage Your DashBoard here</span>
          </div>
          <div className="dashboard-notification-cards d-flex flex-wrap justify-content-between">
            {myUser?.type=='group'?
            <div className="notification-card p-3">
              <h2>{stats?.school}</h2>

              <Link to="/schools"><h6 className="ml-2 notification-subHeading span-h4">Schools</h6></Link>
            </div>
              :null}
            <div className="notification-card p-3">
              <h2>{stats?.teacher}</h2>

             {myUser?.type=='individual'? 
             <Link to={`/schools/${myUser?.schoolId}`}><h6 className="ml-2 notification-subHeading span-h4">Teachers</h6></Link>:
             <h6 className="ml-2 notification-subHeading span-h4">Teachers</h6>}
            </div>
            <div className="notification-card p-3">
              <h2>{stats?.student}</h2>

              <h6 className="ml-2 notification-subHeading span-h4">Students</h6>
            </div>
            <div className="notification-card p-3">
              <h2>{stats?.device}</h2>

              <h6 className="ml-2 notification-subHeading span-h4">Devices</h6>
            </div>
            <div className="notification-card p-3">
              <h2>{stats?.experiment}</h2>

              <h6 className="ml-2 notification-subHeading span-h4">
                Experiments
              </h6>
            </div>
            <div className="notification-card p-3" style={{opacity:0}}>
              <h2>{stats?.experiment}</h2>

              <h6 className="ml-2 notification-subHeading span-h4">
                Experiments
              </h6>
            </div>
          </div>
        </div>
        <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                    <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar>
    </section>
  );
}
