import * as React from 'react';
import { getTrackBackground, Range } from 'react-range';
const MIN = 0;
const MAX = 45;
const rtl = false;

class RangeCustom extends React.Component {
    state = { values: [0] };
    changeInputValue = (val) => {
        if(val<0 || val>45){
            return
        }
        let a = []
        a.push(val)
        this.setState({values:a})
    }
    render() {
        return (
            <div className="d-flex justify-content-between" style={{width:'100%'}}>

                <Range

                    step={0.1}
                    min={0}
                    max={45}
                    values={this.state.values}
                    onChange={(values) => this.setState({ values })}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: '36px',
                                display: 'flex',
                                width: '70%'
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: '5px',
                                    width: '100%',
                                    borderRadius: '4px',
                                    background: getTrackBackground({
                                        values: this.state.values,
                                        colors: ['#548BF4', '#ccc'],
                                        min: MIN,
                                        max: MAX,
                                        rtl
                                    }),
                                    alignSelf: 'center'
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '42px',
                                width: '42px',
                                borderRadius: '4px',
                                backgroundColor: '#FFF',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '0px 2px 6px #AAA'
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '-33px',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                                    padding: '4px',
                                    borderRadius: '4px',
                                    backgroundColor: '#548BF4'
                                }}
                            >
                                {this.state.values[0]}<span>&#176;</span>
                            </div>
                            <div
                                style={{
                                    height: '16px',
                                    width: '5px',
                                    backgroundColor: isDragged ? '#548BF4' : '#CCC'
                                }}
                            />
                        </div>
                    )}
                />
                <input 
                    type="number" 
                    step="0.1" 
                    className="form-control" 
                    style={{ padding: 0, textAlign: 'center', maxWidth:'50px' }} 
                    value={this.state.values[0]} 
                    onChange={e=>this.changeInputValue(e.target.value)}
                />
            </div>
        );
    }
}

export default RangeCustom