import React, { useEffect, useState, useRef, unstable_useTransition } from 'react'
import { getAnalysedData, getThisExperimentDetails } from './api'
import './style.css'
import { useRecoilState, useRecoilValue } from 'recoil'
import getCookie, { userState } from '../../Atom'
// import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, AreaChart, Area, Label, ResponsiveContainer, ReferenceLine } from 'recharts';
import RangeCustom from './Range'
import '../../../../node_modules/react-vis/dist/style.css';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, VerticalGridLines, makeVisFlexible, makeWidthFlexible, makeHeightFlexible } from 'react-vis';
import CanvasJSReact from '../../../assets/js/canvasjs-commercial-3.4/canvasjs.react'
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Analysis({ match }) {
    const [myUser, setmyUser] = useState(userState);
    const FlexibleXYPlot = makeVisFlexible(XYPlot);
    const FlexibleWidthXYPlot = makeWidthFlexible(XYPlot);
    const FlexibleHeightXYPlot = makeHeightFlexible(XYPlot);
    const range_slide_custom = useRef(null)
    const massQ = useRef(null)
    const paraQ = useRef(null)
    // const { token } = useRecoilValue(userState)
    const [angle, setAngle] = useState(0)
    const [mass, setMass] = useState(300.00)
    const [para_area, setPara_area] = useState(0.00)

    const [experimentData, setExperimentData] = useState(null)
    const [calculatedData, setCalculatedData] = useState(null)

    const [thrustModified, setThrustModified] = useState([])
    const [thrustFiltered, setThrustFiltered] = useState([])
    const [bottle_pressure, setBottle_pressure] = useState([])
    const [exhaust_velocity, setExhaust_velocity] = useState([])
    const [massChange, setMassChange] = useState([])
    const [acceleration, setAcceleration] = useState([])
    const [velocity, setVelocity] = useState([])
    const [distance, setDistance] = useState([])
    const [height, setHeight] = useState([])
    const [teacher,setTeacher] = useState('');

    const [clicked, setClicked] = useState(false)
    useEffect(() => {
        const _cookie = getCookie('recoil-persist')
        const user = JSON.parse(_cookie)['KT_app']
        setmyUser(user)
        getBasicDetails(user)
    }, [])
    const getBasicDetails = async (user=myUser) => {

        const res = await getThisExperimentDetails(user?.token, match.params.id)
        if (res.error) {
            alert(`Error retrieving this Experiment Data. Code: exp_geterr_${match.params.id}`)
        } else {
            // console.log(res.res.data.data.experiment)
            let teachers='';
            let experimentDta= {...res.res.data.data.experiment};
            if(experimentDta.teacher!==undefined){
                experimentDta.teacher= experimentDta.teacher.map((e)=>e._id)
                let teach=res.res.data.data.experiment.teacher;
           
            for(let i=0;i<teach.length;i++){
                if(i== teach.length-1)
                    teachers+= teach[i].fName + ' '+ teach[i].lName;
                else
                teachers+= teach[i].fName + ' '+ teach[i].lName +",";
            }
            }
            // console.log(experimentDta);
            setExperimentData(experimentDta)
            
            setTeacher(teachers)
            await getPredictedData(experimentDta)
        }

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(range_slide_custom.current.state.values[0])
        getPredictedData()
    }
    const getPredictedData = async (expData = experimentData) => {
        // console.log(expData)
        setClicked(true)
        const res = await getAnalysedData(range_slide_custom.current.state.values[0], massQ.current.value, paraQ.current.value, expData)
        setClicked(false)
        if (res.error) {
            alert("Experiment Failed. Contact Admin.")
        } else {
            // console.log(res.res.data)
            setCalculatedData(res.res.data)
            setThrustFiltered(res.res.data.thrust)
            setBottle_pressure(res.res.data.bottle_pressure)
            setExhaust_velocity(res.res.data.exhaust_velocity)
            setMassChange(res.res.data.mass_of_rocket)
            setAcceleration(res.res.data.acceleration)
            setVelocity(res.res.data.velocity)
            setDistance(res.res.data.distance)
            setHeight(res.res.data.height)
        }
    }
    return (
        <div>
            <div className="container-fluid form-analysis">
                <div className="row origin-exp-data">
                    <div className="col-6">
                        <table className="table table-sm table-bordered">
                            <tbody>
                                <tr>
                                    <th>School District</th>
                                    <td>{experimentData?.schoolDistrict}</td>
                                </tr>
                                <tr>
                                    <th>School</th>
                                    <td>{experimentData?.school}</td>
                                </tr>
                                <tr>
                                    <th>Class</th>
                                    <td>{experimentData?.className}</td>
                                </tr>
                                <tr>
                                    <th>Teacher</th>
                                    <td>{teacher}</td>
                                </tr>
                                <tr>
                                    <th>Student</th>
                                    <td>{experimentData?.studentName}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6">
                        <table className="table table-sm table-bordered">
                            <tbody>
                                <tr>
                                    <th>Device</th>
                                    <td>{experimentData?.device?._id}</td>
                                </tr>
                                <tr>
                                    <th>Bottle Volume</th>
                                    <td>{experimentData?.bottleVolume} ml</td>
                                </tr>
                                <tr>
                                    <th>Fuel Volume</th>
                                    <td>{experimentData?.fuelVolume} ml</td>
                                </tr>
                                <tr>
                                    <th>Nozzle Diameter</th>
                                    <td>{experimentData?.nozzleDia} mm</td>
                                </tr>
                                <tr>
                                    <th>Pressure (at launch)</th>
                                    <td>{experimentData?.pressure.toFixed(0)} psi</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <form className="form container-fluid form-analysis" onSubmit={e => handleSubmit(e)}>
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <label>Angle Of Launch (degrees)</label>
                        <div className="d-flex align-items-center">
                            <RangeCustom angle={angle} ref={range_slide_custom} />
                            {/* <input
                                ref={range_slide_custom}
                                type="range"
                                min={0}
                                max={45}
                                step={0.1}
                                style={{width:'100%'}}
                                // value={angle} // don't set value from state
                                defaultValue={angle} // but instead pass state value as default value
                                // onChange={e => setAngle(e.target.value)} // don't set state on all change as react will re-render
                                onMouseUp={e => setAngle(e.target.value)} // only set state when handle is released
                            /> */}
                            {/* <div className="angle-box"> */}
                            {/* <input type="number" step="0.1" className="form-control" style={{ padding: 0, textAlign: 'center' }} onChange={e => setAngle(e.target.value)} value={angle} /> */}
                            {/* </div> */}

                        </div>

                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <label>Dry Mass Of Rocket (grams)</label>
                        <input
                            type="number"
                            step="0.01"
                            placeholder="Mass in grams"
                            className="form-control"
                            // onChange={e => setMass(e.target.value)}
                            defaultValue={300}
                            ref={massQ}
                        />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <label>Parachute Area (m^2)</label>
                        <input
                            type="number"
                            step="0.01"
                            placeholder="Area of Parachute"
                            className="form-control"
                            // onChange={e => setPara_area(e.target.value)} 
                            defaultValue={0.00}
                            ref={paraQ}
                            />
                    </div>
                    <div className="col-lg-3">
                        <label>Ready for results?</label>
                        {!clicked && <input type="submit" value="Calculate" className="btn form-control" />}
                        {clicked && <input type="submit" value="Calculate" className="btn form-control" disabled />}
                    </div>
                </div>

            </form>
            <div style={{ position: 'relative' }}>
                <div className="container-fluid form-analysis">
                    <div className="row origin-exp-data">
                        <div className="col-6">
                            <table className="table table-sm table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Total Thrust Time</th>
                                        <td>{calculatedData?.total_thrust_time} sec</td>
                                    </tr>
                                    <tr>
                                        <th>Water Phase Length </th>
                                        <td>{calculatedData?.water_phase_length} sec</td>
                                    </tr>
                                    <tr>
                                        <th>Total Impulse</th>
                                        <td>{calculatedData?.total_impulse} N-sec</td>
                                    </tr>
                                    <tr>
                                        <th>Peak Thrust</th>
                                        <td>{calculatedData?.peak_thrust} N</td>
                                    </tr>
                                    <tr>
                                        <th>Time from apogee to ground</th>
                                        <td>{calculatedData?.apogee_to_ground} sec</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className="col-6">
                            <table className="table table-sm table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Peak Acceleration</th>
                                        <td>{calculatedData?.peak_acceleration} m/s^2</td>
                                    </tr>
                                    <tr>
                                        <th>Peak Velocity</th>
                                        <td>{calculatedData?.peak_velocity} m/s</td>
                                    </tr>
                                    <tr>
                                        <th>Max. Altitude</th>
                                        <td>{calculatedData?.max_altitude} m</td>
                                    </tr>
                                    <tr>
                                        <th>Distance Travelled</th>
                                        <td>{calculatedData?.distance_travelled} m</td>
                                    </tr>
                                    <tr>
                                        <th>Total Flight</th>
                                        <td>{calculatedData?.total_flight_time} s</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div className="d-flex flex-wrap justify-content-between align-items-start ">
                    <div className="individual-graph thrust-chart-area-left">
                        <CanvasJSChart options={{
                            animationEnabled: false,
                            exportEnabled: true,
                            theme: "light1", // "light1", "dark1", "dark2"
                            height: 800,
                            title: {
                                text: "Thrust vs Time",
                                fontSize: 25,
                                fontWeight:"normal"
                            },
                            axisY: {
                                title: "Thrust (N)",
                                suffix: "N",
                                labelFontSize: 15,
                                titleFontSize: 18,
                                
                            },
                            axisX: {
                                title: "Time",
                                suffix: "sec",
                                interval: 0.1,
                                labelFontSize: 15,
                                titleFontSize: 18,
                                gridThickness: 1,
                                gridColor: "#dbdbdb"
                            },
                            data: [{
                                type: "area",
                                toolTipContent: "{x} sec: {y} N",
                                dataPoints: thrustFiltered
                            }],
                            legend:{
                                fontSize: 10,
                            },
                        }}
                        />
                    </div>
                    <div className="thrust-chart-area-right d-flex flex-wrap">
                        <div className="individual-graph" style={{ flexBasis: '100%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                height: 245,
                                title: {
                                    text: "Exhaust Velocity vs Time",
                                    fontSize: 18,
                                    fontWeight:"normal"
                                },
                                axisY: {
                                    title: "Velocity (m/s)",
                                    suffix: "m/s"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 0.1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "line",
                                    toolTipContent: "{x} sec: {y} m/s",
                                    dataPoints: exhaust_velocity
                                }]
                            }}
                            />
                        </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                height: 245,
                                title: {
                                    text: "Pressure vs Time",
                                    fontSize: 18,
                                    fontWeight:"normal"
                                },
                                axisY: {
                                    title: "Pressure (kPa)",
                                    suffix: "kPa"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 0.1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} kPa",
                                    dataPoints: bottle_pressure
                                }]
                            }}
                            />
                            </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                height: 245,
                                title: {
                                    text: "Mass vs Time",
                                    fontSize: 18,
                                    fontWeight:"normal"
                                },
                                axisY: {
                                    title: "Mass (kg)",
                                    suffix: "kg"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 0.1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "area",
                                    toolTipContent: "{x} sec: {y} kg",
                                    dataPoints: massChange
                                }]
                            }}
                            />
                            </div>
                        </div>


                        <div className="individual-graph mini-graph" style={{ flexBasis: '100%' }}>
                        <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Acceleration vs Time",
                                    fontSize: 18,
                                    fontWeight:"normal"
                                },
                                axisY: {
                                    title: "Acceleration (m/s^2)",
                                    suffix: "m/s^2"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 0.1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m/s^2",
                                    dataPoints: acceleration
                                }]
                            }}
                            />
                        </div>
                        <div className="individual-graph mini-graph" style={{ flexBasis: '49%' }}>
                        <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Velocity vs Time",
                                    fontSize: 18,
                                    fontWeight:"normal"
                                },
                                axisY: {
                                    title: "Velocity (m/s)",
                                    suffix: "m/s"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m/s",
                                    dataPoints: velocity
                                }]
                            }}
                            />
                        </div>
                        <div className="individual-graph mini-graph" style={{ flexBasis: '49%' }}>
                        <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Height vs Time",
                                    fontSize: 18,
                                    fontWeight:"normal"
                                },
                                axisY: {
                                    title: "Height (m)",
                                    suffix: "m"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m",
                                    dataPoints: height
                                }]
                            }}
                            />
                        </div>
                        {/* <div className="individual-graph mini-graph" style={{ flexBasis: '32.5%' }}>
                        <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", 
                                title: {
                                    text: "Distance vs Time",
                                    fontSize: 18,
                                    fontWeight:"normal"
                                },
                                axisY: {
                                    title: "Distance (m)",
                                    suffix: "m"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m",
                                    dataPoints: distance
                                }]
                            }}
                            />
                        </div> */}
                    </div>
                    {/* <div className="d-flex flex-wrap justify-content-between align-items-start ">
                        <div className="individual-graph thrust-chart-area-left">
                            <p>Thrust-Time</p>
                            <FlexibleWidthXYPlot height={660} >
                                <HorizontalGridLines />
                                <VerticalGridLines />
                                <LineSeries data={thrustFiltered} />
                                <XAxis title="Time (s)" />
                                <YAxis title="Thrust (N)" />
                            </FlexibleWidthXYPlot>
                        </div>
                        <div className="thrust-chart-area-right d-flex flex-wrap">
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <p>Exhaust Velocity-Time</p>
                                <FlexibleWidthXYPlot height={175} >
                                    <HorizontalGridLines />
                                    <VerticalGridLines />
                                    <LineSeries data={exhaust_velocity} />
                                    <XAxis title="Time (s)" />
                                    <YAxis title="Exh. Velocity (m/s)" />
                                </FlexibleWidthXYPlot>
                            </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <p>Pressure-Time</p>
                                <FlexibleWidthXYPlot height={175} >
                                    <HorizontalGridLines />
                                    <VerticalGridLines />
                                    <LineSeries data={bottle_pressure} />
                                    <XAxis title="Time (s)" />
                                    <YAxis title="Pressure (kPa)" />
                                </FlexibleWidthXYPlot>
                            </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <p>Mass-Time</p>
                                <FlexibleWidthXYPlot height={175} >
                                    <HorizontalGridLines />
                                    <VerticalGridLines />
                                    <LineSeries data={massChange} />
                                    <XAxis title="Time (s)" />
                                    <YAxis title="Mass (Kg)" />
                                </FlexibleWidthXYPlot>
                            </div>
                        </div>


                        <div className="individual-graph mini-graph" style={{ flexBasis: '100%' }}>
                            <p>Acceleration-Time</p>
                            <FlexibleWidthXYPlot height={300} >
                                <HorizontalGridLines />
                                <VerticalGridLines />
                                <LineSeries data={acceleration} />
                                <XAxis title="Time (s)" />
                                <YAxis title="Acceleration (m/s^2)" />
                            </FlexibleWidthXYPlot>
                        </div>
                        <div className="individual-graph mini-graph" style={{ flexBasis: '32.5%' }}>
                            <p>Velocity-Time</p>
                            <FlexibleWidthXYPlot height={300} >
                                <HorizontalGridLines />
                                <VerticalGridLines />
                                <LineSeries data={velocity} />
                                <XAxis title="Time (s)" />
                                <YAxis title="Velocity (m/s)" />
                            </FlexibleWidthXYPlot>
                        </div>
                        <div className="individual-graph mini-graph" style={{ flexBasis: '32.5%' }}>
                            <p>Height-Time</p>
                            <FlexibleWidthXYPlot height={300} >
                                <HorizontalGridLines />
                                <VerticalGridLines />
                                <LineSeries data={height} />
                                <XAxis title="Time (s)" />
                                <YAxis title="Height (m)" />
                            </FlexibleWidthXYPlot>
                        </div>
                        <div className="individual-graph mini-graph" style={{ flexBasis: '32.5%' }}>
                            <p>Distance-Time</p>
                            <FlexibleWidthXYPlot height={300} >
                                <HorizontalGridLines />
                                <VerticalGridLines />
                                <LineSeries data={distance} />
                                <XAxis title="Time (s)" />
                                <YAxis title="Distance (m)" />
                            </FlexibleWidthXYPlot>
                        </div>
                    </div> */}
                    {/* <div className="d-flex flex-wrap justify-content-between align-items-start ">
                        <div className="individual-graph thrust-chart-area-left">
                            <p>Thrust-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="1.41">
                                <AreaChart width={300} height={240} data={thrustFiltered}
                                    margin={{ top: 5, right: 20, left: 1, bottom: 5 }}
                                >
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={-5} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Thrust (N)', angle: -90, position: 'insideLeft' }} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="Thrust" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="thrust-chart-area-right d-flex flex-wrap">
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <p>Exhaust Velocity-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="2.09">
                                    <LineChart width={300} height={240} data={exhaust_velocity} margin={{ top: 5, right: 30, bottom: 5, left: 1 }}>
                                        <Line type="monotone" dataKey="Exhaust Velocity" stroke="#8884d8" dot={false} />
                                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={-5} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Exhaust Velocity (m/s)', angle: -90, position: 'insideBottomLeft', offset: 10 }} />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <p>Pressure-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="2.09">
                                    <LineChart width={300} height={240} data={bottle_pressure} margin={{ top: 5, right: 30, bottom: 5, left: 1 }} >
                                        <Line type="monotone" dataKey="Pressure" stroke="#8884d8" dot={false} />
                                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={-5} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Pressure (kPa)', angle: -90, position: 'insideBottomLeft', offset: 10 }} />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <p>Mass-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="2.09">
                                    <AreaChart width={300} height={240} data={massChange}
                                        margin={{ top: 5, right: 30, left: 1, bottom: 5 }}>
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                                                <stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
                                            </linearGradient>
                                        </defs>
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={-5} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Mass (kg)', angle: -90, position: 'insideBottomLeft', offset: 10 }} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Area type="monotone" dataKey="Mass" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>


                        <div className="individual-graph mini-graph" style={{ flexBasis: '100%' }}>
                            <p>Acceleration-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="5">
                                <LineChart width={300} height={240} data={acceleration} margin={{ top: 5, right: 30, bottom: 5, left: 1 }}>
                                    <Line type="monotone" dataKey="Acceleration" stroke="#8884d8" dot={false} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={-5} position="insideBottom" />
                                    </XAxis>
                                    <ReferenceLine y={0} stroke="#ccc" />
                                    <YAxis label={{ value: 'Acceleration (m/s^2)', angle: -90, position: 'insideBottomLeft', offset: 10 }} />
                                    <Tooltip />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="individual-graph mini-graph" style={{ flexBasis: '32.5%' }}>
                            <p>Velocity-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="1">
                                <LineChart width={300} height={240} data={velocity} margin={{ top: 5, right: 30, bottom: 5, left: 0 }}>
                                    <Line type="monotone" dataKey="Velocity" stroke="#8884d8" dot={false} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" orientation="top" />
                                    <XAxis y={0} dataKey="time" >
                                        <Label value="time (s)" offset={-5} position="insideBottom" />
                                    </XAxis>
                                    <ReferenceLine y={0} stroke="#ccc" />
                                    <YAxis label={{ value: 'Velocity (m/s)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                    <Tooltip />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="individual-graph mini-graph" style={{ flexBasis: '32.5%' }}>
                            <p>Height-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="1">
                                <LineChart width={300} height={240} data={height} margin={{ top: 5, right: 30, bottom: 5, left: 0 }}>
                                    <Line type="monotone" dataKey="Height" stroke="#8884d8" dot={false} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={-5} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Height (m)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                    <Tooltip />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="individual-graph mini-graph" style={{ flexBasis: '32.5%' }}>
                            <p>Distance-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="1">
                                <LineChart width={300} height={240} data={distance} margin={{ top: 5, right: 30, bottom: 5, left: 0 }}>
                                    <Line type="monotone" dataKey="Distance" stroke="#8884d8" dot={false} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Distance (m)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                    <Tooltip />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div> */}

                    {clicked && <>
                        <div className="glass"></div>
                        <div className="glass-top">
                            <h1>Calculating...</h1>
                        </div>
                    </>}
                </div>

            </div>
            )
}

            export default Analysis
