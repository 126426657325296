import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./School-District.css";
import { CircularProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useEffect, useState } from "react";
import {
  createStudent,
  deleteStudent,
  getAllStudentbyTeacher,
  getTeacherDetails,
} from "./api";
import { useParams, Link } from "react-router-dom";
import CustomModal from "../Modal/CusModal";
import { Delete } from "@material-ui/icons";
import { useRecoilValue } from "recoil";
import { userState } from "../../Atom";

export default function Class() {
  const [loading, setLoading] = useState(false);
  const [loginId, setloginId] = useState("");
  const [password, setPassword] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [teacherDetails, setteacherDetails] = useState({});
  const [students, setstudents] = useState([]);
  const {token} = useRecoilValue(userState)
  useEffect(() => {
    getTeacherData();
  }, [0]);
  let params = useParams();
  let { districtId, schoolid, id } = params;

  const getTeacherData = async () => {
    let ids = { did: districtId, sid: schoolid, teacherid: id };
    let res = await getTeacherDetails(ids, token);
    
    let res1 = await getAllStudentbyTeacher(ids, token);
    if (res.error) {
      alert("Something went wrong");
    } else {
      // console.log(res1);
      setstudents(res1.res.data.data.students);
       setteacherDetails(res.res.data.data.teacher);
    }
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const addStudent = async () => {
    let ids = { did: districtId, sid: schoolid, tid: id };
    let data = { loginId: loginId, password: password };
    // console.log(ids);
    let res = await createStudent(ids, data, token);
    if (res.error) {
      alert("Error");
    } else {
      alert("Success");
      getTeacherData();
    }
  };

  const deleteHandler = async (studentId) => {
    let ids = { did: districtId, sid: schoolid, tid: id, studentId: studentId };
    let res = await deleteStudent(ids, token);
    alert("Delete");
    getTeacherData();
  };

  return (
    <section id="hero-school ">
      <CustomModal handleClose={handleCloseAddModal} show={showAddModal}>
        <section id="hero-Msd">
          <div className="row main-div-modal">
            <div className="col-md-6 m-auto center-div text-center">
              <div className="p-2 center-div">
                <h2>
                  <button
                    onClick={handleCloseAddModal}
                    className="btn close-btn  rounded-circle float-right"
                  >
                    <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#011627"/>
                      <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </h2>
                <div className="mt-3">
                  <h6>Add Student</h6>
                </div>
                <div className="col-md-7 text-left col-9 m-auto">
                  <div className="form-group mt-4   p-20">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Login Id"
                      onChange={(e) => setloginId(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-4   p-20">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="cta-btn"
                      onClick={addStudent}
                    >
                      {loading ? <CircularProgress /> : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CustomModal>

      <div>
        <NavBar />
        <div className="dashboard-heading ml-4" style={{ marginRight: "20px" }}>
          <h2 style={{textTransform:'capitalize'}}>
            {teacherDetails.fName} {teacherDetails.lName}
            <button
              className="btn btn-add-device float-right"
              onClick={handleShowAddModal}
            >
              <i className="fas fa-plus-circle"></i> {"  "}
              <span> Add Student</span>
            </button>
          </h2>
        </div>

        <div>
            <table className="table">
              <thead>
                <tr>
                  <th> Id</th>
                  <th>LoginId</th>
                  <th>Email</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {students.map((item, index) => {
                  return (
                    <tr key={index} hover role="checkbox" tabIndex={-1}>
                      <td className="">{item._id}</td>
                      <td className="">
                        {item.loginId}
                      </td>{" "}
                      <td className="">____</td>{" "}
                      <td className="">
                        <Link onClick={() => deleteHandler(item._id)}>
                          <Delete /> {"  "}
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
        </div>
      </div>
    </section>
  );
}
