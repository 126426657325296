import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./Devices.css";
import DashboardStats from "../Dashboard/DashboardStats";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import {
  getAllDevice,
  createDevice,
  getDeviceDetails,
  editDevice,
} from "./api";
import { useEffect, useState } from "react";
import CustomModal from "../Modal/CusModal";
import { CircularProgress } from "@material-ui/core";

export default function Device() {
  const [show, setShow] = useState(false);
  const [editMode, seteditMode] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [name, setName] = useState("");
  const [editName, setEditName] = useState("");
  const [activeId, setactiveId] = useState("");

  const editHandler = async (e) => {
    e.preventDefault();
    let dataToSend = { name: editName };
    let res = await editDevice(activeId, dataToSend);
    if (res.error) {
      alert("error");
    } else {
      setactiveId("");
      setEditName("");
      handleCloseAddModal();
      getDevice();
      //showModal(true)
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let res = await createDevice({ name: name });
    if (res.error) {
      alert("error");
      //setColor("red");
      setLoading(false);
    } else {
      setLoading(false);

      getDevice();
      handleCloseAddModal();
      //showModal(true)
    }
  };

  const getDeviceDetail = async (activeId) => {
    setactiveId(activeId);
    handleShowAddModal();
    let res = await getDeviceDetails(activeId);
    if (res.error) {
      alert("error");
    } else {
      setEditName(res.res.data.data.device.name);
      // console.log(res.res.data.data);
    }
  };

  useEffect(() => {
    getDevice();
  }, [0]);

  const getDevice = async () => {
    let res = await getAllDevice();
    if (res.error) {
      alert("error");
    } else {
      // console.log(res.res.data.data);
      setDevices(res.res.data.data.devices);
    }
  };


  const handleCloseAddModal = () => {
    // console.log(show);
    setShow(false);
    seteditMode(true);
  };

  const handleShowAddModal = () => {
    setShow(true);
  };


  const handleCloseEditModal = () => {
    // console.log(show);
    setShow(false);
    seteditMode(false);
  };

  return (
    <section id="hero-school ">

      <CustomModal handleClose={handleCloseAddModal} show={show}>
        <section id="hero-Msd">
          <div className="row main-div">
            <div className="col-md-6 m-auto center-div text-center">
            
            
              <div className="p-2">
                <h2>
                  <button onClick={handleCloseAddModal} className="btn close-btn  rounded-circle float-right">
                    <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#011627"/>
                      <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </h2>

                  <>
                    <div className="mt-3">
                      <h6>Edit Device</h6>
                    </div>
                    <div className="col-md-9 text-left  col-9 m-auto">
                      <div className="form-group mt-4   p-20">
                        <label>Device Name</label>
                        <input
                          value={editName}
                          type="text"
                          className="form-control"
                          placeholder="Device ID"
                          onChange={(e) => setEditName(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-4   p-20">
                        <h3>New Fields After Client Feedback</h3>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                      <div className="form-group p-20">
                        <button
                          onClick={editHandler}
                          type="submit"
                          className="cta-btn"
                        >
                          {loading ? <CircularProgress /> : "Edit"}
                        </button>
                      </div>
                    </div>
                  </>
                
              </div>



            </div>
          </div>
        </section>
      </CustomModal>
      <NavBar />
      <DashboardStats/>




      <div >   
      <h2 className="ml-4">All Devices List</h2>
        <>
            <table className="table">
              <TableHead>
                <TableRow>
                  <TableCell>Device Name</TableCell>
                  {/* <TableCell>Other Details</TableCell>
                  <TableCell>Avaibility</TableCell>
                  <TableCell>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {devices.map((item, index) => {
                  return (
                    <TableRow hover key={index} role="checkbox" tabIndex={-1}>
                      {/* <TableCell className="table-data">{item._id}</TableCell> */}
                      <TableCell className="table-data">
                        {item.name}
                      </TableCell>{" "}
                      {/* <TableCell className="table-data">Yes</TableCell> */}
                      {/* <TableCell className="table-data">
                      
                        <span onClick={() => { getDeviceDetail(item._id)}}>
                          <i className="fas fa-edit icon-size-20"></i>
                        </span>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </table>
        </>
      </div>


    </section>
  );
}
