import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./School-District.css";
import { CircularProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useEffect, useState, useRef } from "react";
import Tooltip from '@mui/material/Tooltip';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Group_332 from "../../assets/images/Group_332.png";
import Group_299 from "../../assets/images/Group_299.png";
import Group_305 from "../../assets/images/Group_305.png";
import key from "../../assets/images/pass2.png";
import CloseIcon from '@mui/icons-material/Close';
import {
  createStudent,
  deleteStudent,
  getAllStudentbyTeacher,
  getTeacherDetails,
  uploadFileForStudentCreation, editStudent,classInfo
} from "./api";
import { useParams, Link } from "react-router-dom";
import CustomModal from "../Modal/CusModal";
import { Delete, Edit } from "@material-ui/icons";
import Popup from "../popup/Popup";
import getCookie, { setTeacherCookie, teacherState } from "../../Atom";
import { useRecoilState, useRecoilValue } from "recoil";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom";
import KeyIcon from '@mui/icons-material/Key';
import studentCSV from '../../assets/csv/studentCSV.csv';

import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SchoolClass(props) {
  const [open, setOpen] = useState(false);
  const [ myUser , setmyUser ] = useState(teacherState);
  const history = useHistory();
    let search = window.location.search;
    let paramse = new URLSearchParams(search);

    const [snackBarShow, setSnackBarShow] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState('');
    const [editLoginIdState, setEditLoginIdState] = useState('');
    const [headerErrorCount, setHeaderErrorCount] = useState([]);

    const [oldName, setoldName] = useState(false);
    const [newID, setNewID] = useState('');
    const [successOpen, setSuccessOpen] = useState(false);
    const [successMode,setSuccessMode] = useState(0);

    const logoutHandler=async()=>{
      setTimeout(()=>{
        // setmyUser({
        //   fName: null,
        //   userId: null,
        //   token: null,
        //   lName: null,
        // })
        setTeacherCookie({
          fName: null,
          userId: null,
          token: null,
          lName: null,
        })
        let hostname= window.location.host;
        // console.log(hostname,window.location.protocol)
        window.location.assign(`${window.location.protocol}//${hostname}/teachers`)   
      })  
    }

    
  const feedBackMsg =(snackShow,snackSeverity,snackMessage)=>{
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    
    feedBackMsg(false,'','');
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPassword('');
    setloginId('');
    setStudId('');
    setEditState(false);
    setDeleteState(false);
    setLoading(false);
    setloginId('');
    setPassword('');
    setUploadOpen(false);
    setEditLoginIdState(false);
  };
  const newBulkModal = useRef()
  // const {token} = useRecoilValue(teacherState)
  const [loading, setLoading] = useState(false);
  const [loginId, setloginId] = useState("");
  const [password, setPassword] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [students, setStudents] = useState([]);
  const [clicked, setclicked] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [studId, setStudId] = useState('');
  const [fileValid, setFileValid] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [duplicateIds, setDuplicateIds] = useState([]);
  const [editState, setEditState] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [noOfPages, setNoOfPages] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [paged, setPaged] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [pageLimit, setPageLimit] = useState(25);

  let params = useParams();
  let id  = params.sclassId;
  let classID= params.classID;
  const [classDetails, setClassDetails] = useState(null);
  // const csvtojson = require('csvtojson');
  let arr=[];



  useEffect(() => {
    const _cookie = getCookie('recoil-persist')
    const user = _cookie ? JSON.parse(_cookie)['teacher_v1'] : {}
    setmyUser({ ...user, 'page': "className" });
    props.page('page')
    let urlPage=parseInt(paramse.get('page'));
        // console.log(urlPage)
        if(parseInt(paramse.get('page'))){
            // console.log('lol1')
            history.push({
                pathname: `/myclass/${id}/${classID}`,
                search: '?page=' + urlPage
              })
              setPaged(urlPage)
              getTeacherData(urlPage,pageLimit,user)
        }else{
            // console.log('lol')
            setPaged(0)
            history.push({
                pathname: `/myclass/${id}/${classID}`,
                search: '?page=0'
              })
              getTeacherData(0,pageLimit,user)
        }
    // setmyUser({...myUser,'page':"className"});
    
  }, [1]);


  
  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setloginId("");
    setPassword("");
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
    setloginId("");
    setPassword("");

  };



  
  const getTeacherData = async (pages,limits,user=myUser) => {
    setLoading(true)
    // console.log(id);
    let res1= await classInfo(user?.token,id);
    let res = await getAllStudentbyTeacher(id, user?.token,pages,limits);
    if (res.error) {
      // alert("Error fetching Students. Try Again");
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
    } else {
      setClassDetails(res1.res.data.data.sclass);
      setStudents(res.res.data.data.students);
      
      setLoading(false)
      if(res.res.data.data.students.length==0){
        alert('No Students present in this class')
      }
      let divisor=parseInt(res.res.data.data.totalcount/limits);
            let remainder= (res.res.data.data.totalcount%limits==0)?0:1;
            setNoOfPages(divisor+remainder)
            setCurrPage(pages);
    }

  };

  const editingStudent = async () =>{
    let res;
    if(editLoginIdState){
      res= await editStudent(id,studId,{loginId:loginId},myUser?.token);
      setSuccessMode(2);
      setNewID(loginId.trim())
    }else{
      res= await editStudent(id,studId,{password:password},myUser?.token);
      setSuccessMode(3);
    }
    
    // console.log(res);
    if (res.error) {
      // alert('Error editing student. Try again');
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
    } else {
      feedBackMsg(true,'success',res.res.data.msg);
      // setSuccessOpen(true);
      
    }
    handleClose();
    getTeacherData(paged,pageLimit)
  }


  const addStudent = async () => {
    setLoading(true);
    setoldName(loginId.trim());
    let data = { loginId: loginId.trim(), password: password.trim(), classID:classID };
    // console.log(data);
    let res = await createStudent(id, data, myUser?.token);
    // console.log(res);
    if (res.error) {
      // alert((res.data.msg!=undefined)?res.data.msg:'Error adding Student. Try Again');
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
      handleCloseAddModal();
      handleClose();
    } else {
      feedBackMsg(true,'success',res.res.data.msg);
      // setSuccessMode(1);
      // setSuccessOpen(true);
      handleCloseAddModal();
      getTeacherData(paged,pageLimit);
      handleClose();
    }
    // handleClose();
  
  };

  const handleBulk = async (e) => {
    e.preventDefault()
    if(!uploadedFile){
      return
    }
    setclicked(true)
    const formData = new FormData();
    formData.append(
      "file",
      uploadedFile,
      "file"
    );
    // console.log(formData,uploadedFile)
    const res = await uploadFileForStudentCreation(id,classID,formData, myUser?.token);
    // console.log(res)
    setclicked(false);
    if(res.error){
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
      // alert(res.data);
      // handleClose();
    }else {
    if(res.res.data.data.duplicateLoginIds.length>0 || res.res.data.data.headerErrorCount.length>0){
      let duplicateIds=res.res.data.data.duplicateLoginIds;
      setShowDuplicate(true)
      setDuplicateIds(duplicateIds);
      setHeaderErrorCount(res.res.data.data.headerErrorCount);
      // handleClose();
    }
    // setclicked(false)
    if(res.res.data.data.studentsAdded.length>0){
      feedBackMsg(true,'success',res.res.data.msg);
    }
    // newBulkModal.current.close();

    getTeacherData(paged,pageLimit)
    }
    handleClose();
    // newBulkModal.current.close();
    
  }


  const deleteHandler = async (studentId) => {
    let res = await deleteStudent(id, studentId, myUser?.token);
    if (res.error) {
      // alert("Error deleting student. Try again");
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
      handleClose();
    } else {
      feedBackMsg(true,'success',res.res.data.msg);
      // setSuccessOpen(true);
      // setSuccessMode(4);
      getTeacherData(paged,pageLimit);
      handleClose();
    }
    handleCloseAddModal();
    // handleClose();
  };
  const handleFile = e => {
    // console.log(e.target.files[0]);
    if(e.target.files[0].name.includes('.csv')){
      const file= e.target.files;
      setUploadedFile(e.target.files[0]);
      setFileValid(true);
    }
    else{
      alert('Please enter a csv file');
      setFileValid(false);
    }
  }

  const OpenDialogPassword = (ids,loginId)=>{
    setloginId(loginId);
    setStudId(ids);
    handleClickOpen();
    setEditState(true);
  }

  const OpenDialogLoginId = (ids,loginId)=>{
    setloginId(loginId);
    setStudId(ids);
    handleClickOpen();
    // setEditState(true);
    setEditLoginIdState(true);
  }

  const disableFormPassword = () =>{
    if(password.trim().length<7)return true; else return false;
  }

  const handleClose1 = ()=>{
    setShowDuplicate(false);
    // handleClose();
  }

  const handleChangePage = (event, newPage) => {
    setPaged(event.selected);       
    getTeacherData(event.selected,pageLimit);
    history.push({
        pathname: `/myclass/${id}/${classID}`,
        search: '?page=' + event.selected
      })
  };

  const closeDialog = ()=>{
    setoldName('');
    setSuccessOpen(false);
    setSuccessMode(0);
  }

  return (
    <main style={{position:"absolute",width:"100%",top:"20%"}}>

<div class="container px-4 pt-3">
			<div class="row table-section">
				<div class="w-100 pe-0">
				<div class="ps-60">
					<div class="breadcrumb-menu py-2">
					<Link to="/">Teacher Panel </Link><i class="fas fa-angle-double-right"></i><Link to="/myclasses">Class </Link><i class="fas fa-angle-double-right"></i>{classDetails?.name}
				</div>
				<div class="text-blue maintext"><h2>{classDetails?.name}</h2></div>
				<div class="row">
					<div class="col-md-6">
						<div class="card-header px-0"><h3>Manage {classDetails?.name} details here</h3></div>
					</div>
          <div class="col-md-6 text-end d-flex justify-content-end">
            <button type="button" onClick={()=>{
              setUploadOpen(true);
              }} style={{width:"80%"}} className="btn btn-primary add-btn px-3 btn-add-size fonted fontAdjust-500" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Upload Excel<img className="" style={{height:"25px", width:"auto",float:"right"}} src={Group_305}/></button>
              <span style={{width:'10%'}}></span>
						<a onClick={handleShowAddModal} data-bs-toggle="modal" className="btn btn-primary add-btn px-3 btn-add-size fonted fontAdjust-500" data-bs-target="#exampleModalans" data-bs-whatever="@mdo" style={{width:"80%"}}>Add New Student<img style={{height:"25px", width:"auto",float:"right"}} src={Group_305}/></a>
					
					
				  </div>
				</div>
        <br/>
        <div className="d-flex justify-content-end align-items-end">
                
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={noOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handleChangePage}
                    containerClassName="pagination"
                    activeClassName="activePage active"
                    forcePage={currPage}/>

            </div>
			</div>
				<div class=" ps-3 pb-5 class-table class-table1">
				
				<div class="table-section student-tab">
					<div class=" lower-section p-5">
						<table class="table w-100" style={{backgroundColor:'transparent'}}>
					  	<thead class="mb-5">
						    <tr>
						      <th scope="col" class="col1 pb-4 fonted" style={{backgroundColor:'transparent'}}>Sr. No.</th>
						      <th scope="col" class="col2 pb-4 text-center fonted" style={{backgroundColor:'transparent'}}>Student ID</th>
						      <th scope="col" class="text-end pe-37 col3 pb-4 fonted" style={{backgroundColor:'transparent'}}>Manage student list</th>
						    </tr>
					  	</thead>
					  	<tbody class="table-body" style={{backgroundColor:'transparent'}}>
                {students.length>0 && students.map((item, index) => {
                  return (
						    <tr className="noBord">
						      	<td class="text-end fonted">{index+1}</td>
						      	<td> <div class="d-flex class-btn justify-content-center fonted  text-purple"><a>{item?.loginId}</a></div></td>
						      	<td>
						      	{/* <div class="d-flex class-btn justify-content-end">
							      	<div>
							      		<a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3" data-bs-whatever="@mdo">Edit<img src={Group_332} class="ps-3"/></a>
							      	</div>
							      	<div class="px-3">
							      		<a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal-del" data-bs-whatever="@mdo">Delete<img src={Group_299} class="ps-3"/></a>
							      	</div>
							    </div> */}
                  <div className="d-flex class-btn ">
							      	<div className="px-3">
                      <Tooltip title="Edit LoginId" placement="top">
                        <Link onClick={()=>{OpenDialogLoginId(item._id,item.loginId);setoldName(item?.loginId)}}>
							      		<img src={Group_332} className="ps-3"/>
                        </Link>
                        </Tooltip>
							      	</div>
                      <div className="px-3">
                      <Tooltip title="Change Password" placement="top">
                        <Link onClick={()=>{OpenDialogPassword(item._id,item.loginId);;setoldName(item?.loginId)}}>
							      		<img src={key} className="ps-3"/>
                        </Link>
                        </Tooltip>
							      	</div>
							      	<div className="px-3">
                      <Tooltip title="Delete" placement="top">
                        <Link onClick={() =>{ 
                          setoldName(item?.loginId)
                          setloginId(item.loginId)
                          setOpen(true);
                          setStudId(item._id);
                          setDeleteState(true)}
                          }>
							      		<img src={Group_299} className="ps-3"/>
                        </Link>
                        </Tooltip>
							      	</div>
							    </div>
						      	</td>
						    </tr>
                  )})}
					  </tbody>
					</table>
					</div>
					
						
					
					
				</div>
			</div>
				</div>
				
			</div>
			{/* <div class="container px-4 pt-0">
	<div class="d-flex justify-content-end">
		<nav aria-label="Page navigation example ">
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Previous">
        <i class="fa fa-caret-left" aria-hidden="true" style={{fontSize: "17px"}}></i>
      </a>
    </li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link active" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item"><a class="page-link" href="#">4</a></li>
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Next">
        <i class="fa fa-caret-right" aria-hidden="true" style="font-size: 17px;"></i>
      </a>
    </li>
  </ul>
</nav>
	</div>
</div> */}
			
		</div>
		

    <Dialog open={open} onClose={handleClose} PaperProps={{
    style: {
      backgroundColor: "transparent",
      boxShadow: "none"
    },
  }}
 aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modal-dialog modal-dialog-centered modal-dialog-scrollable teacher-div">
  {/* Hello */}
{/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable "> */}
    <div className="modal-content" style={{width:'400px'}}>
      <div className="modal-header-n d-flex justify-content-end">
        
        <button type="button" onClick={handleClose} class="btn-close" data-bs-dismiss="modal" aria-label="Close"><CloseIcon/></button>
      </div>
      {editState?<>
       <form>
      <div class="modal-body px-4 pb-2">
       
          <div class="mb-0">
            <label for="recipient-name" className="col-form-label ps-4">Change Password for {loginId}</label>
            <input type="text" className="form-control1 pt-3 w-100" value={password}
            onChange={(e)=>{setPassword(e.target.value)}}
            onBlur={(e)=>{setPassword(e.target.value.trim())}}/>
          </div>
      </div>
      <div class="modal-footer px-4">
       
        <button type="button" disabled={disableFormPassword()} onClick={editingStudent} class="btn btn-pitch">Update</button>
        {/* <button type="button" class="btn text-purple" onClick={handleClose} data-bs-dismiss="modal"><span class="text-purple">Cancel</span></button> */}
      </div>
      </form>
      </>:(editLoginIdState)?<>
      <form>
      <div class="modal-body px-4 pb-2">
       
          <div class="mb-0">
            <label for="recipient-name" className="col-form-label ps-4">Edit Student Id</label>
            <input type="text" className="form-control1 pt-3 w-100"  value={loginId}
            onChange={(e)=>{setloginId(e.target.value)}}
            onBlur={(e)=>{setloginId(e.target.value.trim())}}/>
          </div>
      </div>
      <div class="modal-footer px-4">
       
        <button type="button" onClick={editingStudent} disabled={loginId.trim().length<1} class="btn btn-pitch">Update</button>
        {/* <button type="button" class="btn text-purple" onClick={handleClose} data-bs-dismiss="modal"><span class="text-purple">Cancel</span></button> */}
      </div>
      </form> 
      </>
      :(deleteState)?<>
      <form >
      <div class="modal-body px-5 py-0">
       
          <div class="mb-0">
            <h5 class="modal-title1 h5" id="exampleModalLabel">Delete Student</h5>
            <label for="recipient-name" class="col-form-label1">Are you sure you want to delete {loginId}?</label>
          </div>
      </div>
      <div class="modal-footer1 py-4 st-btn px-5 d-flex">
        <button type="button" onClick={()=>deleteHandler(studId)} class="btn btn-pitch" name="btn"  id="submitBtn" data-bs-toggle="modal" data-bs-target="#confirm-submit">Delete</button>
        <button type="button" class="btn text-purple" onClick={handleClose} data-bs-dismiss="modal"><span class="text-purple">Cancel</span></button>
      </div>
      </form>
      </>:null}
    </div>
  {/* </div>
</div> */}
</Dialog>

<Dialog onClose={handleCloseAddModal} open={showAddModal} PaperProps={{
    style: {
      backgroundColor: "transparent",
      boxShadow: "none"
    },
  }}
 aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"  className="modal-dialog modal-dialog-centered modal-dialog-scrollable teacher-div">

    <div className="modal-content" style={{width:'400px'}}>
      <div className="modal-header-n d-flex justify-content-end">
        
        <button type="button" onClick={handleCloseAddModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"><CloseIcon/></button>
      </div>
       <form>
      
      <div className="modal-body px-5">
       
       <div className="mb-0">
         <label for="recipient-name" className="col-form-label">Add Student </label>
         {/* <br/> */}
         <input type="text" className="form-control1 pt-3 w-100" id="recipient-name" value={loginId}
                      placeholder="Login Id"
                      onChange={(e) => setloginId(e.target.value)}
                      onBlur={(e) => setloginId(e.target.value.trim())} />
                      <br/>
                      <br/>
          <input type="text" className="form-control1 pt-3 w-100"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      onBlur={(e) => setPassword(e.target.value.trim())} 
                    />
        <br />
                    <small style={{color:'red'}}>Password should be upto 8 characters or more</small>
       </div>
     
   </div>
      <div class="modal-footer px-4 ">
       
        <button type="button" onClick={addStudent} disabled={loginId.trim().length<1 || password.trim().length<7} class="btn btn-pitch">Create </button>
      </div>
      </form>
    </div>

</Dialog>

<Dialog open={uploadOpen} onClose={handleClose} PaperProps={{
    style: {
      backgroundColor: "transparent",
      boxShadow: "none"
    },
  }}
 aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modal-dialog modal-dialog-centered modal-dialog-scrollable teacher-div">

    <div className="modal-content" style={{width:'400px'}}>
      <div className="modal-header-n d-flex justify-content-end">
        
        <button type="button" onClick={handleClose} class="btn-close" data-bs-dismiss="modal" aria-label="Close"><CloseIcon/></button>
      </div>
       <form onSubmit={e=>handleBulk(e)}>
      <div class="modal-body px-4 pb-2">
       
          <div class="">
            <label for="recipient-name" className="col-form-label ps-4">Add Students from Excel</label>
            <input type="file" className="modal-form-control px-4" required onChange={e=>{handleFile(e)}}/>
          </div>
          <a className="a" href={studentCSV} target="_blank" style={{color:'blue',fontSize:'15px'}}>Download Sample File.</a>
      </div>
       
      <div class="modal-footer px-4">
      <button type="submit"  disabled={!fileValid} class="btn btn-pitch">Upload</button>
      {/* <button type="button" class="btn text-purple" onClick={handleClose} data-bs-dismiss="modal"><span class="text-purple">Cancel</span></button> */}
        {/* <button type="button" class="btn text-purple" onClick={handleClose}><span class="text-purple">Close</span></button> */}
      </div>
      </form>
    </div>


      </Dialog>
      <Dialog scroll="paper" open={showDuplicate} onClose={handleClose1} fullWidth>
  <DialogTitle>Duplicate LoginIds</DialogTitle>
  <DialogContent>
    {duplicateIds.length>0?<>
    <h6 className="h6">These LoginIds are already present. Try with another LoginIds.</h6>
    <br/>
    {duplicateIds.map((e,i)=>{
      return <div>{i+1}. {e}</div>
    })}
    <br /></>:null}
    {headerErrorCount.length>0?<>
    <h6 className="h6">Password is missing from these LoginIds</h6>
    <br/>
    {headerErrorCount.map((e,i)=>{
      return <div>{i+1}. {e}</div>
    })}</>:null}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose1}>Close</Button>
  </DialogActions>
</Dialog>

      <Dialog
        onClose={closeDialog}
        open={successOpen}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none"
          },
        }}
        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modal-dialog modal-dialog-centered teacher-div">
        
        <div class="modal-content" style={{width:'400px'}}>
            <div class="modal-header-n ms-auto pt-3 pb-0 d-flex justify-content-end">
            <button type="button" class="btn-close" onClick={closeDialog} data-bs-dismiss="modal" aria-label="Close"><CloseIcon /></button>
            </div>
            <div class="modal-body px-5">
              <div class="mb-0">
                {(successMode==1)?<>
                  <label for="recipient-name" class="col-form-label"><span>{oldName}</span>: Add Student ID <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                  <p class="submitted py-3 p">Student ID {oldName} successfully added to class {classDetails?.name}</p>
                </>
                :
                (successMode==2)?<>
                <label for="recipient-name" class="col-form-label"><span>{oldName}</span>: Edit Student ID <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                <p class="submitted py-3 p">Student ID successfully changed from "{oldName}" to "{newID}"</p>
                </>:(successMode==3)?<>
                  <label for="recipient-name" class="col-form-label"><span>{oldName}</span>: Edit Student Password <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                  <p class="submitted py-3 p">Student {oldName} password successfully changed</p>
                </>:<>
                <label for="recipient-name" class="col-form-label"><span>{oldName}</span>: Delete <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                <p class="submitted py-3 p">{oldName} successfully deleted</p>
                </>}
              </div>
            </div>
          </div>
      </Dialog>

                <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                    <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar>

	</main>
  );
}

{/* <div className="container-teacher px-4 pt-3">
			<div className="row table-section">
				<div className="col-lg-9c pe-0">
				<div className="ps-60">
					<div className="breadcrumb-menu py-2">
					<Link to={'/'}>Teacher Panel</Link><i className="fas fa-angle-double-right"></i><Link to={'/myclasses'}>Class </Link><i className="fas fa-angle-double-right"></i>{classDetails?.name}
				</div>
				<div className="text-blue maintext"><h2 className="h2">{classDetails?.name}</h2></div>
				<div className="">
					<div className="">
						<div className="card-header px-0"><h3 className="h3">Manage {classDetails?.name} details here</h3></div>
					</div>
				</div>
			</div>
      <div className="d-flex justify-content-end align-items-end">
                
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={noOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handleChangePage}
                    containerClassName="pagination"
                    activeClassName="activePage active"
                    forcePage={currPage}/>

            </div>

				<div className=" ps-3 pb-5 class-table class-table1" >
				
				<div className="table-section student-tab" >
					<div className=" lower-section p-5" >
						<table className="table" style={{backgroundColor:'transparent'}}>
					  	<thead className="mb-5">
						    <tr>
						      <th scope="col" className="col1 pb-4 fonted" style={{backgroundColor:'transparent'}}>Sr. No.</th>
						      <th scope="col" className="col2 pb-4 text-center fonted" style={{backgroundColor:'transparent'}}>Student ID</th>
						      <th scope="col" className="text-end pe-37 col3 pb-4 fonted" style={{backgroundColor:'transparent'}}>Manage student list</th>
						    </tr>
					  	</thead>
					  	<tbody className="table-body" style={{backgroundColor:'transparent'}}>
              {students.length>0 && students.map((item, index) => {
                  return (
						    <tr className="noBord">
						      	<td className="text-end">{index+1}</td>
						      	<td> <div className="d-flex className-btn justify-content-center  text-purple">{item.loginId}</div></td>
						      	<td>
						      	<div className="d-flex className-btn ">
							      	<div className="px-3">
                      <Tooltip title="Edit LoginId" placement="top">
                        <Link onClick={()=>OpenDialogLoginId(item._id,item.loginId)}>
							      		<img src={Group_332} className="ps-3"/>
                        </Link>
                        </Tooltip>
							      	</div>
                      <div className="px-3">
                      <Tooltip title="Change Password" placement="top">
                        <Link onClick={()=>OpenDialogPassword(item._id,item.loginId)}>
							      		<img src={key} className="ps-3"/>
                        </Link>
                        </Tooltip>
							      	</div>
							      	<div className="px-3">
                      <Tooltip title="Delete" placement="top">
                        <Link onClick={() =>{ 
                          
                          setloginId(item.loginId)
                          setOpen(true);
                          setStudId(item._id);
                          setDeleteState(true)}
                          }>
							      		<img src={Group_299} className="ps-3"/>
                        </Link>
                        </Tooltip>
							      	</div>
							    </div>
						      	</td>
						    </tr>
						    
                  )})}
					  </tbody>
					</table>
					</div>
					
						
					
					
				</div>
			</div>
				</div>
				<div className="col-lg-3c pe-60 pt-5 text-end ps-0">
					<div className="pt-4">
						<button type="button" onClick={()=>{
              setUploadOpen(true);
              }} style={{width:"80%"}} className="btn btn-primary add-btn px-3 btn-add-size" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Upload Excel<img className="" style={{height:"25px", width:"auto",float:"right"}} src={Group_305}/></button><br/><br/>
						<a onClick={handleShowAddModal} data-bs-toggle="modal" className="btn btn-primary add-btn px-3 btn-add-size a" data-bs-target="#exampleModalans" data-bs-whatever="@mdo" style={{width:"80%"}}>Add New Student<img style={{height:"25px", width:"auto",float:"right"}} src={Group_305}/></a>
					
					</div>
				</div>
			</div>
			
			
		</div> */}