import { Link } from '@material-ui/core'
import ResetForm from './ResetForm'
import '../Auth.css'
export default function ForgotPasswordScreen() {
  return (
    <div>
      <div className='authentication-section'>
        <div className=''>
          <div className='main-form row '>
            <div className='col-lg-6 col-md-10 m-auto pb-10'>
          <div className='auth-form  mt-10  '>
          <div className='auth-heading'>

      <h2>Forgot Password
      </h2>
   
        </div>
          <ResetForm/>
          </div>
            </div>
            <div className='col-md-0 col-lg-6 svg-div '>
          <div className='ptb-100 '  >
            <img className='img-height-100' src='assets/loginVector.svg'/>
        
          </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  )
}
