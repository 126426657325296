import { BrowserRouter as Router, Switch, Route, useHistory, Redirect } from "react-router-dom";
import SideBar from './components/Sidebar/SideBar'
import DashBoard from "./components/Dashboard/DashBoard";
import School from './components/School-Districts/School'
import LoginScreen from "./components/Auth/LoginPage/LoginScreen";
import { useRecoilState, useRecoilValue } from "recoil";
import getCookie, { districtAdminState } from './Atom'
import AllSchools from "./components/School-Districts/AllSchools";
import Teacher from "./components/School-Districts/Teacher";
import Devices from "./components/Devices/Devices"
import Experiment from "./components/Experiments/Experiment"
import ForgotPasswordScreen from "./components/Auth/ForgotPasswordPage/ForgotPasswordScreen"
import Analysis from "./components/Analysis/Analysis";
import User from "./components/User/user";
import { useState } from "react";
function SchoolDistrictRouter() {
  const _cookie = getCookie('recoil-persist')
  const user = JSON.parse(_cookie)?.district_admin_v1
  const [myUser, setmyUser] =  useState(user)
  // const { token } = useRecoilValue(districtAdminState)
  // const [myUser, setmyUser] = useRecoilState(districtAdminState);
  //   let history=useHistory()
  //  const [myUser,setmyUser]=useRecoilState(userState)

  function loginOpen(){
    // console.log('This was called')
    const _cookie = getCookie('recoil-persist')
    const user = JSON.parse(_cookie)['district_admin_v1']
    setmyUser(user)
  }

  return (
    <div className="district">
      <Router basename="/school-district">
        {myUser?.token ?
          <div style={{ minHeight: '100vh', background: '#f8f8fb' }}>
            <div style={{ minHeight: '100vh', display:'flex' }}>
              <div style={{display: 'flex', flexDirection: 'column', cursor: 'pointer', padding: '0', backgroundColor:'#fff', width:'100%',maxWidth: '300px'}}>
                <SideBar />
              </div>
              <div className="dashboard-div min-height" style={{width: '100%',maxWidth:'100%', paddingLeft: '30px', paddingRight: '30px',overflow:'scroll'}}>

                <Switch>
                  <Route path="/" exact component={DashBoard} />
                  <Route path="/devices" exact component={Devices} />
                  <Route path="/users" exact component={User} />
                  <Route path="/experiments" exact component={Experiment} />
                  <Route path="/experiments/:id" exact component={Analysis} />
                  <Route path="/schools/:id" exact component={School} />
                  <Route path="/schools" exact component={AllSchools} />
                  <Route path="/school/:schoolId/teacher/:teacherId" exact component={Teacher} />
                </Switch>
              </div>
            </div>
          </div> :
          <Switch>
            {/* <Route path="/invite"  component={AddSchoolDestrictAdmin} /> */}
            <Route path="/" exact 
            // component={LoginScreen} 
            render={props => (<LoginScreen location={loginOpen} />)} 
            />
            {/* <Redirect to="/"/>  */}
             {/* <Route path="/sign-up" exact component={SignupPage} />  */}
           <Route path="/forgot-password" component={ForgotPasswordScreen} />
          {/* <Route path="/new-password/:id" exact component={NewPasswordScreen} />  */}
          </Switch>
        }


      </Router>

    </div>
  );
}

export default SchoolDistrictRouter;
