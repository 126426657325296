import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
  import Home from "./student-build/components/Home/Home";
  
  
  function Student() {
  
  
  
    return (
      <div className="App">
        <Router basename="/">
                  <Switch>
                    <Route path="/"  component={Home} />
                  </Switch>
        </Router>
      </div>
    );
  }
  
  export default Student;
  