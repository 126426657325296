import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import './style.css'

const Popup = forwardRef(({children}, ref) => {
    const [show, setshow] = useState(false)
    
    const close = () => {
        setshow(false)
    }
    useImperativeHandle(ref,
        () => ({
            open(){
                setshow(true)
            },
            close(){
                setshow(false)
            }
        }),
        )
    return (
        <>
        {show && <div className="popup">
            <div className="form-body">
                {children}
                <button className="close-btn" onClick={close}>
                    <svg width="35" height="35" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="20" fill="#011627"/>
                        <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M27.0693 27.2427L12.9272 13.1005" stroke="white" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>}
        </>
    )
})

export default Popup
