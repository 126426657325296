import { useState } from "react"
import {  useHistory, useLocation } from "react-router";
import {CircularProgress} from '@material-ui/core'
export default function ResetForm() {
  const [password,setPassword]=useState("")
  const [cpassword,setCPassword]=useState("")
  const [loading,setLoading]=useState(false)
  const location=useLocation()
  const history=useHistory()
 // console.log(history)
const submitHandler=async(e)=>{
 
}

  return (  
     <form id='#authForm'  onSubmit={submitHandler}>
  <div className='col-md-8 m-auto col-sm-12  text-center'>
  <div className='form-group'>
  <label className='form-label'>
          New Password
        </label>
    <input
      type='text'
      className='form-control'
      placeholder='Password'
      onChange={(e)=>setPassword(e.target.value)}
    />
  </div>
  
<div className='form-group'>
<label className='form-label'>
          Confirm Password
        </label>
  <input
    type='text'
    className='form-control'
    placeholder='Confirm Password'   
    onChange={(e)=>setCPassword(e.target.value)}
  />
</div>
  <div className='form-group'>
  <button type='submit'  className='cta-btn'>
{loading?<CircularProgress/> : "Login" }     
  </button>
</div>

 </div>
</form>
)
}
