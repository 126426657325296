import axios from "axios";
import { baseUrl } from "../Base_url";

// const python_server = "https://3.128.255.65";
// const python_server = "http://127.0.0.1:8000";
const python_server = "https://experiments-api.spacetrek.com";

export const getThisExperimentDetails = async (token, expId) => {
    try {
        const res = await axios.get(`${baseUrl}/admin/experiment/${expId}?getThrust=true`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};

export const getAnalysedData = async (angle, dry_mass, para_area, expData) => {
    try {
        const res = await axios.post(`${python_server}/computations-xy`,{
            angle: angle,
            dry_mass: dry_mass,
            para_area: para_area,
            expData: expData
        },{
            headers: {"Content-Type": "application/json"}
        })
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true};
        return err;
    }
};