import { Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useEffect, useState } from "react";
import AddCustomModal from "./AddCustomModal";
import showModal from "./showModal";

export default function CustomModal({ handleClose, show, children }) {
  useEffect(() => {
    if (show) {
      AddCustomModal(true);
    } else {
      AddCustomModal(false);
    }
  }, [show]);
  return (
    <div id="addmodal" className="cusmodal__wrap">
      <div className="addmodel text-center">
        {children}
      </div>{" "}
    </div>
  );
}
