import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutoCompletes(props) {


    const getlable = (option) => {
        {

            if (props?.label === "School-District") {
                const x = props?.options?.find((item) => {
                    return item._id === option
                });
                return x?.name
            }
            else if (props.label === "School") {
                const x = props?.options?.find((item) => {
                    return item._id === option
                });
                return x?.name

            } else if (props?.label === "Teacher") {
                const x = props?.options?.find((item) => {
                    return item._id === option
                });
                return x?.fName + " " + x?.lName
            } else {
                return option
            }
        }
    }
    return (
        // <Autocomplete
        //     size="small"
        //     value={props?.value}
        //     options={props.options}
        //     // freeSolo={props?.freeSolo}
        //     autoHighlight
        //     isOptionEqualToValue={(option, value) => option?.name ? option?.name === value : option?.fName ? option?._id === value : option?.loginId === value}
        //     getOptionLabel={(option) => option?.name ? option?.name : option?.fName ? option?.fName : option?.loginId ? option?.loginId : option}
        //     onChange={(event, newValue) => {
        //         props.setValue(newValue);
        //     }}

        //     renderOption={(props, option) => (
        //         <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, display: 'flex !important', alignItems: 'center' }} {...props}>

        //             <p style={{margin: 0}}>{option?.name ? `${option?.name}` : option?.fName ? `${option?.fName}` : option?.loginId}</p>
        //             { !option?.name || !option.fName ? 
        //                 <p style={{margin: 0, fontSize: 12, color: '#bdbdbd'}}>&nbsp;({option?._id})</p> : null
        //             }
        //         </Box>
        //     )}
        //     renderInput={(params) => (
        //         <TextField
        //             label={null}
        //             required={props?.notR ? false : true}
        //             {...params}
        //             inputProps={{
        //                 ...params.inputProps,
        //                 autoComplete: 'new-password', // disable autocomplete and autofill
        //             }}
        //         />
        //     )}
        // />
        <Autocomplete
            fullWidth
            id="country-select-demo"
            size='small'
            options={props?.options}
            onChange={(event, newValue) => {
                props.setValue(newValue);
            }}
            value={props.value}
            autoHighlight

            isOptionEqualToValue={(option, value) => option?.name ? option?._id === value : option?.fName ? option?._id === value : option?.loginId === value}
            getOptionLabel={getlable}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, display: 'flex !important', alignItems: 'center' }} {...props}>

                    <p style={{ margin: 0 }}>{option?.name ? `${option?.name}` : option?.fName ? `${option?.fName}` : option?.loginId}</p>
                    {!option?.name || !option?.fName ?
                        <p style={{ margin: 0, fontSize: 12, color: '#bdbdbd' }}>&nbsp;({option?._id})</p> : null
                    }
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={""}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}