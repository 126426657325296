import React, {useState} from 'react'
import { useRecoilValue } from 'recoil'
import { teacherState } from '../../Atom'
import { changePass } from './api'

const styles={
    formBody:{
        backgroundColor: '#fff',
        padding: '10px',
        borderRadius: '10px'
    }
}
function ChangePassword() {
    const {token} = useRecoilValue(teacherState)
    const [pass, setpass] = useState("")
    const [confirmPass, setconfirmPass] = useState("")
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(pass !== confirmPass){
            alert("Password Mismatch!")
            return
        }
        if(pass.trim().length < 1){
            return 
        }
        let data = {
            "password": pass
        }
        const res = await changePass(data, token);
        if(res.res.status === "success"){
            alert(res.res.msg)
        }
        setpass("")
        setconfirmPass("")
    }
    return (
        <div className="d-flex justify-content-center item-align-center mt-5">
            <form className="form" style={styles.formBody} onSubmit={(e)=>handleSubmit(e)}>
                <p className='p' style={{fontWeight: 'bold'}}>Change Password</p>
                <div className="form-group">
                    <lable>Enter New Password</lable>
                    <input type="password" className="form-control" placeholder="Password" value={pass} required onChange={e=>setpass(e.target.value)}/>
                </div>
                <div className="form-group">
                    <lable>Confirm New Password</lable>
                    <input type="password" className="form-control" placeholder="Confirm Password" value={confirmPass} required onChange={e=>setconfirmPass(e.target.value)}/>
                </div>
                <input type="submit" className="form-control" value="Submit"/>
            </form>
            
        </div>
    )
}

export default ChangePassword
