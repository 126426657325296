import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./School-District.css";
import { CircularProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useEffect, useState } from "react";
import {
  createStudent,
  deleteStudent,
  getAllStudentbyTeacher,
  getTeacherDetails,
} from "./api";
import { useParams, Link } from "react-router-dom";
import CustomModal from "../Modal/CusModal";
import { Delete } from "@material-ui/icons";

export default function SchoolClass() {

  
  const [loading, setLoading] = useState(false);
  const [loginId, setloginId] = useState("");
  const [password, setPassword] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [students, setStudents] = useState([]);
  let params = useParams();
  let {id } = params;



  useEffect(() => {
    getTeacherData();
  }, [1]);




  
  const getTeacherData = async () => {

let res = await getAllStudentbyTeacher(id) ;
if (res.error) {
  alert("Something Went Wrong !");
} else {
  // console.log('Res is',res.res.data.data)
  setStudents(res.res.data.data.students)
}

  };



  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };


  const addStudent = async () => {
    let data = { loginId: loginId, password: password };
    let res = await createStudent(id, data);
    if (res.error) {
      alert("Something Went Wrong !");
    } else {
      handleCloseAddModal();
      getTeacherData();
    }
  };


  const deleteHandler = async (studentId) => {
    let res = await deleteStudent(id, studentId);
    if (res.error) {
      alert("Something Went Wrong !");
    } else {
      handleCloseAddModal();
      getTeacherData();
    }
  };




  return (
    <section id="hero-school ">




      <CustomModal handleClose={handleCloseAddModal} show={showAddModal}>
        <section id="hero-Msd">
          <div className="row main-div-modal">
            <div className="col-md-6 m-auto center-div text-center">
              <div className="p-2 center-div">
                <h2>
                  <button
                    onClick={handleCloseAddModal}
                    className="btn close-btn  rounded-circle float-right"
                  >
                    <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#011627"/>
                      <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </h2>
                <div className="mt-3">
                  <h6>Add Student</h6>
                </div>
                <div className="col-md-7 text-left col-9 m-auto">
                  <div className="form-group mt-4   p-20">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Login Id"
                      onChange={(e) => setloginId(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-4   p-20">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="cta-btn"
                      onClick={addStudent}
                    >
                      {loading ? <CircularProgress /> : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CustomModal>

      <div>
        <NavBar />



        <div className="dashboard-heading ml-4 " style={{ marginRight: "20px" }}>
          <h2>
            Students's List
            <button className="btn btn-add-device float-right" onClick={handleShowAddModal}>
              <i className="fas fa-plus-circle"></i> 
              <span> Add Student</span>
            </button>
          </h2>
        </div>

        <div  >
          <TableContainer className=" table pr-0">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell> Id</TableCell>
                  {/* <TableCell>Email</TableCell> */}
                  <TableCell>LoginId</TableCell>
                  <TableCell>Manage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.length>1 && students.map((item, index) => {
                  return (
                    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                      <TableCell className="table-data">{item._id}</TableCell>
                      {/* <TableCell className="table-data">{item.email}</TableCell> */}
                      <TableCell className="table-data">
                        {item.loginId}
                      </TableCell>
                   
                      <TableCell className="table-data">
                        <Link onClick={() => deleteHandler(item._id)}>
                          <Delete /> 
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </section>
  );
}
