import axios from "axios";
import { baseUrl } from "../Base_url";
const userData = JSON.parse(localStorage.getItem('recoil-persist'))
let token = ""
if (userData) {
  if (userData.KT_app) {
    // console.log(userData.KT_app)
    token = userData.KT_app.token
  }
}
export const getAllAssignedDevicesForTeacher = async (sid,tid, token) => {
  try {
    const res = await axios.get(`${baseUrl}/district-admin/school-district/school/${sid}/teacher/${tid}/device/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
}
export const SpareAssignedDevicesTeacher = async (sid,tid, token) => {
  try {
    const res = await axios.get(`${baseUrl}/district-admin/school-district/school/${sid}/teacher/${tid}/device/spare`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const AssignDevice = async (sid,tid, deviceId, token) => {
  try {
    const res = await axios.post(`${baseUrl}/district-admin/school-district/school/${sid}/teacher/${tid}/device/assign?deviceId=${deviceId}`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const DeleteAssignedDevice = async (sid,tid, deviceId,history, token) => {
  try {
    const res = await axios.delete(`${baseUrl}/district-admin/school-district/school/${sid}/teacher/${tid}/device/assign?deviceId=${deviceId}&historyId=${history}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

