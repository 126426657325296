import axios from "axios";
import { baseUrl } from "../Base_url";

export const getAllExperimentsForTeacher = async (token, page) => {
    try {
        const res = await axios.get(`${baseUrl}/school-admin/school/experiment/all?page=${page}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};