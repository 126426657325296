import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import LoginScreen from "./super-admin-build/components/Auth/LoginPage/LoginScreen";
import SignupPage from "./super-admin-build/components/Auth/RegisterPage/RegisterScreen";
import ForgotPasswordScreen from "./super-admin-build/components/Auth/ForgotPasswordPage/ForgotPasswordScreen";
import NewPasswordScreen from "./super-admin-build/components/Auth/NewPasswordPage/NewPasswordScreen";
import { useEffect, useState } from "react";
import DashBoard from "./super-admin-build/components/Dashboard/DashBoard";
import SchoolDestrict from "./super-admin-build/components/School-Districts/School-Districts";
import Device from "./super-admin-build/components/Devices/Devices";
import Destrict from "./super-admin-build/components/School-Districts/Districts";
import School from "./super-admin-build/components/School-Districts/School";
import Class from "./super-admin-build/components/School-Districts/Class";
import MSD from "./super-admin-build/components/ManageSchoolDestrict/ManageSchoolDestrict";
import AddSchool from "./super-admin-build/components/AddSchool/AddSchool";
import AddSchoolDestrict from "./super-admin-build/components/AddSchoolDestrict/AddSchoolDestrict";
import AddSchoolDestrictAdmin from "./super-admin-build/components/AddSchoolDestrictAdmin/AddSchoolDestrictAdmin";
import AddDevice from "./super-admin-build/components/AddDevice/AddDevice";
import ManageSchoolAdmin from "./super-admin-build/components/ManageSchoolAdmin/ManageSchoolAdmin";
import UploadDevice from "./super-admin-build/components/UploadDevice/UploadDevice";
import CreateSuperAdmin from "./super-admin-build/components/CreateSuperAdmin/CreateSuperAdmin";
import getCookie, { userState } from "./super-admin-build/Atom";
import { useRecoilState } from "recoil";
import SideBar from "./super-admin-build/components/Sidebar/SideBar";
import Profile from "./super-admin-build/components/Profile/Profile";
import Experiment from "./super-admin-build/components/Experiments/Experiment";
import Analysis from "./super-admin-build/components/Analysis/Analysis";
import User from "./super-admin-build/components/Users/user";
function SuperAdminRouter() {
  let history = useHistory();
  // const [myUser, setmyUser] = useRecoilState(userState);
  const _cookie = getCookie('recoil-persist')
  const user = JSON.parse(_cookie)?.KT_app
  const [myUser, setmyUser] =  useState(user)


  function loginOpen(){
    // console.log('This was called')
    const _cookie = getCookie('recoil-persist')
    const user = JSON.parse(_cookie)['KT_app']
    setmyUser(user)
  }

  return (
    <div className="super">
      <Router basename="/super-admin">
        {myUser?.token ? (
          <div style={{ minHeight: '100vh', background: '#f8f8fb' }}>
            <div style={{ minHeight: '100vh', display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', padding: '0', backgroundColor: '#fff', width: '100%', maxWidth: '300px' }}>
                <SideBar />
              </div>
              <div className="dashboard-div min-height" style={{ width: '100%', paddingLeft: '30px', paddingRight: '30px', height:'100vh', overflow:'scroll' }}>
                <Switch >
                  <Route path="/" exact component={DashBoard}  />
                  <Route
                    path="/school-districts"
                    exact
                    component={SchoolDestrict}
                  />
                  <Route
                    path="/district/:districtId"
                    exact
                    component={Destrict}
                  />
                  <Route
                    path="/district/:districtId/school/:id"
                    exact
                    component={School}
                  />
                  <Route
                    path="/users"
                    exact
                    component={User}
                  />
                  <Route
                    path="/districts/:districtId/school/:schoolid/teacher/:id"
                    exact
                    component={Class}
                  />
                  <Route
                    path="/experiments"
                    exact
                    component={Experiment}
                  />
                  <Route
                    path="/experiments/:id"
                    exact
                    component={Analysis}
                  />
                  <Route path="/devices" exact component={Device} />
                  <Route path="/add-device" exact component={AddDevice} />
                  <Route path="/upload-device" exact component={UploadDevice} />
                  <Route path="/profile" exact component={Profile} />
                  <Route path="/manage-school-destrict" exact component={MSD} />
                  <Route
                    path="/manage-school-admin"
                    exact
                    component={ManageSchoolAdmin}
                  />
                  <Route path="/add-school" exact component={AddSchool} />

                  <Route
                    path="/add-school-district"
                    exact
                    component={AddSchoolDestrict}
                  />

                  <Route path="/invite" component={AddSchoolDestrictAdmin} />
                  <Route path="/create-super" component={CreateSuperAdmin} />
                </Switch>
              </div>
            </div>
          </div>
        ) : (
          <Switch>
            {/* <Redirect to ="/" /> */}
            
            <Route path="/sign-up" exact component={SignupPage} />
            <Route path="/invite" component={AddSchoolDestrictAdmin} />
            <Route path="/forgot-password" component={ForgotPasswordScreen} />
            <Route
              path="/new-password/:id"
              exact
              component={NewPasswordScreen}
            />
            <Route path="/" 
              // component={LoginScreen} 
              render={props => (<LoginScreen location={loginOpen} />)} 
              />
          </Switch>
        )}
      </Router>
    </div>
  );
}

export default SuperAdminRouter;
