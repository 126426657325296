import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import NavBar from "../Navbar/Navbar";
import { useRecoilValue, useRecoilState } from 'recoil'
import getCookie, { setTeacherCookie, teacherState } from '../../Atom'
import { editAnExperiment, getAllClassbyTeacher, getAllExperimentsForTeacher, getAllStudentbyTeacher } from './api'
import './style.css'
import Popup from '../popup/Popup'
import Group_332 from '../../assets/images/Group_332.png'
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom";

import Dialog from '@mui/material/Dialog';
import Grid from "@material-ui/core/Grid";
// import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import Typography from "@material-ui/core/Typography";
import DialogTitle from '@mui/material/DialogTitle';



import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Snackbar from '@mui/material/Snackbar';
// import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { setDefaultLocale } from 'react-datepicker';
import { Autocomplete, TextField } from '@mui/material';
import AutoCompletes from './AutoCompletes';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [

  {
    id: 'expId',
    numeric: false,
    disablePadding: true,
    label: 'Exp Id',
  },
  // {
  //   id: 'deviceName',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'Device Name',
  // },
  {
    id: 'bottleVolume',
    numeric: true,
    disablePadding: true,
    label: 'Bottle Volume',
  },
  {
    id: 'fuelVolume',
    numeric: true,
    disablePadding: true,
    label: 'Fuel Volume',
  },
  {
    id: 'nozzleDia',
    numeric: true,
    disablePadding: true,
    label: 'Nozzle Diameter',
  },
  {
    id: 'pressure',
    numeric: true,
    disablePadding: true,
    label: 'Pressure',
  },
  {
    id: 'className',
    numeric: false,
    disablePadding: true,
    label: 'Class',
  },
  {
    id: 'studentName',
    numeric: false,
    disablePadding: true,
    label: 'Student',
  },

  // {
  //   id: 'time',
  //   numeric: true,
  //   disablePadding: true,
  //   label: 'Time',
  // },
  // {
  //   id: 'Analyze',
  //   numeric: true,
  //   disablePadding: true,
  //   label: 'Analyze',
  // },
  // {
  //   id: 'Action',
  //   numeric: true,
  //   disablePadding: true,
  //   label: 'Action',
  // },
];
const headCells1 = [
  {
    id: 'date',
    numeric: true,
    disablePadding: true,
    label: 'Date',
  }

]
function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;

  const [iconShow, setIconShow] = useState(true);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function showIcon() {
    setIconShow(true);

  }

  return (



    <TableHead sx={{ fontFamily: 'Exo2-Regular' }}>
      {/* <div className=' pt-4'>
      </div> */}
      <TableRow sx={{ fontFamily: 'Exo2-Regular' }}>
        {/* <TableCell padding="checkbox">
          
        </TableCell> */}

        {/* <TableCell 
          align={'center'}
          sx={{backgroundColor:'transparent'}}
        >
          Sr No.
          
        </TableCell> */}

        {/*        
         <TableCell 
          align={'center'}
       
        padding={ 'normal'}>
          Serial Id
          
        </TableCell> */}

        {/* <TableCell
        align={'center'}
          padding={ 'normal'}>
         Exp Id
        </TableCell> */}


        {headCells.map((headCell) => (
          <TableCell
            // className='btn-wh2'
            key={headCell.id}
            align={'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ backgroundColor: 'transparent', marginX: '1px' }}

          >
            <TableSortLabel
              className={`sort-header btn-wh2`}
              sx={{ display: 'flex', justifyContent: 'center', minHeight: '40px' }}

              active={orderBy === headCell.id && !iconShow}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              // hideSortIcon={iconShow}
              onMouseEnter={() => setIconShow(false)}
              onMouseLeave={() => setIconShow(true)}
            >
              <div className="text-Center fonted fontAdjust-700">{headCell.label}</div>

            </TableSortLabel>

          </TableCell>
        ))}

        <TableCell sx={{ backgroundColor: 'transparent' }}
          align={'center'}
          padding={'normal'}>
          <span>
            <div className='text-Center fonted fontAdjust-700'>Action</div>

          </span>




        </TableCell>

        {headCells1.map((headCell) => (
          <TableCell
            // className='btn-wh2'
            key={headCell.id}
            align={'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ backgroundColor: 'transparent', marginX: '1px' }}
          >
            <TableSortLabel
              className="sort-header btn-wh2"
              sx={{ display: 'flex', justifyContent: 'center', minHeight: '40px' }}
              active={orderBy === headCell.id && !iconShow}
              // hideSortIcon={iconShow}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              onMouseEnter={() => setIconShow(false)}
              onMouseLeave={() => setIconShow(true)}
            >
              <div className='text-Center fonted fontAdjust-700'>{headCell.label}</div>
              {/* {orderBy === headCell.id ? (
                <Box component="div" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}

            </TableSortLabel>
            {/* <div className=' flextable'>
              
                
          
           
               </div> */}
          </TableCell>
        ))}

      </TableRow>
    </TableHead>




  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    // 
    <div className='pt-10'>

    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const history = useHistory();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const experimentEditModal = useRef()
  const [experiments, setExperiments] = useState([])
  //  const [page, setPage] = useState(1)
  // const { token } = useRecoilValue(teacherState)

  const [currentEdit, setCurrentEdit] = useState(null)
  const [clicked, setclicked] = useState(false)
  //   const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [paged, setPaged] = useState(0);
  const [myUser, setmyUser] = useState(teacherState);
  const [noOfPages, setNoOfPages] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [open, setOpen] = useState(false);

  const [snackBarShow, setSnackBarShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [pageLimit, setPageLimit] = useState(25);
  const [editMode, setEditmode] = useState(false);

  // let params = new URLSearchParams(search);
  let paramse = useParams();
  const [serial, setSerial] = useState(null);

  const logoutHandler = async () => {
    setTimeout(() => {
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })
      setTeacherCookie({
        fName: null,
        userId: null,
        token: null,
        lName: null,
      })
      let hostname = window.location.host;
      // console.log(hostname,window.location.protocol)
      window.location.assign(`${window.location.protocol}//${hostname}/teachers`)
    })
  }


  const feedBackMsg = (snackShow, snackSeverity, snackMessage) => {
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    feedBackMsg(false, '', '');
  }

  useEffect(() => {
    const _cookie = getCookie('recoil-persist')
    const user = _cookie ? JSON.parse(_cookie)['teacher_v1'] : {}
    setmyUser({ ...user, 'page': "experiment" });
    props.page('experiment')
    let urlPage = parseInt(params.get('page'));
    let urlLimit = parseInt(params.get('limit'));
    let urlSerial = params.get('serial')
    // console.log(urlPage)
    if (parseInt(params.get('page')) || parseInt(params.get('limit'))) {
      // console.log('lol1')
      history.push({
        pathname: '/myexperiments',
        search: `?${(urlSerial) ? `serial=${urlSerial}&` : ''}` + 'page=' + urlPage + '&limit=' + urlLimit
      })
      getListOfExperiments(urlPage, urlLimit, user);
      setPaged(urlPage);
      setSerial(urlSerial);
      setPageLimit(urlLimit);
    } else {
      // console.log('lol')
      history.push({
        pathname: '/myexperiments',
        search: `?${(urlSerial) ? `serial=${urlSerial}&` : ''}` + 'page=0&limit=25'
      })
      getListOfExperiments(0, pageLimit, user)
    }


    // console.log('Again call')
  }, [])


  const getListOfExperiments = async (pages, limits, user = myUser) => {
    let arr = [];
    let res;
    // console.log('get the serial number',paramse.serial)
    res = await getAllExperimentsForTeacher(user?.token, pages, limits, (params.get('serial')) ? params.get('serial') : null)

    if (res.error) {
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {
      // console.log(res.res.data.data.experiments)
      let experiment = res.res.data.data.experiments;
      experiment.forEach(element => {
        let obj = {
          ...element, expId: element?._id, deviceName: element?.deviceName || element?.device?.name,
          date: new Date(element.createdAt).toLocaleDateString() + ' ' + new Date(element.createdAt).toLocaleTimeString()
          //  , time: new Date(element.createdAt).toLocaleTimeString() 
        }
        arr.push(obj)
      });
      setExperiments(arr);
      // setExperiments(res.res.data.data.experiments);

      setTotalPage(res.res.data.data.totalPages);

      let divisor = parseInt(res.res.data.data.totalPages / limits);
      let remainder = (res.res.data.data.totalPages % limits == 0) ? 0 : 1;
      setNoOfPages(divisor + remainder)
      setCurrPage(pages);
      // console.log(pages)
    }
  }
  const initiateEdit = (data) => {
    getClassByTeacher()

    setCurrentEdit(data);
    // experimentEditModal.current.open();
    setEditmode(true);
    setOpen(true);
  }
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    let { _id, className, studentName, bottleVolume, fuelVolume, nozzleDia } = currentEdit;
    let data = {
      "className": className,
      "studentName": studentName,
      "bottleVolume": bottleVolume,
      "fuelVolume": fuelVolume,
      "nozzleDia": nozzleDia
    }
    setclicked(true)
    const res = await editAnExperiment(_id, data, myUser?.token);
    if (res.error) {

      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }

    } else {
      // feedBackMsg(true,'success',res.res.data.msg);
    }
    setclicked(false)
    // experimentEditModal.current.close();
    handleClose()
    // console.log('hello')
    getListOfExperiments(paged, pageLimit)
  }

  // const handleDelete = async (id) => {
  //     if (window.confirm("Delete this Experiment?")) {
  //         const res = await deleteAnExperiment(id, token);
  //         if(res.error){
  //           if(res.data.statusCode==401){
  //             logoutHandler();
  //           }
  //           else{
  //             feedBackMsg(true,'error',res.data.msg);

  //         }

  //         }else{
  //           feedBackMsg(true,'success',res.res.data.msg);
  //         }
  //         // console.log('Delete')
  //         getListOfExperiments(paged,pageLimit);
  //     }

  // }

  // const handleChangePage = (event, newPage) => {

  //     console.log(event)
  //     setPaged(newPage);

  //     getListOfExperiments(event.selected,pageLimit);
  //     history.push({
  //         pathname: '/experiments',
  //         search: `?${(serial)?`serial=${serial}&`:''}`+'page=' + event.selected + '&limit=' + pageLimit
  //       })
  //   };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPageLimit(parseInt(event.target.value, 10))
  //     setPaged(0);
  //     // console.log('Row change')
  //     getListOfExperiments(0,parseInt(event.target.value, 10));
  //     history.push({
  //       pathname: '/experiments',
  //       search: `?${(serial)?`serial=${serial}&`:''}` +'page=0' + '&limit=' + parseInt(event.target.value, 10)
  //     })
  //   };


  // console.log(experiments)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageLimit);
  const [classData, setClassData] = useState([]);
  const [studentData, setStudentData] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = experiments.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {

    setPaged(newPage);
    // console.log(paged)

    getListOfExperiments(event.selected, pageLimit);
    history.push({
      pathname: '/myexperiments',
      search: `?${(serial) ? `serial=${serial}&` : ''}` + 'page=' + event.selected + '&limit=' + pageLimit
    })
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };




  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };




  const getClassByTeacher = async (id) => {
    const _cookie = getCookie('recoil-persist')

    const user = _cookie ? JSON.parse(_cookie)['teacher_v1'] : {} = _cookie ? JSON.parse(_cookie)['teacher_v1'] : {}
    const data = await getAllClassbyTeacher(user.id, user.token);
    console.log(data?.res?.data?.data?.sclass)

    setClassData(data?.res?.data?.data?.sclass)

  }





  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - experiments.length) : 0;

  const handleClose = () => {

    setOpen(false);
    setEditmode(false);
  }
  const getStudentByClassId = async (id) => {
    const _cookie = getCookie('recoil-persist')

    const user = _cookie ? JSON.parse(_cookie)['teacher_v1'] : {} = _cookie ? JSON.parse(_cookie)['teacher_v1'] : {}
    const data = await getAllStudentbyTeacher(id, user.token);
    console.log(data?.res?.data?.data?.students)

    setStudentData(data?.res?.data?.data?.students)

  }


  useEffect(() => {
    if (currentEdit?.classId) { getStudentByClassId(currentEdit?.classId) ;setCurrentEdit({ ...currentEdit, studentName: "" })}
  }, [currentEdit?.classId])



  return (<div>

    <main style={{ position: "absolute", width: "100%", top: "20%" }}>
      <div className="container px-4 pt-3">
        <div className="upper-section px-60">
          <div className="breadcrumb-menu py-2">
            <Link to={'/'}>Teacher Panel </Link><i className="fas fa-angle-double-right"></i><a className='disable'>Experiments</a>
          </div>
          <div className="text-blue maintext"><h2 className='h2'>Experiments</h2></div>
          <div className="">
            <div className="">
              <div className="card-header px-0"><h3 className='h3'>Manage and analyse experiments here</h3></div>
            </div>

          </div>
          {/* <br/> */}
          <div className="d-flex justify-content-end align-items-end">

            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={noOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handleChangePage}
              containerClassName="pagination"
              activeClassName="activePage active"
              forcePage={currPage} />

          </div>
        </div>
        <div className=" px-3">

          <div className="row table-section experiments ">
            <div className="lower-section px-4 pt-3 w-100">


              <Box sx={{ width: '100%' }}>

                <Paper sx={{ width: '100%', mb: 2, backgroundColor: 'transparent', boxShadow: 'none' }}>
                  <EnhancedTableToolbar numSelected={selected.length} />
                  <TableContainer sx={{ boxShadow: 'none' }}>
                    <Table
                      sx={{ minWidth: 750, backgroundColor: 'transparent', border: 'none' }}
                      aria-labelledby="tableTitle"
                      size={'small'}
                      stickyHeader
                    >
                      <EnhancedTableHead

                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={experiments.length}
                      />
                      <TableBody sx={{ backgroundColor: 'transparent', color: '#1A273B', border: 'none' }} className="table-body fonted noBor">

                        {stableSort(experiments, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((experiments, index) => {
                            const isItemSelected = isSelected(experiments.name);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, experiments.name)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={experiments.name}
                                selected={isItemSelected}
                                sx={{ backgroundColor: 'transparent', fontFamily: 'Exo2-Regular' }}
                                className="fonted noBord"
                              >
                                {/* <TableCell align="right" className="fitted" sx={{fontFamily:'Exo2-Regular',fontSize:'medium'}}>{index+1}</TableCell> */}


                                <TableCell align="center" className="fitted" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{experiments.expId}</TableCell>

                                <TableCell className="fitted experimental-td" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{Number.isInteger(experiments?.bottleVolume) ? experiments?.bottleVolume : experiments?.bottleVolume.toFixed(2)} ml</TableCell>
                                <TableCell className="fitted" align="center" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{Number.isInteger(experiments?.fuelVolume) ? experiments?.fuelVolume : experiments?.fuelVolume.toFixed(2)} ml</TableCell>

                                <TableCell className="fitted" align="center" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{Number.isInteger(experiments?.nozzleDia) ? experiments?.nozzleDia : experiments?.nozzleDia.toFixed(2)} mm</TableCell>

                                <TableCell className="fitted" align="center" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{Number.isInteger(experiments?.pressure) ? experiments?.pressure : experiments?.pressure.toFixed(2)} psi</TableCell>

                                <TableCell className="fitted" align="center" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{experiments.className}</TableCell>
                                <TableCell className="fitted" align="center" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{experiments.studentName}</TableCell>

                                <TableCell className="fitted" sx={{ fontFamily: 'Exo2-Regular', fontSize: '14px' }}>
                                  <div className="d-flex class-btn">
                                    <Tooltip title="Edit" placement="left">
                                      <div>
                                        <div className='fonted fixImg' type='button' onClick={() => initiateEdit(experiments)}><img src={Group_332} className="ps-4" /></div>
                                      </div>
                                    </Tooltip>
                                    <span style={{ width: '10%' }}></span>
                                    <div className="btn-wh1 text-center d-flex align-items-center py-0">
                                      <div className="pt-1 pb-0"><Link to={`/experiments/${experiments._id}`} style={{ color: 'black' }}>Analyze</Link></div>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell className="fitted" align="right" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{experiments.date}</TableCell>





                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                </Paper>

                <Dialog handleClose={handleClose} open={open} fullWidth PaperProps={{
                  style: {
                    backgroundColor: "transparent",
                    boxShadow: "none"
                  },
                }} className="modal-dialog modal-dialog-centered modal-dialog-scrollable teacher-div" >
                  <section id="hero-Msd">
                    {editMode ? <>
                      <div className="modal-content" style={{ width: '400px' }}>
                        <div className="modal-header-n d-flex justify-content-end">

                          <button type="button" onClick={handleClose} class="btn-close" data-bs-dismiss="modal" aria-label="Close"><CloseIcon /></button>
                        </div>

                        <form onSubmit={(e) => handleEditSubmit(e)} className="form">
                          <div className="modal-body px-5">

                            <div className="mb-0">

                              <div style={{ display: 'flex' }}>
                                <p style={{paddingRight:20}}>Device Name : </p><p>{currentEdit?.device?.name}</p>
                                {/* <input type="text" className="form-control1 pt-3" readOnly required Value={currentEdit?.device?.name}/> */}
                              </div>

                              <div style={{ display: 'flex' }}>
                                <p style={{paddingRight:20}}>Exp Id: </p><p>{currentEdit._id}</p>
                              </div>

                              <div className="form-group">
                                <label>Class</label>

                                <AutoCompletes value={currentEdit?.className} label={""} options={classData} setValue={(value) => setCurrentEdit({ ...currentEdit, className: value?.name, classId: value?._id })} />
                              </div>
                              <div className="form-group">
                                <label>Student</label>

                                <AutoCompletes value={currentEdit?.studentName} label={""} options={studentData} setValue={(value) => setCurrentEdit({ ...currentEdit, studentName: value?.loginId })} />
                              </div>
                              {/* <div className="form-group">
                                <label>Student</label>
                                <input type="text" className="form-control1 pt-3" readOnly required value={currentEdit.studentName} onChange={e => { setCurrentEdit({ ...currentEdit, studentName: e.target.value }) }} />
                              </div> */}

                              <div className="form-group">
                                <label>Bottle Volume (ml)</label>
                                <input type="text" className="form-control1 pt-3" required value={currentEdit.bottleVolume} onChange={e => { setCurrentEdit({ ...currentEdit, bottleVolume: e.target.value }) }} />
                              </div>
                              <div className="form-group">
                                <label>Fuel Volume (ml)</label>
                                <input type="text" className="form-control1 pt-3" required Value={currentEdit.fuelVolume} onChange={e => { setCurrentEdit({ ...currentEdit, fuelVolume: e.target.value }) }} />
                              </div>
                              <div className="form-group">
                                <label>Nozzle Diamenter (mm)</label>
                                <input type="text" className="form-control1 pt-3" required value={currentEdit.nozzleDia} onChange={e => { setCurrentEdit({ ...currentEdit, nozzleDia: e.target.value }) }} />
                              </div>
                            </div>
                            <div class="modal-footer px-4">
                              <button type="submit" className="btn btn-pitch" >Edit</button>
                            </div>
                            <br />

                          </div>
                        </form>
                      </div>

                      <br />
                    </> : null}
                  </section>
                </Dialog>



                {/* <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                    <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar> */}
              </Box>
            </div>


          </div>
        </div>
      </div>
      <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
        <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </main>
  </div>
  );
}