import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState, useRef } from 'react'
import NavBar from "../Navbar/Navbar";
import { useRecoilValue, useRecoilState } from 'recoil'
import getCookie, { setDistrictCookie, userState } from '../../Atom'
import './style.css'
import Popup from '../popup/Popup'
import CloseIcon from "@material-ui/icons/Close";


import { districtAdminState } from '../../Atom'
import { getAllExperimentsForDistrictAdmin, editAnExperiment, getStudentByclassId, getClassesByTeachers, getAllSchoolTeachers, getSchoolByDistrictAdmin } from './api'

import './style.css'
import ReactPaginate from 'react-paginate';







import { useHistory } from "react-router-dom";



import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Snackbar from '@mui/material/Snackbar';
// import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Dialog, DialogTitle, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import AutoCompletes from './AutoCompletes';
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});





function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [

	{
		id: 'serialId',
		numeric: false,
		disablePadding: true,
		label: 'Serial Id',
	},
	{
		id: '_id',
		numeric: false,
		disablePadding: true,
		label: 'Exp Id',
	},
	{
		id: 'deviceName',
		numeric: false,
		disablePadding: true,
		label: 'Device Name',
	},
	{
		id: 'fuelVolume',
		numeric: true,
		disablePadding: true,
		label: 'Fuel Volume',
	},
	{
		id: 'nozzleDia',
		numeric: true,
		disablePadding: true,
		label: 'Nozzle Diameter',
	},
	{
		id: 'pressure',
		numeric: true,
		disablePadding: true,
		label: 'Pressure',
	},
	{
		id: 'className',
		numeric: false,
		disablePadding: true,
		label: 'Class',
	},
	{
		id: 'studentName',
		numeric: false,
		disablePadding: true,
		label: 'Student',
	},
	{
		id: 'date',
		numeric: true,
		disablePadding: true,
		label: 'Date',
	},
	{
		id: 'time',
		numeric: true,
		disablePadding: true,
		label: 'Time',
	},
	// {
	//   id: 'Analyze',
	//   numeric: true,
	//   disablePadding: true,
	//   label: 'Analyze',
	// },
	// {
	//   id: 'Action',
	//   numeric: true,
	//   disablePadding: true,
	//   label: 'Action',
	// },
];

function EnhancedTableHead(props) {

	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
		props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (



		<TableHead>
			{/* <div className=' pt-4'>
      </div> */}
			<TableRow>
				{/* <TableCell padding="checkbox">
          
        </TableCell> */}

				{/* <TableCell
					align={'center'}

					padding={'normal'}>
					Serial Id

				</TableCell>


				<TableCell
					align={'center'}
					padding={'normal'}>
					Exp Id
				</TableCell> */}


				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}


						align={'center'}
						padding={'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>



						<TableSortLabel

							sx={{ display: 'flex' }}
							className=' mb-2'
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>


							{headCell.label}




							{orderBy === headCell.id ? (
								<Box component="div" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}


						</TableSortLabel>


					</TableCell>
				))}

				<TableCell
					align={'center'}
					padding={'normal'}>
					<div className=' mb-2'>

						Analysis
					</div>
				</TableCell>
			</TableRow>
		</TableHead>

	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
	const { numSelected } = props;

	return (
		// 
		<div className='pt-10'>

		</div>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
	const [myUser, setmyUser] = useState()
	const history = useHistory();
	let search = window.location.search;
	let paramse = useParams();
	let params = new URLSearchParams(search);
	const [experiments, setExperiments] = useState([])
	//	const [page, setPage] = useState(1)
	// const { token } = useRecoilValue(districtAdminState)
	//	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [totalPage, setTotalPage] = useState(0);
	const [paged, setPaged] = useState(0);
	const [noOfPages, setNoOfPages] = useState(0);
	const [currPage, setCurrPage] = useState(0);
	const [pageLimit, setPageLimit] = useState(25);
	const [serial, setSerial] = useState(null);
	const [currentEdit, setCurrentEdit] = useState();
	const [open, setOpen] = useState();
	const [editmode, setEditmode] = useState();
	const [snackBarShow, setSnackBarShow] = useState(false);
	const [snackBarMessage, setSnackBarMessage] = useState('');
	const [snackBarSeverity, setSnackBarSeverity] = useState('');
	const [clicked, setclicked] = useState(false)
	const [teacherData, setTeacherData] = useState([]);
	const [schoolData, setSchoolData] = useState([]);
	const top100Films = [
		{ label: 'The Shawshank Redemption', year: 1994 },
		{ label: 'The Godfather', year: 1972 },
		{ label: 'The Godfather: Part II', year: 1974 },
		{ label: 'The Dark Knight', year: 2008 },
		{ label: '12 Angry Men', year: 1957 },
		{ label: "Schindler's List", year: 1993 },
		{ label: 'Pulp Fiction', year: 1994 },
		{
			label: 'The Lord of the Rings: The Return of the King',
			year: 2003,
		},
		{ label: 'The Good, the Bad and the Ugly', year: 1966 },
		{ label: 'Fight Club', year: 1999 },
		{
			label: 'The Lord of the Rings: The Fellowship of the Ring',
			year: 2001,
		},
		{
			label: 'Star Wars: Episode V - The Empire Strikes Back',
			year: 1980,
		},
		{ label: 'Forrest Gump', year: 1994 },
		{ label: 'Inception', year: 2010 },
		{
			label: 'The Lord of the Rings: The Two Towers',
			year: 2002,
		},
		{ label: "One Flew Over the Cuckoo's Nest", year: 1975 },
		{ label: 'Goodfellas', year: 1990 },
		{ label: 'The Matrix', year: 1999 },
		{ label: 'Seven Samurai', year: 1954 },
		{
			label: 'Star Wars: Episode IV - A New Hope',
			year: 1977,
		},
		{ label: 'City of God', year: 2002 },
		{ label: 'Se7en', year: 1995 },
		{ label: 'The Silence of the Lambs', year: 1991 },
		{ label: "It's a Wonderful Life", year: 1946 },
		{ label: 'Life Is Beautiful', year: 1997 },
		{ label: 'The Usual Suspects', year: 1995 },
		{ label: 'Léon: The Professional', year: 1994 },
		{ label: 'Spirited Away', year: 2001 },
		{ label: 'Saving Private Ryan', year: 1998 },
		{ label: 'Once Upon a Time in the West', year: 1968 },
		{ label: 'American History X', year: 1998 },
		{ label: 'Interstellar', year: 2014 },
		{ label: 'Casablanca', year: 1942 },
		{ label: 'City Lights', year: 1931 },
		{ label: 'Psycho', year: 1960 },
		{ label: 'The Green Mile', year: 1999 },
		{ label: 'The Intouchables', year: 2011 },
		{ label: 'Modern Times', year: 1936 },
		{ label: 'Raiders of the Lost Ark', year: 1981 },
		{ label: 'Rear Window', year: 1954 },
		{ label: 'The Pianist', year: 2002 },
		{ label: 'The Departed', year: 2006 },
		{ label: 'Terminator 2: Judgment Day', year: 1991 },
		{ label: 'Back to the Future', year: 1985 },
		{ label: 'Whiplash', year: 2014 },
		{ label: 'Gladiator', year: 2000 },
		{ label: 'Memento', year: 2000 },
		{ label: 'The Prestige', year: 2006 },
		{ label: 'The Lion King', year: 1994 },
		{ label: 'Apocalypse Now', year: 1979 },
		{ label: 'Alien', year: 1979 },
		{ label: 'Sunset Boulevard', year: 1950 },
		{
			label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
			year: 1964,
		},
		{ label: 'The Great Dictator', year: 1940 },
		{ label: 'Cinema Paradiso', year: 1988 },
		{ label: 'The Lives of Others', year: 2006 },
		{ label: 'Grave of the Fireflies', year: 1988 },
		{ label: 'Paths of Glory', year: 1957 },
		{ label: 'Django Unchained', year: 2012 },
		{ label: 'The Shining', year: 1980 },
		{ label: 'WALL·E', year: 2008 },
		{ label: 'American Beauty', year: 1999 },
		{ label: 'The Dark Knight Rises', year: 2012 },
		{ label: 'Princess Mononoke', year: 1997 },
		{ label: 'Aliens', year: 1986 },
		{ label: 'Oldboy', year: 2003 },
		{ label: 'Once Upon a Time in America', year: 1984 },
		{ label: 'Witness for the Prosecution', year: 1957 },
		{ label: 'Das Boot', year: 1981 },
		{ label: 'Citizen Kane', year: 1941 },
		{ label: 'North by Northwest', year: 1959 },
		{ label: 'Vertigo', year: 1958 },
		{
			label: 'Star Wars: Episode VI - Return of the Jedi',
			year: 1983,
		},
		{ label: 'Reservoir Dogs', year: 1992 },
		{ label: 'Braveheart', year: 1995 },
		{ label: 'M', year: 1931 },
		{ label: 'Requiem for a Dream', year: 2000 },
		{ label: 'Amélie', year: 2001 },
		{ label: 'A Clockwork Orange', year: 1971 },
		{ label: 'Like Stars on Earth', year: 2007 },
		{ label: 'Taxi Driver', year: 1976 },
		{ label: 'Lawrence of Arabia', year: 1962 },
		{ label: 'Double Indemnity', year: 1944 },
		{
			label: 'Eternal Sunshine of the Spotless Mind',
			year: 2004,
		},
		{ label: 'Amadeus', year: 1984 },
		{ label: 'To Kill a Mockingbird', year: 1962 },
		{ label: 'Toy Story 3', year: 2010 },
		{ label: 'Logan', year: 2017 },
		{ label: 'Full Metal Jacket', year: 1987 },
		{ label: 'Dangal', year: 2016 },
		{ label: 'The Sting', year: 1973 },
		{ label: '2001: A Space Odyssey', year: 1968 },
		{ label: "Singin' in the Rain", year: 1952 },
		{ label: 'Toy Story', year: 1995 },
		{ label: 'Bicycle Thieves', year: 1948 },
		{ label: 'The Kid', year: 1921 },
		{ label: 'Inglourious Basterds', year: 2009 },
		{ label: 'Snatch', year: 2000 },
		{ label: '3 Idiots', year: 2009 },
		{ label: 'Monty Python and the Holy Grail', year: 1975 },
	];
	const logoutHandler = async () => {
		setTimeout(() => {
			// setmyUser({
			// 	fName: null,
			// 	userId: null,
			// 	token: null,
			// 	lName: null,
			// })
			setDistrictCookie({
				fName: null,
				lName: null,
				token: null,
				userId: null
			})
			let hostname = window.location.host;
			// console.log(hostname, window.location.protocol)
			window.location.assign(`${window.location.protocol}//${hostname}/school-district`)
		})
	}

	const handleClose = () => {
		setOpen(false);
		setEditmode(false);
	}
	const feedBackMsg = (snackShow, snackSeverity, snackMessage) => {
		setSnackBarShow(snackShow);
		setSnackBarSeverity(snackSeverity);
		setSnackBarMessage(snackMessage)
	}
	const handleEditSubmit = async (e) => {
		const _cookie = getCookie('recoil-persist')

		const user = JSON.parse(_cookie)['district_admin_v1']
		e.preventDefault();
		let { _id, className, studentName, bottleVolume, fuelVolume, nozzleDia } = currentEdit;
		let data = {
			"className": className,
			"studentName": studentName,
			"bottleVolume": bottleVolume,
			"fuelVolume": fuelVolume,
			"nozzleDia": nozzleDia,
			"school": currentEdit?.school,
			"teacher": currentEdit?.teacher,
		}
		// setclicked(true)
		const res = await editAnExperiment(_id, data, user?.token);
		if (res.error) {
			if (res.data.statusCode == 401) {
				logoutHandler();
			}
			else {
				feedBackMsg(true, 'error', res.data.msg);
			}
		} else {
			feedBackMsg(true, 'success', res.res.data.msg);
		}
		// setclicked(false)
		handleClose();
		getListOfExperiments(paged, pageLimit, user)
	}
	const SnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		feedBackMsg(false, '', '');
	}



	useEffect(() => {
		const _cookie = getCookie('recoil-persist')

		if (!_cookie) {
			logoutHandler()
		} else {
			const user = JSON.parse(_cookie)['district_admin_v1']
			setmyUser(user)
			let urlPage = parseInt(params.get('page'));
			let urlLimit = parseInt(params.get('limit'));
			let urlSerial = params.get('serial')
			// console.log(urlPage)
			if (parseInt(params.get('page')) || parseInt(params.get('limit'))) {
				// console.log('lol1')
				history.push({
					pathname: '/experiments',
					search: `?${(urlSerial) ? `serial=${urlSerial}&` : ''}` + 'page=' + urlPage + '&limit=' + urlLimit
				})
				getListOfExperiments(urlPage, urlLimit, user);
				setPaged(urlPage);
				setSerial(urlSerial);
				setPageLimit(urlLimit);
			} else {
				// console.log('lol')
				history.push({
					pathname: '/experiments',
					search: `?${(urlSerial) ? `serial=${urlSerial}&` : ''}` + 'page=0&limit=25'
				})
				getListOfExperiments(0, pageLimit, user)
			}
		}
		// console.log('Again call')
	}, [0])


	const [expUpdateData, setExpUpdateData] = React.useState({
		teachers: [],
		classes: [],
		students: []
	})



	const getListOfExperiments = async (pages, limits, user) => {
		let arr = [];
		let res;
		// console.log('get the serial number',paramse.serial)
		res = await getAllExperimentsForDistrictAdmin(user?.token, pages, limits, (params.get('serial')) ? params.get('serial') : null)

		if (res.error) {
			if (res.data.statusCode == 401) {
				logoutHandler();
			}
			else {
				feedBackMsg(true, 'error', res.data.msg);
			}
		} else {
			//   console.log(res.res.data.data.experiments)
			let experiment = res.res.data.data.experiments;
			experiment.forEach(element => {
				let obj = {
					...element, serialId: element?.deviceSerialId || element?.device?._id, deviceName: element?.deviceName || element?.device?.name,
					date: new Date(element.createdAt).toLocaleDateString(), time: new Date(element.createdAt).toLocaleTimeString()
				}
				arr.push(obj)
			});
			setExperiments(arr);
			// setExperiments(res.res.data.data.experiments);

			setTotalPage(res.res.data.data.totalPages);

			let divisor = parseInt(res.res.data.data.totalPages / limits);
			let remainder = (res.res.data.data.totalPages % limits == 0) ? 0 : 1;
			setNoOfPages(divisor + remainder)
			setCurrPage(pages);
			// console.log(pages)
		}
	}

	const increment = () => {
		setPage(page + 1)
	}
	const decrement = () => {
		if (page > 1) {
			setPage(page - 1)
		}
	}

	const handleChangePage = (event, newPage) => {
		const _cookie = getCookie('recoil-persist')

		const user = JSON.parse(_cookie)['district_admin_v1']
		setPaged(newPage);

		getListOfExperiments(event.selected, pageLimit, user);
		history.push({
			pathname: '/experiments',
			search: `?${(serial) ? `serial=${serial}&` : ''}` + 'page=' + event.selected + '&limit=' + pageLimit
		})
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPageLimit(parseInt(event.target.value, 10))
		setPaged(0);
		getListOfExperiments(0, parseInt(event.target.value, 10));
		history.push({
			pathname: '/experiments',
			search: `?${(serial) ? `serial=${serial}&` : ''}` + 'page=0' + '&limit=' + parseInt(event.target.value, 10)
		})
	};


	//  const initiateEdit = (data) => {
	//       setCurrentEdit(data);
	//       experimentEditModal.current.open();
	//   }
	//   const handleEditSubmit = async (e) => {
	//       e.preventDefault();
	//       let {_id, className, studentName, bottleVolume, fuelVolume, nozzleDia} = currentEdit;
	//       let data = {
	//           "className": className,
	//           "studentName": studentName,
	//           "bottleVolume": bottleVolume,
	//           "fuelVolume": fuelVolume,
	//           "nozzleDia": nozzleDia
	//       }
	//       setclicked(true)
	//       const res = await editAnExperiment(_id, data, token);
	//       if(res.error){

	//         if(res.data.statusCode==401){
	//           logoutHandler();
	//         }
	//         else{
	//           feedBackMsg(true,'error',res.data.msg);
	//             }

	//       }else{
	//         feedBackMsg(true,'success',res.res.data.msg);
	//       }
	//       setclicked(false)
	//       experimentEditModal.current.close();
	//       // console.log('hello')
	//       getListOfExperiments(paged,pageLimit)
	//   }

	//   const handleDelete = async (id) => {
	//       if (window.confirm("Delete this Experiment?")) {
	//           const res = await deleteAnExperiment(id, token);
	//           if(res.error){
	//             if(res.data.statusCode==401){
	//               logoutHandler();
	//             }
	//             else{
	//               feedBackMsg(true,'error',res.data.msg);

	//           }

	//           }else{
	//             feedBackMsg(true,'success',res.res.data.msg);
	//           }
	//           // console.log('Delete')
	//           getListOfExperiments(paged,pageLimit);
	//       }

	//   }

	// const handleChangePage = (event, newPage) => {

	//     console.log(event)
	//     setPaged(newPage);

	//     getListOfExperiments(event.selected,pageLimit);
	//     history.push({
	//         pathname: '/experiments',
	//         search: `?${(serial)?`serial=${serial}&`:''}`+'page=' + event.selected + '&limit=' + pageLimit
	//       })
	//   };

	//   const handleChangeRowsPerPage = (event) => {
	//     setRowsPerPage(parseInt(event.target.value, 10));
	//     setPageLimit(parseInt(event.target.value, 10))
	//     setPaged(0);
	//     // console.log('Row change')
	//     getListOfExperiments(0,parseInt(event.target.value, 10));
	//     history.push({
	//       pathname: '/experiments',
	//       search: `?${(serial)?`serial=${serial}&`:''}` +'page=0' + '&limit=' + parseInt(event.target.value, 10)
	//     })
	//   };


	// console.log(experiments)
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(pageLimit);
	const [classData, setClassData] = useState([]);
	const [studentData, setStudentData] = useState([]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = experiments.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};
	const initiateEdit = (data) => {
		setCurrentEdit(data);

		setOpen(true);
		setEditmode(true)
		getSchoolByDistrict();
	}
	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};

	//   const handleChangePage = (event, newPage) => {

	//       console.log(event)
	//       setPaged(newPage);

	//       getListOfExperiments(event.selected,pageLimit);
	//       history.push({
	//           pathname: '/experiments',
	//           search: `?${(serial)?`serial=${serial}&`:''}`+'page=' + event.selected + '&limit=' + pageLimit
	//         })
	//     };

	// const handleChangeRowsPerPage = (event) => {
	//   setRowsPerPage(parseInt(event.target.value, 10));
	//   setPage(0);
	// };

	const handleChangeDense = (event) => {
		setDense(event.target.checked);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - experiments.length) : 0;


	const getSchoolByDistrict = async () => {
		const _cookie = getCookie('recoil-persist')

		const user = JSON.parse(_cookie)['district_admin_v1']
		const res = await getSchoolByDistrictAdmin(user.token);
		setSchoolData(res?.res?.data?.data?.schools);
		// res?.res?.data?.data?.schools?.map((item) => {
		// 	if (item._id === currentEdit?.school && typeof(currentEdit?.school)) {
		// 		setCurrentEdit({ ...currentEdit, school: item })
		// 		return
		// 	}

		// })

	}
	const getClassBySchoolId = async (id) => {
		const _cookie = getCookie('recoil-persist')

		const user = JSON.parse(_cookie)['district_admin_v1']
		const res = await getAllSchoolTeachers(id, user.token);
		setTeacherData(res?.res?.data);
		
	}

	const getclassbyTeacherId = async (id) => {
		const _cookie = getCookie('recoil-persist')

		const user = JSON.parse(_cookie)['district_admin_v1']
		const res = await getClassesByTeachers(id, user.token);
		console.log(res?.res?.data);
		setClassData(res?.res?.data);
	}

	const getStudentByclassIds = async (id) => {
		const _cookie = getCookie('recoil-persist')

		const user = JSON.parse(_cookie)['district_admin_v1']
		const res = await getStudentByclassId(id, user.token);
		console.log(res?.res?.data);
		setStudentData(res?.res?.data);
	}


	useEffect(() => {
		if (currentEdit?.school && currentEdit?.school.length > 3) {
			getClassBySchoolId(currentEdit?.school)
		}
	}, [currentEdit?.school])

	useEffect(() => {
		if (currentEdit?.teacher?.length > 0) {
			getclassbyTeacherId(currentEdit?.teacher[0])
		}
	}, [currentEdit?.teacher])

	useEffect(() => {
		if (currentEdit?.classId?.length > 0) {
			getStudentByclassIds(currentEdit?.classId)
		}
	}, [currentEdit?.classId])

	return (<div>
		<NavBar />
		<br />
		<div className="d-flex justify-content-end align-items-end">
			<ReactPaginate
				previousLabel="Previous"
				nextLabel="Next"
				pageClassName="page-item"
				pageLinkClassName="page-link"
				previousClassName="page-item"
				previousLinkClassName="page-link"
				nextClassName="page-item"
				nextLinkClassName="page-link"
				breakLabel="..."
				breakClassName="page-item"
				breakLinkClassName="page-link"
				pageCount={noOfPages}
				marginPagesDisplayed={2}
				pageRangeDisplayed={5}
				onPageChange={handleChangePage}
				containerClassName="pagination"
				activeClassName="activePage active"
				forcePage={currPage} />
		</div>
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar numSelected={selected.length} />
				<TableContainer sx={{ height: '80vh', overflow: 'auto' }}>
					<Table

						aria-labelledby="tableTitle"
						// size={ 'small'}
						stickyHeader
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={experiments.length}
						/>
						<TableBody>
							{stableSort(experiments, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((experiments, index) => {
									const isItemSelected = isSelected(experiments.name);
									const labelId = `enhanced-table-checkbox-${index}`;
									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, experiments.name)}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={experiments.name}
											selected={isItemSelected}
										>
											<TableCell align="center">{experiments?.serialId}</TableCell>
											<TableCell align="center">{experiments._id}</TableCell>
											<TableCell align="center">{experiments?.deviceName}</TableCell>
											<TableCell align="center">{Number.isInteger(experiments?.fuelVolume) ? experiments?.fuelVolume : experiments?.fuelVolume.toFixed(2)} ml</TableCell>
											<TableCell align="center">{Number.isInteger(experiments?.nozzleDia) ? experiments?.nozzleDia : experiments?.nozzleDia.toFixed(2)} mm</TableCell>
											<TableCell align="center">{Number.isInteger(experiments?.pressure) ? experiments?.pressure : experiments?.pressure.toFixed(2)} psi</TableCell>
											<TableCell align="center">{experiments.className}</TableCell>
											<TableCell align="center">{experiments.studentName}</TableCell>
											<TableCell align="center">{experiments.date}</TableCell>
											<TableCell align="center">{experiments.time}</TableCell>
											<TableCell>
												<Link to={`/experiments/${experiments._id}`} className="btn btn-sm btn-outline-primary">Analyze</Link>
											</TableCell>
											<TableCell>

												<Tooltip title="Edit" placement="left">
													<button className="btn btn-sm btn-outline-primary mr-2" onClick={() => initiateEdit(experiments)}>
														<svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M13.75 5H5C4.33696 5 3.70107 5.26339 3.23223 5.73223C2.76339 6.20107 2.5 6.83696 2.5 7.5V25C2.5 25.663 2.76339 26.2989 3.23223 26.7678C3.70107 27.2366 4.33696 27.5 5 27.5H22.5C23.163 27.5 23.7989 27.2366 24.2678 26.7678C24.7366 26.2989 25 25.663 25 25V16.25" stroke="#495057" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
															<path d="M23.125 3.125C23.6223 2.62772 24.2967 2.34835 25 2.34835C25.7033 2.34835 26.3777 2.62772 26.875 3.125C27.3723 3.62228 27.6517 4.29674 27.6517 5C27.6517 5.70326 27.3723 6.37772 26.875 6.875L15 18.75L10 20L11.25 15L23.125 3.125Z" stroke="#495057" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
														</svg>
													</button>
												</Tooltip>
											</TableCell >
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}





						</TableBody>
					</Table>
				</TableContainer>
			</Paper>


			<Dialog handleClose={handleClose} open={open} fullWidth>
				<section id="hero-Msd">
					{editmode ? <>
						<DialogTitle>
							<Grid container justify="space-between" alignItems="center">
								<Typography variant="h6" style={{ fontWeight: '600' }}>Edit Experiment</Typography>
								<IconButton onClick={handleClose} >
									<CloseIcon style={{ border: '2px solid black', fontSize: '30px' }} />
								</IconButton>
							</Grid>
						</DialogTitle>
						<form onSubmit={(e) => handleEditSubmit(e)} className="form">
							<div className="col-md-9 text-left  col-9 m-auto">
								<div className="form-group mt-4   p-20">
									<div className="form-group">
										<label>School</label>
										<AutoCompletes value={currentEdit?.school} label={"School"} options={schoolData} setValue={(value) => setCurrentEdit({ ...currentEdit, school: value?._id, teacher: [], className: "", studentName: "" })} />
									</div>
									<div className="form-group">
										<label>Teacher</label>
										<AutoCompletes value={currentEdit?.teacher[0]} label={"Teacher"} options={teacherData} setValue={(value) => setCurrentEdit({ ...currentEdit, teacher: [value?._id], className: "", studentName: "" })} />
									</div>
									{console.log(currentEdit)}
									<div className="form-group">
										<label>Class</label>
										<AutoCompletes value={currentEdit?.className} label={"Class"} options={classData} setValue={(value) => setCurrentEdit({ ...currentEdit, className: value?.name, classId: value?._id, studentName: "" })} />

									</div>


									<div className="form-group">
										<label>Student</label>
										<AutoCompletes value={currentEdit?.studentName} label={"Student"} options={studentData} setValue={(value) => setCurrentEdit({ ...currentEdit, studentName: value?.loginId, })} />
									</div>
									<div className="form-group">
										<label>Bottle Volume (ml)</label>
										<input type="text" className="form-control" required value={currentEdit.bottleVolume} onChange={e => { setCurrentEdit({ ...currentEdit, bottleVolume: e.target.value }) }} />
									</div>
									<div className="form-group">
										<label>Fuel Volume (ml)</label>
										<input type="text" className="form-control" required Value={currentEdit.fuelVolume} onChange={e => { setCurrentEdit({ ...currentEdit, fuelVolume: e.target.value }) }} />
									</div>
									<div className="form-group">
										<label>Nozzle Diamenter (mm)</label>
										<input type="text" className="form-control" required value={currentEdit.nozzleDia} onChange={e => { setCurrentEdit({ ...currentEdit, nozzleDia: e.target.value }) }} />
									</div>
								</div>
								{!clicked && <input type="submit" className="custom-form-button" value="Update" />}
								{clicked && <input type="submit" className="custom-form-button" value="Updating" disabled />}
								<br />

							</div>
						</form>
						<br />
					</> : null}
				</section>
			</Dialog>

			<Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
				<Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
					{snackBarMessage}
				</Alert>
			</Snackbar>
		</Box>
	</div>
	);
}