import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SelectSearch, { fuzzySearch, useSelect } from 'react-select-search';
import { getAllClassesBySchool, getAllSchoolsForStudent, loginThisStudent } from './Api';
import './loginpage.css';
import { setStudentCookie, studentState } from '../../Atom';
import { useRecoilState } from 'recoil';
import banner from '../../../assets/imgs/login_banner.jfif'
import logo from '../../assets/images/logo.png';
import Sign_in_image from '../../../teacher-admin-builds/assets/images/Sign_in_image.jpg';
import Group_290 from '../../../teacher-admin-builds/assets/images/Group_290.png';
import Sign_in from "../../assets/images/image.png";
import Tag from "../../assets/images/tag.png";

const classes = {
  input: "text-in",
  select: "text-in",
}
function Home(props) {
  const [authUser, setAuthUser] = useState(studentState);

  const [schools, setSchools] = useState([])
  const [curentSchool, setCurentSchool] = useState(null)

  const [sClasses, setSClasses] = useState([])
  const [curentSClass, setCurentSClass] = useState(null)

  const [clicked, setClicked] = useState(false)

  const [userData, setUserData] = useState({ loginId: "", password: "", classId: "" })
  useEffect(() => {
    getAllSchools()
  }, [])
  useEffect(() => {
    if (curentSchool) {
      getAllSClasses(curentSchool)
    }
  }, [curentSchool])
  const getAllSchools = async () => {
    const res = await getAllSchoolsForStudent()

    let tempSchool = res.res.data.data.schools.map(item => {
      return {
        value: item._id,
        name: item.name
      }
    })
    setSchools(tempSchool)
  }
  const getAllSClasses = async (sClassId) => {
    const res = await getAllClassesBySchool(sClassId)
    // console.log(res.res.data.data)
    let tempClasses = res.res.data.data.sclasses.map(item => {
      return {
        value: item._id,
        name: item.name
      }
    })
    setSClasses(tempClasses)
  }
  const handleSubmit = async (event) => {
    event.preventDefault()

    // if(!curentSchool){
    //   alert("Select School")
    //   return
    // }
    // if(!curentSClass){
    //   alert("Select Class/Grade")
    //   return
    // }
    setClicked(true)
    const res = await loginThisStudent({
      // sclassId: curentSClass,
      // schoolId: curentSchool,
      // ...userData
      loginId: userData.loginId.trim(),
      password: userData.password.trim(),
      classId: userData.classId.trim()
    })
    setClicked(false)
    // console.log('Login res',res)
    if (!res.error) {

      // console.log(res.res.data.data)
      // setAuthUser({
      //   token: res.res.data.data.token,
      //   userId: res.res.data.data.userId,
      //   loginId: res.res.data.data.loginId,
      //   className: res.res.data.data.className,
      //   teacher: res.res.data.data.teacher,
      //   classId: res.res.data.data.sclassId,
      //   passChange: res.res.data.data.passwordChange
      // })

      setStudentCookie({
        token: res.res.data.data.token,
        userId: res.res.data.data.userId,
        loginId: res.res.data.data.loginId,
        className: res.res.data.data.className,
        teacher: res.res.data.data.teacher,
        classId: res.res.data.data.sclassId,
        passChange: res.res.data.data.passwordChange
      })
      props.location()
    } else {
      alert("Invalid Credentials!")
    }
  }
  return (
    <div className='teacher-div'>
      <div className="index-bg">
        <img className="class1 fit-cover" src={Sign_in_image} />
        <div className="login main" >
          <header style={{ position: "absolute", width: "100%" }}>
            <div className="container px-4 py-4 mx1-3" >
              <div className="logo px-1 py-1"><a style={{ position: "absolute" }}><img src={Group_290} /></a></div>
            </div>
          </header>
          <main className="indexc">
            <div className="container px-0 pt-4 abs" >
              <div className="row login-form">
                <div className="col-lg-71 d-flex align-items-end justify-content-end ">
                  <div className="">
                    {/* className="pb-5" */}
                    <div className="heading text-end1 border-end border-2 pe-3 border-white d-flex">
                      {/* <h2 className=" main-text">Test engines like</h2>
						<h2 className=" main-text">real rocket scientists</h2> */}
                      <div>
                        <h2 className="h2 main-text loginMessage-font">Test engines like</h2>
                        <h2 className="h2 main-text loginMessage-font">real rocket scientists</h2>
                      </div>

                      <div style={{ borderRight: '3px solid white', marginLeft: '20px' }}>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-51 d-flex align-items-center justify-content-end pe-5">
                  <div className=" p-5 mt-0 bg-gray me-5" style={{ borderRadius: '48px' }}>
                    <div className="">

                      <form>
                        <div className="form-group">
                          <h2 className="form-title">Student login</h2>
                          <input type="text" className="form-control form-control2 mt-3 border border-1 border-white" onBlur={e => setUserData({ ...userData, classId: e.target.value.trim() })}
                            placeholder="Enter Class Id" required onChange={e => setUserData({ ...userData, classId: e.target.value })} value={userData.classId} />
                        </div>
                        <div className="form-group">
                          <input type="text" placeholder="Enter Login Id" className="form-control form-control2 mt-3 border border-1 border-white" onBlur={e => setUserData({ ...userData, loginId: e.target.value.trim() })}
                            onChange={e => setUserData({ ...userData, loginId: e.target.value })} value={userData.loginId} />
                        </div>

                        <div className="form-group">
                          <input type="password" placeholder="Enter password" className="form-control form-control2 mt-3 border border-1 border-white" onBlur={e => setUserData({ ...userData, password: e.target.value.trim() })}
                            onChange={e => setUserData({ ...userData, password: e.target.value })} value={userData.password} />
                        </div>

                        <div className="btn btn1 mt-40 px-0 text-center">
                          <a onClick={(e) => handleSubmit(e)} type="button" className="btn btn-default sign-in">Sign in</a><br />
                          {/* <a type="forgot" className="btn btn-default forgot"><Link to="/forgot-password" style={{color:'black'}}>Forgot-Password?</Link></a> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default Home

{/* <div>
      <div className="student-container">
            <div className='logo'>
                <img src={logo} alt="SpaceTrex" />
            </div>
        <div className="components">
      <div className="login-container">
          <div className="login-content">
          Test engines like <br/> real rocket scientists   
          </div>
          <form className="form login-form" onSubmit={(e)=>handleSubmit(e)}>
            <h1>Welcome, Student</h1>
            <div className="login-input">
              
                  <input type="text" className="text-in" onBlur={e=>setUserData({...userData, classId:e.target.value.trim()})} 
                  placeholder="Enter Class Id" required onChange={e=>setUserData({...userData, classId:e.target.value})} value={userData.classId}/>
                  <input type="text" className="text-in" placeholder="Enter Student Id" required onBlur={e=>setUserData({...userData, loginId:e.target.value.trim()})} 
                  onChange={e=>setUserData({...userData, loginId:e.target.value})} value={userData.loginId}/>
                  <input type="password" className="text-in" placeholder="Enter password" required onBlur={e=>setUserData({...userData, password:e.target.value.trim()})}
                  onChange={e=>setUserData({...userData, password:e.target.value})} value={userData.password}/>
            </div>
            <br />
            {!clicked && <button type="submit" className="login-btn">Sign in</button>}
            {clicked && <button type="submit" className="login-btn" disabled>Signing...</button>}
          </form>
      </div>
      </div>
      </div>
      
    </div> */}

