import axios from "axios";
import { baseUrl } from "../../Base_url";

const localData = JSON.parse(localStorage.getItem("recoil-persist-rocket-app-012034220"));

export const loginAdmin = async (data) => {
    try {
      const res = await axios.post(`${baseUrl}/admin/auth/login`, data);
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const SignupAdmin = async (data,token) => {
    try {
      const res = await axios.post(`${baseUrl}/admin/accounts/signup`, data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const EditAdmin = async (data,id,token) => {
    try {
      const res = await axios.put(`${baseUrl}/admin/accounts/signup?id=${id}`, data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const DeleteAdmin = async (id,token) => {
    try {
      const res = await axios.delete(`${baseUrl}/admin/accounts/signup?id=${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const forgotPassword = async (data) => {
    try {
      const res = await axios.post(`${baseUrl}/admin/auth/forget-password`,data);
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };