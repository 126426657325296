import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import React, { useEffect, useState, useRef } from 'react'
import getCookie, { setTeacherCookie, teacherState } from '../../Atom';
import { Switch, useHistory } from 'react-router'
import "./Navbar.css";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@mui/icons-material/Close';
import Group_66 from "../../assets/images/Group_66.png";
import Group_73 from "../../assets/images/Group_73.png"
import Group_75 from "../../assets/images/Group_75.png"
import location from "../../assets/images/location.png"
import UserImg from "../../assets/images/userimg.png"
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { editTeacherDetails } from './api';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import user from "../../assets/images/user.png";
import Group_88 from "../../assets/images/Group_88.png";
import Group_290 from "../../assets/images/Group_290.png"
// import { useRecoilState } from "recoil";

function Header({ firstName, lastName, email,login,title }) {

	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [password, setPassword] = useState('');
	const [confirmpassword, setConfirmPassword] = useState('');
	const [editOpen, setEditOpen] = useState(false);


	const ChangePasswordForm = async () => {
		let res;
		if (password.trim() == confirmpassword.trim()) {
			let req = { password: password, schoolId: myUser.schoolId, teacherId: myUser?.userId };
			res = await editTeacherDetails(req, myUser?.token);
			if (res.error) {
				alert('Failed to update password');
				handleEditClose();
				handleClose()
			} else {
				alert('Password successfully changed');
				handleEditClose()
				handleClose();
				logoutHandler();
			}
		} else {
			alert('Password does not match');
		}
	}

	const validateForm = () => {
		if (password.trim().length == 0 || confirmpassword.trim().length == 0) {
			return true;
		} else {
			return false;
		}
	}



	const [myUser, setmyUser] = useState(teacherState);

	const logoutHandler = async () => {
		setTimeout(() => {
			// setmyUser({
			// 	fName: null,
			// 	userId: null,
			// 	token: null,
			// 	lName: null,
			// 	districtId: null,
			// 	schoolId: null,
			// 	email: null
			// })
			setTeacherCookie(
				{
					fName: null,
					userId: null,
					token: null,
					lName: null,
					districtId: null,
					schoolId: null,
					email: null
				}
			)
			let hostname = window.location.host;
			window.location.assign(`${window.location.protocol}//${hostname}/teachers`)
		})
	}
	useEffect(() => {
		
		const _cookie = getCookie('recoil-persist')
    if(!_cookie){
      logoutHandler()
    }
    const user = JSON.parse(_cookie)['teacher_v1']
    // setmyUser({ ...myUser, 'page': "dashboard" });
    setmyUser({ ...user});
		checkActive()
	},[login,title])

	const checkActive = () => {
		let dash = document.getElementById('dashboard');
		let clash = document.getElementById('class');
		let device = document.getElementById('device');
		let exp = document.getElementById('experiment');
		let user = document.getElementById('users');
		if (title == 'dashboard') {
			dash.classList.add("active");
			clash.classList.remove("active");
			device.classList.remove("active");
			exp.classList.remove("active");
			user.classList.remove("active");
		} else if (title == 'class') {
			dash.classList.remove("active");
			clash.classList.add("active");
			device.classList.remove("active");
			user.classList.remove("active");
			exp.classList.remove("active")
		} else if (title == 'device') {
			dash.classList.remove("active");
			clash.classList.remove("active");
			device.classList.add("active");
			user.classList.remove("active");
			exp.classList.remove("active")
		} else if (title == 'experiment') {
			dash.classList.remove("active");
			clash.classList.remove("active");
			device.classList.remove("active");
			exp.classList.add("active");
			user.classList.remove("active");
		} else if (title == 'users') {
			dash.classList.remove("active");
			clash.classList.remove("active");
			device.classList.remove("active");
			exp.classList.remove("active");
			user.classList.add("active");
		}
	}

	const validateFields = () => {
		if (password.trim().length == 0 || confirmpassword.trim().length == 0) {
			return true;
		} else {
			return false;
		}
	}

	const handleEditClose = () => {
		setEditOpen(false);
		setPassword('');
		setConfirmPassword('');
		// handleEditClose()
	}

	return (
		<header style={{ position: "absolute", width: "100%" }} >
			<div class="container px-4 py-4 bs-container">
				<div class="row px-1 py-1">
					<div class="col-lg-2c pe-0">
						<div class="logo"><Link to="/"><img src={Group_290} /></Link></div>
					</div>
					<div class="col-lg-7c px-0 pt-4">
						<div class="d-flex justify-content-center">
							<Link to={'/'} class="tab-icon header-pad active text-center" id="dashboard" style={{ cursor: "pointer" }} >
								<img src={Group_75} class="pe-3" />
								<span className="header-pad"></span>
								<span style={{ fontSize: '16px', color: 'black', fontWeight: 300 }}>Dashboard</span>
							</Link>
							<Link to={'/myclasses'} class="tab-icon header-pad text-center" id="class" style={{ cursor: "pointer" }} >
								<img src={location} class="pe-3" />
								<span className="header-pad"></span>
								<span style={{ fontSize: '16px', color: 'black' }}>Class</span>
							</Link>
							<Link to={'/mydevices'} class="tab-icon header-pad text-center" id="device" style={{ cursor: "pointer" }} >
								<img src={Group_73} class="pe-3" />
								<span className="header-pad"></span>
								<span style={{ fontSize: '16px', color: 'black', fontWeight: 300 }}>Devices</span>
							</Link>
							<Link to={'/users'} class="tab-icon header-pad text-center" id="users" style={{ cursor: "pointer" }} >
								<img src={UserImg} class="pe-3" />
								<span className="header-pad"></span>
								<span style={{ fontSize: '16px', color: 'black', fontWeight: 300 }}>User</span>
							</Link>
							<Link to={'/myexperiments'} class="tab-icon header-pad text-center" id="experiment" style={{ cursor: "pointer" }} >
								<img src={Group_66} class="pe-3" />
								<span className="header-pad"></span>
								<span style={{ fontSize: '16px', color: 'black', fontWeight: 300 }}>Experiment</span>
							</Link>
						</div>
					</div>
					<div class="col-lg-3c mt-4">
						<div class="d-flex justify-content-end align-items-center">
							<div class="info text-end d-flex flex-column pe-1">
								<span class="fonted" style={{ fontSize: 'medium', textAlign: 'end' }}>{firstName} {lastName}</span>
								<span class="fonted" style={{ fontSize: 'medium', textAlign: 'end' }}>{email}</span>
							</div>
							&nbsp;
							<div>
								<div class="dropdown">
									<div className="bell-icon">
										<button className="btn dropdown-toggle bell-icon1" onClick={handleClick} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
											<img src={Group_88} />
										</button>
										<Menu sx={{ textAlign: 'center', width: '500px' }}
											id="basic-menu"
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{ 'aria-labelledby': 'basic-button' }}
											transformOrigin={{ horizontal: 'right', vertical: 'top' }}
											anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
										>
											<MenuItem ><img src={user} style={{ height: "70px", width: "auto" }} /></MenuItem>
											<MenuItem className="fonted" onClick={() => setEditOpen(true)}>Change Password</MenuItem>
											<MenuItem className="fonted" onClick={logoutHandler}>Logout</MenuItem>
										</Menu>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Dialog
				onClose={handleEditClose}
				open={editOpen}
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none"
					},
				}}
				aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modal-dialog modal-dialog-centered modal-dialog-scrollable teacher-div">
				<div className="modal-content" style={{ width: '350px' }}>
					<div className="modal-header-n d-flex justify-content-end">
						<button type="button" onClick={handleEditClose} class="btn-close" data-bs-dismiss="modal" aria-label="Close"><CloseIcon /></button>
					</div>
					<form>
						<div class="modal-body px-4 pb-2">
							<div >
								<label for="recipient-name" className="col-form-label ps-4">Change Password </label>
								<br />
								<input type="password" class="modal-form-control px-4" value={password} placeholder="Password"
									onChange={(e) => { setPassword(e.target.value) }}
									onBlur={(e) => { setPassword(e.target.value.trim()) }} />
								<br />
								<br />
								<input type="password" class="modal-form-control px-4" value={confirmpassword} placeholder=" Confirm Password"
									onChange={(e) => { setConfirmPassword(e.target.value) }}
									onBlur={(e) => { setConfirmPassword(e.target.value.trim()) }} />
							</div>
						</div>
						<div class="modal-footer px-4">
							<button type="button" disabled={validateFields()} onClick={ChangePasswordForm} class="btn btn-pitch">Submit</button>
						</div>
					</form>
				</div>
			</Dialog>
		</header>
	)
}

export default Header