import axios from "axios";
import { baseUrl } from "../Base_url";

export const createSuper = async (data,token) => {
    try {
        const res = await axios.post(`${baseUrl}/admin/accounts/signup`, data,{
            headers: { Authorization: `Bearer ${token}` },
          });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};