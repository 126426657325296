import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./profile.css";
import getCookie, { setAdminCookie, userState } from "../../Atom";
import { useRecoilState } from "recoil";
import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { editProfile, getProfile } from "./api";
export default function Profile() {
  const [myUser, setmyUser] = useRecoilState(userState);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [fname, setfName] = useState("");
  const [lname, setlName] = useState("");

  // console.log(myUser);
  useEffect(() => {
    const _cookie = getCookie('recoil-persist')
        if(!_cookie){
          logoutHandler()
        }else{
        const user = JSON.parse(_cookie)['KT_app']
        setmyUser(user)
    getMyProfile();
        }
  }, [0]);

  const logoutHandler=async()=>{
    setTimeout(()=>{
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })
      setAdminCookie({
        fName:null,
        lName:null,
        token:null,
        userId:null
      })
      let hostname= window.location.host;
    //   console.log(hostname,window.location.protocol)
      window.location.assign(`${window.location.protocol}//${hostname}/super-admin`)   
    })  
  }

  const getMyProfile = async () => {
    let res = await getProfile(myUser.token);
    if (res.error) {
      alert("Failed to fetch profile details. Try Again");
    } else {
      // console.log(res.res.data.data);
      let { fName, lName, email } = res.res.data.data.admin;
      setfName(fName);
      setlName(lName);
      setEmail(email);
      //  console.log(fName)
    }
  };
  const edit = async () => {
    let data = { fName: fname, lName: lname };
    let res = await editProfile(data, myUser.token);
    if (res.error) {
      alert("Failed to edit profile. Try Again");
    } else {
      // console.log(res.res.data.data);
      alert("Profile details Successfully Changed");
      //  console.log(fName)
      setmyUser({
        fName: fname,
        userId: myUser.userId,
        token: myUser.token,
        lName: lname,
        email: myUser.email,
      });
    }
  };
  return (
    <section id="hero-dashborad" className="main-div-admin ">
          <NavBar />
      <div className="row m-auto">
       
        <div className="col-md-10 m-auto dashboard-div min-height">
          <div className="dashboard-heading">
            <h1 className="span-h1">Welcome {fname}</h1>
          </div>
          <div className="mt-4">
            <div className="col-md-7 text-left col-9 m-auto">
              <div className="form-group mt-4   p-20">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={fname}
                  onChange={(e) => setfName(e.target.value)}
                />
              </div>

              <div className="form-group mt-4   p-20">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={lname}
                  onChange={(e) => setlName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <button type="submit" className="cta-btn" onClick={edit}>
                  {loading ? <CircularProgress /> : "Edit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
