import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import SideBar from "./school-admin-build/components/Sidebar/SideBar";
import DashBoard from "./school-admin-build/components/Dashboard/DashBoard";
import School from "./school-admin-build/components/School-Districts/School";
import { useRecoilState } from "recoil";
import { schoolAdminState } from "./school-admin-build/Atom";
import ForgotPasswordScreen from "./school-admin-build/components/Auth/ForgotPasswordPage/ForgotPasswordScreen";
import LoginScreen from "./school-admin-build/components/Auth/LoginPage/LoginScreen";
import NewPasswordScreen from "./school-admin-build/components/Auth/NewPasswordPage/NewPasswordScreen";
import Device from './school-admin-build/components/Devices/Devices'
import UploadDevice from './school-admin-build/components/UploadDevice/UploadDevice'
import AddDevice from './school-admin-build/components/AddDevice/AddDevice'
import SchoolClass from "./school-admin-build/components/School-Districts/SchoolClass";
import Experiment from "./school-admin-build/components/Experiments/Experiment";
import Analysis from "./school-admin-build/components/Analysis/Analysis";


function SchoolRouter() {
  const [authUser, setAuthUser] = useRecoilState(schoolAdminState);



  return (
    <div className="App">
      <Router basename="/schools">
        {authUser ? (
          <div
            className="container-fluid"
            style={{ minHeight: "100vh", background: "#f8f8fb" }}
          >
            <div className="row" style={{ minHeight: "100vh" }}>
              <div
                className="col-2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  padding: "0",
                }}
              >
                <SideBar />
              </div>
              <div className="col-md-10 dashboard-div min-height main-div-admin ">
                <Switch>
                  <Route path="/" exact component={DashBoard} />
                  <Route path="/myschool" exact component={School} />
                  <Route path="/devices" exact component={Device} />
                  <Route path="/add-device" exact component={AddDevice} />
                  <Route path="/upload-device" exact component={UploadDevice} />
                  <Route path="/teacher/:id" exact component={SchoolClass}/>
                  <Route path="/experiments" exact component={Experiment}/>
                  <Route path="/experiments/:id" exact component={Analysis}/>
                  
                </Switch>
              </div>
            </div>
          </div>
        ) : (
          <Switch>
            <Route path="/"  component={LoginScreen} />
            <Route path="/forgot-password" exact component={ForgotPasswordScreen} />
            <Route
              path="/new-password/:id"
              exact
              component={NewPasswordScreen}
            />
           
          </Switch>
        )}
      </Router>
    </div>
  );
}

export default SchoolRouter;
