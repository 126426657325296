import axios from "axios";
import { baseUrl } from "../Base_url";

export const getAllExperimentsForDistrictAdmin = async (token, page, limit, serialId) => {
    try {
        const res = await axios.get(`${baseUrl}/district-admin/school-district/experiment/all?page=${page}&limit=${limit}&serial=${serialId}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};

export const editAnExperiment = async (id, data, token) => {
    try {
        const res = await axios.put(`${baseUrl}/district-admin/school-district/experiment/${id}`, data, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};
export const getSchoolByDistrict = async (id, token) => {
    try {
        const res = await axios.get(`${baseUrl}/admin/school-district/${id}/school/all`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
}


export const getAllSchoolTeachers = async (sid, token) => {
    try {
        const res = await axios.post(`${baseUrl}/getTeacherBySchoolId`, { id: sid }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
}
export const getClassesByTeachers = async (sid, token) => {
    try {
        const res = await axios.post(`${baseUrl}/getClassesByTeachers`, { teacherId: sid }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
}
export const getStudentByclassId = async (sid, token) => {
    try {
        const res = await axios.post(`${baseUrl}/getStudentByclassId`, { id: sid }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
}
export const getSchoolByDistrictAdmin = async (token) => {
    try {
        const res = await axios.get(`${baseUrl}/district-admin/school-district/school/all`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
}