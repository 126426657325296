import axios from "axios";
import { baseUrl } from "../../Base_url";


export const loginTeacher = async (data) => {
  try {
    const res = await axios.post(`${baseUrl}/teacher/auth/login`, data);
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const forgotPassword = async (data) => {
  try {
    const res = await axios.post(`${baseUrl}/teacher/auth/forget-password`, data);
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};