export default (value) =>{
    let modalElement = document.getElementById("addmodal");
  //  console.log(value)
    if(value){
        modalElement.classList.add("visible");
    }
  else{
    modalElement.classList.remove("visible");
  }
      // setTimeout(()=>{
      //     elem.style.animation = `fadout 0.5s  0.2s`
      // },timeOutInMilliSeconds-500)
    }