import {
	BrowserRouter as Router,
	Switch,
	Route,
	useHistory,
	Redirect,
} from "react-router-dom";
import Sign_in_image from "./assets/images/Sign_in_image.jpg";
import SideBar from "./components/Sidebar/SideBar";
import DashBoard from "./components/Dashboard/DashBoard";
import LoginScreen from "./components/Auth/LoginPage/LoginScreen";
import { useRecoilValue } from "recoil";
import getCookie, { teacherState } from "./Atom";
import SchoolClass from './components/School-Districts/SchoolClass'
import Devices from './components/Devices/Devices'
import Experiment from "./components/Experiments/Experiment";
import Analysis from "./components/Analysis/Analysis";
import SClasses from "./components/School-Districts/SClasses";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import logo from "./assets/images/logo.png";
// import "./teacherStyle.css";
import "./teach-Style.css";
import "./bootstrap.min.css";
import Header from "./components/Navbar/Header";
import { useRecoilState } from "recoil";
import User from "./components/Users/user";
import ForgotPasswordScreen from "./components/Auth/ForgotPasswordPage/ForgotPasswordScreen";
import { useState } from "react";


function TeacherRouter() {
	// const { token, fName, lName, email } = useRecoilValue(teacherState);
	const _cookie = getCookie('recoil-persist')
  const user = JSON.parse(_cookie)?.teacher_v1
  const [myUser, setmyUser] =  useState(user)
  const [loginDone, setLoginDone] = useState(false)
  const [pageTitle, setPageTitle] = useState('')
  
function setPageHeader(title){
	setPageTitle(title)
}

  function loginOpen(){
    // console.log('This was called')
    const _cookie = getCookie('recoil-persist')
    const user = JSON.parse(_cookie)['teacher_v1']
    setmyUser(user)
	setLoginDone(true)
  }
	return (
		<div className="App teacher-div" >
			<Router basename="/teachers">
				{myUser?.token ? (
					<div className="index-bg">
						<img className="class fit-cover" src={Sign_in_image} />
						<div className="login">
							<Header firstName={myUser?.fName} lastName={myUser?.lName} email={myUser?.email}
							 	login={loginDone} title={pageTitle} />
							<br />
							<br />
							<br />
							<Switch>
								<Route path="/" exact render={props => (<DashBoard page={setPageHeader} />)} />
								<Route path="/myclass/:sclassId/:classID" exact render={props => (<SchoolClass page={setPageHeader} />)}/>
								<Route path="/myclasses" exact render={props => (<SClasses page={setPageHeader} />)} />
								<Route path="/users" exact render={props => (<User page={setPageHeader} />)} />
								<Route path="/mydevices" exact render={props => (<Devices page={setPageHeader} />)}/>
								<Route path="/myexperiments" exact render={props => (<Experiment page={setPageHeader} />)}/>
								<Route path="/experiments/:id" exact render={props => (<Analysis page={setPageHeader} />)}/>
								<Route path="/change-password" exact component={ChangePassword} />
							</Switch>
						</div>
					</div>
				) : (
					<Switch>
						<Route exact path="/" 
						// component={LoginScreen} 
						render={props => (<LoginScreen location={loginOpen} />)} 
						/>
						<Route exact path="/forgot-password" component={ForgotPasswordScreen} />
					</Switch>
				)}
			</Router>
		</div>
	);
}

export default TeacherRouter;
