import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutoCompletes(props) {
    console.log(props.value)
    return (
        <Autocomplete

            id="country-select-demo"
            fullWidth

            value={props?.value}
            options={props.options}
            // freeSolo={props?.freeSolo}
            autoHighlight
            isOptionEqualToValue={(option, value) => option.name? option?.name === value: option?.loginId=== value}

            getOptionLabel={(option) => option?.name ? option?.name :option}
            onChange={(event, newValue) => {
                props.setValue(newValue);
            }}

            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                    {option?.loginId ? option?.loginId : option?.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    variant="standard"
                    required={props?.notR ? false : true}
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}