import axios from "axios";
import { baseUrl } from "../Base_url";

export const inviteDetails = async (token) => {
    try {
        const res = await axios.get(`${baseUrl}/invite?token=${token}`);
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};
export const registerInvite = async (token, data) => {
    try {
        const res = await axios.post(`${baseUrl}/invite?token=${token}`,data, {
            headers: { "Content-Type": "application/json" },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};

export const changePasswordInvite = async (token) => {
    try {
        const res = await axios.get(`${baseUrl}/invite/resetPassword?token=${token}`, {
            headers: { "Content-Type": "application/json" },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};

export const resetPassword = async (token, data) => {
    try {
        const res = await axios.post(`${baseUrl}/invite/resetPassword?token=${token}`,data, {
            headers: { "Content-Type": "application/json" },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};

