import RegisterForm from './RegisterForm'
import CustomModal from '../../Modal/Modal'
import './Register.css'
import '../Auth.css'
export default function RegisterScreen() {
  return (
    <div>
    
      <div>
      <div className='authentication-section'>
        <div className=''>
          <div className='main-form row '>
            <div className='col-lg-6 col-md-10 m-auto pb-10'>
          <div className='auth-form  mt-10  '>
          <div className='auth-heading'>

      <h2 > Sign-up
      </h2>
      <span>Create A new Account </span>
        </div>
          <RegisterForm/>
          </div>
            </div>
            <div className='col-md-0 col-lg-6 svg-div '>
          <div className='ptb-100 '  >
            <img className='img-height-100' src='assets/loginVector.svg'/>
        
          </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
