import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./DashBoard.css";
import getCookie, { setTeacherCookie, teacherState } from '../../Atom'
import { useRecoilState } from "recoil";
import DashboardStats from "./DashboardStats";
import React, { useEffect, useState, } from "react";
import { fetchTeacherProfile } from "./api";
import Header from "../Navbar/Header"

import Snackbar from '@mui/material/Snackbar';
// import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
// import { useRecoilState } from "recoil";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DashBoard(props) {
  const [myUser, setmyUser] = useState(teacherState)
  const [data, setData] = useState(null);

  const [snackBarShow, setSnackBarShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');

  const logoutHandler = async () => {
    setTimeout(() => {
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })
      setTeacherCookie({
        fName: null,
        userId: null,
        token: null,
        lName: null,
      })
      // let hostname = window.location.host;
      // // console.log(hostname,window.location.protocol)
      // window.location.assign(`${window.location.protocol}//${hostname}/teachers`)
    })
  }


  const feedBackMsg = (snackShow, snackSeverity, snackMessage) => {
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    feedBackMsg(false, '', '');
  }

  useEffect(() => {
    
    const _cookie = getCookie('recoil-persist')
    if(!_cookie){
      logoutHandler()
    }
    const user = JSON.parse(_cookie)['teacher_v1']
    // setmyUser({ ...myUser, 'page': "dashboard" });
    setmyUser({ ...user, 'page': "dashboard" });
    props.page('dashboard')
    getProfileDetail(user);
  }, []);

  const getProfileDetail = async (user=myUser) => {
    const res = await fetchTeacherProfile(user?.token);
    if (res.error) {
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {
      setData(res?.res?.data?.teacher)
    }
    // console.log(res.res.data.teacher.school.name)

  }
  return (
    <main style={{ position: "absolute", width: "100%", top: "20%" }}>
      <div className="container px-4 pt-3">
        <div className="upper-section px-60">
          <div className="breadcrumb-menu py-2">
            <a className="disable">Teacher Panel </a><i className="fas fa-angle-double-right"></i><a className="disable">Dashboard</a>
          </div>
          <div className="text-blue subtext"><h2 className="font-WeightAdjust h2">{data?.school?.name}</h2></div>
          <div className="text-blue maintext" style={{ textTransform: 'capitalize' }}>
            <h2 className="font-WeightAdjust h2">Welcome {myUser.fName} {myUser.lName}</h2>
          </div>
        </div>
        <DashboardStats />
      </div>
      <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
        <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </main>
  );
}
