import axios from "axios";
import { baseUrl } from "../Base_url";

export const getProfile = async (token) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/accounts/profile`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const editProfile = async (data, token) => {
    try {
      const res = await axios.put(`${baseUrl}/admin/accounts/profile`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

 
  
 
  
  
  