import axios from "axios";
import { baseUrl } from "../Base_url";


const userData = JSON.parse(localStorage.getItem("recoil-persist-rocket-app-012034220"));


let token = "";

if (userData) {
  if (userData.KT_app_School_Admin) {
    token = userData.KT_app_School_Admin.token;
  }else{
    // alert('You need to login first')
  }
}



export const getStats = async (token) => {
    try {
      const res = await axios.get(`${baseUrl}/school-admin/school/dashboard/entities-count`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res.data };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
