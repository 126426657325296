import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./School-District.css";

import { Link, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { editTeacherDetails, getSchoolById, getAllSchoolTeachers,deleteStudent, getTeacherDetails, InviteSchoolTeacher, getAllStudents, createNewStudent } from "./api";
import { districtAdminState } from '../../Atom'
import { useRecoilState } from "recoil";
import ManageTeacherDevice from '../ManageTeacherDevice/ManageTeacherDevice'
import CustomModal from "../Modal/CusModal";
import { CircularProgress, } from "@material-ui/core";
import { Modal, Button } from 'react-bootstrap'
import Popup from "../popup/Popup";
import { AssignDevice, DeleteAssignedDevice, getAllAssignedDevicesForTeacher, SpareAssignedDevicesTeacher } from "../ManageTeacherDevice/api";


export default function Teacher({match}) {
  const schoolId = match.params.schoolId;
  const teacherId = match.params.teacherId;
  const [myUser, setmyUser] = useRecoilState(districtAdminState)
  const [schoolDetails, setSchoolDetails] = useState({})
  const [students, setStudents] = useState([])
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fname, setfName] = useState("")
  const [lname, setlName] = useState("")
  const [teacherDetails, setteacherDetails] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);

  const [newStudentData, setNewStudentData] = useState({loginId:'', password:''})
  let params = useParams()


  let { districtId, id } = params
  const newStudentForm = useRef()

  useEffect(() => {
    getThisTeacherInfo()
    getStudents()
  }, [0])

  const getThisTeacherInfo = async () => {
    let res = await getTeacherDetails(teacherId, schoolId,myUser.token)
    // console.log("Tecaher =>", res.res.data.data.teacher)
    setteacherDetails(res.res.data.data.teacher)
  }

  const getStudents = async () => {
    let res1 = await getAllStudents(teacherId, schoolId, myUser.token)


    if (res1.error) {
      // alert('Something went Wrong')
    }
    else {

      // console.log(res1.res.data.data.students)
      setStudents(res1.res.data.data.students)
    }
  }



  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };


  const sendMail = async () => {
    setLoading(true)
    let data = { email: email, message: message }
    let ids = { did: districtId, sid: id }
    let res = await InviteSchoolTeacher(schoolId, myUser.token, data);
    if (!res.error) {
      setLoading(false)
      alert('Mail Sent')
      handleClose()
    }
    else {
      setLoading(false)

      alert('Something went wrong')

    }
    // console.log(res);
  };





  const showEditModal = async (teacherid) => {
    let res = await getTeacherDetails(teacherid,schoolId, myUser.token);
    if (!res.error) {
      // console.log(res.res.data.data, "hello")
      setteacherDetails(res.res.data.data.teacher)
      handleShowAddModal()
    }
    else {
      setLoading(false)

      alert('Something went wrong')

    }
  }


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showManage, setShowManage] = useState(false);
  const handleShowManageDevice = () => {
    setShowManage(false)
  }

  const assignThisDeviceToTeacher = async (deviceId) => {
    let res = await AssignDevice(schoolId, teacherId, deviceId, myUser.token)
    // console.log(res)
  }

  const unassignThisDeviceToTeacher = async (deviceId) => {
    let res = await DeleteAssignedDevice(schoolId, teacherId, deviceId)
  };

  const handleStudentCreation = async (e) => {
    e.preventDefault()
    let res = await createNewStudent(schoolId, teacherId, myUser.token, newStudentData)
    newStudentForm.current.close()
    getStudents()
  }
  const deleteStudentFunc = async (id) => {
    var result = window.confirm("Sure to delete?");
    if (result) {
        let res = await deleteStudent(schoolId, teacherId, id, myUser.token);
        getStudents()
    }
  }
  return (
    <section id="hero-school ">



      <Popup ref={newStudentForm}>
        <form className="form" onSubmit={(e)=>{handleStudentCreation(e)}}>
          <center><h5>New Student</h5></center>
          <div className="form-group">
            <label>Login Id</label>
            <input className="form-control" type="text" required onChange={(e)=>{setNewStudentData({...newStudentData, loginId: e.target.value.trim()})}}/>
          </div>
          <div className="form-group">
            <label>Password</label>
            <input className="form-control" type="text" required onChange={(e)=>{setNewStudentData({...newStudentData, password: e.target.value.trim()})}}/>
          </div>
          <input type="submit" className="btn btn-primary" value="Create"/>
        </form>
      </Popup>





      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Teacher</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <>
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value.trim())}
              />
            </div>
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control"
                placeholder="Message"
                onChange={(e) => setMessage(e.target.value.trim())}
              />
            </div>
          </>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={sendMail}>
            Send Request
          </Button>

        </Modal.Footer>
      </Modal>

     
        <div >
          <NavBar />
          <div className="dashboard-heading ml-4 textCap">
            <h2>{schoolDetails.name}</h2>
          </div>
          {/* <div className="dashboard-notification-cards m-auto row">
            <div className="col-md-3 col-sm-12 notification-card p-3">
              <h2>9</h2>

              <h6 className="ml-2 notification-subHeading span-h4">Teachers</h6>
            </div>
            <div className="col-md-3 col-sm-12 notification-card p-3">
              <h2>30</h2>

              <h6 className="ml-2 notification-subHeading span-h4">Students</h6>
            </div>
            <div className="col-md-3 col-sm-12 notification-card p-3">
              <h2>30</h2>

              <h6 className="ml-2 notification-subHeading span-h4">Device</h6>
            </div>
          </div> */}
          <div
            className="mt-5"
          >
            <h2 style={{textTransform:'capitalize'}}>
              {`${teacherDetails?.fName} ${teacherDetails?.lName}`}
              <Link onClick={()=>newStudentForm.current.open()}>
                <button className="btn btn-add-device float-right ">
                  <i className="fas fa-plus-circle"></i> {"  "}
                  <span> Add New Student</span>
                </button>



              </Link>

            </h2>
          </div>

          <div>
          <table className="table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Login Id</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {students.map((item, index) => {
                return (

                  <tr key={index} hover role="checkbox" tabIndex={-1}>
                    <td className="">

                      {item._id}        </td>
                      <td className=" textCap">{item.loginId}</td>{" "}
                    
                    <td className="">

                      
                      <Link onClick={() => {
                        deleteStudentFunc(item._id)
                      }}>
                        <svg width="21" height="21" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.75 11.25L11.25 18.75" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.25 11.25L18.75 18.75" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                      </Link>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        </div>
 
    </section>
  );
}
