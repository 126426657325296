import React, { useState, useEffect } from "react";
import "./DashBoard.css";
import { getStats } from "./api";
import { useRecoilValue } from "recoil";
import { schoolAdminState } from "../../Atom";
// eslint-disable-next-line react-hooks/rules-of-hooks



export default function DashboardStats() {

  const { token, schoolId, districtId } = useRecoilValue(schoolAdminState);
  const [stats, setStats] = useState({
    teacher: 0,
    student: 0,
    device: 0,
    experiment: 0,
  });

  const getDashboardStats = async () => {
    const res = await getStats(token);

    if (res.error) {
      return console.error("Error is", res.error);
    }

    if (!res.error) {
      setStats(res.res.data);
    }
  };

  useEffect(() => {
    getDashboardStats();
    return () => {};
  }, [1]);



  return (
    <section id="hero-dashborad">
        <div className="container dashboard-table-container" >
          <div className="row justify-content-between">
            <div className="col-md-4 col-sm-12 notification-card p-3">
              <h2>{stats.student}</h2>

              <h6 className="ml-2 notification-subHeading span-h4 ">
                Students
              </h6>
            </div>
            <div className="col-md-4 col-sm-12 notification-card p-3">
              <h2>{stats.teacher}</h2>

              <h6 className="ml-2 notification-subHeading span-h4">Teachers</h6>
            </div>
            <div className="col-md-4 col-sm-12 notification-card p-3">
              <h2>{stats.device}</h2>

              <h6 className="ml-2 notification-subHeading span-h4">Devices</h6>
            </div>
            <div className="col-md-4 col-sm-12 notification-card p-3">
              <h2>{stats.experiment}</h2>

              <h6 className="ml-2 notification-subHeading span-h4">
                Experiments
              </h6>
            </div>
          </div>
        </div>
    </section>
  );
}
