import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import NavBar from "../Navbar/Navbar";
import { useRecoilValue, useRecoilState } from 'recoil'
import getCookie, { setAdminCookie, userState } from '../../Atom'
import { deleteAnExperiment, editAnExperiment, getAllExperimentsForSuperAdmin, getAllSchoolDistricts, getAllSchoolTeachers, getClassesByTeachers, getSchoolByDistrict, getSchoolDistrictsById, getStudentByclassId } from './api'
import Badge from '@mui/material/Badge';
import './style.css'
import Popup from '../popup/Popup';

import Dialog from '@mui/material/Dialog';
import Grid from "@material-ui/core/Grid";
// import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import Typography from "@material-ui/core/Typography";
import DialogTitle from '@mui/material/DialogTitle';

import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom";



import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Snackbar from '@mui/material/Snackbar';
// import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Autocomplete, TextField } from '@mui/material';
import AutoCompletes from './AutoCompletes';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'deviceId',
    numeric: false,
    disablePadding: true,
    label: 'Serial Id',
  },
  {
    id: '_id',
    numeric: false,
    disablePadding: true,
    label: 'Exp Id',
  },
  {
    id: 'deviceName',
    numeric: false,
    disablePadding: true,
    label: 'Device Name',
  },
  {
    id: 'fuelVolume',
    numeric: true,
    disablePadding: true,
    label: 'Fuel Volume',
  },
  {
    id: 'nozzleDia',
    numeric: true,
    disablePadding: true,
    label: 'Nozzle Diameter',
  },
  {
    id: 'pressure',
    numeric: true,
    disablePadding: true,
    label: 'Pressure',
  },
  {
    id: 'className',
    numeric: false,
    disablePadding: true,
    label: 'Class',
  },
  {
    id: 'studentName',
    numeric: false,
    disablePadding: true,
    label: 'Student',
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: true,
    label: 'Date',
  },
  {
    id: 'time',
    numeric: true,
    disablePadding: true,
    label: 'Time',
  },

];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              className="sort-header"
              sx={{ display: 'flex' }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell
          align={'center'}
          padding={'normal'}>
          <div className=' mb-2 '>
            Analysis
          </div>
        </TableCell>
        <TableCell
          align={'center'}
          padding={'normal'}>
          <div className=' mb-2'>
            Action
          </div>
        </TableCell>
      </TableRow>
    </TableHead>

  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    // 
    <div className='pt-10'>

    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [totalPage, setTotalPage] = useState(0);
  const history = useHistory();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let paramse = useParams();
  const experimentEditModal = useRef()
  const [experiments, setExperiments] = useState([])
  const [paged, setPaged] = useState(0)
  // const { token } = useRecoilValue(userState)


  const [clicked, setclicked] = useState(false)

  const [noOfPages, setNoOfPages] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(25);
  const [serial, setSerial] = useState(null);
  const [editMode, setEditmode] = useState(false);
  const [snackBarShow, setSnackBarShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [currentEdit, setCurrentEdit] = useState(null);
  const [open, setOpen] = useState(false);
  const [schoolDistrictData, setSchoolDistrictData] = useState([]);
  const [teacherData, setTeacherData] = useState([]);
  const [classData, setclassData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [myUser, setmyUser] = useState(userState);
  const logoutHandler = async () => {
    setTimeout(() => {
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })
      setAdminCookie({
        fName: null,
        lName: null,
        token: null,
        userId: null
      })
      let hostname = window.location.host;
      window.location.assign(`${window.location.protocol}//${hostname}/super-admin`)
    })
  }

  const feedBackMsg = (snackShow, snackSeverity, snackMessage) => {
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    feedBackMsg(false, '', '');
  }

  useEffect(() => {
    const _cookie = getCookie('recoil-persist')
    if (!_cookie) {
      logoutHandler()
    } else {
      const user = JSON.parse(_cookie)['KT_app']
      setmyUser(user)
      let urlPage = parseInt(params.get('page'));
      let urlLimit = parseInt(params.get('limit'));
      let urlSerial = params.get('serial')
      // console.log(urlPage)
      if (parseInt(params.get('page')) || parseInt(params.get('limit'))) {
        // console.log('lol1')
        history.push({
          pathname: '/experiments',
          search: `?${(urlSerial) ? `serial=${urlSerial}&` : ''}` + 'page=' + urlPage + '&limit=' + urlLimit
        })
        getListOfExperiments(urlPage, urlLimit, user);
        setPaged(urlPage);
        setSerial(urlSerial);
        setPageLimit(urlLimit);
      } else {
        // console.log('lol')
        history.push({
          pathname: '/experiments',
          search: `?${(urlSerial) ? `serial=${urlSerial}&` : ''}` + 'page=0&limit=25'
        })
        getListOfExperiments(0, pageLimit, user)
      }
    }
    // console.log('Again call')
  }, [])

  const createTableFormatData = (data) => {
    // ADDS SOME MORE PROPETIES TO TABLE DATA FROM SERVER -- BASICALLY NESTED OBJECTS IN RESPONSE
    return new Promise(resolve => {
      let arr = []
      for (let index = 0; index < data.length; index++) {
        const __exp = { ...data[index], date: new Date(data[index].createdAt).toLocaleDateString(), time: new Date(data[index].createdAt).toLocaleTimeString(), deviceId: data[index]?.device?._id || data[index]?.deviceSerialId, deviceName: data[index]?.device?.name || data[index]?.deviceName };
        arr.push(__exp)
        if (index == data.length - 1) resolve(arr)
      }
    })
  }

  const getListOfExperiments = async (pages, limits, user = myUser) => {
    let arr = [];
    let res;
    res = await getAllExperimentsForSuperAdmin(user?.token, pages, limits, (params.get('serial')) ? params.get('serial') : null)
    if (res.error) {
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {
      // console.log(res.res.data.data.experiments)
      let experiment = await createTableFormatData(res.res.data.data.experiments)

      setExperiments(experiment);
      setTotalPage(res.res.data.data.totalPages);
      let divisor = parseInt(res.res.data.data.totalPages / limits);
      let remainder = parseInt((res.res.data.data.totalcount % limits == 0) ? 0 : 1);
      setNoOfPages(divisor + remainder)
      setCurrPage(pages);
      // console.log(pages)
    }
  }
  const initiateEdit = (data) => {
    getSchoolDistrictAll();
    setCurrentEdit(data);
    setOpen(true);
    setEditmode(true)
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    let { _id, className, studentName, bottleVolume, fuelVolume, nozzleDia, school, schoolDistrict, teacher } = currentEdit;
    let data = {
      "className": className,
      "studentName": studentName,
      "bottleVolume": bottleVolume,
      "fuelVolume": fuelVolume,
      "nozzleDia": nozzleDia,
      "school": school,
      "schoolDistrict": schoolDistrict,
      "teacher": teacher
    }
    setclicked(true)
    const res = await editAnExperiment(_id, data, myUser.token);
    if (res.error) {
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {
      feedBackMsg(true, 'success', res.res.data.msg);
    }
    setclicked(false)
    handleClose();
    getListOfExperiments(paged, pageLimit)
  }

  const handleDelete = async (id) => {
    if (window.confirm("Delete this Experiment?")) {
      const res = await deleteAnExperiment(id, myUser.token);
      if (res.error) {
        if (res.data.statusCode == 401) {
          logoutHandler();
        }
        else {
          feedBackMsg(true, 'error', res.data.msg);
        }
      } else {
        feedBackMsg(true, 'success', res.res.data.msg);
      }
      getListOfExperiments(paged, pageLimit);
    }
  }


  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageLimit);
  const handleRequestSort = (event, property) => {
    // console.log(property)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = experiments.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPaged(event.selected);
    getListOfExperiments(event.selected, pageLimit);
    history.push({
      pathname: '/experiments',
      search: `?${(serial) ? `serial=${serial}&` : ''}` + 'page=' + event.selected + '&limit=' + pageLimit
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClose = () => {
    setOpen(false);
    setEditmode(false);
  }

  const getSchoolDistrictAll = async () => {
    const _cookie = getCookie('recoil-persist')

    const user = JSON.parse(_cookie)['KT_app']
    const res = await getAllSchoolDistricts(user.token);
    setSchoolDistrictData(res?.res?.data?.data?.data)
  }

  const getSchoolSchoolDistrictsid = async (id) => {
    const _cookie = getCookie('recoil-persist')

    const user = JSON.parse(_cookie)['KT_app']
    const res = await getSchoolByDistrict(id, user.token);
    setSchoolData(res?.res?.data?.data?.schools)
  }
  const getClassBySchoolId = async (id) => {
    const _cookie = getCookie('recoil-persist')

    const user = JSON.parse(_cookie)['KT_app']
    const res = await getAllSchoolTeachers(id, user.token);
    setTeacherData(res?.res?.data);
  }

  const getclassbyTeacherId = async (id) => {
    const _cookie = getCookie('recoil-persist')

    const user = JSON.parse(_cookie)['KT_app']
    const res = await getClassesByTeachers(id, user.token);
    setclassData(res?.res?.data);
  }

  const getStudentByclassIds = async (id) => {
    const _cookie = getCookie('recoil-persist')

    const user = JSON.parse(_cookie)['KT_app']
    const res = await getStudentByclassId(id, user.token);
    setStudentData(res?.res?.data);
  }

  useEffect(() => {
    if (currentEdit?.schoolDistrict?.length > 3) {
      getSchoolSchoolDistrictsid(currentEdit?.schoolDistrict)
    }
  }, [currentEdit?.schoolDistrict])


  useEffect(() => {
    if (currentEdit?.school?.length > 3) {
      getClassBySchoolId(currentEdit?.school)
    }
  }, [currentEdit?.school])

  useEffect(() => {
    if (currentEdit?.teacher?.length > 0) {
      getclassbyTeacherId(currentEdit?.teacher[0])
    }
  }, [currentEdit?.teacher])

  useEffect(() => {
    if (currentEdit?.classId?.length > 0) {
      getStudentByclassIds(currentEdit?.classId)
    }
  }, [currentEdit?.classId])



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - experiments.length) : 0;

  return (<div>
    <NavBar />
    <br />
    <div className="d-flex justify-content-end align-items-end">
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={noOfPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handleChangePage}
        containerClassName="pagination"
        activeClassName="activePage active"
        forcePage={currPage} />
    </div>
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer sx={{ height: '80vh', overflow: 'auto' }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'small'}
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={experiments.length}
            />
            <TableBody>
              {stableSort(experiments, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((experiments, index) => {
                  const isItemSelected = isSelected(experiments.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => { handleClick(event, experiments.name) }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={experiments.name}
                      // style={(experiments.device==null)?{backgroundColor:'#C08081',color:'white'}:null}
                      // disabled={(experiments.device==null)}
                      selected={isItemSelected}
                    >
                      <TableCell align="center"><div style={{ display: 'flex' }}>{experiments?.deviceSerialId} &nbsp; {(experiments?.device == null) ? <><Tooltip title="Device Deleted" placement='top'>
                        <div style={{ background: 'red', color: 'white', width: '20px', textAlign: 'center', borderRadius: '20px' }}>D</div></Tooltip>
                      </> : null}</div></TableCell>
                      <TableCell align="right">{experiments._id}</TableCell>
                      <TableCell align="right">{experiments.deviceName || experiments?.device?.name}</TableCell>
                      <TableCell align="right">{Number.isInteger(experiments?.fuelVolume) ? experiments?.fuelVolume : experiments?.fuelVolume.toFixed(2)} ml</TableCell>
                      <TableCell align="right">{Number.isInteger(experiments?.nozzleDia) ? experiments?.nozzleDia : experiments?.nozzleDia.toFixed(2)} mm</TableCell>
                      <TableCell align="right">{Number.isInteger(experiments?.pressure) ? experiments?.pressure : experiments?.pressure.toFixed(2)} psi</TableCell>
                      <TableCell align="right">{experiments.className}</TableCell>
                      <TableCell align="right">{experiments.studentName}</TableCell>
                      <TableCell align="right">{experiments.date}</TableCell>
                      <TableCell align="right">{experiments.time}</TableCell>
                      <TableCell>
                        {(experiments?.device != null) ? <>
                          <Link to={`/experiments/${experiments._id}`} className="btn btn-sm btn-outline-primary">Analyze</Link>
                        </> : <>-</>}
                      </TableCell>
                      <TableCell>
                        {(experiments?.device != null) ? <>
                          <Tooltip title="Edit" placement="left">
                            <button className="btn btn-sm btn-outline-primary mr-2" onClick={() => initiateEdit(experiments)}>
                              <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.75 5H5C4.33696 5 3.70107 5.26339 3.23223 5.73223C2.76339 6.20107 2.5 6.83696 2.5 7.5V25C2.5 25.663 2.76339 26.2989 3.23223 26.7678C3.70107 27.2366 4.33696 27.5 5 27.5H22.5C23.163 27.5 23.7989 27.2366 24.2678 26.7678C24.7366 26.2989 25 25.663 25 25V16.25" stroke="#495057" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M23.125 3.125C23.6223 2.62772 24.2967 2.34835 25 2.34835C25.7033 2.34835 26.3777 2.62772 26.875 3.125C27.3723 3.62228 27.6517 4.29674 27.6517 5C27.6517 5.70326 27.3723 6.37772 26.875 6.875L15 18.75L10 20L11.25 15L23.125 3.125Z" stroke="#495057" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </button>
                          </Tooltip>
                        </> : '-'}
                        {/* <Tooltip title="Delete" placement="right">
                          <button className="btn btn-sm btn-outline-danger " onClick={() => handleDelete(experiments._id)}>
                            <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="#495057" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M18.75 11.25L11.25 18.75" stroke="#495057" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M11.25 11.25L18.75 18.75" stroke="#495057" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                          </button>
                        </Tooltip> */}

                      </TableCell>

                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog handleClose={handleClose} open={open} fullWidth>
        <section id="hero-Msd">
          {editMode ? <>
            <DialogTitle>
              <Grid container justify="space-between" alignItems="center">
                <Typography variant="h6" style={{ fontWeight: '600' }}>Edit Experiment</Typography>
                <IconButton onClick={handleClose} >
                  <CloseIcon style={{ border: '2px solid black', fontSize: '30px' }} />
                </IconButton>
              </Grid>
            </DialogTitle>
            <form onSubmit={(e) => handleEditSubmit(e)} className="form">
              <div className="col-md-9 text-left  col-9 m-auto">
                <div className="form-group mt-4   p-20">

                  <div className="form-group">
                    <label>School-District</label>
                    <AutoCompletes value={currentEdit?.schoolDistrict} label={"School-District"} options={schoolDistrictData} setValue={(value) => { setCurrentEdit({ ...currentEdit, schoolDistrict: value?._id, school: null, teacher: [],className:"",studentName:"" }) }} />
                  </div>
                  <div className="form-group">
                    <label>School</label>
                    <AutoCompletes value={currentEdit?.school} label={"School"} options={schoolData} setValue={(value) => setCurrentEdit({ ...currentEdit, school: value?._id, teacher: [],className:"",studentName:"" })} />
                  </div>
                  <div className="form-group">
                    <label>Teacher</label>
                    <AutoCompletes value={currentEdit?.teacher[0]} label={"Teacher"} options={teacherData} setValue={(value) => setCurrentEdit({ ...currentEdit, teacher: [value?._id],className:"",studentName:"" })} />
                  </div>
                  <div className="form-group">
                    <label>Class</label>
                    <AutoCompletes value={currentEdit?.className} label={"Class"} options={classData} setValue={(value) => setCurrentEdit({ ...currentEdit, className: value?.name, classId: value?._id,studentName:"" })} />

                  </div>
                  

                  <div className="form-group">
                    <label>Student</label>
                    <AutoCompletes value={currentEdit?.studentName} label={""} options={studentData} setValue={(value) => setCurrentEdit({ ...currentEdit, studentName: value?.loginId, })} />
                  </div>
                  <div className="form-group">
                    <label>Bottle Volume (ml)</label>
                    <input type="text" className="form-control" required value={currentEdit.bottleVolume} onChange={e => { setCurrentEdit({ ...currentEdit, bottleVolume: e.target.value }) }} />
                  </div>
                  <div className="form-group">
                    <label>Fuel Volume (ml)</label>
                    <input type="text" className="form-control" required Value={currentEdit.fuelVolume} onChange={e => { setCurrentEdit({ ...currentEdit, fuelVolume: e.target.value }) }} />
                  </div>
                  <div className="form-group">
                    <label>Nozzle Diamenter (mm)</label>
                    <input type="text" className="form-control" required value={currentEdit.nozzleDia} onChange={e => { setCurrentEdit({ ...currentEdit, nozzleDia: e.target.value }) }} />
                  </div>
                </div>
                {!clicked && <input type="submit" className="custom-form-button" value="Update" />}
                {clicked && <input type="submit" className="custom-form-button" value="Updating" disabled />}
                <br />

              </div>
            </form>
            <br />
          </> : null}
        </section>
      </Dialog>

      <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
        <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </Box>
  </div>
  );
}