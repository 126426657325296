import axios from "axios";
import { baseUrl } from "../Base_url";
const userData=JSON.parse(localStorage.getItem('recoil-persist'))
let token=""
if(userData){
  if(userData.KT_app){
    // console.log(userData.KT_app)
    token=userData.KT_app.token
  }
}
export const getAllSchoolDistricts = async () => {
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/all`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
 
  export const deleteSchoolDistrictsAdmin = async (id) => {
    try {
      const res = await axios.delete(`${baseUrl}/admin/school-district/${id}/admin`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const getSchoolDistrictsById = async (id) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const createFullNewTeacher = async (schoolId, data, token) => {
    try {
      const res = await axios.post(`${baseUrl}/district-admin/school-district/school/${schoolId}/teacher/manualcreate`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const getSchoolByDistrict=async (id)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}/school/all`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getSchoolById=async (token,did,sid)=>{
    try {
      const res = await axios.get(`${baseUrl}/district-admin/school-district/school/${sid}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getUsersforTeacher=async (districtId,page,limit,query,token)=>{
    try {
      const res = await axios.get(`${baseUrl}/district-admin/accounts/users?page=${page}&limit=${limit}&query=${query}&districtId=${districtId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const editSchoolById=async (did,sid,data)=>{
    try {
      const res = await axios.put(`${baseUrl}/admin/school-district/${did}/school/${sid}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const EditSchoolById=async (sid,data,token)=>{
    try {
      const res = await axios.put(`${baseUrl}/district-admin/school-district/school/${sid}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const deleteSchoolById=async (did,sid)=>{
    try {
      const res = await axios.delete(`${baseUrl}/admin/school-district/${did}/school/${sid}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const DeleteSchoolById=async (sid,token)=>{
    try {
      const res = await axios.delete(`${baseUrl}/district-admin/school-district/school/${sid}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getAllSchoolTeachers=async (sid, token, pages, limits)=>{
    try {
      const res = await axios.get(`${baseUrl}/district-admin/school-district/school/${sid}/teacher/all?page=${pages}&limit=${limits}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const addSchoolDestrict=async (data)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getSchoolDestrictAdmin=async (data)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${data}/admin`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  
  export const createSchoolDestrictAdmin=async (data)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${data.id}/admin`,data.data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const InviteSchoolTeacher=async (id, token, data)=>{
    try {
      const res = await axios.post(`${baseUrl}/district-admin/school-district/school/${id}/teacher/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const createNewStudent=async (schoolId, teacherId, token, data)=>{
    try {
      const res = await axios.post(`${baseUrl}/district-admin/school-district/school/${schoolId}/teacher/${teacherId}/student/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getTeacherDetails=async (teacherId, schoolId, token)=>{
    try {
      const res = await axios.get(`${baseUrl}/district-admin/school-district/school/${schoolId}/teacher/${teacherId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getAllStudents=async (teacherId, schoolId, token)=>{
    try {
      const res = await axios.get(`${baseUrl}/district-admin/school-district/school/${schoolId}/teacher/${teacherId}/student/all`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
 
  export const editTeacherDetails=async (teacherId, schoolId, token,data)=>{
    try {
      const res = await axios.put(`${baseUrl}/district-admin/school-district/school/${schoolId}/teacher/${teacherId}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const deleteTeacherDetails=async (teacherId, schoolId, token)=>{
    try {
      const res = await axios.delete(`${baseUrl}/district-admin/school-district/school/${schoolId}/teacher/${teacherId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const passwordChangeTeacher=async (teacherId, schoolId, data, token)=>{
    try {
      const res = await axios.post(`${baseUrl}/district-admin/school-district/school/${schoolId}/teacher/${teacherId}/changePassword`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log('Check respons of Teacher',res)
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const createSchool=async (id,data)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${id}/school/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getAllSchoolAdmins=async (id)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin/all`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getSchoolByDistrictAdmin=async (token,pages,limits)=>{
    try {
      const res = await axios.get(`${baseUrl}/district-admin/school-district/school/all?page=${pages}&limit=${limits}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getSchoolByDistrictAdmins=async (token)=>{
    try {
      const res = await axios.get(`${baseUrl}/district-admin/school-district/school/allSchools`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }


  export const getAllDevicesForDestrictAdmin=async (token, type,school)=>{
    try {
      const res = await axios.get(`${baseUrl}/district-admin/school-district/device/${type}?schoolId=${school}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getAllAdminsForASchool=async (token, schoolId)=>{
    try {
      const res = await axios.get(`${baseUrl}/district-admin/school-district/school/${schoolId}/admin/all`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const createSchoolByDistrict = async (token, data) => {
    try {
      const res = await axios.post(`${baseUrl}/district-admin/school-district/school/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const createAdminForSchool = async (token, data, schoolId) => {
    try {
      const res = await axios.post(`${baseUrl}/district-admin/school-district/school/${schoolId}/admin`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const districtAdminAssignDeviceToSchool = async (deviceId, schoolId,token) => {
    try {
      const res = await axios.post(`${baseUrl}/district-admin/school-district/school/${schoolId}/device/assign?deviceId=${deviceId}`,{},{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const districtAdminRemoveDeviceFromSchool = async (deviceId, schoolId,token) => {
    try {
      const res = await axios.delete(`${baseUrl}/district-admin/school-district/school/${schoolId}/device/assign?deviceId=${deviceId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const districtAdminRemoveSchoolAdmin = async (token, adminId, schoolId) => {
    try {
      const res = await axios.delete(`${baseUrl}/district-admin/school-district/school/${schoolId}/admin/${adminId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const InviteAdminToSchool=async (id,data)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const EditSchoolAdmin=async (id,data)=>{
    try {
      const res = await axios.put(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin/${id.adminId}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getSchoolAdminDetails=async (id)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin/${id.adminId}`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getAllAssignedDevicesForSchoolDistrict=async (id)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}/device/all`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  

  /// Student Apis

    export const getAllStudentbyTeacher=async (id)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.tid}/student/all`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
 
   export const getStudentDetails=async (id)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.tid}/student/${id.studentId}`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const deleteStudent=async (schoolId, teacherId, studentId, token)=>{
    try {
      const res = await axios.delete(`${baseUrl}/district-admin/school-district/school/${schoolId}/teacher/${teacherId}/student/${studentId}`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const editStudentDetails=async (id,data)=>{
    try {
   const res = await axios.put(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.tid}/student/${id.studentId}`,data,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const createStudent=async (id,data)=>{
    try {
  const res = await axios.post(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.tid}/student/create`,data,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }