import axios from "axios";
import { baseUrl } from "../Base_url";



const userData = JSON.parse(localStorage.getItem('recoil-persist-rocket-app-012034220'))
let token = ""



if (userData) {
  if (userData.KT_app_School_Admin) {
    token = userData.KT_app_School_Admin.token;
  }else{
    // alert('You need to login first')
  }
}



export const getAllAssignedDevicesForTeacher = async (teacherId) => {
  try {
    const res = await axios.get(`${baseUrl}/school-admin/school/teacher/${teacherId}/device/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
}



export const SpareDevices = async () => {
  try {
    const res = await axios.get(`${baseUrl}/school-admin/school/device/spare`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};



export const AssignDevice = async (teacherId, deviceId) => {
  try {
    const res = await axios.post(`${baseUrl}/school-admin/school/teacher/${teacherId}/device/assign?deviceId=${deviceId}`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};



export const DeleteAssignedDevice = async (teacherId, deviceId) => {
  try {
    const res = await axios.delete(`${baseUrl}/school-admin/school/teacher/${teacherId}/device/assign?deviceId=${deviceId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

