import axios from "axios";
import { baseUrl } from "../Base_url";

export const getAllExperimentsForSuperAdmin = async (token, page, limit, serialId) => {
    try {
        const res = await axios.get(`${baseUrl}/admin/experiment/all?page=${page}&limit=${limit}&serial=${serialId}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};
export const editAnExperiment = async (id, data, token) => {
    try {
        const res = await axios.put(`${baseUrl}/admin/experiment/${id}`, data, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};
export const deleteAnExperiment = async (id, token) => {
    try {
        const res = await axios.delete(`${baseUrl}/admin/experiment/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};
export const getAllSchoolDistricts = async (token) => {
    try {
        const res = await axios.get(`${baseUrl}/admin/school-district/all`, {
            headers:
                { Authorization: `Bearer ${token}` }
            // { Authorization: `Bearer jhjhjhkjjklk` }
            ,
        });
        return { error: false, res: res };
    } catch (e) {
        const err = {
            error: true,
            data: e.response.data.data
            // data:e
        };
        return err;
    }
};


export const getSchoolDistrictsById = async (id, token) => {
    try {
        const res = await axios.get(`${baseUrl}/admin/school-district/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};


export const getSchoolByDistrict = async (id, token) => {
    try {
        const res = await axios.get(`${baseUrl}/admin/school-district/${id}/school/all`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
}


export const getAllSchoolTeachers = async (sid, token) => {
    try {
        const res = await axios.post(`${baseUrl}/getTeacherBySchoolId`, { id: sid }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
}
export const getClassesByTeachers = async (sid, token) => {
    try {
        const res = await axios.post(`${baseUrl}/getClassesByTeachers`, { teacherId: sid }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
}
export const getStudentByclassId = async (sid, token) => {
    try {
        const res = await axios.post(`${baseUrl}/getStudentByclassId`, { id: sid }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
}
