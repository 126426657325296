import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { schoolAdminState } from '../../Atom'
import { getAllExperimentsForTeacher } from './api'
import './style.css'

function Experiment() {
    const [experiments, setExperiments] = useState([])
    const [page, setPage] = useState(1)
    const {token} = useRecoilValue(schoolAdminState)

    useEffect(() => {
        getListOfExperiments()
    }, [page])


    const getListOfExperiments = async () => {
        let res = await getAllExperimentsForTeacher(token, page)
        if(res.error){
            alert("Something Went Wrong!")
        }else{
            // console.log(res.res)
            setExperiments(res.res.data.data.experiments)
        }
    }
    
    const increment = () => {
        setPage(page+1)
    }
    const decrement = () => {
        if(page>1){
            setPage(page-1)
        }
    }
    return (
        <div>
            <div className="d-flex justify-content-end">
                <div className="d-flex justify-content-between align-items-baseline pagination-box">
                    <button className="btn btn-transparent" onClick={decrement}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.75 7.66588L2.75 16.3349C2.75 19.3549 4.889 21.2499 7.916 21.2499L16.084 21.2499C19.111 21.2499 21.25 19.3649 21.25 16.3349L21.25 7.66588C21.25 4.63588 19.111 2.74988 16.084 2.74988L7.916 2.74988C4.889 2.74988 2.75 4.63588 2.75 7.66588Z" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.91406 11.9999L16.0861 11.9999" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.6777 15.7479L7.91373 11.9999L11.6777 8.25189" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <p>{page}</p>
                    <button className="btn btn-transparent" onClick={increment}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.25 16.3341V7.66512C21.25 4.64512 19.111 2.75012 16.084 2.75012H7.916C4.889 2.75012 2.75 4.63512 2.75 7.66512L2.75 16.3341C2.75 19.3641 4.889 21.2501 7.916 21.2501H16.084C19.111 21.2501 21.25 19.3641 21.25 16.3341Z" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.0859 12.0001H7.91394" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.3223 8.25211L16.0863 12.0001L12.3223 15.7481" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                    </button>
                </div>
            </div>
            <table className="table experimental">
              <thead>
                <tr>
                  {/* <th className="experimental-th">Serial no.</th> */}
                  <th className="experimental-th">Fuel Volume</th>
                  <th className="experimental-th">Nozzle Diameter</th>
                  <th className="experimental-th">Pressure</th>
                  <th className="experimental-th">Class</th>
                  <th className="experimental-th">Student</th>
                  <th className="experimental-th">Date</th>
                  <th className="experimental-th">Time</th>
                  <th className="experimental-th">Action</th>
                </tr>
              </thead>
              <tbody>
                {experiments.map((item, index) => {
                  return (
                    <tr key={index}>
                      {/* <td className="experimental-td">{item.device}</td> */}
                      <td className="experimental-td">{item.fuelVolume} ml</td>
                      <td className="experimental-td">{item.nozzleDia} mm</td>
                      <td className="experimental-td">{item.pressure?.toFixed(0)} psi</td>
                      <td className="experimental-td">{item.className}</td>
                      <td className="experimental-td">{item.studentName}</td>
                      <td className="experimental-td">{new Date(item.createdAt).toLocaleDateString()}</td>
                      <td className="experimental-td">{new Date(item.createdAt).toLocaleTimeString()}</td>
                      <td className=""><Link to={`/experiments/${item._id}`} className="btn btn-sm btn-outline-primary">Analyze</Link></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
        </div>
    )
}

export default Experiment
