import React, { useEffect, useState, useRef } from 'react'
import { getAnalysedData, getThisExperimentDetails } from './api'
import './style.css'
import { useRecoilValue } from 'recoil'
import { schoolAdminState } from '../../Atom'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, AreaChart, Area, Label, ResponsiveContainer } from 'recharts';
import Navbar from '../Navbar/Navbar'
import RangeCustom from './Range'
function Analysis({ match }) {
    const range_slide_custom = useRef(null)
    const massQ = useRef(null)
    const paraQ = useRef(null)
    const { token } = useRecoilValue(schoolAdminState)
    const [angle, setAngle] = useState(0)
    const [mass, setMass] = useState(300.00)
    const [para_area, setPara_area] = useState(0)

    const [experimentData, setExperimentData] = useState(null)
    const [calculatedData, setCalculatedData] = useState(null)

    const [thrustModified, setThrustModified] = useState([])
    const [thrustFiltered, setThrustFiltered] = useState([])
    const [bottle_pressure, setBottle_pressure] = useState([])
    const [exhaust_velocity, setExhaust_velocity] = useState([])
    const [massChange, setMassChange] = useState([])
    const [acceleration, setAcceleration] = useState([])
    const [velocity, setVelocity] = useState([])
    const [distance, setDistance] = useState([])
    const [height, setHeight] = useState([])

    const [clicked, setClicked] = useState(false)
    useEffect(() => {
        getBasicDetails()
    }, [])
    const getBasicDetails = async () => {

        const res = await getThisExperimentDetails(token, match.params.id)
        if (res.error) {
            alert(`Something Went Wrong. Code: exp_geterr_${match.params.id}`)
        } else {
            // console.log(res.res.data.data.experiment)
            setExperimentData(res.res.data.data.experiment)
            await getPredictedData(res.res.data.data.experiment)
        }

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        getPredictedData()
    }
    const getPredictedData = async (expData = experimentData) => {
        setClicked(true)
        const res = await getAnalysedData(angle, mass, para_area, expData)
        setClicked(false)
        if (res.error) {
            alert("Experiment Failed. Contact Admin.")
        } else {
            // console.log(res.res.data)
            setCalculatedData(res.res.data)
            setThrustFiltered(res.res.data.thrust)
            setBottle_pressure(res.res.data.bottle_pressure)
            setExhaust_velocity(res.res.data.exhaust_velocity)
            setMassChange(res.res.data.mass_of_rocket)
            setAcceleration(res.res.data.acceleration)
            setVelocity(res.res.data.velocity)
            setDistance(res.res.data.distance)
            setHeight(res.res.data.height)
        }
    }
    return (
        <div>
            <Navbar/>
            <h4 style={{ color: '#004e9d' }}>Experiment Data</h4>
            <div className="info-card-conatiner">
                <div className="info-card-stats">
                    <p className="head1">Bottle Volume</p>
                    <p className="head2">{experimentData?.bottleVolume} ml</p>
                </div>
                <div className="info-card-stats">
                    <p className="head1">Fuel Volume</p>
                    <p className="head2">{experimentData?.fuelVolume} ml</p>
                </div>
                <div className="info-card-stats">
                    <p className="head1">Nozzle Diameter</p>
                    <p className="head2">{experimentData?.nozzleDia} mm</p>
                </div>
                <div className="info-card-stats">
                    <p className="head1">Pressure (at launch)</p>
                    <p className="head2">{experimentData?.pressure} psi</p>
                </div>
            </div>
            {/* <div className="container-fluid form-analysis-student">
                <div className="row origin-exp-data-student">

                    <div className="col-6">
                        <table className="table table-sm table-bordered">
                            <tbody>
                                <tr>
                                    <th>Bottle Volume</th>
                                    <td>{experimentData?.bottleVolume} ml</td>
                                </tr>
                                <tr>
                                    <th>Fuel Volume</th>
                                    <td>{experimentData?.fuelVolume} ml</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6">
                        <table className="table table-sm table-bordered">
                            <tbody>

                                <tr>
                                    <th>Nozzle Diameter</th>
                                    <td>{experimentData?.nozzleDia} mm</td>
                                </tr>
                                <tr>
                                    <th>Pressure (at launch)</th>
                                    <td>{experimentData?.pressure.toFixed(0)} psi</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
            <form className="form container-fluid form-analysis" onSubmit={e => handleSubmit(e)}>
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <label>Angle Of Launch (degrees)</label>
                        <div className="d-flex align-items-center">
                            <RangeCustom angle={angle} ref={range_slide_custom}/>
                            {/* <input
                                ref={range_slide_custom}
                                type="range"
                                min={0}
                                max={45}
                                step={0.1}
                                style={{width:'100%'}}
                                // value={angle} // don't set value from state
                                defaultValue={angle} // but instead pass state value as default value
                                // onChange={e => setAngle(e.target.value)} // don't set state on all change as react will re-render
                                onMouseUp={e => setAngle(e.target.value)} // only set state when handle is released
                            /> */}
                            {/* <div className="angle-box"> */}
                            {/* <input type="number" step="0.1" className="form-control" style={{ padding: 0, textAlign: 'center' }} onChange={e => setAngle(e.target.value)} value={angle} /> */}
                            {/* </div> */}
                            
                        </div>

                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <label>Dry Mass Of Rocket (grams)</label>
                        <input 
                            type="number" 
                            step="0.01" 
                            placeholder="Mass in grams" 
                            className="form-control" 
                            // onChange={e => setMass(e.target.value)}
                            defaultValue={300}
                            ref={massQ}
                        />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <label>Parachute Area (m^2)</label>
                        <input 
                            type="number" 
                            step="0.01" 
                            placeholder="Area of Parachute" 
                            className="form-control" 
                            // onChange={e => setPara_area(e.target.value)} 
                            defaultValue={0.00}
                            ref={paraQ}
                            />
                    </div>
                    <div className="col-lg-3">
                        <label>Ready for results?</label>
                        {!clicked && <input type="submit" value="Calculate" className="btn form-control" />}
                        {clicked && <input type="submit" value="Calculate" className="btn form-control" disabled />}
                    </div>
                </div>

            </form>
            <div style={{ position: 'relative' }}>
                <br />
                <h4 style={{ color: '#004e9d' }}>Analysis</h4>
                <div className="info-card-conatiner">
                    <div className="info-card-stats">
                        <p className="head1">Total Thrust Time</p>
                        <p className="head2">{calculatedData?.total_thrust_time} sec</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Water Phase Length</p>
                        <p className="head2">{calculatedData?.water_phase_length} sec</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Total Impulse</p>
                        <p className="head2">{calculatedData?.total_impulse} N-sec</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Peak Thrust</p>
                        <p className="head2">{calculatedData?.peak_thrust} N</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Apogee to Ground</p>
                        <p className="head2">{calculatedData?.apogee_to_ground} sec</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Peak Acceleration</p>
                        <p className="head2">{calculatedData?.peak_acceleration} m/s^2</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Peak Velocity</p>
                        <p className="head2">{calculatedData?.peak_velocity} m/s</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Max. Altitude</p>
                        <p className="head2">{calculatedData?.max_altitude} m</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Distance Travelled</p>
                        <p className="head2">{calculatedData?.distance_travelled} m</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Total Flight</p>
                        <p className="head2">{calculatedData?.total_flight_time} sec</p>
                    </div>

                </div>
                {/* <div className="container-fluid form-analysis-student">
                    <div className="row origin-exp-data-student">
                        <div className="col-6">
                            <table className="table table-sm table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Total Thrust Time</th>
                                        <td>{calculatedData?.total_thrust_time} sec</td>
                                    </tr>
                                    <tr>
                                        <th>Water Phase Length </th>
                                        <td>{calculatedData?.water_phase_length} sec</td>
                                    </tr>
                                    <tr>
                                        <th>Total Impulse</th>
                                        <td>{calculatedData?.total_impulse} N-sec</td>
                                    </tr>
                                    <tr>
                                        <th>Peak Thrust</th>
                                        <td>{calculatedData?.peak_thrust} N</td>
                                    </tr>
                                    <tr>
                                        <th>Time from apogee to ground</th>
                                        <td>{calculatedData?.apogee_to_ground} sec</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className="col-6">
                            <table className="table table-sm table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Peak Acceleration</th>
                                        <td>{calculatedData?.peak_acceleration} m/s^2</td>
                                    </tr>
                                    <tr>
                                        <th>Peak Velocity</th>
                                        <td>{calculatedData?.peak_velocity} m/s</td>
                                    </tr>
                                    <tr>
                                        <th>Max. Altitude</th>
                                        <td>{calculatedData?.max_altitude} m</td>
                                    </tr>
                                    <tr>
                                        <th>Distance Travelled</th>
                                        <td>{calculatedData?.distance_travelled} m</td>
                                    </tr>
                                    <tr>
                                        <th>Total Flight</th>
                                        <td>{calculatedData?.total_flight_time} s</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> */}
                <div className="desktop-view">
                    <div className="d-flex flex-wrap justify-content-between align-items-start ">
                        <div className="individual-graph thrust-chart-area-left-student">
                            <p>Thrust-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="1.47">
                                <AreaChart width={300} height={240} data={thrustFiltered}
                                    margin={{ top: 5, right: 20, left: 1, bottom: 0 }}
                                >
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Thrust (N)', angle: -90, position: 'insideLeft' }} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="Thrust" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="thrust-chart-area-right-student d-flex flex-wrap">
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <p>Exhaust Velocity-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="2.09">
                                    <LineChart width={300} height={240} data={exhaust_velocity} margin={{ top: 5, right: 30, bottom: 5, left: 1 }}>
                                        <Line type="monotone" dataKey="Exhaust Velocity" stroke="#8884d8" dot={false} />
                                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={0} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Exhaust Velocity (m/s)', angle: -90, position: 'insideBottomLeft', offset: 10 }} />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <p>Pressure-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="2.09">
                                    <LineChart width={300} height={240} data={bottle_pressure} margin={{ top: 5, right: 30, bottom: 5, left: 1 }} >
                                        <Line type="monotone" dataKey="Pressure" stroke="#8884d8" dot={false} />
                                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={0} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Pressure (kPa)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <p>Mass-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="2.09">
                                    <AreaChart width={300} height={240} data={massChange}
                                        margin={{ top: 5, right: 30, left: 1, bottom: 5 }}>
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                                                <stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
                                            </linearGradient>
                                        </defs>
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={0} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Mass (kg)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Area type="monotone" dataKey="Mass" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>


                        <div className="individual-graph mini-graph" style={{ flexBasis: '49%' }}>
                            <p>Acceleration-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="2.1">
                                <LineChart width={300} height={240} data={acceleration} margin={{ top: 5, right: 30, bottom: 5, left: 1 }}>
                                    <Line type="monotone" dataKey="Acceleration" stroke="#8884d8" dot={false} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Acceleration (m/s^2)', angle: -90, position: 'insideBottomLeft', offset: 10 }} />
                                    <Tooltip />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="individual-graph mini-graph">
                            <p>Velocity-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="1">
                                <LineChart width={300} height={240} data={velocity} margin={{ top: 5, right: 30, bottom: 5, left: 0 }}>
                                    <Line type="monotone" dataKey="Velocity" stroke="#8884d8" dot={false} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Velocity (m/s)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                    <Tooltip />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="individual-graph mini-graph">
                            <p>Height-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="1">
                                <LineChart width={300} height={240} data={height} margin={{ top: 5, right: 30, bottom: 5, left: 0 }}>
                                    <Line type="monotone" dataKey="Height" stroke="#8884d8" dot={false} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Height (m)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                    <Tooltip />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                <div className="mac-view">
                    <div className="d-flex flex-wrap justify-content-between align-items-start ">
                        <div className="individual-graph thrust-chart-area-left-student">
                            <p>Thrust-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="1.37">
                                <AreaChart width={300} height={240} data={thrustFiltered}
                                    margin={{ top: 5, right: 20, left: 1, bottom: 0 }}
                                >
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Thrust (N)', angle: -90, position: 'insideLeft' }} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="Thrust" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    
                    <div className="thrust-chart-area-right-student d-flex flex-wrap">
                        <div className="individual-graph" style={{ flexBasis: '100%' }}>
                            <p>Exhaust Velocity-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="2.09">
                                <LineChart width={300} height={240} data={exhaust_velocity} margin={{ top: 5, right: 30, bottom: 5, left: 1 }}>
                                    <Line type="monotone" dataKey="Exhaust Velocity" stroke="#8884d8" dot={false} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Exhaust Velocity (m/s)', angle: -90, position: 'insideBottomLeft', offset: 10 }} />
                                    <Tooltip />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="individual-graph" style={{ flexBasis: '100%' }}>
                            <p>Pressure-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="2.09">
                                <LineChart width={300} height={240} data={bottle_pressure} margin={{ top: 5, right: 30, bottom: 5, left: 1 }} >
                                    <Line type="monotone" dataKey="Pressure" stroke="#8884d8" dot={false} />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Pressure (kPa)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                    <Tooltip />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="individual-graph" style={{ flexBasis: '100%' }}>
                            <p>Mass-Time</p>
                            <ResponsiveContainer width="100%" height="100%" aspect="2.09">
                                <AreaChart width={300} height={240} data={massChange}
                                    margin={{ top: 5, right: 30, left: 1, bottom: 5 }}>
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Mass (kg)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="Mass" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                    
                    <div className="individual-graph" style={{ flexBasis: '49%' }}>
                        <p>Acceleration-Time</p>
                        <ResponsiveContainer width="100%" height="100%" aspect="2.1">
                            <LineChart width={300} height={240} data={acceleration} margin={{ top: 5, right: 30, bottom: 5, left: 1 }}>
                                <Line type="monotone" dataKey="Acceleration" stroke="#8884d8" dot={false} />
                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                <XAxis dataKey="time" >
                                    <Label value="time (s)" offset={0} position="insideBottom" />
                                </XAxis>
                                <YAxis label={{ value: 'Acceleration (m/s^2)', angle: -90, position: 'insideBottomLeft', offset: 10 }} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="individual-graph" style={{ flexBasis: '24%' }}>
                        <p>Velocity-Time</p>
                        <ResponsiveContainer width="100%" height="100%" aspect="1">
                            <LineChart width={300} height={240} data={velocity} margin={{ top: 5, right: 30, bottom: 5, left: 0 }}>
                                <Line type="monotone" dataKey="Velocity" stroke="#8884d8" dot={false} />
                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                <XAxis dataKey="time" >
                                    <Label value="time (s)" offset={0} position="insideBottom" />
                                </XAxis>
                                <YAxis label={{ value: 'Velocity (m/s)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="individual-graph" style={{ flexBasis: '24%' }}>
                        <p>Height-Time</p>
                        <ResponsiveContainer width="100%" height="100%" aspect="1">
                            <LineChart width={300} height={240} data={height} margin={{ top: 5, right: 30, bottom: 5, left: 0 }}>
                                <Line type="monotone" dataKey="Height" stroke="#8884d8" dot={false} />
                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                <XAxis dataKey="time" >
                                    <Label value="time (s)" offset={0} position="insideBottom" />
                                </XAxis>
                                <YAxis label={{ value: 'Height (m)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                </div>
                <div className="mobile-view">
                    <div className="row">
                        <div className="col-12">
                            <div className="individual-graph">
                                <p>Thrust-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="1">
                                    <AreaChart width={300} height={240} data={thrustFiltered}
                                        margin={{ top: 5, right: 20, left: 1, bottom: 0 }}
                                    >
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                                                <stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
                                            </linearGradient>
                                        </defs>
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={0} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Thrust (N)', angle: -90, position: 'insideLeft' }} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Area type="monotone" dataKey="Thrust" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph">
                                <p>Exhaust Velocity-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="1">
                                    <LineChart width={300} height={240} data={exhaust_velocity} margin={{ top: 5, right: 30, bottom: 5, left: 1 }}>
                                        <Line type="monotone" dataKey="Exhaust Velocity" stroke="#8884d8" dot={false} />
                                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={0} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Exhaust Velocity (m/s)', angle: -90, position: 'insideBottomLeft', offset: 10 }} />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <p>Pressure-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="1">
                                    <LineChart width={300} height={240} data={bottle_pressure} margin={{ top: 5, right: 30, bottom: 5, left: 1 }} >
                                        <Line type="monotone" dataKey="Pressure" stroke="#8884d8" dot={false} />
                                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={0} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Pressure (kPa)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph">
                                <p>Mass-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="1">
                                    <AreaChart width={300} height={240} data={massChange}
                                        margin={{ top: 5, right: 30, left: 1, bottom: 5 }}>
                                        <defs>
                                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                                                <stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
                                            </linearGradient>
                                        </defs>
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={0} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Mass (kg)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Area type="monotone" dataKey="Mass" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph">
                                <p>Acceleration-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="1">
                                    <LineChart width={300} height={240} data={acceleration} margin={{ top: 5, right: 30, bottom: 5, left: 1 }}>
                                        <Line type="monotone" dataKey="Acceleration" stroke="#8884d8" dot={false} />
                                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={0} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Acceleration (m/s^2)', angle: -90, position: 'insideBottomLeft', offset: 10 }} />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph">
                                <p>Velocity-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="1">
                                    <LineChart width={300} height={240} data={velocity} margin={{ top: 5, right: 30, bottom: 5, left: 0 }}>
                                        <Line type="monotone" dataKey="Velocity" stroke="#8884d8" dot={false} />
                                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={0} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Velocity (m/s)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph">
                                <p>Height-Time</p>
                                <ResponsiveContainer width="100%" height="100%" aspect="1">
                                    <LineChart width={300} height={240} data={height} margin={{ top: 5, right: 30, bottom: 5, left: 0 }}>
                                        <Line type="monotone" dataKey="Height" stroke="#8884d8" dot={false} />
                                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <XAxis dataKey="time" >
                                            <Label value="time (s)" offset={0} position="insideBottom" />
                                        </XAxis>
                                        <YAxis label={{ value: 'Height (m)', angle: -90, position: 'insideLeft', offset: 10 }} />
                                        <Tooltip />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>





                    </div>
                </div>
                {clicked && <>
                    <div className="glass"></div>
                    <div className="glass-top">
                        <h1>Calculating...</h1>
                    </div>
                </>}
            </div>

        </div>
    )
}

export default Analysis
