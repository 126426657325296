import NavBar from "../Navbar/Navbar";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import getCookie, { districtAdminState, setDistrictCookie } from '../../Atom';
import Snackbar from '@mui/material/Snackbar';
import {
  getUsersforTeacher, getSchoolByDistrictAdmins, createFullNewTeacher, editTeacherDetails, passwordChangeTeacher,
  deleteTeacherDetails
} from "../School-Districts/api";
import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Link, useParams } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function User() {

  const history = useHistory();
  let search = window.location.search;
  let paramse = new URLSearchParams(search);
  let params = useParams();
  const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const [snackBarShow, setSnackBarShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [currPage, setCurrPage] = useState(0);
  const [paged, setPaged] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [noOfPages, setNoOfPages] = useState(0);
  const [query, setQuery] = useState("");
  const [pageLimit, setPageLimit] = useState(25);
  const [open, setOpen] = useState(false);
  const [teacherId, setTeacherId] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [myUser, setmyUser] = useState();
  const [districtId, setDistrictId] = useState();
  const [allTeachers, setAllTeachers] = useState([]);
  const [allSchools, setAllSchools] = useState([]);
  const [schoolId, setSchoolId] = useState('');
  const [passwordState, setPasswordState] = useState(false);
  const [password, setPassword] = useState('');


  const [dataNewTeacher, setDataNewTeacher] = useState({
    "fName": "",
    "lName": "",
    "email": "",
    "password": ""
  });

  const [teacherTouched, setTeacherTouched] = useState({
    "fName": false,
    "lName": false,
    "email": false,
    "password": false,
  });

  const logoutHandler = async () => {
    setTimeout(() => {
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })
      setDistrictCookie({
        fName: null,
        lName: null,
        token: null,
        userId: null
      })
      let hostname = window.location.host;
      // console.log(hostname,window.location.protocol)
      window.location.assign(`${window.location.protocol}//${hostname}/school-district`)
    })
  }

  const handleClose = () => {
    setOpen(false);
    setAddMode(false);
    setEditMode(false);
    setDeleteMode(false);

    // setloginId('');

    // setSchoolId('')
    setTeacherTouched({
      "fName": false,
      "lName": false,
      "email": false,
      "password": false,
    });
    setSchoolId('');
  }

  const handleClose1 = () => {
    setPasswordState(false);
  }

  const teacherValidation = () => {
    if ((dataNewTeacher.fName.trim() == '' || dataNewTeacher.fName == null) ||
      (dataNewTeacher.lName.trim() == '' || dataNewTeacher.lName == null) ||
      (dataNewTeacher.email.trim() == '' || dataNewTeacher.email == null || !emailRegex.test(dataNewTeacher.email)) ||
      (dataNewTeacher.password.trim() == '' || dataNewTeacher.password == null || dataNewTeacher.password.length < 7)) {
      return true;
    } else {
      return false;
    }
  }



  const feedBackMsg = (snackShow, snackSeverity, snackMessage) => {
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const placeHolderEditTeacher = (teacher) => {
    // console.log(teacher)
    setDataNewTeacher({
      fName: teacher.fName,
      lName: teacher.lName,
      email: teacher.email,
      password: ''
    });
    setSchoolId(teacher.school)
  }

  const SnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    feedBackMsg(false, '', '');
  }

  useEffect(() => {
    // getSchool()
    const _cookie = getCookie('recoil-persist')
    if (!_cookie) {
      logoutHandler()
    } else {
      const user = JSON.parse(_cookie)['district_admin_v1']
      setDistrictId(user?.districtId)
      setmyUser(user)
      let urlPage = parseInt(paramse.get('page')) || 0;
      let urlLimit = parseInt(paramse.get('limit')) || 25;
      let urlSearch = parseInt(paramse.get('query')) || '';

      history.push({
        pathname: `/users/`,
        search: '?page=' + urlPage + `&limit=${urlLimit}&query=${query}`
      })
      setPaged(urlPage)
      getUsers(urlPage, urlLimit, urlSearch, user);
      getAllSchools(user);
    }
  }, [0]);

  const CreateNewTeacher = async () => {
    const res = await createFullNewTeacher(schoolId, dataNewTeacher, myUser.token)
    if (!res.error) {
      getUsers(paged, pageLimit, query)
      feedBackMsg(true, 'success', res.res.data.msg);
    } else {
      // alert('Could not create Teacher ')
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    }
    handleClose();
    setTeacherTouched({
      "fName": false,
      "lName": false,
      "email": false,
      "password": false,
    })

    setDataNewTeacher({
      "fName": "",
      "lName": "",
      "email": "",
      "password": "",
    })
  }

  const getUsers = async (pages, limits, query, user = myUser) => {
    let res = await getUsersforTeacher(user?.districtId, pages, limits, query, user.token);
    if (res.error) {
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {
      // console.log(res.res.data.data);
      setAllTeachers(res.res.data.data.teacher)
      let divisor = parseInt(res.res.data.data.teachercount / limits);
      let remainder = (res.res.data.data.teachercount % limits == 0) ? 0 : 1;
      setNoOfPages(divisor + remainder)
      setCurrPage(pages);

    }
  }

  const passwordChange = async (user) => {
    let res, ids, data;
    res = await passwordChangeTeacher(teacherId, schoolId, { password: password, email: dataNewTeacher.email }, myUser.token)

    if (res.error) {
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {

      feedBackMsg(true, 'success', res.res.data.msg);
    }
    handleClose1();
  }

  const editTeacher = async () => {
    // let ids = { did: districtId, sid: id, teacherid: teacherDetails._id }
    let data = { fName: dataNewTeacher.fName, lName: dataNewTeacher.lName, email: dataNewTeacher.email }
    let res;
    if (editMode) {
      res = await editTeacherDetails(teacherId, schoolId, myUser.token, data);
    } else {
      res = await deleteTeacherDetails(teacherId, schoolId, myUser.token);
    }

    if (!res.error) {
      feedBackMsg(true, 'success', res.res.data.msg);
      getUsers(paged, pageLimit, query)


    }
    else {
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }

    }

    setDataNewTeacher({
      "fName": "",
      "lName": "",
      "email": "",
      "password": "",
    });
    handleClose();
  }

  const getAllSchools = async (user = myUser) => {

    let res = await getSchoolByDistrictAdmins(user.token);
    if (res.error) {
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {
      // console.log(res.res.data.data);
      setAllSchools(res.res.data.data.schools)
    }
  }

  const schoolChange = (event) => {
    setSchoolId(event.target.value)
  }

  const handleChangePage = (event, newPage) => {
    setPaged(event.selected);

    getUsers(event.selected, pageLimit, query);
    history.push({
      pathname: `/users`,
      search: '?page=' + event.selected + `&limit=${pageLimit}&query=${query}`
    })
  }

  const ValidationForm = (type) => {
    if (type == 'add') {

      if ((dataNewTeacher.fName.trim() == '' || dataNewTeacher.fName == null) ||
        (dataNewTeacher.lName.trim() == '' || dataNewTeacher.lName == null) ||
        (dataNewTeacher.email.trim() == '' || dataNewTeacher.email == null || !emailRegex.test(dataNewTeacher.email)) ||
        (dataNewTeacher.password.trim() == '' || dataNewTeacher.password == null || dataNewTeacher.password.length < 7)
        || schoolId.length < 1) {
        return true;
      } else {
        return false;
      }
    } else {
      if ((dataNewTeacher.fName.trim() == '' || dataNewTeacher.fName == null) ||
        (dataNewTeacher.lName.trim() == '' || dataNewTeacher.lName == null) ||
        (dataNewTeacher.email.trim() == '' || dataNewTeacher.email == null || !emailRegex.test(dataNewTeacher.email))
        || schoolId.length < 1) {
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <section id="hero-school ">
      <NavBar />
      <br />
      <div className="d-flex justify-content-end align-items-end">

        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={noOfPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handleChangePage}
          containerClassName="pagination"
          activeClassName="activePage active"
          forcePage={currPage} />

      </div>
      <div className="col-lg-8 col-md-7 col-sm-12">

        <TextField sx={{ minWidth: 250, width: 600, background: 'white' }} value={query} id="outlined-search" label="Search" type="search"
          onChange={(e) => {
            setQuery(e.target.value);
            getUsers(0, pageLimit, e.target.value)
          }}
          onBlur={(e) => { setQuery(e.target.value.trim()) }} />
      </div>
      <div className="d-flex justify-content-end align-items-end">
        <button className="btn btn-add float-right" onClick={() => {
          setOpen(true); setAddMode(true)
        }}>
          <i className="fas fa-plus-circle"></i> {"  "}
          <span>Add Teacher</span>
        </button>
      </div>
      <br />
      <div style={{ width: '100%' }} className="scrollLimits">
        <table className="table">
          <thead>
            <tr>
              <th>UserType</th>
              <th>Name</th>
              <th>Email</th>
              <th>Created On</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allTeachers.map((item, index) => {
              return (
                <tr style={{ opacity: item.deleted ? 0.5 : 1 }} key={index} hover role="checkbox" tabIndex={-1}>
                  <td className="">
                    {'Teacher'}
                  </td>
                  <td className=" textCap">
                    {item.fName} {item.lName}
                  </td>{" "}
                  <td>
                    {item.email === item._id ? item.emailF : item.email}
                  </td>{" "}
                  <td className="">
                    {new Date(item.createdAt).toLocaleDateString()} {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td className="">

                    {item.deleted ? (<Typography>Removed</Typography>) : (
                      <div>
                        <Tooltip title="Edit" placement="top">
                          <span style={{ cursor: "pointer" }} onClick={() => {

                            setOpen(true); setEditMode(true); setTeacherId(item._id);
                            placeHolderEditTeacher(item)

                          }}>
                            <i className="fas fa-edit icon-size-30" ></i>
                          </span>
                        </Tooltip>
                        &nbsp;
                        <Tooltip title="Remove" placement="top">
                          <span style={{ cursor: "pointer" }} onClick={() => {

                            setOpen(true); setDeleteMode(true); setTeacherId(item._id);
                            placeHolderEditTeacher(item)
                          }}>

                            <i className="fas fa-trash-alt icon-size-30" ></i>
                          </span>
                        </Tooltip>
                        &nbsp;
                        <Tooltip title="Change Password" placement="top">
                          <Link onClick={() => {
                            setPassword(''); setPasswordState(true);
                            setTeacherId(item._id);
                            placeHolderEditTeacher(item); setSchoolId(item.school);

                          }}>
                            <i className="fa fa-key icon-size-20"></i>
                          </Link>
                        </Tooltip>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
        <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={passwordState} handleClose={handleClose1} fullWidth>
        <section id="hero-Msd">
          <DialogTitle>
            <Grid container justify="space-between" alignItems="center">
              <Typography variant="h6" style={{ fontWeight: '600' }}>
                {`Change Password for ${dataNewTeacher.fName} ${dataNewTeacher.lName}`}

              </Typography>
              <IconButton onClick={handleClose1} >
                <CloseIcon style={{ border: '2px solid black', fontSize: '30px' }} />
              </IconButton>
            </Grid>
          </DialogTitle>
          <div className="col-md-9 text-left col-9 m-auto">

            <div className="form-group mt-4 p-20">

              <input type="text" className="form-control" value={password} placeholder="Password"
                onChange={(e) => setPassword(e.target.value)} onBlur={(e) => setPassword(e.target.value.trim())}
              />
              <span style={{ color: 'red' }}>Password should be atleast 8 characters</span>
            </div>
          </div>
          <div className="col-md-9 text-left  col-9 m-auto">
            <div className="form-group p-20">
              <button type="button" disabled={password.trim().length < 8} className="cta-btn" onClick={() => {
                passwordChange()
              }}>Change Password</button>
            </div>
          </div>
        </section>
      </Dialog>

      <Dialog handleClose={handleClose} open={open} fullWidth>
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h6" style={{ fontWeight: '600' }}>
              {(addMode) ? 'Add' : (editMode) ? 'Edit' : 'Delete'} Teacher
            </Typography>
            <IconButton onClick={handleClose} >
              <CloseIcon style={{ border: '2px solid black', fontSize: '30px' }} />
            </IconButton>
          </Grid>
        </DialogTitle>
        {addMode ? <>
          <div className="col-md-9 text-left col-9 m-auto">
            <Box sx={{ minWidth: 100 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">School</InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={schoolId}
                  label="Class"
                  // sx={{border:'none',borderRadius:'23px',borderTop:'none'}}
                  onChange={schoolChange}>
                  {allSchools.map((item, index) => {
                    return (
                      <MenuItem value={item._id}>{item.name}</MenuItem>
                    )
                  })}

                </Select>

              </FormControl>
            </Box>

            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control" value={dataNewTeacher.fName}
                placeholder="First Name"
                onChange={(e) => setDataNewTeacher({ ...dataNewTeacher, fName: e.target.value })}
                onBlur={e => { setTeacherTouched({ ...teacherTouched, fName: true }); setDataNewTeacher({ ...dataNewTeacher, fName: e.target.value.trim() }) }}
              />
              {(dataNewTeacher.fName.trim() == '' && teacherTouched.fName) ? <span style={{ color: 'red' }}>FirstName is required</span> : null}
            </div>
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control" value={dataNewTeacher.lName}
                placeholder="Last Name"
                onChange={(e) => setDataNewTeacher({ ...dataNewTeacher, lName: e.target.value })}
                onBlur={e => { setTeacherTouched({ ...teacherTouched, lName: true }); setDataNewTeacher({ ...dataNewTeacher, lName: e.target.value.trim() }) }}
              />
              {(dataNewTeacher.lName.trim() == '' && teacherTouched.lName) ? <span style={{ color: 'red' }}>LastName is required</span> : null}
            </div>
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control" value={dataNewTeacher.email}
                placeholder="Email"
                onChange={(e) => setDataNewTeacher({ ...dataNewTeacher, email: e.target.value })}
                onBlur={e => { setTeacherTouched({ ...teacherTouched, email: true }); setDataNewTeacher({ ...dataNewTeacher, email: e.target.value.trim() }) }}
              />
              {(dataNewTeacher.email.trim() == '' && teacherTouched.email) ? <span style={{ color: 'red' }}>Email is required</span> :
                (!emailRegex.test(dataNewTeacher.email) && dataNewTeacher.email.trim() != '' && teacherTouched.email) ?
                  <span style={{ color: 'red' }}>Email pattern is required</span> : null}
            </div>
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control" value={dataNewTeacher.password}
                placeholder="Password"
                onChange={(e) => setDataNewTeacher({ ...dataNewTeacher, password: e.target.value })}
                onBlur={e => { setTeacherTouched({ ...teacherTouched, password: true }); setDataNewTeacher({ ...dataNewTeacher, password: e.target.value.trim() }) }}
              />
              {(dataNewTeacher.password.trim() == '' && teacherTouched.password) ? <span style={{ color: 'red' }}>Password is required</span> : null}
              <br />
              <small style={{ color: "red" }}>Password should contain upto 8 characters</small>
            </div>
          </div>
        </> : (editMode) ? <>
          <div className="col-md-9 text-left  col-9 m-auto">
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control" value={dataNewTeacher.fName}
                placeholder="First Name"
                onChange={(e) => setDataNewTeacher({ ...dataNewTeacher, fName: e.target.value })}
                onBlur={e => { setTeacherTouched({ ...teacherTouched, fName: true }); setDataNewTeacher({ ...dataNewTeacher, fName: e.target.value.trim() }) }}
              />
              {(dataNewTeacher.fName.trim() == '' && teacherTouched.fName) ? <span style={{ color: 'red' }}>FirstName is required</span> : null}
            </div>
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control" value={dataNewTeacher.lName}
                placeholder="Last Name"
                onChange={(e) => setDataNewTeacher({ ...dataNewTeacher, lName: e.target.value })}
                onBlur={e => { setTeacherTouched({ ...teacherTouched, lName: true }); setDataNewTeacher({ ...dataNewTeacher, lName: e.target.value.trim() }) }}
              />
              {(dataNewTeacher.lName.trim() == '' && teacherTouched.lName) ? <span style={{ color: 'red' }}>LastName is required</span> : null}
            </div>
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control" value={dataNewTeacher.email}
                placeholder="Email"
                onChange={(e) => setDataNewTeacher({ ...dataNewTeacher, email: e.target.value })}
                onBlur={e => { setTeacherTouched({ ...teacherTouched, email: true }); setDataNewTeacher({ ...dataNewTeacher, email: e.target.value.trim() }) }}
              />
              {(dataNewTeacher.email.trim() == '' && teacherTouched.email) ? <span style={{ color: 'red' }}>Email is required</span> :
                (!emailRegex.test(dataNewTeacher.email) && dataNewTeacher.email.trim() != '' && teacherTouched.email) ?
                  <span style={{ color: 'red' }}>Email pattern is required</span> : null}
            </div>

          </div>
        </> : (deleteMode) ? <>
          <div className="col-md-9 text-left  col-9 m-auto">
            <h6>Remember that this action is irreversible. Once the teacher is removed, you cannot re-instate it.</h6>
            <h5>Are you sure to remove this Teacher?</h5>
            <div className="form-group mt-4   p-20">
              <input readOnly
                value={`${dataNewTeacher.fName} ${dataNewTeacher.lName}`}
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </> : null}

        <div className="col-md-9 text-left  col-9 m-auto">
          <div className="form-group p-20">
            {(addMode || editMode) ? <>
              <button type="button" className="cta-btn" onClick={() => {
                if (addMode) CreateNewTeacher()
                else editTeacher()
              }} disabled={(addMode) ? ValidationForm('add') : ValidationForm('edit')}>
                {(addMode) ? 'Add' : 'Edit'}
              </button>
            </> : (deleteMode) ? <>
              <button type="button" className="cta-btn-red" onClick={editTeacher}>
                Remove
              </button>
            </> : null}
          </div>
        </div>
      </Dialog>

      <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
        <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>

    </section>

  )

}