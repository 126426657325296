import axios from "axios";
import { baseUrl } from "../../Base_url";

const localData = JSON.parse(localStorage.getItem("recoil-persist"));

export const loginDistrictAdmin = async (data) => {
    try {
      const res = await axios.post(`${baseUrl}/district-admin/auth/login`, data);
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const forgotPassword = async (data) => {
    try {
      const res = await axios.post(`${baseUrl}/district-admin/auth/forget-password`, data);
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  // export const SignupAdmin = async (data) => {
  //   try {
  //     const res = await axios.post(`${baseUrl}/admin/auth/signup`, data);
  //     return { error: false, res: res };
  //   } catch (e) {
  //     const err = { error: true, data: e.response.data.data };
  //     return err;
  //   }
  // };