import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./Devices.css";
import dayjs from 'dayjs'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { getAllDevice,createDevice, getDeviceDetails, editDevice } from "./api";
import { useEffect, useState } from "react";
import CustomModal from "../Modal/CusModal";
import EditModal from "../Modal/CusModal";
import { CircularProgress } from "@material-ui/core";
import { useRecoilValue, useRecoilState } from "recoil";
import getCookie, { districtAdminState, setDistrictCookie } from "../../Atom";
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';  
import * as React from 'react';



import MuiAlert, { AlertProps } from '@mui/material/Alert';
// import { useRecoilState } from "recoil";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: '_id',
    numeric: false,
    disablePadding: true,
    label: 'Serial Id',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Device Name',
  }
]

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              className="sort-header"
              sx={{ display: 'flex' }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>

        ))}
        <TableCell
          align={'center'}
          padding={'normal'}>
          <div className=' mb-2 '>
            Assigned on
          </div>
        </TableCell>

      </TableRow>
    </TableHead>

  );
}      

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    // 
    <div className='pt-10'>

    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


export default function Devices() {
  const history = useHistory();
  let search = window.location.search;
  let paramse = new URLSearchParams(search);
  const [myUser, setmyUser] = useState()
  // const {token} = useRecoilValue(districtAdminState)
  const [show, setShow] = useState(false);
  const [editMode, seteditMode] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [devices,setDevices]=useState([]);
  const [name, setName] = useState("");
  const [editName, setEditName] = useState('');
  const [activeId, setactiveId] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [paged, setPaged] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [noOfPages, setNoOfPages] = useState(0);
  const [pageLimit, setPageLimit] = useState(25);
  const [snackBarShow, setSnackBarShow] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState('');

    const logoutHandler=async()=>{
      setTimeout(()=>{
        // setmyUser({
        //   fName: null,
        //   userId: null,
        //   token: null,
        //   lName: null,
        // })
        setDistrictCookie({
          fName:null,
          lName:null,
          token:null,
          userId:null
        })
        let hostname= window.location.host;
        // console.log(hostname,window.location.protocol)
        window.location.assign(`${window.location.protocol}//${hostname}/school-district`)   
      })  
    }

    
  const feedBackMsg =(snackShow,snackSeverity,snackMessage)=>{
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    
    feedBackMsg(false,'','');
  }

  const editHandler = async (e) => {
    e.preventDefault();
    let dataToSend={name: editName}
    let res = await editDevice(activeId,dataToSend);
    if (res.error) {
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }

    } else {
      setactiveId('')
      setEditName('')
      handleCloseAddModal();
 getDevice(paged,pageLimit)
      //showModal(true)
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true)
    let res = await createDevice({ name: name });
    if (res.error) {
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
      // alert("Error ");
      //setColor("red");
    setLoading(false)

    } else {
    setLoading(false)

      getDevice(paged,pageLimit);
      handleCloseAddModal();
      //showModal(true)
    }
  };
  const getDeviceDetail=async(activeId)=>{
    seteditMode(true)
    setactiveId(activeId)
    handleShowAddModal()
    let res=await getDeviceDetails(activeId)
    if(res.error){
        // alert('error')
        if(res.data.statusCode==401){
          logoutHandler();
        }
        else{
          feedBackMsg(true,'error',res.data.msg);
        }
    }
    else{
        setEditName(res.res.data.data.device.name)
        // console.log(res.res.data.data)
    }
}
  useEffect(()=>{
    // getDevice();
    const _cookie = getCookie('recoil-persist')
    if(!_cookie){
      logoutHandler()
    }else{
    const user = JSON.parse(_cookie)['district_admin_v1']
    setmyUser(user)
    let urlPage=parseInt(paramse.get('page'));
        if(parseInt(paramse.get('page'))){
            history.push({
                pathname: `/devices`,
                search: '?page=' + urlPage
              })
              setPaged(urlPage)
              getDevice(urlPage,pageLimit,user)
        }else{
            // console.log('lol')
            setPaged(0)
            history.push({
                pathname: `/devices`,
                search: '?page=0'
              })
              getDevice(0,pageLimit,user)
        }
      }
      },[0])

const getDevice=async(pages,limits,user=myUser)=>{
  let res=await getAllDevice(user?.token,pages,limits)
  // console.log(res)
  if(res.error){
    // alert('Failed fetching Device. Try Again')
    if(res.data.statusCode==401){
      logoutHandler();
    }
    else{
      feedBackMsg(true,'error',res.data.msg);
    }
  }
  else{
    // console.log(res.res.data.data)
    // let arr= [...res.res.data.data.devices].splice(2,5);
    // console.log(arr)
    setDevices(res.res.data.data.devices);
    // setDevices(arr)
    let divisor=parseInt(res.res.data.data.totalcount/limits);
      let remainder= (res.res.data.data.totalcount%limits==0)?0:1;
            setNoOfPages(divisor+remainder)
            setCurrPage(pages);
  }
}
const handleCloseAddModal = () => {
  // console.log(show)
  setShow(false);
  seteditMode(true);

};
const handleShowAddModal = () => {
  setShow(true);
};
const handleCloseEditModal = () => {
  // console.log(show)
  setShow(false);
  seteditMode(false);
};

const handleChangePage = (event, newPage) => {
       
  // console.log(event)
  setPaged(event.selected);
  
  getDevice(event.selected,pageLimit);
  history.push({
      pathname:  `/devices`,
      search: '?page=' + event.selected
    })
};


const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageLimit);
  const handleRequestSort = (event, property) => {
    // console.log(property)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;


  return (
    <section id="hero-school ">
      <CustomModal handleClose={handleCloseAddModal} show={show}>
      <section id="hero-Msd">
      <div className="row main-div">
        <div className="col-md-6 m-auto center-div text-center">
          <div className='p-2'>
            <h2>
              <button  onClick={handleCloseAddModal} className="btn close-btn  rounded-circle float-right">
                <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#011627"/>
                      <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
              </button>
            </h2>
        {!editMode?<>  
            <div className="mt-3">
              <h6>Add Device</h6>
            </div>
            <div className="col-md-9 text-left  col-9 m-auto">
              <div className="form-group mt-4   p-20">
                <input
                 
                  type="text"
                  className="form-control"
                  placeholder="Device ID"
                    onChange={(e)=>setName(e.target.value)}
                />
              </div>
              <div className="form-group mt-4   p-20">

             
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <div className="form-group p-20">
                <button onClick={submitHandler} type="submit" className="cta-btn">
                  {loading ? <CircularProgress /> : "Add"}
                </button>
              </div>
            </div></>:<>
            <div className="mt-3">
            <h6>Edit Device</h6>
          </div>
          <div className="col-md-9 text-left  col-9 m-auto">
            <div className="form-group mt-4   p-20">
              <input
               value={editName}
                type="text"
                className="form-control"
                placeholder="Device ID"
                  onChange={(e)=>setEditName(e.target.value)}
              />
            </div>
            <div className="form-group mt-4   p-20">

            <h3>
{/* New Fields After Client Feedback */}

            </h3>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="form-group p-20">
              <button onClick={editHandler} type="submit" className="cta-btn">
                {loading ? <CircularProgress /> : "Edit"}
              </button>
            </div>
          </div></>}
    </div>
        </div>
      </div>
    </section>
 
      </CustomModal>

    
    
        <div >
          <NavBar />
          <div className="dashboard-heading ml-4">
            {/* <h2>All Devices</h2> */}
          </div>
          {/* <div className="dashboard-notification-cards m-auto row">
            <div className="col-md-3 col-sm-12 notification-card p-3">
              <h2>2</h2>

              <h6 className="ml-2 notification-subHeading span-h4">Total</h6>
            </div>
            <div className="col-md-3 col-sm-12 notification-card p-3">
              <h2>8</h2>

              <h6 className="ml-2 notification-subHeading span-h4">Allocated</h6>
            </div>
            <div className="col-md-3 col-sm-12 notification-card p-3">
              <h2>30</h2>

              <h6 className="ml-2 notification-subHeading span-h4">Unallocated</h6>
            </div>
          </div> */}
          <div
            className="dashboard-heading "
            style={{ marginRight: "20px" }}
          >
            <h2>
              Devices List
              {/* <Link onClick={handleShowAddModal}>

              <button className="btn btn-add-device float-right ">
                <i className="fas fa-plus-circle"></i> {"  "}
                <span>Add new Device</span>
              </button>
              </Link>
              <Link to ='/upload-device'>

              <button className="btn btn-add-device float-right ">
                <i className="fas fa-cloud-upload-alt"></i> {"  "}
                <span>Upload Item In Bulk</span>
              </button>
              </Link> */}
            </h2>
          </div>
          <br />
          <div className="d-flex justify-content-end align-items-end">
                
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={noOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handleChangePage}
                    containerClassName="pagination"
                    activeClassName="activePage active"
                    forcePage={currPage}/>

            </div>
          {/* <div>
            
              <table className="table" style={{width:'100%'}}>
                <TableHead>
                  <TableRow>
                    <TableCell>Serial ID</TableCell>
                    <TableCell>Device Name</TableCell>
                    <TableCell>Assigned on</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {devices.map((item,index)=>{
                    return(
                  <TableRow hover key={index} role="checkbox" tabIndex={-1}>
                    <TableCell className="table-data">
                      <Link className="sec-link" to={`/experiments?serial=${item._id}`}>{item._id}</Link>
                    </TableCell>{" "}
                    <TableCell className="table-data">
                     {item.name}
                    </TableCell>{" "}
                    <TableCell className="table-data">
                     
                     {(item.assignedAt==undefined)?`${new Date(item.updatedAt).toLocaleDateString()} ${new Date(item.updatedAt).toLocaleTimeString()}`
                        :`${new Date(item.assignedAt).toLocaleDateString()} ${new Date(item.assignedAt).toLocaleTimeString()}`}
                    </TableCell>{" "}
  
                    
                  </TableRow>
                   )
                  })}
                </TableBody>
              </table>
     
          </div> */}

<Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className="scrollLimits">
          <Table
            // sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'small'}
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={devices.length}
            />
            <TableBody>
              {stableSort(devices, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((devices, index) => {
                  const isItemSelected = isSelected(devices.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, devices.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={devices.name}
                      selected={isItemSelected}
                    >
                      <TableCell align="center">
                        
                        {/* <Link className="sec-link">{devices._id}</Link> */}
                        {devices._id}
                      </TableCell>
                      <TableCell align="right">{(devices?.name)}</TableCell>
                      <TableCell align="right">
                        {/* {experiments.deviceName || experiments?.device?.name} */}
                        {(devices.assignedAt==undefined)?`${new Date(devices.updatedAt).toLocaleDateString()} ${new Date(devices.updatedAt).toLocaleTimeString()}`
                        :`${new Date(devices.assignedAt).toLocaleDateString()} ${new Date(devices.assignedAt).toLocaleTimeString()}`}
                      </TableCell>

                      </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>




        </div>

                <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                    <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar>
  
    </section>
  );
}
