import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./Devices.css";

// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from "react-router-dom";
import { getAllMyDevice,createDevice, getDeviceDetails, editDevice,getMyDeviceInfo } from "./api";
import Box from '@mui/material/Box';
import { useEffect, useState } from "react";
import CustomModal from "../Modal/CusModal";
import Paper from '@mui/material/Paper';   
import EditModal from "../Modal/CusModal";
import { CircularProgress } from "@material-ui/core";
import { useRecoilState, useRecoilValue } from "recoil";
import dayjs from 'dayjs'
import DashboardStats from "../Dashboard/DashboardStats";
import getCookie, { setTeacherCookie, teacherState } from "../../Atom";
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom";
import TableSortLabel from '@mui/material/TableSortLabel';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import PropTypes from 'prop-types';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: '_id',
    numeric: false,
    disablePadding: true,
    label: 'Serial Id',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Device Name',
  }
]

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [iconShow,setIconShow] = useState(true);

  return (
    <TableHead >
      <TableRow>
      {/* <TableCell sx={{backgroundColor:'transparent'}}
          align={'center'}
          padding={'normal'}>
          <div className=' mb-2 '>
            Sr No.
          </div>
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell 
            key={headCell.id}
            align={'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{backgroundColor:'transparent',marginX:'1px'}}
            >
            <TableSortLabel
              className="sort-header btn-wh2"
              sx={{ display: 'flex' ,justifyContent:'center',minHeight:'40px'}}
              active={orderBy === headCell.id && !iconShow}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              // hideSortIcon={iconShow}
              onMouseEnter={()=>setIconShow(false)}
              onMouseLeave={()=>setIconShow(true)}
            >
              {/* {headCell.label} */}
              <div className="text-Center fonted fontAdjust-700">{headCell.label}</div>
            </TableSortLabel>
          </TableCell>

        ))}
        <TableCell sx={{backgroundColor:'transparent'}}
          align={'center'}
          padding={'normal'}>
          <span>
          <div className="fonted fontAdjust-700">Assigned on</div>
          </span>
          
          
        </TableCell>

        <TableCell sx={{backgroundColor:'transparent'}}
          align={'center'}
          padding={'normal'}>
          
          <div className="fonted fontAdjust-700">Total Experiments</div>
          
        </TableCell>

      </TableRow>
    </TableHead>

  );
}      

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    // 
    <div className='pt-10'>

    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


export default function Device(props) {
  const history = useHistory();
    let search = window.location.search;
    let paramse = new URLSearchParams(search);
  const [show, setShow] = useState(false);
  const [editMode, seteditMode] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [devices,setDevices]=useState([]);
  
  const [name, setName] = useState("");
  const [editName, setEditName] = useState('');
  const [activeId, setactiveId] = useState("");
  const [ myUser , setmyUser ] = useState(teacherState);
  const [noOfPages, setNoOfPages] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [paged, setPaged] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageLimit, setPageLimit] = useState(25);

  const [snackBarShow, setSnackBarShow] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState('');

    const [deviceInfo, setDeviceInfo] = useState(null);

    const logoutHandler=async()=>{
      setTimeout(()=>{
        // setmyUser({
        //   fName: null,
        //   userId: null,
        //   token: null,
        //   lName: null,
        // })
        setTeacherCookie({
          fName: null,
          userId: null,
          token: null,
          lName: null,
        })
        let hostname= window.location.host;
        // console.log(hostname,window.location.protocol)
        window.location.assign(`${window.location.protocol}//${hostname}/teachers`)   
      })  
    }

    
  const feedBackMsg =(snackShow,snackSeverity,snackMessage)=>{
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    
    feedBackMsg(false,'','');
  }


  useEffect(()=>{
    // getDevice(); 
    const _cookie = getCookie('recoil-persist')
    const user = _cookie ? JSON.parse(_cookie)['teacher_v1'] : {}
    setmyUser({ ...user, 'page': "device" });
    props.page('device')
    getDeviceinfo(user);
    let urlPage=parseInt(paramse.get('page'));
        // console.log(urlPage)
        if(parseInt(paramse.get('page'))){
            // console.log('lol1')
            history.push({
                pathname: `/mydevices`,
                search: '?page=' + urlPage
              })
              setPaged(urlPage)
              getDevice(urlPage,pageLimit,user)
        }else{
            // console.log('lol')
            setPaged(0)
            history.push({
                pathname: `/mydevices`,
                search: '?page=0'
              })
              getDevice(0,pageLimit,user)
        }
    // setmyUser({...myUser,'page':"device"});
    
      },[1])


  const getDevice=async(pages,limits,user=myUser)=>{
    let res=await getAllMyDevice(user?.token,pages,limits)
    if(res.error){
      // alert('Error fetching Devices. Try Again')
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
    }
    else{
      setDevices(res.res.data.data.listOfDevices)
      let divisor=parseInt(res.res.data.data.totalcount/limits);
            let remainder=(res.res.data.data.totalcount%limits==0)?0:1;
            setNoOfPages(divisor+remainder)
            setCurrPage(pages);
    }
  }

  const getDeviceinfo=async(user=myUser)=>{
    let res=await getMyDeviceInfo(user?.token)
    if(res.error){
      // alert('Error fetching Devices. Try Again')
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }
    }
    else{
      setDeviceInfo(res.res.data.data)
     
    }
  }
  
  const editHandler = async (e) => {
    e.preventDefault();
    let dataToSend={name: editName}
    let res = await editDevice(activeId,dataToSend);
    if (res.error) {
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
      }

    } else {
      setactiveId('')
      setEditName('')
      handleCloseAddModal();
 getDevice(paged,pageLimit)
      //showModal(true)
    }
  };


  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true)
    let res = await createDevice({ name: name });
    if (res.error) {
      if(res.data.statusCode==401){
        logoutHandler();
      }
      else{
        feedBackMsg(true,'error',res.data.msg);
        setLoading(false)
      }
    

    } else {
    setLoading(false)

      getDevice(paged,pageLimit);
      handleCloseAddModal();
      //showModal(true)
    }
  };



const handleCloseAddModal = () => {
  // console.log(show)
  setShow(false);
  seteditMode(true);

};


const handleShowAddModal = () => {
  setShow(true);
};

const handleChangePage = (event, newPage) => {
  setPaged(event.selected);       
  getDevice(event.selected,pageLimit);
  history.push({
      pathname: `/mydevices`,
      search: '?page=' + event.selected
    })
};

const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageLimit);
  const handleRequestSort = (event, property) => {
    // console.log(property)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - devices.length) : 0;


  return (
    <main style={{position:"absolute",width:"100%",top:"20%"}}>
		<div className="container px-4 pt-3">
			<div className="upper-section px-60">
				<div className="breadcrumb-menu py-2">
					<Link to={'/'}>Teacher Panel </Link><i className="fas fa-angle-double-right"></i><a className="disable">Devices</a>
				</div>
				<div className="text-blue maintext"><h2 className="h2">All Devices</h2></div>
        {/* <br/> */}
            <div className="d-flex justify-content-end align-items-end">
                
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={noOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handleChangePage}
                    containerClassName="pagination"
                    activeClassName="activePage active"
                    forcePage={currPage}/>

            </div>
			</div>
		<div className=" px-3 pb-5 ">
				
				<div className="row table-section experiments devices">
				    
					<div className=" col-9 ps-0 pe-4 pt-3">
					    <div className="card-header ps-5"><h3 className="h3">View all assigned devices here</h3></div>
					    <div className="lower-section mt-2">
						{/* <table className="table no-border pb-5 pt-4 px-5" style={{backgroundColor:'transparent'}}>
					  	<thead className="mb-2" >
						    <tr >
						      <th scope="col" className="col1 pb-4" style={{backgroundColor:'transparent'}}>Sr. No.</th>
						      <th scope="col" className="col2 pb-4 px-4 " style={{backgroundColor:'transparent'}}><center><div className="btn-wh text-center">Device Name</div></center></th>
						      <th scope="col" className="col2 pb-4 pe-4 " style={{backgroundColor:'transparent'}}><center><div className="btn-wh text-center">Latest Experiment</div></center></th>
						      <th scope="col" className="col2 pb-4 text-center" style={{backgroundColor:'transparent'}}>No. Of Experiments</th>
						    </tr>
					  	</thead>
					  	<tbody className="table-body " style={{color:"#1A273B",backgroundColor:'transparent'}}>
						    <tr>
						      	<td className="text-center">1</td>
						      	<td className="text-center"><a href="#">Device 1</a></td>
						      	<td className="text-center">09/09/21</td>
						      	<td className="text-center">4</td>
						    </tr>
					  </tbody>
					</table> */}
          	<div className=" px-3" style={{backgroundColor:'transparent'}}>
				
				<div className="row table-section experiments " >
					<div className="lower-section px-4 pt-3 w-100" style={{backgroundColor:'transparent'}}>
          <Box sx={{ width: '100%',backgroundColor:'transparent'}}>

            <Paper sx={{ width: '100%', mb: 2,backgroundColor:'transparent',boxShadow:'none' }}>
              <EnhancedTableToolbar numSelected={selected.length} />
  <TableContainer >
    <Table
      sx={{ minWidth: 750,backgroundColor:'transparent',border:'none' }}
      aria-labelledby="tableTitle"
      size={ 'small' }
      stickyHeader
    >
      <EnhancedTableHead

        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        onSelectAllClick={handleSelectAllClick}
        onRequestSort={handleRequestSort}
        rowCount={devices.length}
      />
      <TableBody sx={{backgroundColor:'transparent',color:'#1A273B'}} className="table-body fonted">
      
        {stableSort(devices, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((devices, index) => {
            const isItemSelected = isSelected(devices.name);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                 hover
                onClick={(event) => handleClick(event, devices.name)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={devices.name}
                selected={isItemSelected}
                style={{backgroundColor:'transparent',fontFamily:'Exo2-Regular'}}
                className="fonted noBord"
              >
                {/* <TableCell align="center" className="fitted" sx={{fontFamily:'Exo2-Regular',fontSize:'medium'}}>{index+1}</TableCell> */}
                  <TableCell align="center" className="fitted" sx={{fontFamily:'Exo2-Regular',fontSize:'medium'}}>
                        {devices._id}
                      </TableCell>
                      <TableCell align="right" className="fitted" sx={{fontFamily:'Exo2-Regular',fontSize:'medium',color:'#7F2490'}}>{(devices?.name)}</TableCell>
                      <TableCell align="right" className="fitted" sx={{fontFamily:'Exo2-Regular',fontSize:'medium'}}>
                        
                        {(devices.assignedAt==undefined)?`${new Date(devices.updatedAt).toLocaleDateString()} ${new Date(devices.updatedAt).toLocaleTimeString()}`
                        :`${new Date(devices.assignedAt).toLocaleDateString()} ${new Date(devices.assignedAt).toLocaleTimeString()}`}
                      </TableCell>

                      <TableCell align="center" className="fitted" sx={{fontFamily:'Exo2-Regular',fontSize:'medium'}}>
                        
                        {devices?.experimentCount}
                      </TableCell>

              </TableRow>
            );
          })}
        {emptyRows > 0 && (
          <TableRow
            style={{
              height: (dense ? 33 : 53) * emptyRows,
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
 
</Paper>
</Box>
</div>
</div>
</div>
					</div>
					</div>
					<div className="col-3 px-0 pt-3 ">
					    <div className="card-header px-0"><h3 className="h3">Summary</h3></div>
					    <div className=" lower-section2 mt-2">
						<table className="table no-border px-4 py-2 mb-0 devices-2tab" style={{backgroundColor:'transparent'}}>
					  	
					  	<tbody className="table-body" style={{backgroundColor:'transparent'}}>
						    <tr className="noBord">
						      	<td className="tab-title fonted">Classes</td>
						      	<td className="tab-value fonted">{deviceInfo?.sclass}</td>
						    </tr>
						    <tr className="noBord">
						      <td className="tab-title fonted">Students</td>
						      <td className="tab-value fonted">{deviceInfo?.student}</td>
						    </tr>
						    <tr className="noBord">
						      <td className="tab-title fonted">Devices</td>
						      <td className="tab-value fonted">{deviceInfo?.device}</td>
						    </tr>
						    <tr className="noBord">
						      <td className="tab-title fonted">Experiments</td>
						      <td className="tab-value fonted">{deviceInfo?.experiment}</td>
						    </tr>
					  </tbody>
					</table>
					</div>
					</div>
					
				</div>
			</div>
			{/* <div className="container pe-1 pt-5 device" >
	<div className="d-flex justify-content-end">
		<nav aria-label="Page navigation example ">
  <ul className="pagination" >
    <li className="page-item">
      <a className="page-link pre" href="#" aria-label="Previous">
        <i className="fa fa-caret-left d-flex align-items-center" aria-hidden="true"></i>
      </a>
    </li>
    <li className="page-item"><a className="page-link active" href="devices.html">1</a></li>
    <li className="page-item"><a className="page-link" href="devices-page2.html">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item"><a className="page-link" href="#">4</a></li>
    <li className="page-item">
      <a className="page-link next" href="#" aria-label="Next">
        <i className="fa fa-caret-right d-flex align-items-center" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
  
</nav>
	</div>
</div> */}
		</div>
    <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                    <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar>
	</main>
  );
}
