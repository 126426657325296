import axios from "axios";
import { baseUrl } from "../Base_url";


export const getInvitationDetails = async (data) => {
    try {
      const res = await axios.get(`${baseUrl}/invite${data}`);
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const postAdminThroughInvitation = async (invitationToken,data) => {
    try {
      const res = await axios.post(`${baseUrl}/invite${invitationToken}`,data);
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const addAdminToSchoolDistrict = async (id,data) => {
    try {
      const res = await axios.put(`${baseUrl}/admin/school-district/${id}/admin`,data);
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
 