import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import './studentdb.css'
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import Navbar from '../Navbar/Navbar'
import { useRecoilValue, useRecoilState } from 'recoil'
import getCookie, { setStudentCookie, studentState } from '../../Atom'
import { fetchStudentsDetail, getAllExperimentsForStudent } from './api'
import './style.css'
import logo from '../../assets/images/logo.png';
import EachExperiment from './EachExperiment'
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom";
import logo1 from '../../assets/images/BETA-LOGO-SpaceTrek.png';

import Sign_in_image from '../../../teacher-admin-builds/assets/images/Sign_in_image.jpg'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Snackbar from '@mui/material/Snackbar';
// import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});





function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [

  {
    id: 'bottleVolume',
    numeric: false,
    disablePadding: true,
    label: 'Bottle Volume',
  },
  {
    id: 'fuelVolume',
    numeric: true,
    disablePadding: true,
    label: 'Fuel Volume',
  },
  {
    id: 'nozzleDia',
    numeric: true,
    disablePadding: true,
    label: 'Nozzle Diameter',
  },
  {
    id: 'pressure',
    numeric: true,
    disablePadding: true,
    label: 'Pressure',
  },
  {
    id: 'student',
    numeric: false,
    disablePadding: true,
    label: 'Student',
  },

  {
    id: 'Date',
    numeric: true,
    disablePadding: true,
    label: 'Date',
  },
  {
    id: 'Time',
    numeric: true,
    disablePadding: true,
    label: 'Time',
  },
  // {
  //   id: 'Analyze',
  //   numeric: true,
  //   disablePadding: true,
  //   label: 'Analyze',
  // },
  // {
  //   id: 'Action',
  //   numeric: true,
  //   disablePadding: true,
  //   label: 'Action',
  // },
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const [iconShow, setIconShow] = useState(true);

  return (



    <TableHead >
      {/* <div className=' pt-4'>
      </div> */}
      <TableRow>
        {/* <TableCell padding="checkbox">
          
        </TableCell> */}

        {/* <TableCell 
          align={'center'}
          sx={{backgroundColor:'transparent'}}
          
        padding={ 'normal'}>
          <div className="btn-wh2">SL No.</div>
          
        </TableCell> */}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}

            //  sx={{background:'none'}}
            align={'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ backgroundColor: 'transparent', marginX: '1px' }}
          >
            <TableSortLabel
              className="sort-header btn-wh2"
              sx={{ display: 'flex', justifyContent: 'center', minHeight: '40px' }}

              active={orderBy === headCell.id && !iconShow}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              // hideSortIcon={iconShow}
              onMouseEnter={() => setIconShow(false)}
              onMouseLeave={() => setIconShow(true)}
            >
              <div className="text-Center fonted fontAdjust-700">{headCell.label}</div>

            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell align={'center'} sx={{ backgroundColor: 'transparent' }}
          padding={'normal'} >

          <div className="text-Center fonted fontAdjust-700">Analyze</div>

        </TableCell>
      </TableRow>
    </TableHead>

  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    // 
    <div className='pt-10'>

    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const history = useHistory();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const [experiments, setExperiments] = useState([])
  //const [page, setPage] = useState(1)
  const [school, setSchool] = useState("")
  const [sClass, setSClass] = useState("")
  // const { token, loginId, className, teacher, classId } = useRecoilValue(studentState);
  const [myUser, setmyUser] = useState(studentState)
  const [experimentpage, setExperimentPage] = useState(0)
  const [experimentlimit, setExperimentLimit] = useState(10)
  const [totalpage, setTotalPage] = useState(0);
  const [totalExperiment, setTotalExperiment] = useState(0);
  const [noOfPages, setNoOfPages] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageLimit, setPageLimit] = useState(25);
  const [paged, setPaged] = useState(0)
  let paramse = useParams();
  const [serial, setSerial] = useState(null);
  const [snackBarShow, setSnackBarShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');

  const logoutHandler = async () => {
    setTimeout(() => {
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })

      setStudentCookie({
        fName: null,
        userId: null,
        token: null,
        lName: null,
      })

      let hostname = window.location.host;
      // console.log(hostname,window.location.protocol)
      window.location.assign(`${window.location.protocol}//${hostname}/`)
    })
  }


  const feedBackMsg = (snackShow, snackSeverity, snackMessage) => {
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    feedBackMsg(false, '', '');
  }


  useEffect(() => {
    const _cookie = getCookie('recoil-persist')
    if(!_cookie){
      logoutHandler()
    }else{
    const user = JSON.parse(_cookie)['student']
    setmyUser(user)
    if (!user.classId) {
      logoutHandler()
    }
    let urlPage = parseInt(params.get('page'));
    let urlLimit = parseInt(params.get('limit'));
    let urlSerial = params.get('serial')
    // console.log(urlPage)
    if (parseInt(params.get('page')) || parseInt(params.get('limit'))) {
      // console.log('lol1')
      history.push({
        pathname: '/',
        search: `?${(urlSerial) ? `serial=${urlSerial}&` : ''}` + 'page=' + urlPage + '&limit=' + urlLimit
      })
      getListOfExperiments(urlPage, urlLimit,user);
      setPaged(urlPage);
      setSerial(urlSerial);
      setPageLimit(urlLimit);
    } else {
      // console.log('lol')
      history.push({
        pathname: '/',
        search: `?${(urlSerial) ? `serial=${urlSerial}&` : ''}` + 'page=0&limit=25'
      })
      getListOfExperiments(0, pageLimit,user)
    }
    getStudentDetails(user)
    }
    // console.log('Again call')
  }, [])
  const getStudentDetails = async (user=myUser) => {
    const res = await fetchStudentsDetail(user?.token)
    // alert('')
    // console.log(res.res.data.data)
    if (!res.error) {
      setSchool(res.res.data.data.student?.school?.name)
      setSClass(res.res.data.data.student?.sclass?.name)
    } else {
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    }

  }

  const getListOfExperiments = async (pages, limits,user=myUser) => {
    let arr = [];
    let res;
    // console.log('get the serial number',paramse.serial)
    res = await getAllExperimentsForStudent(user?.token, pages, limits, { className: user?.className, teacher: user?.teacher, classId:user?.classId })
    console.log(res)
    if (res.error) {
      if (res.data.statusCode == 401) {
        // logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {

      // console.log(res.res.data.data.experiments)
      let experiment = res.res.data.data.experiments;
      experiment.forEach(element => {
        let obj = {
          'bottleVolume': element.bottleVolume,
          'fuelVolume': element.fuelVolume,
          'nozzleDia': element.nozzleDia,
          'pressure': element.pressure,
          'student': element.studentName,
          Date: new Date(element.createdAt).toLocaleDateString(),
          Time: new Date(element.createdAt).toLocaleTimeString(),
          _id: element._id
        }
        arr.push(obj)
      });
      setExperiments(arr);
      // setExperiments(res.res.data.data.experiments);

      setTotalPage(res.res.data.data.totalCount);

      let divisor = parseInt(res.res.data.data.totalCount / limits);
      let remainder = (res.res.data.data.totalCount % limits == 0) ? 0 : 1;
      setNoOfPages(divisor + remainder)
      setCurrPage(pages);
      // console.log(pages)
    }
  }





  // console.log(experiments)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageLimit);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = experiments.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {

    setPaged(newPage);
    // console.log(paged)

    getListOfExperiments(event.selected, pageLimit);
    history.push({
      pathname: '/',
      search: `?${(serial) ? `serial=${serial}&` : ''}` + 'page=' + event.selected + '&limit=' + pageLimit
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - experiments.length) : 0;

  return (
    <div className='teacher-div'>
      <div className="index-bg fonted">
        <img className="class fit-cover" src={Sign_in_image} />

        <div className="login">
          <Navbar onlyLogout={false} />
          <main style={{ position: "absolute", width: "100%", top: "20%" }}>
            <div className="container px-4 pt-3">
              <div className="upper-section px-60">
                <div className="breadcrumb-menu py-2">
                  <a className='disable'>Students Panel </a><i className="fas fa-angle-double-right"></i><a className='disable'>Experiments</a>
                </div>
                <div className="text-blue maintext"><h2 className='h2'>Experiments</h2></div>
                <div className="">
                  <div className="">
                    <div className="card-header px-0"><h3 className='h3'>Manage and analyse experiments here</h3></div>
                  </div>

                </div>
                {/* <br/> */}
                <div className="d-flex justify-content-end align-items-end">

                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={noOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handleChangePage}
                    containerClassName="pagination"
                    activeClassName="activePage active"
                    forcePage={currPage} />

                </div>
              </div>
              <div className=" px-3">

                <div className="row table-section experiments ">
                  <div className="lower-section px-4 pt-3 w-100">


                    <Box sx={{ width: '100%' }}>

                      <Paper sx={{ width: '100%', mb: 2, backgroundColor: 'transparent', boxShadow: 'none' }}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                        <TableContainer >
                          <Table
                            sx={{ minWidth: 750, backgroundColor: 'transparent' }}
                            aria-labelledby="tableTitle"
                            size={'small'}
                            stickyHeader
                          >
                            <EnhancedTableHead

                              numSelected={selected.length}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={handleSelectAllClick}
                              onRequestSort={handleRequestSort}
                              rowCount={experiments.length}
                            />
                            <TableBody sx={{ backgroundColor: 'transparent', color: '#1A273B' }} className="table-body fonted">

                              {stableSort(experiments, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((experiments, index) => {
                                  const isItemSelected = isSelected(experiments.name);
                                  const labelId = `enhanced-table-checkbox-${index}`;

                                  return (
                                    <TableRow
                                      hover
                                      onClick={(event) => handleClick(event, experiments.name)}
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={experiments.name}
                                      selected={isItemSelected}
                                      sx={{ backgroundColor: 'transparent', fontFamily: 'Exo2-Regular' }}
                                      className="fonted"
                                    >
                                      {/* <TableCell align="right" className="fitted" sx={{fontFamily:'Exo2-Regular',fontSize:'medium'}}>{index+1}</TableCell> */}


                                      {/* <TableCell align="center" style={{width:'20px'}} className="fitted" sx={{fontFamily:'Exo2-Regular',fontSize:'medium'}}><div className='d-flex justify-content-center'>{index+1}</div></TableCell> */}

                                      <TableCell className="fitted experimental-td" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{Number.isInteger(experiments?.bottleVolume) ? experiments?.bottleVolume : experiments?.bottleVolume.toFixed(2)} ml</TableCell>
                                      <TableCell className="fitted" align="center" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{Number.isInteger(experiments?.fuelVolume) ? experiments?.fuelVolume : experiments?.fuelVolume.toFixed(2)} ml</TableCell>

                                      <TableCell className="fitted" align="center" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{Number.isInteger(experiments?.nozzleDia) ? experiments?.nozzleDia : experiments?.nozzleDia.toFixed(2)} mm</TableCell>

                                      <TableCell className="fitted" align="center" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{Number.isInteger(experiments?.pressure) ? experiments?.pressure : experiments?.pressure.toFixed(2)} psi</TableCell>

                                      {/* <TableCell className="fitted" align="center" sx={{fontFamily:'Exo2-Regular',fontSize:'medium'}}>{experiments.className}</TableCell> */}
                                      <TableCell className="fitted" align="center" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{experiments?.student}</TableCell>
                                      <TableCell className="fitted" align="center" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{experiments?.Date}</TableCell>
                                      <TableCell className="fitted" align="center" sx={{ fontFamily: 'Exo2-Regular', fontSize: 'medium' }}>{experiments?.Time}</TableCell>

                                      <TableCell className="fitted" sx={{ fontFamily: 'Exo2-Regular', fontSize: '16px' }}>
                                        <div className="d-flex class-btn justify-content-center"><div className="btn-wh1 text-center d-flex align-items-center py-0">
                                          <div className="pt-1 pb-0 d-flex align-items-center"><Link to={`/experiments/${experiments._id}`} style={{ color: 'black' }}>Analyze</Link></div>
                                        </div>
                                        </div>
                                      </TableCell>

                                    </TableRow>
                                  );
                                })}
                              {emptyRows > 0 && (
                                <TableRow
                                  style={{
                                    height: (dense ? 33 : 53) * emptyRows,
                                  }}
                                >
                                  <TableCell colSpan={6} />
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>

                      </Paper>

                    </Box>
                    <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                      <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                      </Alert>
                    </Snackbar>
                  </div>


                </div>
              </div>
            </div>
          </main>

        </div>
      </div>
    </div>
  );
}

{/* <div className="student-container">
        <div className='logo'>
          <img src={logo1} alt="SpaceTrex" className='logos' />
        </div>
        <div className="components">
          <div className="studentdb-container">
            
            <Navbar onlyLogout={true} />
            <div className="basic-info" style={{marginTop: '10px'}}>
               <div className="studentdb-name" style={{textTransform : 'capitalize', fontSize:'20px'}}>{school}</div>
               <div className="studentdb-name" style={{textTransform : 'capitalize'}}>Welcome {loginId}</div>
               <div className="studentdb-name"  style={{textTransform : 'capitalize', fontSize:'20px'}}>Experiments of {sClass}</div>
             </div>
              <br />
             <Box sx={{ width: '100%' }} className='dzx'>

<Paper sx={{ width: '100%', mb: 2 }}>
  <EnhancedTableToolbar numSelected={selected.length} />
  <TableContainer  className="blurry-bg">
    <Table  
      aria-labelledby="tableTitle"
      size={'small'}
      stickyHeader
      sx={{backgroundColor:'none',borderCollapse:'collapse'}}
    >
      <EnhancedTableHead
        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        onSelectAllClick={handleSelectAllClick}
        onRequestSort={handleRequestSort}
        rowCount={experiments.length}
      />
      <TableBody sx={{background:'none'}}>
        
        {stableSort(experiments, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((experiments, index) => {
            const isItemSelected = isSelected(experiments.name);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                sx={{border:'2px solid white'}}
                hover
                onClick={(event) => handleClick(event, experiments.name)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={experiments.name}
                selected={isItemSelected}
                className="blurry-bg"
              >
               

                <TableCell align="center" >{index+1}</TableCell>
                <TableCell align="right">{Number.isInteger(experiments?.bottleVolume)?experiments?.bottleVolume:experiments?.bottleVolume.toFixed(2)} ml</TableCell>
                <TableCell align="right">{Number.isInteger(experiments?.fuelVolume)?experiments?.fuelVolume:experiments?.fuelVolume.toFixed(2)} ml</TableCell>
                <TableCell align="right">{Number.isInteger(experiments?.nozzleDia)?(experiments?.nozzleDia):(experiments?.nozzleDia).toFixed(2)} mm</TableCell>
                <TableCell align="right">{Number.isInteger(experiments.pressure)?experiments.pressure:experiments.pressure.toFixed(2)} psi</TableCell>
                <TableCell align="right" sx={{textTransform:'capitalize'}}>{experiments.student}</TableCell>
        
                
      <TableCell align="right">{experiments.Date}</TableCell>
        <TableCell align="right">{experiments.Time}</TableCell> 
        <TableCell >
          <Link to={`/experiments/${experiments._id}`}><button style={{width:'90px',height:'35px',textAlign:'center',borderRadius:'25px'}}>
            Analyse</button></Link>
        </TableCell>
        

              </TableRow>
            );
          })}
        {emptyRows > 0 && (
          <TableRow
            style={{
              height: (dense ? 33 : 53) * emptyRows,
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
 
</Paper>
</Box>
<div >
               {experiments.length>0?
              
              <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={noOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handleChangePage}
              containerClassName="pagination"
              activeClassName="activePage active"
              forcePage={currPage}/>
                :null}
            </div>
            <br/>
          </div>
          
        </div>
      </div>
      

                <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                    <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar> */}


