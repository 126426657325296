import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {CircularProgress} from '@material-ui/core'
import { SignupAdmin } from '../LoginPage/Api'
import CustomModal from '../../Modal/Modal'
import showModal from '../../Modal/showModal'

export default function RegisterForm() {
 // const [ myUser , setmyUser ] = useRecoilState(userState)
  const [email,setEmail]=useState("")
  const [fname,setfName]=useState("")
  const [lname,setlName]=useState("")
  const [password,setPassword]=useState("")
  const [error,setError]=useState(false)
  const [modalMessage,setModalMessage]=useState("")
let history=useHistory()
  const [loading,setLoading]=useState(false)
  function validation(){
    if(!email){
      // console.log('no email')
      setError('Email not found')
      
      return false
    }
    else if(!password){
      // console.log('no email')
      setError('Password not found')
      return false
    
    }
    else if(!fname && lname){
      setError('Name not found')
      return false
    }
    else{
      return true
    }
      }
 // console.log(localStorage.getItem('user-data'))
 const submitHandler=async(e)=>{
   e.preventDefault()
   if(validation()){
     setLoading(true)
     let data={email:email,password:password,fName:fname,lName:lname}
let res=await SignupAdmin(data)
// console.log(res)
if(res.error){
  showModal(true)
  if(res.data){
    setModalMessage(res.data)
  }
  setModalMessage('Check Email and Password')
  // console.log(res) 
}
else{
  // console.log(res) 
  alert('Sign-up')
  history.push('/')

}
setLoading(false)

  }
}
  return (
    <form id='#authForm'  onSubmit={submitHandler}>
        <CustomModal message={modalMessage}/>
      <div className='col-md-12 '>   
      <div className='form-group   p-20'>
        <label className='form-label'>
          Admin Id
        </label>
        <input
          type='text'
          className='form-control'
          placeholder='admin@gmail.com'
          onChange={(e)=>setEmail(e.target.value)}
        />
      </div>
      <div className='form-group   p-20'>
        <label className='form-label'>
         First Name 
        </label>
        <input
          type='text'
          className='form-control'
          placeholder='First Name'
          onChange={(e)=>setfName(e.target.value)}
        />
      </div>
     
      <div className='form-group   p-20'>
        <label className='form-label'>
        Last  Name 
        </label>
        <input
          type='text'
          className='form-control'
          placeholder='Last Name'
          onChange={(e)=>setlName(e.target.value)}
        />
      </div>
     
    <div className='form-group'>
    <label className='form-label'>
          Password
        </label>
      <input
        type='password'
        className='form-control'
        placeholder='Password'   
        onChange={(e)=>setPassword(e.target.value)}
      />
    </div>
    <div className='form-footer d-flex'>
Already Have A Account?
    <span className='text-right pb-2'>
    <Link to='/' >
            Sign-in
          </Link>
</span>


    </div>
      <div className='form-group'>
      <label className='text-danger'>
         {error?error:null}
        </label> 
      <button type='submit'  className='cta-btn'>
  {loading?<CircularProgress/> : "Sign-in" }     
      </button>
    </div>
    
      
     </div>
    </form>
  )
}
