import axios from "axios";
import { baseUrl } from "../Base_url";

export const createFullNewTeacher = async (districtId, schoolId, data, token) => {
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${districtId}/school/${schoolId}/teacher/add`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const GetEverySchoolByDistrict = async (districtId, token) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${districtId}/school/allSchool`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const createDistrictAdminManually = async (districtId, data, token) => {
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${districtId}/admincreate`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const getUsersAdmin = async (token,spage,slimit,squery,dpage,dlimit,dquery,tpage,tlimit,tquery) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/accounts/users?spage=${spage}&slimit=${slimit}&squery=${squery}&dpage=${dpage}&dlimit=${dlimit}&dquery=${dquery}&tpage=${tpage}&tlimit=${tlimit}&tquery=${tquery}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

export const getAllSchoolDistricts = async (token,page,limit) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/all?page=${page}&limit=${limit}`,{
        headers: 
        { Authorization: `Bearer ${token}` }
        // { Authorization: `Bearer jhjhjhkjjklk` }
        ,
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, 
        data: e.response.data.data 
        // data:e
      };
      return err;
    }
  };

  export const districtAdminArray = async (token) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/objectToArray`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const getSchoolDistrictsAnalytics = async (id, token) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}/dashboard/entities-count`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const getSchoolAnalytics = async (id,sid, token) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}/school/${sid}/dashboard/entities-count`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const deleteSchoolDistrictsAdmin = async (id, adminId, token) => {
    try {
      const res = await axios.delete(`${baseUrl}/admin/school-district/${id}/${adminId}/admin`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const editSchoolDistrictsAdmin = async (id, adminId,data, token) => {
    try {
      const res = await axios.put(`${baseUrl}/admin/school-district/${id}/${adminId}/admin`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const getSchoolDistrictsById = async (id, token) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const editSchoolDistrictsById = async (id ,name ,token) => {
    try {
      const res = await axios.put(`${baseUrl}/admin/school-district/${id}`,name,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const deleteSchoolDistrictsById = async (id ,token) => {
    try {
      const res = await axios.delete(`${baseUrl}/admin/school-district/${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const getSchoolByDistrict=async (id, token,pages, limits)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}/school/all?page=${pages}&limit=${limits}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getSchoolById=async (did,sid, token)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${did}/school/${sid}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getSchoolDetails=async (did,sid, token,pages,limits)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${did}/school/${sid}/teacher/all?page=${pages}&limit=${limits}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const addSchoolDestrict=async (data, token)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const addSchoolDistrictSchema= async (token)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/addSchoolDistrictSchema`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  

  export const getSchoolDestrictAdmin=async (data, token)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${data}/admin`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  
  export const createSchoolDestrictAdmin=async (data, token)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${data.id}/admin`,data.data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const InviteSchoolTeacher=async (id,data, token)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const passwordChangeTeacher=async (id,data, token)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.teacherid}/changePassword`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const passwordChangeDistrictAdmin=async (id,data, token)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${id.did}/${id.sdid}/passwordChange`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const passwordChangeSuperAdmin=async (id,data, token)=>{
    try {
      const res = await axios.put(`${baseUrl}/admin/accounts/${id}/passwordChange`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getTeacherDetails=async (id, token)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.teacherid}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
 
  export const editTeacherDetails=async (id,data, token)=>{
    try {
      const res = await axios.put(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.teacherid}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const deleteTeacherDetails=async (id,token)=>{
    try {
      const res = await axios.delete(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.teacherid}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const createSchool=async (id,data, token)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${id}/school/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const editSchoolById=async (id,schoolId,data, token)=>{
    try {
      const res = await axios.put(`${baseUrl}/admin/school-district/${id}/school/${schoolId}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const deleteSchoolById=async (id,schoolId,token)=>{
    try {
      const res = await axios.delete(`${baseUrl}/admin/school-district/${id}/school/${schoolId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getAllSchoolAdmins=async (id, token)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin/all`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  
  export const InviteAdminToSchool=async (id,data, token)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const EditSchoolAdmin=async (id,data, token)=>{
    try {
      const res = await axios.put(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin/${id.adminId}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const deleteSchoolAdmin=async (id,token)=>{
    try {
      const res = await axios.delete(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin/${id.adminId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getSchoolAdminDetails=async (id, token)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin/${id.adminId}`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getAllAssignedDevicesForSchoolDistrict=async (id, token)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}/device/all`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  

  /// Student Apis

    export const getAllStudentbyTeacher=async (id, token)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.teacherid}/student/all`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  
  
   export const getStudentDetails=async (id, token)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.tid}/student/${id.studentId}`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const deleteStudent=async (id, token)=>{
    try {
      const res = await axios.delete(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.tid}/student/${id.studentId}`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const editStudentDetails=async (id,data, token)=>{
    try {
   const res = await axios.put(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.tid}/student/${id.studentId}`,data,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const createStudent=async (id,data, token)=>{
    try {
  const res = await axios.post(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.tid}/student/create`,data,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }