import { useState } from "react"
import { forgotPassword } from "../LoginPage/Api";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useHistory } from "react-router";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
   
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
export default function ResetForm(props) {
  const [email,setEmail]=useState("")
  const [otp,setOtp]=useState("")
 
  const [loading,setLoading]=useState(false)
  const [open, setOpen] = useState(false);
  const history=useHistory()
  
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const passwordForgot = async() =>{
    let res = await forgotPassword({email:email.trim()});
    if(res.error){
      alert(res.data.msg);
    }else{
        alert(res.res.data.msg);
        let hostname= window.location.host;
        window.location.assign(`${window.location.protocol}//${hostname}/school-district`);
    }
  }


  return ( 
  <>
    <div className='auth-heading'>
      <div className='col-sm-12' style={{margin:'auto 0'}}>
        <div className='form-group'>
          <label className='form-label'>
            Email Address
          </label>
          <input
          value={email}
            type='text'
            className='form-control'
            placeholder='Email Address'
            onBlur={(e)=>setEmail(e.target.value.trim())}
            onChange={(e)=>setEmail(e.target.value)}
          />
        </div>
  
        <div className='form-group'>
          <button type='submit' onClick={(e)=>{e.preventDefault();
            passwordForgot()}}  className='cta-btn'>
            {loading?<CircularProgress/> : "Change Password" }     
          </button>
        </div>
      </div>  
    </div>
  </>
  )
}

{/* <><Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={open}>
      <div className={classes.paper}>
        <h2 id="transition-modal-title">Verify OTP</h2>
        <div className='form-group'>
    <input
      type='text'
      className='form-control'
      placeholder='OTP'
      onChange={(e)=>setOtp(e.target.value)}
    />
    <div className='form-group mt-1'>
  <button type='submit' onClick={(e)=>{e.preventDefault()
    handleClose()}}  className='cta-btn'>
{loading?<CircularProgress/> : "Send Otp" }     
  </button>
</div>
  </div>
      </div>
    </Fade>
  </Modal>  <form id='#authForm'  >
  <div className='col-md-8 m-auto col-sm-12  text-center'>
  <div className='form-group'>
  <label className='form-label'>
          Email Address
        </label>
    <input
      type='text'
      className='form-control'
      placeholder='Email Address'
      onChange={(e)=>setEmail(e.target.value)}
    />
  </div>
  
  <div className='form-group'>
  <button type='submit' onClick={(e)=>{e.preventDefault()
    handleOpen()}}  className='cta-btn'>
{loading?<CircularProgress/> : "Send Otp" }     
  </button>
</div>



  
 </div>
</form> */}
