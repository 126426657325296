import NavBar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "./DashBoard.css";
import { getStats } from "./api";
import DashboardStats from "./DashboardStats";
import { useRecoilValue } from "recoil";
import { schoolAdminState } from "../../Atom";
// eslint-disable-next-line react-hooks/rules-of-hooks


export default function DashBoard({ showNavbar }) {
  const { token, schoolId, districtId } = useRecoilValue(schoolAdminState);

  const [stats, setStats] = useState({
    teacher: 0,
    student: 0,
    device: 0,
    experiment: 0,
  });

  const getDashboardStats = async () => {
    const res = await getStats(token);

    if (res.error) {
      return console.error("Error is", res.error);
    }

    if (!res.error) {
      setStats(res.res.data);
    }
  };

  useEffect(() => {
    getDashboardStats();
    return () => {};
  }, [1]);

if(showNavbar!==false){
  showNavbar=true;
}

  return (
    <section id="hero-dashborad">


      <div >

        {showNavbar && (
          <>
            <NavBar />
            <div className="dashboard-heading">
              <h3>Welcome,St Thomas School Admin</h3>
              <span>Manage Your DashBoard here</span>
            </div>
          </>
        )}

          <DashboardStats/>
     
      </div>
    </section>
  );
}
