import LoginForm from "./LoginForm"
import '../Auth.css'
import './Login.css'
export default function LoginScreen(props) {

    function loginOpen(){
     
      console.log('Called Again')
      props.location()
    }

  return (
    <div>
      <div className='authentication-section'>
        <div className=''>
          <div className='main-form row '>
            <div className='col-lg-6 col-md-10 m-auto pb-10'>
          <div className='auth-form  mt-10  '>
          <div className='auth-heading'>

      <h2 > Admin Login
      </h2>
      <span>Login to manage your dashboard and control panel</span>
        </div>
          <LoginForm location={loginOpen}/>
          </div>
            </div>
            <div className='col-md-0 col-lg-6 svg-div '>
          <div className='ptb-100 '  >
            <img className='img-height-100' src='assets/loginVector.svg'/>
        
          </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  )
}
