import axios from "axios";
import { baseUrl } from "../Base_url";

export const getAllExperimentsForTeacher = async (token, page, limits) => {
    try {
        const res = await axios.get(`${baseUrl}/teacher/teacher/experiment/all?page=${page}&&limit=${limits}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};

export const getAllClassbyTeacher = async (id,token) => {
    try {
      const res = await axios.get(
        `${baseUrl}/teacher/teacher/sclass/all`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
export const editAnExperiment = async (id, data, token) => {
    try {
        const res = await axios.put(`${baseUrl}/teacher/teacher/experiment/${id}`, data,{
            headers: { Authorization: `Bearer ${token}` },
        });
        return { error: false, res: res };
    } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
    }
};
export const getAllStudentbyTeacher = async (id, token) => {
    try {
      console.log(id)
      const res = await axios.get(
        `${baseUrl}/teacher/teacher/sclass/${id}/student/all`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };