import axios from "axios";
import { baseUrl } from "../Base_url";




const userData = JSON.parse(localStorage.getItem("recoil-persist"));


let token = "";

if (userData) {
  if (userData.teacher_v1) {
    token = userData.teacher_v1.token;
  }else{
    // alert('You need to login first')
  }
}





export const getAllSchoolDistricts = async () => {
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/all`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
 
  export const deleteSchoolDistrictsAdmin = async (id) => {
    try {
      const res = await axios.delete(`${baseUrl}/admin/school-district/${id}/admin`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const getSchoolDistrictsById = async (id) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const getSchoolByDistrict=async (id)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}/school/all`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const addclassID=async (token)=>{
    try {
      const res = await axios.get(`${baseUrl}/teacher/teacher/sclass/allotclassID`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const classInfo=async (token,classId)=>{
    try {
      const res = await axios.get(`${baseUrl}/teacher/teacher/sclass/${classId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getSchoolById=async (did,sid)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${did}/school/${sid}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getSchoolDetails=async (did,sid)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${did}/school/${sid}/teacher/all`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const addSchoolDestrict=async (data)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const uploadFileForStudentCreation=async (sclassId, classID, data, token)=>{
    try {
      const res = await axios.post(`${baseUrl}/teacher/teacher/sclass/${sclassId}/${classID}/student/create/bulk/xlsx`,data,{
        headers: { Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'},
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getSchoolDestrictAdmin=async (data)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${data}/admin`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  
  export const createSchoolDestrictAdmin=async (data)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${data.id}/admin`,data.data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const InviteSchoolTeacher=async (id,data)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getTeacherDetails=async (id)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.teacherid}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
 
  export const editTeacherDetails=async (id,data)=>{
    try {
      const res = await axios.put(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/teacher/${id.teacherid}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const createSchool=async (id,data)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${id}/school/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getAllSchoolAdmins=async (id)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin/all`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  
  export const InviteAdminToSchool=async (id,data)=>{
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const EditSchoolAdmin=async (id,data)=>{
    try {
      const res = await axios.put(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin/${id.adminId}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  export const getSchoolAdminDetails=async (id)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id.did}/school/${id.sid}/admin/${id.adminId}`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }

  export const getAllAssignedDevicesForSchoolDistrict=async (id)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}/device/all`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }
  

  /// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis

export const getAllStudentbyTeacher = async (id, token,pages,limits) => {
  try {
    console.log(id)
    const res = await axios.get(
      `${baseUrl}/teacher/teacher/sclass/${id}/student/all?page=${pages}&limit=${limits}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const getAllClassbyTeacher = async (id,token,pages,limits) => {
  try {
    const res = await axios.get(
      `${baseUrl}/teacher/teacher/sclass/all?page=${pages}&limit=${limits}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const getAllClassby = async (token) => {
  try {
    const res = await axios.get(
      `${baseUrl}/teacher/teacher/sclass/allStudent`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};


export const getStudentDetails = async (id) => {
  try {
    const res = await axios.get(
      `${baseUrl}/school-admin/school-district/${id.did}/school/${id.sid}/teacher/${id.tid}/student/${id.studentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const editStudent = async (id,studId,data, token) => {
  try {
    const res = await axios.put(
      `${baseUrl}/teacher/teacher/sclass/${id}/student/${studId}`,data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const deleteStudent = async (id,studId, token) => {
  console.log(id)
  try {
    const res = await axios.delete(
      `${baseUrl}/teacher/teacher/sclass/${id}/student/${studId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const getUsersforStudent=async (teacherId,page,limit,query,token)=>{
  try {
    const res = await axios.get(`${baseUrl}/teacher/accounts/users?page=${page}&limit=${limit}&query=${query}&teacherId=${teacherId}`,{
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
}

export const deleteClass = async (id, token) => {
  try {
    const res = await axios.delete(
      `${baseUrl}/teacher/teacher/sclass/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};






export const createStudent = async (sclassId, data, token) => {
  try {
    const res = await axios.post(
      `${baseUrl}/teacher/teacher/sclass/${sclassId}/student/create`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data };
    return err;
  }
};
export const createSClass = async (nameOfClass, token) => {
  try {
    const res = await axios.post(
      `${baseUrl}/teacher/teacher/sclass/create`,
      {
        "name":nameOfClass
    },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data };
    return err;
  }
};


export const editClassDetails = async (id, name, token) => {
  try {
    const res = await axios.put(
      `${baseUrl}/teacher/teacher/sclass/${id}`,
      {
        "name":name
    },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const editStudentDetails = async (studentId, data) => {
  try {
    const res = await axios.put(
      `${baseUrl}/teacher/teacher/student/${studentId}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};