import { useState } from "react";
import "./Navbar.css";
import MenuSharpIcon from "@material-ui/icons/MenuSharp";
import { Link, useHistory } from "react-router-dom";
import getCookie, { setStudentCookie, studentState } from '../../Atom'
import { useRecoilState } from "recoil";
import bellimg from '../../assets/images/bell.png';
import { addTeachersId } from "../Experiments/api";
import Group_290 from "../../../teacher-admin-builds/assets/images/Group_290.png";
import Group_88 from "../../../teacher-admin-builds/assets/images/Group_88.png"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect } from "react";

export default function NavBar({ onlyLogout }) {

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	let [toggle, setToggle] = useState(true);
	const [myUser, setmyUser] = useState(studentState)
	let history = useHistory()
	const logoutHandler = async () => {
		// setmyUser({
		// 	fName: null,
		// 	userId: null,
		// 	token: null,
		// 	lName: null,
		// })
		setStudentCookie({
			fName: null,
			userId: null,
			token: null,
			lName: null,
		})
		// history.push('/')
		let hostname = window.location.host;
      // console.log(hostname,window.location.protocol)
      window.location.assign(`${window.location.protocol}//${hostname}/`)
	}

	useEffect(()=>{
		const _cookie = getCookie('recoil-persist')
    if(!_cookie){
      logoutHandler()
    }else{
    const user = JSON.parse(_cookie)['student']
    setmyUser(user)
		}
	},[0])

	const addTeacherId = async () => {
		let res = await addTeachersId(myUser.token);
		console.log(res.res.data.data)
	}

	return (
		<>
			<div className="teacher-div">
				<header style={{ position: "absolute", width: "100%" }}>
					<div className="container px-4 py-4 bs-container">
						<div className="row px-1 py-1">
							<div className="col-lg-2c pe-0">
								<div className="logo"><Link to="/" className="a"><img src={Group_290} /></Link></div>
							</div>
							<div className="col-lg-7c px-0 pt-4 d-flex ">
								{onlyLogout ? <>
									<div >
										<Link to='/' > <div className="header-student"><div className="header-navitems"><button >Home</button></div></div> </Link>
									</div>
								</> : null}
							</div>
							<div className="col-lg-3c mt-4">
								<div className="d-flex justify-content-end">
									<div className="info text-end pe-1 ">
										<div className="fonted" style={{ fontSize: 'medium', textAlign: 'end' }}>{myUser?.loginId}</div>

									</div>
									&nbsp; &nbsp;
									<div>
										<div className="dropdown">
											<div className="bell-icon">
												<button className="btn dropdown-toggle bell-icon1" onClick={handleClick} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
													<img src={Group_88} style={{ width: '35px' }} />
												</button>
												<Menu
													id="basic-menu"
													anchorEl={anchorEl}
													open={open}
													onClose={handleClose}
													MenuListProps={{ 'aria-labelledby': 'basic-button' }}
													transformOrigin={{ horizontal: 'right', vertical: 'top' }}
													anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
												>
													<MenuItem onClick={logoutHandler}>Logout</MenuItem>
												</Menu>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
			{/* <div className="header-student">
        
        <div className="header-navitems">
          <Link to='/' > <button >Home</button> </Link>
        
        </div>
        
        <div className="header-content">
          <div className="header-content-info">
            <strong>{myUser.loginId}</strong>
            <strong><a href="#" onClick={logoutHandler}  style={{color: '#880707'}}>Logout</a></strong>
          </div>
          &nbsp;&nbsp;
          <div className="header-content-img" >
            <img src={bellimg} alt="bell" style={{marginTop:'-12px'}}/>
          </div>
        </div>

         For Mobile devices *
        <nav role="navigation" className='header-mobile'>
          <div id="menuToggle">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu">
              <Link to="/"><li>Home</li></Link>
              
              <div className="mobile-header-content">
                <div className="mobile-header-content-img">
                  <img src={bellimg} alt="bell" />
                </div>
                &nbsp;&nbsp;
                <div className="mobile-header-content-info">
                  <strong>{myUser.loginId}</strong>
                  <strong><a href="#" onClick={logoutHandler}  style={{color: '#880707'}}>Logout</a></strong>
                </div>
              </div>
            </ul>
          </div>
        </nav>
      </div> */}

		</>
	);
}
