import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";
import logo from '../../../assets/imgs/logo.png'
export default function Navbar() {
  let location = useLocation();
  const [activeNavbarIndex, setactiveNavbarIndex] = useState(1);




  useEffect(() => {
  
    switch (location.pathname) {
      case "/":
        setactiveNavbarIndex(1);
        break;

      case "/devices":
        setactiveNavbarIndex(2);
        break;

      case "/myschool":
        setactiveNavbarIndex(3);
        break;
      case "/experiments":
        setactiveNavbarIndex(4);
        break;
    }
  }, [location]);


  return (
    <div className="m-auto side-bar-sticky">
      <div className="sidebar-logo">
        <img src={logo} alt="logo"/>
      </div>

      <br />
      <ul className="list-group w-300  text-left mt-5 ">
        <Link to="/">
          <li
            className={`list-group-item ${
              activeNavbarIndex == 1 && "sidebar-active"
            }`}
            id="dashboard"
            style={{ border: "none" }}
          >
            <i className="fas fa-laptop pt-2 pb-2"></i>
            <span> DashBoard</span>
          </li>
        </Link>

        <Link to="/devices">
          <li
            className={`list-group-item ${
              activeNavbarIndex == 2 && "sidebar-active"
            }`}
            id="devices"
            style={{ border: "none" }}
          >
            <i className="fas fa-mobile-alt pt-2 pb-2"></i>
            <span> Devices</span>
          </li>
        </Link>

        <Link to="/myschool">
          <li
            className={`list-group-item ${
              activeNavbarIndex == 3 && "sidebar-active"
            }`}
            id="myschool"
            style={{ border: "none" }}
          >
            <i className="fa fa-graduation-cap pt-2 pb-2"></i>
            <span>My School</span>
          </li>
        </Link>
        <Link to="/experiments">
          <li
            className={`list-group-item ${
              activeNavbarIndex == 4 && "sidebar-active"
            }`}
            id="myschool"
            style={{ border: "none" }}
          >
            <i className="fa fa-graduation-cap pt-2 pb-2"></i>
            <span>Experiments</span>
          </li>
        </Link>
      </ul>
    </div>
  );
}
