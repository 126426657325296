import axios from "axios"
import { baseUrl } from "../Base_url"

export const destrictAdminOrgDetail = async (token) => {
    try {
        let res = await axios.get(`${baseUrl}/district-admin/school-district`,{
            headers: { Authorization: `Bearer ${token}` },
        })
        return { error: false, res: res };
      } catch (e) {
        const err = { error: true, data: e.response.data.data };
        return err;
      }
}
export const dashboardStats = async (token) => {
    try {
        let res = await axios.get(`${baseUrl}/district-admin/school-district/dashboard/entities-count`,{
            headers: { Authorization: `Bearer ${token}` },
            // headers: { Authorization: `Bearer 566561666bhcs` }
        })
        return { error: false, res: res };
      } catch (e) {
        const err = { error: true, 
          data: e.response.data.data 
        // data:e
        };
        return err;
      }
}