import axios from "axios";
import { baseUrl } from "../Base_url";







const userData = JSON.parse(localStorage.getItem('recoil-persist'))
let token = ""



if (userData && userData.KT_app) {
    token = userData.KT_app.token;
}











export const getSpareDevice=async (token)=>{
  try {
    const res = await axios.get(`${baseUrl}/admin/device/spare`,{
    headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
}
export const getAllAssignedDevicesForSchoolDistrict=async (id,token)=>{
    try {
      const res = await axios.get(`${baseUrl}/admin/school-district/${id}/device/all`,{
      headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  }


  export const AssignDevice = async (id,deviceId,body,token) => {
    try {
      const res = await axios.post(`${baseUrl}/admin/school-district/${id}/device/assign?deviceId=${deviceId}`,body,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const DeleteAssignedDevice = async (id,deviceId,token) => {
    try {
      const res = await axios.delete(`${baseUrl}/admin/school-district/${id}/device/assign?deviceId=${deviceId}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

