import "./addSchoolDestrict.css";
import { CircularProgress } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { addSchoolDestrict } from "../School-Districts/api";
import {userState} from '../../Atom'
import {Modal} from "react-bootstrap";
import showModal from "../Modal/showModal";

export default function AddSchoolDestrict({handleClose,show,handleShow}) {
  const [modalMessage,setModalMessage]=useState("")
  const [loading, setLoading] = useState(false);
const [name,setName]=useState("")
const [color,setColor]=useState("")
const [ myUser , setmyUser ] = useRecoilState(userState)
const submitHandler=async(e)=>{
  e.preventDefault()
let res=await addSchoolDestrict(myUser.token,{name:name})
if(res.error){
  showModal(true)
  setModalMessage('School Not Added')
setColor('red')
}
else{
  // console.log(res)
  setColor('green')
  setModalMessage('Added SuccessFully')
  showModal(true)

}
}
  return (
    <Modal show={show} onHide={handleClose}>
   
  
  

    <section id="hero-Msd">
       
      <div className="row main-div">
        <div className="col-md-6 m-auto center-div text-center">
            <div className='p-2 center-div'>
            <h2>
              <button onClick={handleClose} className="btn close-btn  rounded-circle float-right">
                <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#011627"/>
                      <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
              </button>
            </h2>
            <div className="mt-3">
              <h6>New School Destrict</h6>
            </div>
            <div className="col-md-9 text-left  col-9 m-auto">
              <div className="form-group mt-4   p-20">
                <input
                  type="text"
                  className="form-control"
                  placeholder="School Destrict Name"
                   onChange={(e)=>setName(e.target.value)}
                />
              </div>
             

              <div className="form-group">
                  {loading ? <CircularProgress />:
                   <button type="submit" className="cta-btn" onClick={submitHandler}>Add
                </button>
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
   
  </Modal>
  );
}
