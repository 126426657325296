import NavBar from "../Navbar/Navbar";
import "./School-District.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { useRecoilValue } from "recoil";
import { schoolAdminState } from "../../Atom";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  editTeacherDetails,
  getSchoolById,
  getAllTeachers,
  getTeacherDetails,
  InviteSchoolTeacher,deleteTeacherDetails,passwordChangeTeacher
} from "./api";
import ManageTeacherDevice from "../ManageTeacherDevice/ManageTeacherDevice";
import CustomModal from "../Modal/CusModal";
import { CircularProgress } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import DashBoard from "../Dashboard/DashBoard";



export default function School() {
  const [schoolDetails, setSchoolDetails] = useState({});
  const { token, schoolId, districtId } = useRecoilValue(schoolAdminState);
  const [teachers, setTeachers] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [fname, setfName] = useState("");
  const [lname, setlName] = useState("");
  const [teacherDetails, setteacherDetails] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [teacherid, setTeacherId] = useState("");
  const [show, setShow] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [password, setPassword] = useState('');
  let params = useParams();

  let { districtid, id } = params;

  useEffect(() => {
    getSchool();
  }, [0]);

  const getSchool = async () => {
    // console.log(token)
    let res = await getAllTeachers(token);

    if(!res.error){
    setTeachers(res.res.data.data.teachers);
    }
  };

  const ManageModal = (teacher) => {
    setTeacherId(teacher);
    setShowManage(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setEditMode(false);
    setDeleteMode(false);
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const sendMail = async () => {
    setLoading(true);
    let data = { email: email, message: message };
    let ids = { did: districtId, sid: id };
    let res = await InviteSchoolTeacher(ids, data, token);
    if (!res.error) {
      setLoading(false);
      alert("Mail Sent");
      handleClose();
    } else {
      setLoading(false);

      alert("Something went wrong");
    }
    // console.log(res);
  };

  const editTeacher = async () => {
    let res;
    let data = { fName: fname, lName: lname, email: email };
    if(editMode){
    res = await editTeacherDetails(districtId, schoolId, teacherid, data, token);
    }else{
      res= await deleteTeacherDetails(teacherid, token);
    }
    if (!res.error) {
      // console.log(res);
      alert("Teacher Details Editted");
      getSchool();
      handleCloseAddModal();
    } else {
      setLoading(false);
      alert("Something went wrong");
    }
    setEditMode(false);
    setDeleteMode(false);
  };

  const showEditModal = async (teacherid,type) => {
    if(type==='edit') setEditMode(true); else setDeleteMode(true);
    let ids = { did: districtId, sid: schoolId, teacherid: teacherid };
    let res = await getTeacherDetails(ids, token);
    if (!res.error) {
      setteacherDetails(res.res.data.data.teacher);
      // console.log(res.res.data.data.teacher)
      setTeacherId(res.res.data.data.teacher._id);
      setfName(res.res.data.data.teacher.fName);
      setlName(res.res.data.data.teacher.lName);
      setEmail(res.res.data.data.teacher.email);
      handleShowAddModal();
    } else {
      setLoading(false);

      alert("Something went wrong");
    }
  };

  const handleClose = () => {setShow(false);setEditPassword(false);}
  const handleShow = () => setShow(true);
  const [showManage, setShowManage] = useState(false);

  const handleShowManageDevice = () => {
    setShowManage(false);
  };

  const passwordChange = (id,email)=>{
    setEmail(email);
    setTeacherId(id);
    setEditPassword(true);
    setShow(true);
  }

  const passwordChanges = async ()=>{
    let data={email:email,password:password};
    // let data={email:'john2@mailinator.com',password:'pass'};
    let res= await passwordChangeTeacher(teacherid,data,token);
    if (!res.error) {
      // console.log(res)
      alert(res.res.data.msg);
      getSchool()
      // handleCloseAddModal();
      // setDeleteMode(false);
      // setEditMode(false);
      // setShow(false);
      
    }
    else {
      setLoading(false)

      alert('Something went wrong')

    }
    handleClose()
  }

  return (
    <section id="hero-school ">
      {showManage ? (
        <ManageTeacherDevice
          destrictId={params.districtId}
          schoolId={params.id}
          teacherId={teacherid}
          showManageDevice={handleShowManageDevice}
        />
      ) : null}




{/* Edit Teacher modal  */}
      <CustomModal handleClose={handleCloseAddModal} show={showAddModal}>
        <section id="hero-Msd">
          <div className="row main-div-modal">
            <div className="col-md-6 m-auto center-div text-center">
              <div className="p-2 center-div">
                <h2>
                  <button
                    onClick={handleCloseAddModal}
                    className="btn close-btn  rounded-circle float-right"
                  >
                    <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#011627"/>
                      <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </h2>
                {editMode?<>
                <div className="mt-3">
                  <h6>Edit Teacher</h6>
                </div>
                <div className="col-md-7 text-left col-9 m-auto">
                  <div className="form-group mt-4   p-20">
                  <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      defaultValue={teacherDetails.fName}
                      onChange={(e) => setfName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-4   p-20">
                  <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      defaultValue={teacherDetails.lName}
                      onChange={(e) => setlName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mt-4   p-20">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      defaultValue={teacherDetails.email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="cta-btn"
                      onClick={editTeacher}
                    >
                      {loading ? <CircularProgress /> : "Update Details"}
                    </button>
                  </div>
                </div>
                </>:<>
                <div className="mt-3">
                  <h6>Delete Teacher</h6>
                </div>
                <div className="col-md-7 text-left col-9 m-auto">

                  <br />
                  <h5>Are you sure to delete this Teacher?</h5>

                  <div className="form-group mt-4   p-20">
                    <input
                      type="text" readOnly
                      className="form-control"
                      placeholder="First Name"
                      value={teacherDetails.fName+ " "+ teacherDetails.lName}
                      
                    />
                  </div>
                  <div className='form-group'>
                    <button type='submit' className='cta-btn-red' onClick={editTeacher}>
                      {loading ? <CircularProgress /> : "Delete"}
                    </button>
                  </div>
                </div>
                </>}
              </div>
            </div>
          </div>
        </section>
      </CustomModal>


{/* Invitation modal  */}
      <Modal show={show} onHide={handleClose}>
        { editPassword?<>
          <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mt-4 p-20">
            <input type="password" className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={passwordChanges} disabled={(password.length<8)}>
            Change Password
          </Button>
        </Modal.Footer>
        </>:<>
        <Modal.Header closeButton>
          <Modal.Title>Invite Teacher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control"
                placeholder="Message"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendMail}>
            Send Request
          </Button>
        </Modal.Footer>
        </>
        }
      </Modal>

      <div>
        <NavBar />



    <DashBoard showNavbar={false}/>



        <div className="container table">
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell> Id</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Class Teacher</TableCell>
                  <TableCell>Manage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teachers.map((item, index) => {
                  return (
                    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                      <TableCell className="table-data">{item._id} </TableCell>
                      
                      
                      <TableCell className="table-data textCap">
                        <Link
                          to={`/teacher/${item._id}`}>
                          {item.fName} {item.lName}
                        </Link>
                      </TableCell>
                      
                      <TableCell className="table-data">
                        {item.email}
                      </TableCell>
                    
                      <TableCell className="table-data">
                       

                        <Link onClick={() => ManageModal(item)}>
                          <PhoneAndroidIcon />
                        </Link>

                          &nbsp;
                        <Link onClick={() => {showEditModal(item._id,'edit')}}>
                          <i className="fas fa-edit icon-size-20"></i>
                        </Link>

                        &nbsp;
                        <Link onClick={() => {showEditModal(item._id,'delete')}}>
                          <i className="fas fa-trash-alt icon-size-20"></i>
                        </Link>
                        &nbsp;
                      <Link onClick={() => {
                        passwordChange(item._id,item.email)
                      }}>
                        <i className="far fa-envelope icon-size-20"></i>
                      </Link>
                    

                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>


          {teachers.length < 1 && (
            <center style={{ padding: "8px", width: "100%" }}>
              <h6>Loading.....</h6>
            </center>
          )}
        </div>
      </div>
    </section>
  );
}
