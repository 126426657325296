import axios from "axios";
import { baseUrl } from "../Base_url";

const userData = JSON.parse(localStorage.getItem("recoil-persist"));


let token = "";

if (userData) {
  if (userData.teacher_v1) {
    token = userData.teacher_v1.token;
  }else{
    // alert('You need to login first')
  }
}

export const getStats = async (token) => {
    try {
      const res = await axios.get(`${baseUrl}/teacher/teacher/dashboard/entities-count`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res.data };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
export const fetchTeacherProfile = async (token) => {
    try {
      const res = await axios.get(`${baseUrl}/teacher/teacher`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res.data };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
