import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import { changePasswordInvite, resetPassword } from './api'
import './style.css';
import { CircularProgress } from "@material-ui/core";


function ResetPasswordForm(){
    const history = useHistory();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token=params.get('token');
    const [email,setEmail]=useState("");
    const [loading,setLoading]=useState(false);
    const [valid, setValid] = useState(true);
    const [userRegistered, setUserRegistered] = useState(false);
    const [info, setinfo] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordCheckError, setPasswordCheckError] = useState(false);

    const passwordForgot = () =>{
        // let res = forgotPassword({email:email});
        // console.log(res);
      }
    //   const [user, setUser] = useState({ lName: "", fName: "", password: "",mobile:"" })
      useEffect(()=>{
          checkPasswordInvite(token)
      },[])

      const checkPasswordInvite = async (token) =>{
        // console.log('call this')
          if(token){
            let res = await changePasswordInvite(token);
            if(res.error){
                // alert(res.data.msg);
                setValid(false)
            }else{
                // console.log(res)
                setinfo(res.res.data.data.invite)
            }
          }else{
            alert('Invalid Invite Link');
            history.push("/")

          }
      }

      const handleSubmit = async()=>{

        let res = await resetPassword(token,{password:password.trim()});
        // console.log(res)
        if(res.error){
            // alert(res.data.msg)
            setValid(false);
        }else{
            setUserRegistered(true)
            setValid(true);
        }

      }

      const manageRedirect = ()=>{
        switch(info.role){
            case 'Admin':
                history.push("/super-admin")
                break;
            case 'SchoolDistrictAdmin': 
                history.push("/school-district")
                break;
            // case 'SchoolAdmin': 
            //     history.push("/schools")
            //     break;
            case 'Teacher': 
                history.push("/teachers")
                break;
        }
      }
    


    return(
        <div className="invitation-body">
        {!userRegistered && <>
            {valid && <form className="form">
                {info && <div className="welcom-msg">
                    <p>Change password of <span>{info?.email}</span> for the role of <span>{info?.role}</span>.</p>
                </div>}
                <div className="form-group"> 
                    <label>Password</label>
                    <input type="password" className="form-control" required onChange={(e) => setPassword(e.target.value.trim())  } />
                </div>
                {(password.trim().length>7)?
                <div className="form-group">
                    <label>Confirm Password</label>
                    <input type="password" className="form-control" onBlur={()=>setPasswordCheckError(true)} required onChange={(e) => setConfirmPassword(e.target.value.trim())} />
                </div>
                    :null}
                    {(passwordCheckError && (password.trim()!=confirmPassword.trim()))?<span style={{color:'red'}}>Passwords do not Match</span>:null}
                <br/>
                <span style={{color:'red'}}>Password should be atleast 8 characters long</span>
                {/* <div className="form-group"> 
                    <label>First Name</label>
                    <input type="text" className="form-control" required onChange={(e) => { setUser({ ...user, fName: e.target.value.trim() }) }} />
                </div>
                <div className="form-group">
                    <label>Last Name</label>
                    <input type="text" className="form-control" required onChange={(e) => { setUser({ ...user, lName: e.target.value.trim() }) }} />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input type="password" className="form-control" required onChange={(e) => { setUser({ ...user, password: e.target.value.trim() }) }} />
                </div>
                {(userType=='SchoolDistrictAdmin')?
                <>
                <div className="form-group">
                    <label>Mobile</label>
                    <input type="text" className="form-control" maxLength="16" required onChange={(e) => { setUser({ ...user, mobile: e.target.value.trim() }) }} />
                    <small style={{color:'red'}}>Enter Mobile Number with country Code</small>
                </div>
                
                </>
                :null} */}
                <button type="button" onClick={handleSubmit} className="form-control btn" value="Change Password" disabled={!(password.trim().length>7 && (password.trim()==confirmPassword.trim()))}>Change Password</button>
            </form>}
            {!valid && <div className="error-box">
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.60253 15.1367C9.41053 15.1367 9.21853 15.0637 9.07253 14.9167C8.77953 14.6237 8.77953 14.1497 9.07253 13.8567L13.8645 9.06472C14.1575 8.77172 14.6315 8.77172 14.9245 9.06472C15.2175 9.35772 15.2175 9.83172 14.9245 10.1247L10.1325 14.9167C9.98653 15.0637 9.79453 15.1367 9.60253 15.1367Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3965 15.1396C14.2045 15.1396 14.0125 15.0666 13.8665 14.9196L9.07046 10.1226C8.77746 9.82965 8.77746 9.35565 9.07046 9.06265C9.36446 8.76965 9.83846 8.76965 10.1305 9.06265L14.9265 13.8596C15.2195 14.1526 15.2195 14.6266 14.9265 14.9196C14.7805 15.0666 14.5875 15.1396 14.3965 15.1396Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.665 3.5C5.135 3.5 3.5 5.233 3.5 7.916V16.084C3.5 18.767 5.135 20.5 7.665 20.5H16.333C18.864 20.5 20.5 18.767 20.5 16.084V7.916C20.5 5.233 18.864 3.5 16.334 3.5H7.665ZM16.333 22H7.665C4.276 22 2 19.622 2 16.084V7.916C2 4.378 4.276 2 7.665 2H16.334C19.723 2 22 4.378 22 7.916V16.084C22 19.622 19.723 22 16.333 22Z" fill="currentColor" />
                </svg>
                <p>Invitation Expired!</p>
                <br />
                <h5><Link to={'/'}>Back</Link></h5>
            </div>}
        </>}
        {userRegistered && <div className="success-box">
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.44019 12L10.8142 14.373L15.5602 9.62695" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.74976 12C2.74976 18.937 5.06276 21.25 11.9998 21.25C18.9368 21.25 21.2498 18.937 21.2498 12C21.2498 5.063 18.9368 2.75 11.9998 2.75C5.06276 2.75 2.74976 5.063 2.74976 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <p>Password Changed Successfull</p>
                <button className="btn btn-success" onClick={manageRedirect}>Login</button>
            </div>}
    </div>
    )

}


export default ResetPasswordForm;


{/* <div>
<div className='authentication-section'>
  <div className=''>
    <div className='main-form row '>
      <div className='col-lg-6 col-md-10 m-auto pb-10'>
    <div className='auth-form  mt-10  '>
    <div className='auth-heading'>

<h2>Forgot Password
</h2>

  </div>
  <div className='auth-heading'>
<div className='col-sm-12' style={{margin:'auto 0'}}>
  <div className='form-group'>
    <label className='form-label'>
      Email Address
    </label>
    <input
      type='text'
      className='form-control'
      placeholder='Email Address'
      onChange={(e)=>setEmail(e.target.value)}
    />
  </div>

  <div className='form-group'>
    <button type='submit' onClick={(e)=>{e.preventDefault();
      passwordForgot()}}  className='cta-btn'>
      {loading?<CircularProgress/> : "Change Password" }     
    </button>
  </div>
</div>  
</div>

    </div>
      </div>
      <div className='col-md-0 col-lg-6 svg-div '>
    <div className='ptb-100 '  >
      <img className='img-height-100' src='assets/loginVector.svg'/>
  
    </div>
      </div>
   
    </div>
  </div>
</div>
</div> */}