import React, { useEffect, useState, useRef } from 'react'
import { getAnalysedData, getThisExperimentDetails } from './api'
import './style.css'
import { useRecoilValue } from 'recoil'
import getCookie, { setTeacherCookie, teacherState } from '../../Atom'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, AreaChart, Area, Label, ResponsiveContainer } from 'recharts';
import Navbar from '../Navbar/Navbar';
import { useRecoilState} from "recoil";
import { Link, useLocation, useParams } from 'react-router-dom'
import RangeCustom from './Range'
import CanvasJSReact from '../../../assets/js/canvasjs-commercial-3.4/canvasjs.react';
import { useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
// import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Analysis(props) {
    const history = useHistory()
    const range_slide_custom = useRef(null)
    const massQ = useRef(null)
    const paraQ = useRef(null)
    // const { token } = useRecoilValue(teacherState)
    const [angle, setAngle] = useState(0)
    const [mass, setMass] = useState(300)
    const [para_area, setPara_area] = useState(0)
    let search = window.location.pathname;
    let params = search.split('/');
    let ID = params[params.length-1];
    const [experimentData, setExperimentData] = useState(null)
    const [calculatedData, setCalculatedData] = useState(null)

    const [thrustModified, setThrustModified] = useState([])
    const [thrustFiltered, setThrustFiltered] = useState([])
    const [bottle_pressure, setBottle_pressure] = useState([])
    const [exhaust_velocity, setExhaust_velocity] = useState([])
    const [massChange, setMassChange] = useState([])
    const [acceleration, setAcceleration] = useState([])
    const [velocity, setVelocity] = useState([])
    const [distance, setDistance] = useState([])
    const [height, setHeight] = useState([])
    const [ myUser , setmyUser ] = useState(teacherState)

    const [clicked, setClicked] = useState(false);

    const [snackBarShow, setSnackBarShow] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState('');

    const logoutHandler=async()=>{
      setTimeout(()=>{
        // setmyUser({
        //   fName: null,
        //   userId: null,
        //   token: null,
        //   lName: null,
        // })
        setTeacherCookie({
            fName: null,
            userId: null,
            token: null,
            lName: null,
          })
        let hostname= window.location.host;
        // console.log(hostname,window.location.protocol)
        window.location.assign(`${window.location.protocol}//${hostname}/teachers`)   
      })  
    }

    
  const feedBackMsg =(snackShow,snackSeverity,snackMessage)=>{
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    
    feedBackMsg(false,'','');
  }


    useEffect(() => {
    console.log(ID)
        // setmyUser({...myUser,'page':"experiment"});
        const _cookie = getCookie('recoil-persist')
        const user = _cookie ? JSON.parse(_cookie)['teacher_v1'] : {}
        // console.log(user,match)
        setmyUser({ ...user, 'page': "experiment" });
        props.page('experiment')
        getBasicDetails(user);
    }, [])
    const getBasicDetails = async (user=myUser) => {

        const res = await getThisExperimentDetails(user?.token, ID)
        if (res.error) {
            
                if(res.data.statusCode==401){
                    logoutHandler();
                    }
                else{
                    feedBackMsg(true,'error',res.data.msg);
                }
        
    }
            else {
            // console.log(res.res.data.data.experiment)
            setExperimentData(res.res.data.data.experiment)
            await getPredictedData(res.res.data.data.experiment)
        }
    

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        getPredictedData()
    }
    const getPredictedData = async (expData = experimentData) => {
        setClicked(true)
        // const res = await getAnalysedData(angle, mass, para_area, expData)
        // const res = await getAnalysedData(range_slide_custom.current.state.values[0], massQ.current.value, paraQ.current.value, expData)
        const res = await getAnalysedData(angle,String(mass),String(para_area),expData)
        // const res = await getAnalysedData(0,"300","0",expData)
        setClicked(false)
        if (res.error) {
            alert("Experiment Failed. Contact Admin.")
            if(res.data){
                if(res.data.statusCode==401){
                    logoutHandler();
                }
                else{
                    feedBackMsg(true,'error',res.data.msg);
                }
            }else{
                history.push("/")
            }    
        } else {
            // console.log(res.res.data)
            // feedBackMsg(true,'success',res.res.data.msg);
            setCalculatedData(res.res.data)
            setThrustFiltered(res.res.data.thrust)
            setBottle_pressure(res.res.data.bottle_pressure)
            setExhaust_velocity(res.res.data.exhaust_velocity)
            setMassChange(res.res.data.mass_of_rocket)
            setAcceleration(res.res.data.acceleration)
            setVelocity(res.res.data.velocity)
            setDistance(res.res.data.distance)
            setHeight(res.res.data.height)
        }
    }
    return (
        
        <main style={{position:"absolute",width:"100%",top:"20%"}}>
		<div class="container px-4 pt-3 ">
			<div class="upper-section px-60">
				<div class="breadcrumb-menu py-2 fonted">
					<Link to={'/'}>Teacher Panel </Link><i class="fas fa-angle-double-right"></i><Link  to={'/myexperiments'}>Experiments </Link><i class="fas fa-angle-double-right"></i><a className='disable'>Analysis</a>
				</div>
				
			</div>
		<div class="w-100"><div class=" pe-1">
		    	<div class="lower-section1 pt-1 pb-5  mx-1 w-100">
			    <div class="px-3">
			    <div class="scroll-div-upper">
			        <p className='p'><span class="main-text fonted">Experiment data</span >&nbsp;&nbsp;<span class="sub-text ps-2">Manage and analyze experiments here</span></p>
			    </div>
			    <div class="row ps-2 pe-3">
			        <div class="col-20 name-qunt text-center pe-2 ps-0">
			            <div class="bg-light1 py-1">
			                <h4 class="title text-dark1 h4">Name</h4>
			                <h2 class="quantity text-purple mb-0 h2">{experimentData?.studentName}</h2>
			            </div>
			        </div>
			        <div class="col-20 name-qunt text-center pe-2 ps-0">
			            <div class="bg-light1 py-1">
			                <h4 class="title h4">Bottle Volume</h4>
			                <h2 class="quantity mb-0 h2">{experimentData?.bottleVolume} ml</h2>
			            </div>
			        </div>
			        <div class="col-20 name-qunt text-center pe-2 ps-0">
			            <div class="bg-light1 py-1">
			                <h4 class="title h4">Fuel Volume</h4>
			                <h2 class="quantity mb-0 h2">{experimentData?.fuelVolume} ml</h2>
			            </div>
			        </div>
			        <div class="col-20 name-qunt text-center pe-2 ps-0">
			            <div class="bg-light1 py-1">
			                <h4 class="title h4">Nozzle Diameter</h4>
			                <h2 class="quantity mb-0 h2">{experimentData?.nozzleDia} mm</h2>
			            </div>
			        </div>
			        <div class="col-20 name-qunt text-center ps-0 pe-0">
			            <div class="bg-light1 py-1">
			                <h4 class="title h4">Pressure (st launch)</h4>
			                <h2 class="quantity mb-0 h2">{experimentData?.pressure.toFixed(0)} psi</h2>
			            </div>
			        </div>
			    </div>
			    </div><br/>
			    <div class="calculator-section">
			        <div class="px-3">
			            <form onSubmit={e => handleSubmit(e)} id="form1">
                    
                    <div class="row">
                        <div class="col-4 py-2">
                            <label class="cal-titl form-label" for="amount">Angle of launch</label>
                            
                        
                          <div class="range">
                            <input id="rangeInput" type="range" value={angle} min="0" max="45" oninput="amount.value=rangeInput.value" onChange={e=>setAngle(e.target.value)} required="true"/>
                            <input class="ms-1" id="amount" type="number" value={angle} min="0" max="45" oninput="rangeInput.value=amount.value" required="true" onChange={e=>setAngle(e.target.value)}/>
                          </div>

                        </div>
                        <div class="col-8">
                            <div class="row">
                                <div class="col-4 py-2">
                                    <label class="cal-titl" for="quantity">Dry Mass Of Rocket (gms)</label>
                                    <input type="number" className='form-controlnum' step="0.01" name="quantity" value={mass} onChange={e=>setMass(e.target.value)} id="quantity" required="true"/>
                                </div>
                                 <div class="col-4 py-2">
                                    <label class="cal-titl" for="area">Parachute Area (m2)</label><input type="number" className='form-controlnum' step="0.01" id="area"  name="area" value={para_area} onChange={e=>setPara_area(e.target.value)} required="true"/>
                                </div>
                                 <div class="col-4  d-flex align-items-end" >
                                    <button type="submit" value="Submit" class="btn-submit mb-2">Calculate</button>
                                </div>
                            </div>
                        </div>
                    </div>    
                </form>
			        </div>
			    </div>
			    
			    
			    <div class="px-3 mt-4">
			        <span class="main-text">Analysis</span>
			        <div class="row ps-2 pe-3">
			            <div class="col-20 text-center pe-2 ps-0">
			                <div class="bg-light1 py-1">
			                <h4 class="title h4">Total Thrust Time</h4>
			                <h2 class="quantity h2">{calculatedData?.total_thrust_time} sec</h2>
			                </div>
			            </div>
			            <div class="col-20 text-center pe-2 ps-0">
			                <div class="bg-light1 py-1">
			                <h4 class="title h4">Water Phase Length</h4>
			                <h2 class="quantity h2">{calculatedData?.water_phase_length} sec</h2>
			                </div>
			            </div>
			            <div class="col-20 text-center pe-2 ps-0">
			                <div class="bg-light1 py-1">
			                <h4 class="title h4">Total Impulse</h4>
			                <h2 class="quantity h2">{calculatedData?.total_impulse} N-sec</h2>
			                </div>
			            </div>
			            <div class="col-20 text-center pe-2 ps-0">
			                <div class="bg-light1 py-1">
			                <h4 class="title h4">Peak Thrust</h4>
			                <h2 class="quantity h2">{calculatedData?.peak_thrust} N</h2>
			                </div>
			            </div>
			            <div class="col-20 text-center ps-0 pe-0">
			                <div class="bg-light1 py-1">
			                <h4 class="title h4">Apogee to Ground</h4>
			                <h2 class="quantity h2">{calculatedData?.apogee_to_ground} sec</h2>
			                </div>
			            </div>
			        </div>
			        <div class="row pt-2 ps-2 pe-3">
			            <div class="col-20 text-center pe-2 ps-0">
			                <div class="bg-light1 py-1">
			                <h4 class="title h4">Peak Acceleration</h4>
			                <h2 class="quantity h2">{calculatedData?.peak_acceleration} m/s^2</h2>
			                </div>
			            </div>
			            <div class="col-20 text-center pe-2 ps-0">
			                <div class="bg-light1 py-1">
			                <h4 class="title h4">Peak Velocity</h4>
			                <h2 class="quantity h2">{calculatedData?.peak_velocity} m/s</h2>
			                </div>
			            </div>
			            <div class="col-20 text-center pe-2 ps-0">
			                <div class="bg-light1 py-1">
			                <h4 class="title h4">Max. Altitude</h4>
			                <h2 class="quantity h2">{calculatedData?.max_altitude} m</h2>
			                </div>
			            </div>
			            <div class="col-20 text-center pe-2 ps-0">
			                <div class="bg-light1 py-1">
			                <h4 class="title h4">Distance Travelled</h4>
			                <h2 class="quantity h2">{calculatedData?.distance_travelled} m</h2>
			                </div>
			            </div>
			            <div class="col-20 text-center ps-0 pe-0">
			                <div class="bg-light1 py-1">
			                <h4 class="title h4">Total Flight</h4>
			                <h2 class="quantity h2">{calculatedData?.total_flight_time} sec</h2>
			                </div>
			            </div>
			        </div>
			    </div>


                <div className="desktop-view">
                    {clicked?<>
                        <div className='analyse-blur'>
                            Calculating
                        </div>
                    </>:<>
                    <div className="d-flex flex-wrap justify-content-between align-items-start ">
                        <div className="individual-graph thrust-chart-area-left-student">

                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                height: 750,
                                title: {
                                    text: "Thrust vs Time",
                                    fontSize: 25,
                                    fontWeight: "normal"
                                },
                                axisY: {
                                    title: "Thrust (N)",
                                    suffix: "N",
                                    labelFontSize: 15,
                                    titleFontSize: 18,

                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 0.1,
                                    labelFontSize: 15,
                                    titleFontSize: 18,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "area",
                                    toolTipContent: "{x} sec: {y} N",
                                    dataPoints: thrustFiltered
                                }],
                                legend: {
                                    fontSize: 10,
                                },
                            }}
                            />
                            {/* <ResponsiveContainer width="100%" height="100%" aspect="1.47">
                                <AreaChart width={300} height={240} data={thrustFiltered}
                                    margin={{ top: 5, right: 20, left: 1, bottom: 0 }}
                                >
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Thrust (N)', angle: -90, position: 'insideLeft' }} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="Thrust" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                </AreaChart>
                            </ResponsiveContainer> */}
                        </div>
                        <div className="thrust-chart-area-right-student d-flex flex-wrap">
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", // "light1", "dark1", "dark2"
                                    height: 227,
                                    title: {
                                        text: "Exhaust Velocity vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Velocity (m/s)",
                                        suffix: "m/s"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 0.1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "line",
                                        toolTipContent: "{x} sec: {y} m/s",
                                        dataPoints: exhaust_velocity
                                    }]
                                }}
                                />
                            </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", // "light1", "dark1", "dark2"
                                    height: 227,
                                    title: {
                                        text: "Pressure vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Pressure (kPa)",
                                        suffix: "kPa"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 0.1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "spline",
                                        toolTipContent: "{x} sec: {y} kPa",
                                        dataPoints: bottle_pressure
                                    }]
                                }}
                                />
                            </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", // "light1", "dark1", "dark2"
                                    height: 227,
                                    title: {
                                        text: "Mass vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Mass (kg)",
                                        suffix: "kg"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 0.1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "area",
                                        toolTipContent: "{x} sec: {y} kg",
                                        dataPoints: massChange
                                    }]
                                }}
                                />
                            </div>
                        </div>


                        <div className="individual-graph mini-graph" style={{ flexBasis: '100%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Acceleration vs Time",
                                    fontSize: 18,
                                    fontWeight: "normal"
                                },
                                axisY: {
                                    title: "Acceleration (m/s^2)",
                                    suffix: "m/s^2"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 0.1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m/s^2",
                                    dataPoints: acceleration
                                }]
                            }}
                            />
                        </div>
                        <div className="individual-graph mini-graph" style={{ flexBasis: '49%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Velocity vs Time",
                                    fontSize: 18,
                                    fontWeight: "normal"
                                },
                                axisY: {
                                    title: "Velocity (m/s)",
                                    suffix: "m/s"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m/s",
                                    dataPoints: velocity
                                }]
                            }}
                            />
                        </div>
                        <div className="individual-graph mini-graph" style={{ flexBasis: '49%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Height vs Time",
                                    fontSize: 18,
                                    fontWeight: "normal"
                                },
                                axisY: {
                                    title: "Height (m)",
                                    suffix: "m"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m",
                                    dataPoints: height
                                }]
                            }}
                            />
                        </div>
                        {/* <div className="individual-graph mini-graph" style={{ flexBasis: '32.5%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Distance vs Time",
                                    fontSize: 18,
                                    fontWeight: "normal"
                                },
                                axisY: {
                                    title: "Distance (m)",
                                    suffix: "m"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m",
                                    dataPoints: distance
                                }]
                            }}
                            />
                        </div> */}
                    </div>
                    </>}
                </div>
                <div className="mac-view">
                {clicked?<>
                        <div className='analyse-blur'>
                            Calculating
                        </div>
                    </>:<>
                    <div className="d-flex flex-wrap justify-content-between align-items-start ">
                        <div className="individual-graph thrust-chart-area-left-student">
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                height: 750,
                                title: {
                                    text: "Thrust vs Time",
                                    fontSize: 25,
                                    fontWeight: "normal"
                                },
                                axisY: {
                                    title: "Thrust (N)",
                                    suffix: "N",
                                    labelFontSize: 15,
                                    titleFontSize: 18,

                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 0.1,
                                    labelFontSize: 15,
                                    titleFontSize: 18,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "area",
                                    toolTipContent: "{x} sec: {y} N",
                                    dataPoints: thrustFiltered
                                }],
                                legend: {
                                    fontSize: 10,
                                },
                            }}
                            />
                        </div>

                        <div className="thrust-chart-area-right-student d-flex flex-wrap">
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", // "light1", "dark1", "dark2"
                                    height: 227,
                                    title: {
                                        text: "Exhaust Velocity vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Velocity (m/s)",
                                        suffix: "m/s"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 0.1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "line",
                                        toolTipContent: "{x} sec: {y} m/s",
                                        dataPoints: exhaust_velocity
                                    }]
                                }}
                                />
                            </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", // "light1", "dark1", "dark2"
                                    height: 227,
                                    title: {
                                        text: "Pressure vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Pressure (kPa)",
                                        suffix: "kPa"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 0.1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "spline",
                                        toolTipContent: "{x} sec: {y} kPa",
                                        dataPoints: bottle_pressure
                                    }]
                                }}
                                />
                            </div>
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", // "light1", "dark1", "dark2"
                                    height: 227,
                                    title: {
                                        text: "Mass vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Mass (kg)",
                                        suffix: "kg"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 0.1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "area",
                                        toolTipContent: "{x} sec: {y} kg",
                                        dataPoints: massChange
                                    }]
                                }}
                                />
                            </div>
                        </div>


                        <div className="individual-graph" style={{ flexBasis: '100%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Acceleration vs Time",
                                    fontSize: 18,
                                    fontWeight: "normal"
                                },
                                axisY: {
                                    title: "Acceleration (m/s^2)",
                                    suffix: "m/s^2"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 0.1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m/s^2",
                                    dataPoints: acceleration
                                }]
                            }}
                            />
                        </div>
                        <div className="individual-graph" style={{ flexBasis: '49%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Velocity vs Time",
                                    fontSize: 18,
                                    fontWeight: "normal"
                                },
                                axisY: {
                                    title: "Velocity (m/s)",
                                    suffix: "m/s"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m/s",
                                    dataPoints: velocity
                                }]
                            }}
                            />
                        </div>
                        <div className="individual-graph" style={{ flexBasis: '49%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Height vs Time",
                                    fontSize: 18,
                                    fontWeight: "normal"
                                },
                                axisY: {
                                    title: "Height (m)",
                                    suffix: "m"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m",
                                    dataPoints: height
                                }]
                            }}
                            />
                        </div>
                        {/* <div className="individual-graph" style={{ flexBasis: '32.5%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Distance vs Time",
                                    fontSize: 18,
                                    fontWeight: "normal"
                                },
                                axisY: {
                                    title: "Distance (m)",
                                    suffix: "m"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m",
                                    dataPoints: distance
                                }]
                            }}
                            />
                        </div> */}
                    </div>
                    </>}
                </div>
                <div className="mobile-view">
                {clicked?<>
                        <div className='analyse-blur'>
                            Calculating
                        </div>
                    </>:<>
                    <div className="row">
                        <div className="col-12">
                            <div className="individual-graph">
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", // "light1", "dark1", "dark2"
                                    // height: 800,
                                    title: {
                                        text: "Thrust vs Time",
                                        fontSize: 25,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Thrust (N)",
                                        suffix: "N",
                                        labelFontSize: 15,
                                        titleFontSize: 18,

                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 0.1,
                                        labelFontSize: 15,
                                        titleFontSize: 18,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "area",
                                        toolTipContent: "{x} sec: {y} N",
                                        dataPoints: thrustFiltered
                                    }],
                                    legend: {
                                        fontSize: 10,
                                    },
                                }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph">
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", // "light1", "dark1", "dark2"
                                    // height: 245,
                                    title: {
                                        text: "Exhaust Velocity vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Velocity (m/s)",
                                        suffix: "m/s"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 0.1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "line",
                                        toolTipContent: "{x} sec: {y} m/s",
                                        dataPoints: exhaust_velocity
                                    }]
                                }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1",
                                    title: {
                                        text: "Pressure vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Pressure (kPa)",
                                        suffix: "kPa"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 0.1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "spline",
                                        toolTipContent: "{x} sec: {y} kPa",
                                        dataPoints: bottle_pressure
                                    }]
                                }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph">
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", 
                                    title: {
                                        text: "Mass vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Mass (kg)",
                                        suffix: "kg"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 0.1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "area",
                                        toolTipContent: "{x} sec: {y} kg",
                                        dataPoints: massChange
                                    }]
                                }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph">
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", 
                                    title: {
                                        text: "Acceleration vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Acceleration (m/s^2)",
                                        suffix: "m/s^2"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 0.1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "spline",
                                        toolTipContent: "{x} sec: {y} m/s^2",
                                        dataPoints: acceleration
                                    }]
                                }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph">
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", 
                                    title: {
                                        text: "Velocity vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Velocity (m/s)",
                                        suffix: "m/s"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "spline",
                                        toolTipContent: "{x} sec: {y} m/s",
                                        dataPoints: velocity
                                    }]
                                }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="individual-graph">
                                <CanvasJSChart options={{
                                    animationEnabled: false,
                                    exportEnabled: true,
                                    theme: "light1", 
                                    title: {
                                        text: "Height vs Time",
                                        fontSize: 18,
                                        fontWeight: "normal"
                                    },
                                    axisY: {
                                        title: "Height (m)",
                                        suffix: "m"
                                    },
                                    axisX: {
                                        title: "Time",
                                        suffix: "sec",
                                        interval: 1,
                                        gridThickness: 1,
                                        gridColor: "#dbdbdb"
                                    },
                                    data: [{
                                        type: "spline",
                                        toolTipContent: "{x} sec: {y} m",
                                        dataPoints: height
                                    }]
                                }}
                                />
                            </div>
                        </div>
                        
                    </div>
                    </>}
                </div>
                
                
                </div>
                
        </div>
                    {/* {clicked && <>
                    <div className="glass"></div>
                    <div className="glass-top">
                        <h1>Calculating...</h1>
                    </div>
                </>} */}
            </div>

            <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                    <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                </Snackbar>

       
        </div>
        </main>
    )
}

export default Analysis


{/* <div>
            
            <h4 style={{ color: '#004e9d' }}>Experiment Data</h4>
            <div className="info-card-conatiner">
                <div className="info-card-stats">
                    <p className="head1">Bottle Volume</p>
                    <p className="head2">{experimentData?.bottleVolume} ml</p>
                </div>
                <div className="info-card-stats">
                    <p className="head1">Fuel Volume</p>
                    <p className="head2">{experimentData?.fuelVolume} ml</p>
                </div>
                <div className="info-card-stats">
                    <p className="head1">Nozzle Diameter</p>
                    <p className="head2">{experimentData?.nozzleDia} mm</p>
                </div>
                <div className="info-card-stats">
                    <p className="head1">Pressure (at launch)</p>
                    <p className="head2">{experimentData?.pressure.toFixed(0)} psi</p>
                </div>
            </div>
            
            <form className="form container-fluid form-analysis" onSubmit={e => handleSubmit(e)}>
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <label>Angle Of Launch (degrees)</label>
                        <div className="d-flex align-items-center">
                            <RangeCustom angle={angle} ref={range_slide_custom}/>
                            
                            
                        </div>

                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <label>Dry Mass Of Rocket (grams)</label>
                        <input 
                            type="number" 
                            step="0.01" 
                            placeholder="Mass in grams" 
                            className="form-control" 
                            
                            defaultValue={300}
                            ref={massQ}
                        />
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <label>Parachute Area (m^2)</label>
                        <input 
                            type="number" 
                            step="0.01" 
                            placeholder="Area of Parachute" 
                            className="form-control" 
                            
                            defaultValue={0.00}
                            ref={paraQ}
                            />
                    </div>
                    <div className="col-lg-3">
                        <label>Ready for results?</label>
                        {!clicked && <input type="submit" value="Calculate" className="btn form-control" />}
                        {clicked && <input type="submit" value="Calculate" className="btn form-control" disabled />}
                    </div>
                </div>

            </form>
            <div style={{ position: 'relative' }}>
                <br />
                <h4 style={{ color: '#004e9d' }}>Analysis</h4>
                <div className="info-card-conatiner">
                    <div className="info-card-stats">
                        <p className="head1">Total Thrust Time</p>
                        <p className="head2">{calculatedData?.total_thrust_time} sec</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Water Phase Length</p>
                        <p className="head2">{calculatedData?.water_phase_length} sec</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Total Impulse</p>
                        <p className="head2">{calculatedData?.total_impulse} N-sec</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Peak Thrust</p>
                        <p className="head2">{calculatedData?.peak_thrust} N</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Apogee to Ground</p>
                        <p className="head2">{calculatedData?.apogee_to_ground} sec</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Peak Acceleration</p>
                        <p className="head2">{calculatedData?.peak_acceleration} m/s^2</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Peak Velocity</p>
                        <p className="head2">{calculatedData?.peak_velocity} m/s</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Max. Altitude</p>
                        <p className="head2">{calculatedData?.max_altitude} m</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Distance Travelled</p>
                        <p className="head2">{calculatedData?.distance_travelled} m</p>
                    </div>
                    <div className="info-card-stats">
                        <p className="head1">Total Flight</p>
                        <p className="head2">{calculatedData?.total_flight_time} sec</p>
                    </div>

                </div> */}
