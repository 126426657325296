import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { schoolAdminState } from "../../../Atom";
import { useRecoilState } from "recoil";
import "../Auth.css";
import { CircularProgress } from "@material-ui/core";
import { loginAdmin } from "./Api";
import CustomModal from "../../Modal/Modal";
import showModal from "../../Modal/showModal";


export default function LoginForm() {



  const [myUser, setmyUser] = useRecoilState(schoolAdminState);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState("");


  function validation() {
    if (!email) {
      // console.log("no email");
      setError("Email not found");

      return false;
    } else if (!password) {
      // console.log("no email");
      setError("Password not found");
      return false;
    } else {
      return true;
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validation()) {
      setLoading(true);

      let data = { email: email, password: password };
      let res = await loginAdmin(data);
      if (res.error) {
        setShow(true);
        showModal(true);

        setLoading(false);
        setModalMessage("Check Email and Password");
      } else {
        setLoading(false);
        // console.log(res);
        let { fName, lName, token, userId, email, schoolId, districtId } = res.res.data.data;
        setmyUser({
          fName: fName,
          userId: userId,
          token: token,
          lName: lName,
          email: email,
          schoolId:schoolId,
          districtId:districtId
        });
        window.location.reload("/");
      }
    }
  };


  return (
    <>
      <CustomModal message={modalMessage} />


      <form id="#authForm" onSubmit={submitHandler}>
        <div className="col-md-12 ">
          <div className="form-group   p-20">
            <label className="form-label">Admin Id</label>
            <input
              type="email"
              className="form-control"
              placeholder="admin@gmail.com"
              onChange={(e) => {
                setError(null);
                setEmail(e.target.value);
              }}
            />
          </div>

          <div className="form-group">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              onChange={(e) => {
                setError(null);
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="form-footer ">
            <span className="text-right pb-2">
              <Link to="/forgot-password">Forgot-Password?</Link>
            </span>

            <span className="text-right pb-2 float-right">
              <Link to="/sign-up">Signup ?</Link>
            </span>
          </div>
          <div className="form-group">
            <label className="text-danger">{error ? error : null}</label>
            <button type="submit" className="cta-btn">
              {loading ? <CircularProgress /> : "Login"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
