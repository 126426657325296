import axios from "axios";
import { baseUrl } from "../Base_url";


const userData = JSON.parse(localStorage.getItem("recoil-persist-rocket-app-012034220"));


let token = "";

if (userData) {
  if (userData.KT_app_School_Admin) {
    token = userData.KT_app_School_Admin.token;
  }else{
    // alert('You need to login first')
  }
}


export const getAllDevice = async () => {
    try {
      const res = await axios.get(`${baseUrl}/school-admin/school/device/all`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };


  export const createDevice = async (data) => {
    try {
      const res = await axios.post(`${baseUrl}/admin/device/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  
  export const editDevice = async (id,data) => {
    try {
      const res = await axios.put(`${baseUrl}/admin/device/${id}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };


  
  export const getDeviceDetails = async (id) => {
    try {
      const res = await axios.get(`${baseUrl}/school-admin/school/device/${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  
  
  