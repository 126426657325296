import React, { useState, useEffect } from "react";
import "./DashBoard.css";
import { getStats } from "./api";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import getCookie, { setTeacherCookie, teacherState } from '../../Atom'

export default function DashboardStats() {
  const [myUser, setmyUser] = useState(teacherState)

  const [stats, setStats] = useState({
    teacher: 0,
    student: 0,
    device: 0,
    experiment: 0,
  });

  const logoutHandler = async () => {
    setTimeout(() => {
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })
      setTeacherCookie({
        fName: null,
        userId: null,
        token: null,
        lName: null,
      })
      let hostname = window.location.host;
      window.location.assign(`${window.location.protocol}//${hostname}/teachers`)
    })
  }

  const getDashboardStats = async (user=myUser) => {
    
    const res = await getStats(user?.token);

    if (res.error) {
      // return console.error('Could not fetch Dashboard Stats');
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
    }

    if (!res.error) {
      setStats(res.res.data);
      // console.log(res.res.data)
    }
  };

  useEffect(() => {
    const _cookie = getCookie('recoil-persist')
    if(!_cookie){
      logoutHandler()
    }
    const user = JSON.parse(_cookie)['teacher_v1']
    setmyUser(user)
    // console.log(user)
    getDashboardStats(user);
  
  }, [1]);



  return (
    <div className="lower-section px-60 pt-3 pb-5 mt-3 mx-1">
      <div>
        <div className="card-header px-0"><h3 className="font-WeightAdjust h3">Manage your dashboard here</h3></div>
      </div>
      <div className="row pt-3">
        <div className="col-md-3">
          <div className="card" style={{ cursor: "pointer" }}>
            <div className="card-body py-3 d-flex justify-content-center">
              <div className="text-end">
                <div className="py-3 main-text"><h2 className="font-WeightAdjust d-flex justify-content-end h2">{stats.sclass}</h2></div>
                <div className="pt-4 sub-text"><h4 className="font-WeightAdjust h4"><Link to="/myclasses">Classes</Link></h4></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center" >
            <div className="card-body py-3 d-flex justify-content-center">
              <div className="text-end">
                <div className="py-3 main-text"><h2 className="font-WeightAdjust d-flex justify-content-end h2">{stats.student}</h2></div>
                <div className="pt-4 sub-text"><h4 className="font-WeightAdjust h4">Students</h4></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center">
            <div className="card-body py-3  d-flex justify-content-center">
              <div className="text-end">
                <div className="py-3 main-text"><h2 className="font-WeightAdjust d-flex justify-content-end h2">{stats.device}</h2></div>
                <div className="pt-4 sub-text"><h4 className="font-WeightAdjust h4">Devices</h4></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center" >
            <div className="card-body py-3 d-flex justify-content-center">
              <div className="text-end"><div className="py-3 main-text"><h2 className="font-WeightAdjust d-flex justify-content-end h2">{stats.experiment}</h2></div>
                <div className="pt-4 sub-text"><h4 className="font-WeightAdjust h4">Experiments</h4></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
