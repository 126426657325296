import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./School-District.css";
import { CircularProgress } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

import { useEffect, useState, useRef } from "react";
import {
  createSClass,
  deleteStudent,
  editClassDetails,
  getAllClassbyTeacher,
  getTeacherDetails,
  uploadFileForStudentCreation, deleteClass, addclassID
} from "./api";
import { useParams, Link } from "react-router-dom";
import CustomModal from "../Modal/CusModal";
import { Delete } from "@material-ui/icons";
import Popup from "../popup/Popup";
import getCookie, { setTeacherCookie, teacherState } from "../../Atom";
import { useRecoilState, useRecoilValue } from "recoil";
import Tooltip from '@mui/material/Tooltip';
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom";
// import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
// import CloseIcon from "@material-ui/icons/Close";
import Group_299 from "../../assets/images/Group_299.png";
import Group_332 from "../../assets/images/Group_332.png";
import Group_305 from "../../assets/images/Group_305.png";
// import Typography from "@material-ui/core/Typography";

import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function SClasses(props) {
  const history = useHistory();
  let search = window.location.search;
  let paramse = new URLSearchParams(search);
  const [myUser, setmyUser] = useState(teacherState)
  const newBulkModal = useRef()
  // const { token, schoolId, userId } = useRecoilValue(teacherState)
  const [loading, setLoading] = useState(false);
  const [nameClass, setNameClass] = useState("");
  const [password, setPassword] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [classList, setClassList] = useState([]);
  const [clicked, setclicked] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [currentDelete, setCurrentDelete] = useState(false);
  const [currentEdit, setCurrentEdit] = useState(null);
  const [newEditName, setnewEditName] = useState("");
  const [open, setOpen] = useState(false);
  const [editState, setEditState] = useState(false);
  const [noOfPages, setNoOfPages] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [paged, setPaged] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageLimit, setPageLimit] = useState(25);
  let params = useParams();
  let { id } = params;
  let arr = [];
  
  const [snackBarShow, setSnackBarShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');

  const [oldName, setoldName] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMode,setSuccessMode] = useState(0);

  const logoutHandler = async () => {
    setTimeout(() => {
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })
      setTeacherCookie({
        fName: null,
        userId: null,
        token: null,
        lName: null,
      })
      let hostname = window.location.host;
      // console.log(hostname,window.location.protocol)
      window.location.assign(`${window.location.protocol}//${hostname}/teachers`)
    })
  }


  const feedBackMsg = (snackShow, snackSeverity, snackMessage) => {
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    feedBackMsg(false, '', '');
  }



  useEffect(() => {
    // getTeacherData();
    const _cookie = getCookie('recoil-persist')
    if(!_cookie){
      logoutHandler()
    }else{

    const user = _cookie ? JSON.parse(_cookie)['teacher_v1'] : {}
    setmyUser({ ...user, 'page': "class" });
    props.page('class')
    let urlPage = parseInt(paramse.get('page'));
    // console.log(urlPage)
    if (parseInt(paramse.get('page'))) {
      // console.log('lol1')
      history.push({
        pathname: `/myclasses`,
        search: '?page=' + urlPage
      })
      setPaged(urlPage)
      getTeacherData(urlPage, pageLimit,user)
    } else {
      // console.log('lol')
      setPaged(0)
      history.push({
        pathname: `/myclasses`,
        search: '?page=0'
      })
      getTeacherData(0, pageLimit,user)
    }
    // setmyUser({ ...myUser, 'page': "class" });
    }


  }, [1]);



  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleShowAddModal = () => {
    // console.log('Hello')
    setShowAddModal(true);
  };

  const getTeacherData = async (pages, limits,user=myUser) => {
    setLoading(true)
    let res = await getAllClassbyTeacher(id, user?.token, pages, limits);
    if (res.error) {
      // alert("Failed to get Teacher Data. Try Again !");
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {
      // console.log(res.res.data.data)
      setClassList(res.res.data.data.sclass)
      setLoading(false)
      let divisor = parseInt(res.res.data.data.totalcount / limits);
      let remainder = (res.res.data.data.totalcount % limits == 0) ? 0 : 1;
      setNoOfPages(divisor + remainder)
      setCurrPage(pages);
    }

  };

  const addSClass = async () => {
    setLoading(true)
    let res = await createSClass(nameClass, myUser?.token);
    setoldName(nameClass)
    setLoading(false)
    if (res.error) {
      // alert(res.data.msg)
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {
      feedBackMsg(true, 'success', res.res.data.msg);
      // setSuccessMode(1);
      // setSuccessOpen(true);
      getTeacherData(paged, pageLimit);
    }
    handleCloseAddModal();
  };

  const handleBulk = async (e) => {
    e.preventDefault()
    if (!uploadedFile) {
      return
    }
    setclicked(true)
    const formData = new FormData();
    formData.append(
      "file",
      uploadedFile,
      "file"
    );
    const res = await uploadFileForStudentCreation(formData, myUser?.token)
    setclicked(false)

    newBulkModal.current.close();

    getTeacherData(paged, pageLimit)
  }


  const deleteHandler = async (e) => {
    e.preventDefault()
    let res = await deleteClass(currentEdit._id, myUser?.token);
    if (res.error) {
      // alert("Not allowed to Delete! Try Again");
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {

      feedBackMsg(true, 'success', res.res.data.msg);
      // setSuccessMode(3);
      // setSuccessOpen(true);
      getTeacherData(paged, pageLimit);
    }
    setCurrentEdit(null);
    setCurrentDelete(false);
    setEditState(false);
    setOpen(false);
    handleCloseAddModal();
  };

  const handleFile = e => {
    setUploadedFile(e.target.files[0])
  }
  const editHandler = async (data) => {
    setCurrentEdit(data);
    setnewEditName(data.name);
    // newBulkModal.current.open();
    setEditState(true);
    setOpen(true);
  };

  const deleteOption = async (data) => {
    // setCurrentEdit(data)
    setCurrentDelete(true);
    setCurrentEdit(data);
    setOpen(true);
    setEditState(false);

    // newBulkModal.current.open()
  };

  const editSubmit = async (e) => {
    e.preventDefault()
    if (!currentEdit) {
      return
    }
    if(newEditName && newEditName.length >= 4 ) {
      setclicked(true)
      let res = await editClassDetails(currentEdit._id, newEditName, myUser?.token);
      if (res.error) {
        if (res.data.statusCode == 401) {
          logoutHandler();
        }
        else {
          feedBackMsg(true, 'error', res.data.msg);
        }
      } else {
        feedBackMsg(true, 'success', res.res.data.msg);
        // setSuccessMode(2);
        // setSuccessOpen(true);
  
      }
      setclicked(false)
      getTeacherData(paged, pageLimit);
      setCurrentEdit(null);
      setCurrentDelete(false);
      setOpen(false);
    }
    else {
      feedBackMsg(true, 'error', 'Class name should be atlead 4 characters long')
    }
  };

  const addClassID = async (e) => {
    let res = await addclassID(myUser?.token);
    if (res.error) {
      // alert('Error')
    } else {
      // console.log(res);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPaged(event.selected);
    getTeacherData(event.selected, pageLimit);
    history.push({
      pathname: `/myclasses`,
      search: '?page=' + event.selected
    })
  };

  const closeDialog = ()=>{
    setoldName('');
    setSuccessOpen(false);
  }


  return (
    <main style={{ position: "absolute", width: "100%", top: "20%" }} >
      <div class="container px-4 pt-3">
        <div class="upper-section px-60">
          <div class="breadcrumb-menu py-2">
            <Link to="/">Teacher Panel </Link><i class="fas fa-angle-double-right"></i><a className="disable">Class</a>
          </div>
          <div class="text-blue maintext"><h2>Class List</h2></div>
          <div class="row">
            <div class="col-md-9">
              <div class="card-header px-0"><h3>Manage classes here</h3></div>
            </div>
            <div class="col-md-3 text-end d-flex justify-content-end">
              <button type="button" onClick={handleShowAddModal} class="btn btn-primary add-btn px-0 fonted fontAdjust-500" >Add New Class &nbsp;&nbsp;<img style={{ height: "25px", width: "auto" }} src={Group_305} /></button>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-end align-items-end" >
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={noOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handleChangePage}
              containerClassName="pagination"
              activeClassName="activePage active"
              forcePage={currPage} />
          </div>
        </div>
        <div class=" px-3 pb-5 class-table w-100">
          <div class="row table-section w-100">
            <div class="col-lg-9c1 lower-section py-5 pe-5 ps-4">
              <table class="table" style={{ backgroundColor: 'transparent' }}>
                <thead class="mb-5">
                  <tr>
                    <th scope="col" class="col1 pb-4 fonted" style={{ backgroundColor: 'transparent' }}>Sr. No.</th>
                    <th scope="col" class="col2 pb-4 text-center fonted" style={{ backgroundColor: 'transparent' }}>Class ID</th>
                    <th scope="col" class="col2 pb-4 text-center fonted" style={{ backgroundColor: 'transparent' }}>Class Name</th>
                    <th scope="col" class="d-flex justify-content-end pe-10r col3 pb-4 fonted" style={{ backgroundColor: 'transparent' }}>Manage</th>
                  </tr>
                </thead>
                <tbody class="table-body" style={{ backgroundColor: 'transparent' }}>
                  {classList.length > 0 && classList.map((item, index) => {
                    return (
                      <tr className="noBord">
                        <td class="text-end" style={{ backgroundColor: 'transparent' }}>{index + 1}</td>
                        <td style={{ backgroundColor: 'transparent' }}><div class="d-flex class-btn justify-content-center ps-5 text-purple fonted" >{item?.classID}</div></td>
                        <td style={{ backgroundColor: 'transparent' }}><div class="d-flex class-btn justify-content-center ps-5 text-purple fonted" ><Link to={`/myclass/${item._id}/${item.classID}`} className="sec-link">{item?.name}</Link></div></td>
                        <td style={{ backgroundColor: 'transparent' }}>
                          <div class="d-flex class-btn justify-content-end">
                            <div>
                              {/* <a data-bs-toggle="modal" onClick={() => editHandler(item)} data-bs-target="#exampleModal1" data-bs-whatever="@mdo" >Edit<img src={Group_332} class="ps-3"/></a> */}
                              <Link onClick={() => { editHandler(item); setoldName(item?.name) }}><span className="opt-img font-WeightAdjust200 col-black fonted">Edit</span>&nbsp;&nbsp;&nbsp;<img src={Group_332} className="ps-3" /></Link>
                            </div>
                            <div class="px-4r">
                              <Link onClick={() => {deleteOption(item); setoldName(item?.name)}} ><span className="opt-img font-WeightAdjust200 col-black fonted">Delete</span>&nbsp;&nbsp;&nbsp;<img src={Group_299} className="ps-3" /></Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div class="col-lg-3c">
            </div>
          </div>
        </div>
      </div>
      <Dialog
        onClose={handleCloseAddModal}
        open={showAddModal}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none"
          },
        }}
        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modal-dialog modal-dialog-centered modal-dialog-scrollable teacher-div">
        <div className="modal-content" style={{ width: '350px' }}>
          <div className="modal-header-n d-flex justify-content-end">

            <button type="button" onClick={handleCloseAddModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"><CloseIcon /></button>
          </div>
          <form>
            <div class="modal-body px-4 pb-2">

              <div class="">
                <label for="recipient-name" className="col-form-label ps-4 pad- fonted">Create a new class</label>
                <input type="text" className="modal-form-control px-4" onChange={(e) => setNameClass(e.target.value)} value={nameClass}
                  onBlur={(e) => setNameClass(e.target.value.trim())} id="recipient-name" placeholder="Class Name" />
              </div>
            </div>
            <div class="modal-footer px-4">
              <button type="button" onClick={addSClass} disabled={nameClass.trim().length < 5} class="btn btn-pitch">Create Class</button>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none"
          },
        }}
        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modal-dialog modal-dialog-centered modal-dialog-scrollable teacher-div">
        <div class="modal-content" style={{ width: '400px' }}>
          <div class="modal-header-n d-flex justify-content-end">
            <button type="button" class="btn-close" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close"><CloseIcon /></button>
          </div>
          {editState ? <>
            <form onSubmit={e => editSubmit(e)}>
              <div class="modal-body px-4 pb-2">

                <div class="">
                  <label for="recipient-name" class="col-form-label ps-4 fonted pad-">Edit Class Name</label>
                  <input type="text" class="modal-form-control px-4" id="recipient-name" required placeholder="Test_Class" value={newEditName} onChange={e => { setnewEditName(e.target.value) }}
                    onBlur={e => { setnewEditName(e.target.value.trim()) }} />
                </div>
              </div>
              <div class="modal-footer px-4">
                <button type="submit" class="btn btn-pitch fonted" name="btn" value="Submit" id="submitBtn" data-bs-toggle="modal" data-bs-target="#confirm-submit">Update</button>
              </div>
            </form>
          </> : <>
            <form onSubmit={e => deleteHandler(e)}>
              <div class="modal-body px-5 py-0">
                <div class="mb-0">
                  <h5 class="modal-title1 font-WeightAdjust h5" id="exampleModalLabel">Delete {currentEdit?.name}</h5>
                  <label for="recipient-name" class="col-form-label1 fonted">Are you sure you want to delete {currentEdit?.name}?</label>
                </div>
              </div>
              <div class="modal-footer1 py-4 st-btn px-5 d-flex">
                <button type="submit" class="btn btn-pitch fonted" name="btn" value="Submit" id="submitBtn" data-bs-toggle="modal" data-bs-target="#confirm-submit">Delete</button>
                <button type="button" class="btn text-purple fonted" onClick={handleClose} data-bs-dismiss="modal"><span class="text-purple">Cancel</span></button>
              </div>
            </form>
          </>}
        </div>
      </Dialog>
      <Dialog
        onClose={closeDialog}
        open={successOpen}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none"
          },
        }}
        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modal-dialog modal-dialog-centered teacher-div">
        
        <div class="modal-content" style={{width:'400px'}}>
            <div class="modal-header-n ms-auto pt-3 pb-0 d-flex justify-content-end">
            <button type="button" class="btn-close" onClick={closeDialog} data-bs-dismiss="modal" aria-label="Close"><CloseIcon /></button>
            </div>
            <div class="modal-body px-5">
              <div class="mb-0">
                {(successMode==1)?<>
                  <label for="recipient-name" class="col-form-label"><span>{oldName}</span>: Add Class Name <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                  <p class="submitted py-3 p">{oldName} class successfully added</p>
                </>:
                (successMode==2)?<>
                <label for="recipient-name" class="col-form-label"><span>{oldName}</span>: Edit Class Name <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                <p class="submitted py-3 p">Class successfully changed from "{oldName}" to "{newEditName}"</p>
                </>:<>
                <label for="recipient-name" class="col-form-label"><span>{oldName}</span>: Delete <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                <p class="submitted py-3 p">{oldName} successfully deleted</p>
                </>}
              </div>
            </div>
          </div>
      </Dialog>

      <div class="modal fade bottom" tabindex="-1" role="dialog" id="my-deletedModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header ms-auto pt-3 pb-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body px-5">

              <div class="mb-0">
                <label for="recipient-name" class="col-form-label"><span>Test_Class 1</span>: Delete <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                <p class="submitted py-3 p">Test_Class 1 successfully deleted</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="modal fade bottom" tabindex="-1" role="dialog" id="my-updateModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header ms-auto pt-3 pb-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body px-5">
              <div class="mb-0">
                <label for="recipient-name" class="col-form-label"><span>Test_Class 1</span>: Edit Class Name <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                <p class="submitted py-3 p">Class successfully changed from ------ to ——</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
        <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </main>
  );
}