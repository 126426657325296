
import {Link} from 'react-router-dom'
import { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import CustomModal from "../Modal/CusModal";
import { EditSchoolAdmin, getAllSchoolAdmins, getSchoolAdminDetails, InviteAdminToSchool,deleteSchoolAdmin } from "./api";
import { Modal, Button } from 'react-bootstrap'
import { useRecoilValue } from 'recoil';
import { userState } from '../../Atom';

export default function ManageSchoolAdmin({ districtId, schoolId, showAdminManage,token }) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true)
  const [email, setEmail] = useState("");
  const [lName, setlName] = useState("");
  const [fName, setfName] = useState("");
  const [message, setMessage] = useState("");
  const [showInviteModal, setshowInviteModal] = useState(false)
  const [showEditModal, setshowEditModal] = useState(false);
  const [deleteMode, setDeleteMode]=useState(false);
  const [editMode, setEditMode]=useState(false);
  const [admins, setAdmins] = useState([])
  const [adminDetails, setAdminDetails] = useState({})
  // const {token} = useRecoilValue(userState)
  // console.log(schoolId, districtId)
  const handleClose = () => {setShow(false)
    showAdminManage()};
  const handleEditModalClose = () => {setshowEditModal(false);setEditMode(false);setDeleteMode(false)};
  const handleEditModalShow = () => setshowEditModal(true);
  const handleInviteModalClose = () => setshowInviteModal(false);
  const handleInviteModalShow = () => setshowInviteModal(true);
  useEffect(() => {
    getAdmins()
  }, [0])
  const getAdmins = async () => {
    let id = { did: districtId, sid: schoolId }
    let res = await getAllSchoolAdmins(id, token)
    setAdmins(res.res.data.data.admins)

  }
  const getAdminDetails=async(adminId,type)=>{
    if(type==='edit')setEditMode(true); else setDeleteMode(true);
    let id = { did: districtId, sid: schoolId,adminId:adminId }
    let res=await getSchoolAdminDetails(id, token)
      handleEditModalShow()
    // console.log(res.res.data.data);
    setAdminDetails(res.res.data.data.admin)

  }

  const editAdmin=async()=>{
    let id = { did: districtId, sid: schoolId,adminId:adminDetails._id }
    let data={fName:fName,lName:lName}
    let res=await EditSchoolAdmin(id,data, token)
    // console.log(res)
    getAdmins()
    alert('Success')
    handleEditModalClose()
   
  }

  const deleteAdmin=async()=>{
    let id = { did: districtId, sid: schoolId,adminId:adminDetails._id };
    let res=await deleteSchoolAdmin(id,token);
    getAdmins()
    alert('Success')
    handleEditModalClose()
  }

  const sendMail = async () => {
    setLoading(true)
    let data = { email: email, message: message }
    let ids = { did: districtId, sid: schoolId }
    let res = await InviteAdminToSchool(ids, data, token);
    if (!res.error) {
      setLoading(false)
      alert('Mail Sent')
      handleInviteModalClose()
    }
    else {
      setLoading(false)

      alert('Something went wrong')

    }
    // console.log(res);
  };
  return (
    <CustomModal show={show} handleClose={handleClose}>
      <section id="hero-Msd">
        <Modal show={showInviteModal} onHide={handleInviteModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Invite Admin to School</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <>
              <div className="form-group mt-4   p-20">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group mt-4   p-20">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Message"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </>

          </Modal.Body>
          <Modal.Footer>

            <Button variant="primary" onClick={sendMail}>
              Send Request
            </Button>

          </Modal.Footer>
        </Modal>

        <Modal show={showEditModal} onHide={handleEditModalClose}>
          {editMode?<>
          <Modal.Header closeButton>
            <Modal.Title>Edit School Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="form-group mt-4   p-20">
                <input
                  type="text"
                  className="form-control"
                  placeholder="first Name"
                  defaultValue={adminDetails.fName}
                  onChange={(e) => setfName(e.target.value)}
                />
              </div>
              <div className="form-group mt-4   p-20">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last NAme"
                  defaultValue={adminDetails.lName}

                  onChange={(e) => setlName(e.target.value)}
                />
              </div>
            </>

          </Modal.Body>
          <Modal.Footer>

            <Button variant="primary" onClick={editAdmin} >
              Edit
            </Button>

          </Modal.Footer>
          </>:<>
          <Modal.Header closeButton>
            <Modal.Title>Delete School Admin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br />
            <h5>Are you Sure To Delete School Admin?</h5>
            <br />
            <br />
            <input readOnly className="form-control" value={adminDetails.fName+" "+adminDetails.lName} />
          </Modal.Body>
          <Modal.Footer>

            <Button variant="primary" onClick={deleteAdmin} >
              Delete
            </Button>

          </Modal.Footer>
          </>}
        </Modal>

        <div className="row main-div">
          <div className="col-md-9 m-auto  text-center">
            <div className='p-2 center-div'>
                <button onClick={handleClose} className="btn close-btn ">
                    <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#011627"/>
                      <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
              <div
                className="dashboard-heading ml-3 p-3 text-left"
                style={{ marginRight: "20px" }}
              >
                <h6>
                  Manage School Admins
                  <button className="btn btn-add-device float-right " onClick={handleInviteModalShow}>
                    <i className="fas fa-plus-circle"></i> {"  "}
                    <span>Add New</span>
                  </button>

                </h6>
              </div>

              <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th> Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {admins.map((item, index) => {
                        return (
                          <tr key={index} hover role="checkbox" tabIndex={-1}>
                            <td className="">{item._id}</td>
                            <td className="">
                              {item.fName} {item.lName}
                            </td>{" "}
                            <td className="">{item.email}</td>{" "}
                            <td className="">

                              <i className="far fa-eye icon-size-20"></i> {"  "}
                              <Link onClick={()=>getAdminDetails(item._id,'edit')}>
                                <i className="fas fa-edit icon-size-20"></i>
                              </Link>
                              &nbsp;
                              <Link onClick={()=>getAdminDetails(item._id,'delete')}>
                                <i className="fas fa-trash-alt icon-size-20"></i>
                              </Link>
                            </td>
                          </tr>
                        )
                      })}

                    </tbody>
                  </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </CustomModal>
  );
}
