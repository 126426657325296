import NavBar from "../Navbar/Navbar";
import getCookie, { setAdminCookie, userState } from "../../Atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getUsersAdmin } from "../School-Districts/api";
import ReactPaginate from 'react-paginate';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import './style.css';
import { SignupAdmin, EditAdmin, DeleteAdmin } from '../Auth/LoginPage/Api'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import Typography from "@material-ui/core/Typography";
import DialogTitle from '@mui/material/DialogTitle';
// import Box from '@mui/material/Box';
import {
    createDistrictAdminManually, createFullNewTeacher, editTeacherDetails, deleteTeacherDetails, GetEverySchoolByDistrict, getSchoolDistrictsById,
    deleteSchoolDistrictsAdmin, editSchoolDistrictsAdmin, passwordChangeTeacher, passwordChangeDistrictAdmin, passwordChangeSuperAdmin
} from '../School-Districts/api';

import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import UserTable from "./UserTable";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function User() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        //   console.log(newValue)
        if (newValue == 1) setCurrentUser('district'); else if (newValue == 2) setCurrentUser('teacher'); else setCurrentUser('admin');
        setValue(newValue);
        let currentPhase = (newValue == 1) ? 'district' : (newValue == 2) ? 'teacher' : 'admin';
        history.push({
            pathname: '/users',
            search: `?spage=${urlParam.superPage}&slimit=${urlParam.superLimit}&squery=${urlParam.superQuery}&dpage=${urlParam.superPage}&dlimit=${urlParam.districtLimit}&dquery=${urlParam.districtQuery}&tpage=${urlParam.teacherPage}&tlimit=${urlParam.teacherLimit}&tquery=${urlParam.teacherQuery}&current=${currentPhase}`
        })
    };
    const [open, setOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState('admin');
    const history = useHistory();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const [myUser, setmyUser] = useState(userState);
    const [currPage, setCurrPage] = useState({
        scurrPage: 0,
        dcurrPage: 0,
        tcurrPage: 0
    });
    const [paged, setPaged] = useState(0);
    // const [noOfPages, setNoOfPages] = useState({
    //     snoOfPages:0,
    //     dnoOfPages:0,
    //     tnoOfPages:0
    // });
    const [pageLimit, setPageLimit] = useState(25);
    const [sortType, setSortType] = useState('All');
    const [allUsers, setAllUsers] = useState([]);
    const [snackBarShow, setSnackBarShow] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarSeverity, setSnackBarSeverity] = useState('');
    const [query, setQuery] = useState("");
    const [districtSelect, setDistrictSelect] = useState('');
    const [allDistrict, setAllDistrict] = useState([]);
    const [districtAdmins, setDistrictAdmins] = useState([]);
    const [allSchools, setAllSchools] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [superAdmins, setSuperAdmins] = useState([]);
    const [adminId, setAdminId] = useState('');
    const [selectDistrictId, setSelectDistrictId] = useState('');
    const [selectSchoolId, setSelectSchoolId] = useState('');
    const [districtAdminId, setDistrictAdminId] = useState('');
    const [teacherId, setTeacherId] = useState('');
    const [snoOfPages, setSNoOfPages] = useState(25);
    const [dnoOfPages, setDNoOfPages] = useState(25);
    const [tnoOfPages, setTNoOfPages] = useState(25);
    const [districtAdminMobile, setDistrictAdminMobile] = useState('');
    const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const [showInfo, setShowInfo] = useState(false);
    const [districtName, setDistrictName] = useState('');
    const [schoolName, setSchoolName] = useState('');
    const [user, setUser] = useState({});
    const [urlParam, setUrlParam] = useState({
        superPage: 0,
        superLimit: 25,
        superQuery: '',
        districtPage: 0,
        districtLimit: 25,
        districtQuery: '',
        teacherPage: 0,
        teacherLimit: 25,
        teacherQuery: ''
    });
    const [admin, setAdmin] = useState({
        fName: '',
        lName: '',
        email: '',
        password: ''
    })
    const [district, setDistrict] = useState({
        fName: '',
        lName: '',
        email: '',
        password: '',
        mobile: ''
    })
    const [teacher, setTeacher] = useState({
        fName: '',
        lName: '',
        email: '',
        password: ''
    })

    const [teacherTouched, setTeacherTouched] = useState({
        "fName": false,
        "lName": false,
        "email": false,
        "password": false
    });

    const [adminTouched, setAdminTouched] = useState({
        "fName": false,
        "lName": false,
        "email": false,
        "password": false
    });

    const [districtTouched, setDistrictTouched] = useState({
        "fName": false,
        "lName": false,
        "email": false,
        "password": false,
        "mobile": false
    });

    const [modified, setModified] = useState({
        add: false,
        edit: false,
        delete: false
    })

    const [passwordState, setPasswordState] = useState(false);
    const [password, setPassword] = useState('');

    const logoutHandler = async () => {
        setTimeout(() => {
            //   setmyUser({
            //     fName: null,
            //     userId: null,
            //     token: null,
            //     lName: null,
            //   })
            setAdminCookie({
                fName: null,
                lName: null,
                token: null,
                userId: null
            })
            let hostname = window.location.host;
            //   console.log(hostname,window.location.protocol)
            window.location.assign(`${window.location.protocol}//${hostname}/super-admin`)
        })
    }

    const info = (user, type) => {
        // console.log(user)
        setUser(user);
        if (type == 'admin') {
            setAdmin({
                fName: user.fName,
                lName: user.lName,
                email: user.email,
            });
        } else if (type == 'district') {
            setSelectDistrictId(user.schoolDistrict._id)
            setDistrict({
                fName: user.fName,
                lName: user.lName,
                email: user.email,
                mobile: user.mobile
            });
            setDistrictName(user?.schoolDistrict?.name);
        } else {
            setTeacher({
                fName: user.fName,
                lName: user.lName,
                email: user.email,
                emailF: user.emailF,
                deleted: user.deleted,
            });
            setDistrictName(user?.schoolDistrict?.name);
            setSchoolName(user?.school?.name);
        }
        setShowInfo(true)
    }

    const feedBackMsg = (snackShow, snackSeverity, snackMessage) => {
        setSnackBarShow(snackShow);
        setSnackBarSeverity(snackSeverity);
        setSnackBarMessage(snackMessage)
    }

    const SnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        feedBackMsg(false, '', '');
    }

    const handleChangeDistrict = async (event) => {
        setSelectDistrictId(event.target.value);
        // console.log('Check val', event.target.value)
        if (currentUser == 'teacher') {
            getSchools(event.target.value);
            let res = await getSchoolDistrictsById(event.target.value, myUser.token);
            // console.log(res.res.data.data)
            if (!res.error) {
                let districts = res.res.data.data.schoolDistrict;
                if (districts.schoolId) {
                    setSelectSchoolId(districts.schoolId);
                }
            }
        }
    }

    const handleChangeSchool = (event) => {
        setSelectSchoolId(event.target.value);
    }

    const getSchools = async (districtId) => {
        let res = await GetEverySchoolByDistrict(districtId, myUser.token);
        if (res.error) {
            if (res.data.statusCode == 401) {
                logoutHandler();
            }
            else {
                feedBackMsg(true, 'error', res.data.msg);
            }
        } else {
            setAllSchools(res.res.data.data.schools)
            if (res.res.data.data.schools.length == 0) {
                setSnackBarShow(true);
                setSnackBarSeverity('error')
                setSnackBarMessage('Sorry this District doesnt have any class added yet.Select Another District');
                setSelectDistrictId('')
            }
        }
    }

    const editUser = (type, user) => {
        //   console.log(user)
        if (type == 'admin') {
            setAdmin({
                fName: user.fName,
                lName: user.lName,
                email: user.email,
            })
        } else if (type == 'district') {
            setDistrict({
                fName: user.fName,
                lName: user.lName,
                email: user.email,
                mobile: user.mobile
            })
        } else {
            setTeacher({
                fName: user.fName,
                lName: user.lName,
                email: user.email,
            })
        }
    }

    const addUser = (type) => {
        if (currentUser == 'admin') {
            setAdmin({
                fName: '',
                lName: '',
                email: '',
                password: ''
            })
        } else if (currentUser == 'district') {
            setDistrict({
                fName: '',
                lName: '',
                email: '',
                password: '',
                mobile: ''
            })
        } else {
            setTeacher({
                fName: '',
                lName: '',
                email: '',
                password: ''
            })
        }
    }

    useEffect(() => {
        // getUsers(urlParam)
        const _cookie = getCookie('recoil-persist')
        if (!_cookie) {
            logoutHandler()
        } else {
            const user = JSON.parse(_cookie)['KT_app']
            setmyUser(user)
            let surlPage = parseInt(params.get('spage')) || 0;
            let surlLimit = parseInt(params.get('slimit')) || 25;
            let surlQuery = params.get('squery') || '';
            let durlPage = parseInt(params.get('dpage')) || 0;
            let durlLimit = parseInt(params.get('dlimit')) || 25;
            let durlQuery = params.get('dquery') || '';
            let turlPage = parseInt(params.get('tpage')) || 0;
            let turlLimit = parseInt(params.get('tlimit')) || 25;
            let turlQuery = params.get('tquery') || '';
            let urlCurrent = params.get('current') || 'admin';
            setUrlParam({
                superPage: surlPage, superLimit: surlLimit, superQuery: surlQuery, districtPage: durlPage, districtLimit: durlLimit,
                districtQuery: durlQuery, teacherPage: turlPage, teacherLimit: turlLimit, teacherQuery: turlQuery
            });
            setCurrentUser(urlCurrent);
            setValue(urlCurrent == 'admin' ? 0 : (urlCurrent == 'district') ? 1 : 2);
            history.push({
                pathname: '/users',
                search: `?spage=${surlPage}&slimit=${surlLimit}&squery=${surlQuery}&dpage=${durlPage}&dlimit=${durlLimit}&dquery=${durlQuery}&tpage=${turlPage}&tlimit=${turlLimit}&tquery=${turlQuery}&current=${urlCurrent}`
            })
            getUsers(surlPage, surlLimit, surlQuery, durlPage, durlLimit, durlQuery, turlPage, turlLimit, turlQuery, user)
        }
    }, [0]);

    const getUsers = async (spage, slimit, squery, dpage, dlimit, dquery, tpage, tlimit, tquery, user = myUser) => {
        let res = await getUsersAdmin(user?.token, spage, slimit, squery, dpage, dlimit, dquery, tpage, tlimit, tquery);
        if (res.error) {
            // alert("Couldnt fetch School Districts. Try Again");
            if (res.data.statusCode == 401) {
                logoutHandler();
            }
            else {
                feedBackMsg(true, 'error', res.data.msg);
            }
        } else {
            //   console.log(res.res.data.data);
            //   console.log('Value of urlparam',urlParam)
            setAllDistrict(res.res.data.data.districts)
            setAllUsers(res.res.data.data.user)
            setSuperAdmins(res.res.data.data.superAdmin);
            setTeachers(res.res.data.data.teacher);
            setDistrictAdmins(res.res.data.data.districtAdmin)
            let divisor = parseInt(res.res.data.data.supercount / slimit);
            let remainder = (res.res.data.data.supercount % slimit == 0) ? 0 : 1;
            //   console.log('Divisor count',divisor+remainder)
            //   setNoOfPages({...noOfPages,snoOfPages:(divisor+remainder)})
            setSNoOfPages(divisor + remainder);
            divisor = parseInt(res.res.data.data.districtcount / dlimit);
            remainder = (res.res.data.data.districtcount % dlimit == 0) ? 0 : 1;
            //   setNoOfPages({...noOfPages,dnoOfPages:(divisor+remainder)})
            setDNoOfPages(divisor + remainder);
            divisor = parseInt(res.res.data.data.teachercount / tlimit);
            remainder = (res.res.data.data.teachercount % tlimit == 0) ? 0 : 1;
            //   setNoOfPages({...noOfPages,tnoOfPages:(divisor+remainder)});
            setTNoOfPages(divisor + remainder)
            setCurrPage({
                scurrPage: spage, dcurrPage: dpage, tcurrPage: tpage
            });

        }
    }

    const handleClose1 = () => {
        setPasswordState(false);

    }

    const ValidationForm = (type) => {

        if (currentUser == 'teacher') {

            if (type == 'add') {

                if ((teacher.fName.trim() == '' || teacher.fName == null) ||
                    (teacher.lName.trim() == '' || teacher.lName == null) ||
                    (teacher.email.trim() == '' || teacher.email == null || !emailRegex.test(teacher.email)) ||
                    (teacher.password.trim() == '' || teacher.password == null || teacher.password.length < 7)
                    || selectDistrictId.length < 1 || selectSchoolId.length < 1) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if ((teacher.fName.trim() == '' || teacher.fName == null) ||
                    (teacher.lName.trim() == '' || teacher.lName == null) ||
                    (teacher.email.trim() == '' || teacher.email == null || !emailRegex.test(teacher.email))) {
                    return true;
                } else {
                    return false;
                }
            }
        } else if (currentUser == 'district') {
            if (type == 'add') {

                if ((district.fName.trim() == '' || district.fName == null) ||
                    (district.lName.trim() == '' || district.lName == null) ||
                    (district.email.trim() == '' || district.email == null || !emailRegex.test(district.email)) ||
                    (district.password.trim() == '' || district.password == null || district.password.length < 7) ||
                    (district.mobile.trim() == '' || district.mobile == null || district.mobile.length < 5 || district.mobile.length > 17)
                    || selectDistrictId.length < 1) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if ((district.fName.trim() == '' || district.fName == null) ||
                    (district.lName.trim() == '' || district.lName == null) ||
                    (district.email.trim() == '' || district.email == null || !emailRegex.test(district.email)) ||
                    (district.mobile.trim() == '' || district.mobile == null || district.mobile.length > 17 || district.mobile.length < 5)) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            if (type == 'add') {

                if ((admin.fName.trim() == '' || admin.fName == null) ||
                    (admin.lName.trim() == '' || admin.lName == null) ||
                    (admin.email.trim() == '' || admin.email == null || !emailRegex.test(admin.email)) ||
                    (admin.password.trim() == '' || admin.password == null || admin.password.length < 7)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if ((admin.fName.trim() == '' || admin.fName == null) ||
                    (admin.lName.trim() == '' || admin.lName == null) ||
                    (admin.email.trim() == '' || admin.email == null || !emailRegex.test(admin.email))) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    const add_edit_delete_admin = async () => {
        let res;
        if (modified.add) {
            res = await SignupAdmin(admin, myUser.token)
        } else if (modified.edit) {
            res = await EditAdmin(admin, adminId, myUser.token);
        } else {
            res = await DeleteAdmin(adminId, myUser.token);
        }

        if (res.error) {
            if (res.data.statusCode == 401) {
                logoutHandler();
            }
            else {
                feedBackMsg(true, 'error', res.data.msg);
            }
        } else {

            feedBackMsg(true, 'success', res.res.data.msg);
            getUsers(urlParam.superPage, urlParam.superLimit, urlParam.superQuery, urlParam.districtPage, urlParam.districtLimit,
                urlParam.districtQuery, urlParam.teacherPage, urlParam.teacherLimit, urlParam.teacherQuery);
            setAdmin({
                fName: '',
                lName: '',
                email: '',
                password: ''
            })
        }
        handleClose();
        handleClose2();
    }

    const add_edit_delete_District = async () => {
        let res; let sameMobile = false;
        if (modified.add) {
            res = await createDistrictAdminManually(selectDistrictId, district, myUser.token);
        } else if (modified.edit) {
            if (district.mobile.trim() == districtAdminMobile.trim()) {
                sameMobile = true;
            }
            // console.log(selectDistrictId)
            res = await editSchoolDistrictsAdmin(selectDistrictId._id, districtAdminId, { ...district, sameMobile: sameMobile }, myUser.token);
        } else {
            res = await deleteSchoolDistrictsAdmin(selectDistrictId, districtAdminId, myUser.token);
        }

        if (res.error) {
            if (res.data.statusCode == 401) {
                logoutHandler();
            }
            else {
                feedBackMsg(true, 'error', res.data.msg);
            }
        } else {
            feedBackMsg(true, 'success', res.res.data.msg);
            getUsers(urlParam.superPage, urlParam.superLimit, urlParam.superQuery, urlParam.districtPage, urlParam.districtLimit,
                urlParam.districtQuery, urlParam.teacherPage, urlParam.teacherLimit, urlParam.teacherQuery);
            setDistrict({
                fName: '',
                lName: '',
                email: '',
                password: '',
                mobile: ''
            })
        }
        handleClose();
        handleClose2();
    }

    const add_edit_delete_Teacher = async () => {
        let res;
        if (modified.add) {
            res = await createFullNewTeacher(selectDistrictId, selectSchoolId, teacher, myUser.token);
        } else if (modified.edit) {
            res = await editTeacherDetails({ did: selectDistrictId, sid: selectSchoolId, teacherid: teacherId }, teacher, myUser.token);
        } else {
            res = await deleteTeacherDetails({ did: selectDistrictId, sid: selectSchoolId, teacherid: teacherId }, myUser.token);
        }

        if (res.error) {
            if (res.data.statusCode == 401) {
                logoutHandler();
            }
            else {
                feedBackMsg(true, 'error', res.data.msg);
            }
        } else {

            feedBackMsg(true, 'success', res.res.data.msg);
            getUsers(urlParam.superPage, urlParam.superLimit, urlParam.superQuery, urlParam.districtPage, urlParam.districtLimit,
                urlParam.districtQuery, urlParam.teacherPage, urlParam.teacherLimit, urlParam.teacherQuery);
            setTeacher({
                fName: '',
                lName: '',
                email: '',
                password: ''
            })
        }
        handleClose();
        handleClose2();
    }


    const handleChangePage = (event, newPage) => {

        // console.log(event)
        if (currentUser == 'admin') {
            setUrlParam({ ...urlParam, superPage: event.selected });
            getUsers(event.selected, urlParam.superLimit, urlParam.superQuery, urlParam.districtPage, urlParam.districtLimit,
                urlParam.districtQuery, urlParam.teacherPage, urlParam.teacherLimit, urlParam.teacherQuery);
            history.push({
                pathname: '/users',
                search: `?spage=${event.selected}&slimit=${urlParam.superLimit}&squery=${urlParam.superQuery}&dpage=${urlParam.districtPage}&dlimit=${urlParam.districtLimit}&dquery=${urlParam.districtQuery}&tpage=${urlParam.teacherPage}&tlimit=${urlParam.teacherLimit}&tquery=${urlParam.teacherQuery}&current=${currentUser}`
            })
        } else if (currentUser == 'district') {
            setUrlParam({ ...urlParam, districtPage: event.selected });
            getUsers(urlParam.superPage, urlParam.superLimit, urlParam.superQuery, event.selected, urlParam.districtLimit,
                urlParam.districtQuery, urlParam.teacherPage, urlParam.teacherLimit, urlParam.teacherQuery);
            history.push({
                pathname: '/users',
                search: `?spage=${urlParam.superPage}&slimit=${urlParam.superLimit}&squery=${urlParam.superQuery}&dpage=${event.selected}&dlimit=${urlParam.districtLimit}&dquery=${urlParam.districtQuery}&tpage=${urlParam.teacherPage}&tlimit=${urlParam.teacherLimit}&tquery=${urlParam.teacherQuery}&current=${currentUser}`
            })
        } else {
            setUrlParam({ ...urlParam, teacherPage: event.selected });
            // getUsers(urlParam);
            getUsers(urlParam.superPage, urlParam.superLimit, urlParam.superQuery, urlParam.districtPage, urlParam.districtLimit,
                urlParam.districtQuery, event.selected, urlParam.teacherLimit, urlParam.teacherQuery);
            history.push({
                pathname: '/users',
                search: `?spage=${urlParam.superPage}&slimit=${urlParam.superLimit}&squery=${urlParam.superQuery}&dpage=${urlParam.superPage}&dlimit=${urlParam.districtLimit}&dquery=${urlParam.districtQuery}&tpage=${event.selected}&tlimit=${urlParam.teacherLimit}&tquery=${urlParam.teacherQuery}&current=${currentUser}`
            })
        }
        // setPaged(newPage);

        // getUsers(event.selected,pageLimit);

    };

    const passwordChange = async (user) => {
        let res, ids, data;
        if (user == 'admin') {
            data = { email: admin.email, password: password }
            res = await passwordChangeSuperAdmin(adminId, data, myUser.token)
        }
        else if (user == 'district') {
            ids = { did: selectDistrictId, sdid: districtAdminId };
            data = { email: district.email, password: password }
            res = await passwordChangeDistrictAdmin(ids, data, myUser.token)
        } else {
            ids = { did: selectDistrictId, sid: selectSchoolId, teacherid: teacherId }
            data = { email: teacher.email, password: password };
            res = await passwordChangeTeacher(ids, data, myUser.token);
        }

        if (res.error) {
            if (res.data.statusCode == 401) {
                logoutHandler();
            }
            else {
                feedBackMsg(true, 'error', res.data.msg);
            }
        } else {

            feedBackMsg(true, 'success', res.res.data.msg);
        }
        handleClose1();
        handleClose2();
    }

    const handleClose = () => {
        setOpen(false);
        setModified({
            add: false,
            edit: false,
            delete: false
        });
        setAdminTouched({
            "fName": false,
            "lName": false,
            "email": false,
            "password": false
        })
        setTeacherTouched({
            "fName": false,
            "lName": false,
            "email": false,
            "password": false
        })
        setDistrictTouched({
            "fName": false,
            "lName": false,
            "email": false,
            "password": false,
            "mobile": false
        })
        setSelectDistrictId('');
        setSelectSchoolId('');
    }

    const handleClose2 = () => {
        setShowInfo(false);
    }


    return (
        <section id="hero-school ">
            <div >
                <NavBar />
                <br />


                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Super Admin" {...a11yProps(0)} />
                            <Tab label="District/School Admin" {...a11yProps(1)} />
                            <Tab label="Teacher" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0} user={'admin'}>
                        {/* <UserTable data={superAdmins}/> */}
                        <div className="d-flex justify-content-end align-items-end">

                            <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={snoOfPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handleChangePage}
                                containerClassName="pagination"
                                activeClassName="activePage active"
                                forcePage={currPage.scurrPage} />

                        </div>
                        <div className="col-lg-8 col-md-7 col-sm-12">

                            <TextField sx={{ minWidth: 250, width: 600, background: 'white' }} value={urlParam.superQuery} id="outlined-search" label="Search" type="search"
                                onChange={(e) => {
                                    setUrlParam({ ...urlParam, superQuery: e.target.value, superPage: 0 });
                                    getUsers(0, urlParam.superLimit, e.target.value, urlParam.districtPage, urlParam.districtLimit,
                                        urlParam.districtQuery, urlParam.teacherPage, urlParam.teacherLimit, urlParam.teacherQuery)
                                }} />
                        </div>
                        <div className="d-flex justify-content-end align-items-end">
                            <button className="btn btn-add float-right" onClick={() => {
                                setModified({ ...modified, add: true });
                                setOpen(true); addUser('admin')
                            }}>
                                <i className="fas fa-plus-circle"></i> {"  "}
                                <span>Add Super Admin</span>
                            </button>
                        </div>

                        <div style={{ width: '100%' }} className="scrollLimits">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>UserType</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Created On</th>
                                        {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {superAdmins.map((item, index) => {
                                        return (
                                            <tr key={index} hover role="checkbox" tabIndex={-1} className="cursorBody" onClick={() => info(item, 'admin')}>
                                                <td className="" >
                                                    {'Super Admin'}
                                                </td>
                                                <td className=" textCap">
                                                    {item.fName} {item.lName}
                                                </td>{" "}
                                                <td >
                                                    {item.email}
                                                </td>{" "}
                                                <td >
                                                    {new Date(item.createdAt).toLocaleDateString()} {new Date(item.createdAt).toLocaleTimeString()}
                                                </td>
                                                {/* <td className="">
                                    
                                    <Tooltip title="Edit" placement="top">
                                        <span style={{cursor:"pointer"}} onClick={()=>{
                                            setModified({...modified,edit:true});
                                            setOpen(true);editUser('admin',item);
                                            setAdminId(item._id);
                                    }}>
                              
                                            <i className="fas fa-edit icon-size-30" ></i>
                                        </span>
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title="Delete" placement="top">
                                        <span style={{cursor:"pointer"}} onClick={()=>{
                                            setModified({...modified,delete:true});
                                            setOpen(true);editUser('admin',item);
                                            setAdminId(item._id);
                                        }}>
                                            
                                            <i className="fas fa-trash-alt icon-size-30" ></i>
                                        </span>
                                    </Tooltip>
                                    
                                    &nbsp;
                                    {(item._id==myUser.userId)?
                                    <Tooltip title="Change Password" placement="top">
                                        <span style={{cursor:"pointer"}} onClick={()=>{
                                            editUser('admin',item);
                                            setAdminId(item._id);setPasswordState(true);setPassword('');
                                        }}>
                                            
                                            <i className="fa fa-key icon-size-20" ></i>
                                        </span>
                                    </Tooltip>
                                        :null}
                                    </td> */}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} user={'district'}>
                        {/* <UserTable data={districtAdmins} /> */}
                        <div className="d-flex justify-content-end align-items-end">

                            <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={dnoOfPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handleChangePage}
                                containerClassName="pagination"
                                activeClassName="activePage active"
                                forcePage={currPage.dcurrPage} />

                        </div>
                        <div className="col-lg-8 col-md-7 col-sm-12">

                            <TextField sx={{ minWidth: 250, width: 600, background: 'white' }} value={urlParam.districtQuery} id="outlined-search" label="Search" type="search"
                                onBlur={(e) => { setUrlParam({ ...urlParam, districtQuery: e.target.value.trim() }) }}
                                onChange={(e) => {
                                    setUrlParam({ ...urlParam, districtQuery: e.target.value, districtPage: 0 });
                                    getUsers(urlParam.superPage, urlParam.superLimit, urlParam.superQuery, 0, urlParam.districtLimit,
                                        e.target.value, urlParam.teacherPage, urlParam.teacherLimit, urlParam.teacherQuery)
                                }} />
                        </div>
                        <div className="d-flex justify-content-end align-items-end">
                            <button className="btn btn-add float-right" onClick={() => {
                                setModified({ ...modified, add: true });
                                setOpen(true); addUser('district')
                            }}>
                                <i className="fas fa-plus-circle"></i> {"  "}
                                <span>Add District/School Admin</span>
                            </button>
                        </div>
                        <div style={{ width: '100%' }} className="scrollLimits">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>UserType</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Created On</th>
                                        {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {districtAdmins.map((item, index) => {
                                        return (
                                            <tr key={index} hover role="checkbox" className="cursorBody" tabIndex={-1} onClick={() => info(item, 'district')}>
                                                <td className="" >
                                                    {(item.type == 'individual') ? 'School Admin' : 'District Admin'}
                                                </td>
                                                <td className=" textCap" >
                                                    {item.fName} {item.lName}
                                                </td>{" "}
                                                <td >
                                                    {item.email}
                                                </td>{" "}
                                                <td >
                                                    {new Date(item.createdAt).toLocaleDateString()} {new Date(item.createdAt).toLocaleTimeString()}
                                                </td>
                                                {/* <td className="">
                                    
                                    <Tooltip title="Edit" placement="top">
                                        <span style={{cursor:"pointer"}} onClick={()=>{
                                            setModified({...modified,edit:true});setSelectDistrictId(item.schoolDistrict)
                                            setOpen(true);editUser('district',item);setDistrictAdminId(item._id);setDistrictAdminMobile(item.mobile)
                                    }}>
                              
                                            <i className="fas fa-edit icon-size-30" ></i>
                                        </span>
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title="Delete" placement="top">
                                        <span style={{cursor:"pointer"}} onClick={()=>{
                                            setModified({...modified,delete:true});setSelectDistrictId(item.schoolDistrict)
                                            setOpen(true);editUser('district',item);setDistrictAdminId(item._id)
                                        }}>
                              
                                            <i className="fas fa-trash-alt icon-size-30" ></i>
                                        </span>
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title="Mail Password" placement="top">
                                        <Link onClick={() => {
                                            editUser('district',item);setPassword('');setPasswordState(true);setPassword('');
                                            setSelectDistrictId(item.schoolDistrict);setDistrictAdminId(item._id);
                                        }}>
                                            <i className="fa fa-key icon-size-20"></i>
                                         </Link>
                                    </Tooltip>
                                    </td> */}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2} user={'teacher'}>
                        {/* <UserTable data={teachers}/> */}
                        <div className="d-flex justify-content-end align-items-end">

                            <ReactPaginate
                                previousLabel="Previous"
                                nextLabel="Next"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={tnoOfPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handleChangePage}
                                containerClassName="pagination"
                                activeClassName="activePage active"
                                forcePage={currPage.tcurrPage} />

                        </div>
                        <div className="col-lg-8 col-md-7 col-sm-12">

                            <TextField sx={{ minWidth: 250, width: 600, background: 'white' }} value={urlParam.teacherQuery} id="outlined-search" label="Search" type="search"
                                onBlur={e => setUrlParam({ ...urlParam, teacherQuery: e.target.value.trim() })}
                                onChange={(e) => {
                                    setUrlParam({ ...urlParam, teacherQuery: e.target.value, teacherPage: 0 });
                                    getUsers(urlParam.superPage, urlParam.superLimit, urlParam.superQuery, urlParam.districtPage, urlParam.districtLimit,
                                        urlParam.districtQuery, 0, urlParam.teacherLimit, e.target.value)
                                }} />
                        </div>
                        <div className="d-flex justify-content-end align-items-end">
                            <button className="btn btn-add float-right" onClick={() => {
                                setModified({ ...modified, add: true });
                                setOpen(true); addUser('teacher')
                            }}>
                                <i className="fas fa-plus-circle"></i> {"  "}
                                <span>Add Teacher</span>
                            </button>
                        </div>
                        <div style={{ width: '100%' }} className="scrollLimits">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>UserType</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Created On</th>
                                        {/* <th>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {teachers.map((item, index) => {
                                        return (
                                            <tr key={index} hover role="checkbox" className="cursorBody" style={{ opacity: item.deleted ? 0.5 : 1 }} tabIndex={-1} onClick={() => info(item, 'teacher')}>
                                                <td className="" >
                                                    {'Teacher'}
                                                </td>
                                                <td className=" textCap" >
                                                    {item.fName} {item.lName}
                                                </td>{" "}
                                                <td >
                                                    {item.email === item._id ? item.emailF : item.email}
                                                </td>{" "}
                                                <td >
                                                    {new Date(item.createdAt).toLocaleDateString()} {new Date(item.createdAt).toLocaleTimeString()}
                                                </td>
                                                {/* <td className="">
                                    
                                    <Tooltip title="Edit" placement="top">
                                        <span style={{cursor:"pointer"}} onClick={()=>{
                                            setModified({...modified,edit:true});setSelectDistrictId(item.schoolDistrict);setSelectSchoolId(item.school);
                                            setOpen(true);editUser('teacher',item);setTeacherId(item._id)
                                            
                                    }}>
                                            <i className="fas fa-edit icon-size-30" ></i>
                                        </span>
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title="Delete" placement="top">
                                        <span style={{cursor:"pointer"}} onClick={()=>{
                                            setModified({...modified,delete:true});
                                            setOpen(true);editUser('teacher',item);setTeacherId(item._id)
                                        }}>
                              
                                            <i className="fas fa-trash-alt icon-size-30" ></i>
                                        </span>
                                    </Tooltip>    
                                        &nbsp;
                                        <Tooltip title="Change Password" placement="top">
                                            <Link onClick={() => {
                                                editUser('teacher',item);setPassword('');setPasswordState(true);
                                                setSelectDistrictId(item.schoolDistrict);setSelectSchoolId(item.school);setTeacherId(item._id)
                                            }}>
                                            <i className="fa fa-key icon-size-20"></i>
                                         </Link>
                                    </Tooltip>
                                    
                                    </td> */}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </TabPanel>
                </Box>


            </div>
            <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                    {snackBarMessage}
                </Alert>
            </Snackbar>

            <Dialog open={showInfo} handleClose={handleClose2} fullWidth>
                <section id="hero-Msd">
                    <DialogTitle>
                        <Grid container justify="space-between" alignItems="center">
                            <Typography variant="h6" style={{ fontWeight: '600' }}>
                                {(currentUser == 'district') ? `School District Admin Details` :
                                    (currentUser == 'teacher') ? `Teacher Details` :
                                        (currentUser == 'admin') ? `Super Admin Details` : null}
                            </Typography>
                            <IconButton onClick={handleClose2} >
                                <CloseIcon style={{ border: '2px solid black', fontSize: '30px' }} />
                            </IconButton>
                        </Grid>
                    </DialogTitle>

                    <div className="container">
                        <div className="row">
                            <div className="col-4 h6">First Name</div>
                            <div className="col-8 h5 textCap">
                                {(currentUser == 'admin') ? `${admin.fName}` : (currentUser == 'district') ? `${district.fName}`
                                    : (currentUser == 'teacher') ? `${teacher.fName}` : null}
                            </div>
                            <br />
                            <div className="col-4 h6">Last Name</div>
                            <div className="col-8 h5 textCap">
                                {(currentUser == 'admin') ? `${admin.lName}` : (currentUser == 'district') ? `${district.lName}`
                                    : (currentUser == 'teacher') ? `${teacher.lName}` : null}
                            </div>
                            <br />
                            <div className="col-4 h6">Email</div>
                            <div className="col-8 h5">
                                {(currentUser == 'admin') ? `${admin.email}` : (currentUser == 'district') ? `${district.email}`
                                    : (currentUser == 'teacher') ? `${teacher.deleted ? teacher.emailF : teacher.email}` : null}
                            </div>

                            <br />
                            {(currentUser == 'district') ? <>
                                <div className="col-4 h6">Mobile</div>
                                <div className="col-8 h5">
                                    {district.mobile}
                                </div>
                                <br /></> : null
                            }
                            {(currentUser == 'district' || currentUser == 'teacher') ? <>
                                <div className="col-4 h6">School District</div>
                                <div className="col-8 h5">
                                    {districtName}
                                </div>
                                <br /></> : null
                            }
                            {(currentUser == 'teacher') ? <>
                                <div className="col-4 h6">School</div>
                                <div className="col-8 h5">
                                    {schoolName}
                                </div>
                                <br /></> : null
                            }
                            <div className="col-4 h6">{user?.deleted ? "" : "Actions"}</div>
                            <div className="col-8 h5">
                                {(currentUser == 'admin') ? <>
                                    <Tooltip title="Edit" placement="top">
                                        <span style={{ cursor: "pointer" }} onClick={() => {
                                            setModified({ ...modified, edit: true }); editUser('admin', user);
                                            setAdminId(user._id);
                                            setOpen(true); editUser('district', user);
                                        }}>

                                            <i className="fas fa-edit icon-size-30" ></i>
                                        </span>
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title="Delete" placement="top">
                                        <span style={{ cursor: "pointer" }} onClick={() => {
                                            setModified({ ...modified, delete: true });
                                            setOpen(true); editUser('admin', user);
                                            setAdminId(user._id);
                                        }}>

                                            <i className="fas fa-trash-alt icon-size-30" ></i>
                                        </span>
                                    </Tooltip>
                                    {(user._id == myUser.userId) ? <>
                                        &nbsp;
                                        <Tooltip title="Mail Password" placement="top">
                                            <Link onClick={() => {
                                                editUser('admin', user);
                                                setAdminId(user._id); setPasswordState(true); setPassword('');
                                            }}>
                                                <i className="fa fa-key icon-size-20"></i>
                                            </Link>
                                        </Tooltip>
                                    </> : null}
                                </> : (currentUser == 'district') ? <>
                                    <Tooltip title="Edit" placement="top">
                                        <span style={{ cursor: "pointer" }} onClick={() => {
                                            setModified({ ...modified, edit: true }); setSelectDistrictId(user.schoolDistrict)
                                            setOpen(true); editUser('district', user); setDistrictAdminId(user._id); setDistrictAdminMobile(user.mobile)
                                        }}>

                                            <i className="fas fa-edit icon-size-30" ></i>
                                        </span>
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title="Delete" placement="top">
                                        <span style={{ cursor: "pointer" }} onClick={() => {
                                            setModified({ ...modified, delete: true }); setSelectDistrictId(user.schoolDistrict)
                                            setOpen(true); editUser('district', user); setDistrictAdminId(user._id)
                                        }}>

                                            <i className="fas fa-trash-alt icon-size-30" ></i>
                                        </span>
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title="Mail Password" placement="top">
                                        <Link onClick={() => {
                                            editUser('district', user); setPassword(''); setPasswordState(true); setPassword('');
                                            setSelectDistrictId(user.schoolDistrict); setDistrictAdminId(user._id);
                                        }}>
                                            <i className="fa fa-key icon-size-20"></i>
                                        </Link>
                                    </Tooltip>
                                </> : (currentUser == 'teacher' && !user.deleted) ? <>
                                    <Tooltip title="Edit" placement="top">
                                        <span style={{ cursor: "pointer" }} onClick={() => {
                                            setModified({ ...modified, edit: true }); setSelectDistrictId(user?.schoolDistrict?._id); setSelectSchoolId(user?.school?._id);
                                            setOpen(true); editUser('teacher', user); setTeacherId(user._id)

                                        }}>
                                            <i className="fas fa-edit icon-size-30" ></i>
                                        </span>
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title="Delete" placement="top">
                                        <span style={{ cursor: "pointer" }} onClick={() => {
                                            setModified({ ...modified, delete: true }); setSelectDistrictId(user?.schoolDistrict?._id); setSelectSchoolId(user?.school?._id);
                                            setOpen(true); editUser('teacher', user); setTeacherId(user._id)
                                        }}>

                                            <i className="fas fa-trash-alt icon-size-30" ></i>
                                        </span>
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip title="Change Password" placement="top">
                                        <Link onClick={() => {
                                            editUser('teacher', user); setPassword(''); setPasswordState(true);
                                            setSelectDistrictId(user?.schoolDistrict?._id); setSelectSchoolId(user?.school?._id); setTeacherId(user._id)
                                        }}>
                                            <i className="fa fa-key icon-size-20"></i>
                                        </Link>
                                    </Tooltip>
                                </>
                                    : null}
                            </div>
                            <br />
                        </div>
                    </div>
                    <br />

                </section>
            </Dialog>

            <Dialog open={passwordState} handleClose={handleClose1} fullWidth>
                <section id="hero-Msd">
                    <DialogTitle>
                        <Grid container justify="space-between" alignItems="center">
                            <Typography variant="h6" style={{ fontWeight: '600' }}>
                                {(currentUser == 'district') ? `Change Password for ${district.fName} ${district.lName}` :
                                    (currentUser == 'teacher') ? `Change Password for ${teacher.fName} ${teacher.lName}` :
                                        (currentUser == 'admin') ? `Change Password for ${admin.fName} ${admin.lName}` : null}
                            </Typography>
                            <IconButton onClick={handleClose1} >
                                <CloseIcon style={{ border: '2px solid black', fontSize: '30px' }} />
                            </IconButton>
                        </Grid>
                    </DialogTitle>
                    <div className="col-md-9 text-left col-9 m-auto">
                        {(currentUser == 'district') ? <>
                            <div className="form-group mt-4 p-20">

                                <input type="text" className="form-control" value={password} placeholder="Password" onBlur={(e) => setPassword(e.target.value.trim())}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span style={{ color: 'red' }}>Password should be atleast 8 characters</span>
                            </div>
                        </> : (currentUser == 'teacher') ? <>
                            <div className="form-group mt-4 p-20">

                                <input type="text" className="form-control" value={password} placeholder="Password" onBlur={(e) => setPassword(e.target.value.trim())}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span style={{ color: 'red' }}>Password should be atleast 8 characters</span>
                            </div>
                        </> : (currentUser == 'admin') ? <>
                            <div className="form-group mt-4 p-20">

                                <input type="text" className="form-control" value={password} placeholder="Password" onBlur={(e) => setPassword(e.target.value.trim())}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span style={{ color: 'red' }}>Password should be atleast 8 characters</span>
                            </div>
                        </> : null}
                    </div>
                    <div className="col-md-9 text-left  col-9 m-auto">
                        <div className="form-group p-20">
                            <button type="button" className="cta-btn" disabled={(password.trim().length > 7) ? false : true} onClick={() => {
                                passwordChange((currentUser == 'district') ? 'district' : (currentUser == 'admin') ? 'admin' : 'teacher')
                            }}>Change Password</button>
                        </div>
                    </div>
                </section>
            </Dialog>

            <Dialog handleClose={handleClose} open={open} fullWidth>
                <section id="hero-Msd">
                    <DialogTitle>
                        <Grid container justify="space-between" alignItems="center">
                            <Typography variant="h6" style={{ fontWeight: '600' }}>
                                {(modified.add) ? `Add ${(currentUser == 'admin') ? 'Super Admin' : (currentUser == 'district') ? 'District/School Admin' : 'Teacher'}` :
                                    (modified.edit) ? `Edit ${(currentUser == 'admin') ? 'Super Admin' : (currentUser == 'district') ? 'District/School Admin' : 'Teacher'}` : (modified.delete) ?
                                        ` Delete ${(currentUser == 'admin') ? 'Super Admin' : (currentUser == 'district') ? 'District/School Admin' : 'Teacher'}` : null}
                            </Typography>
                            <IconButton onClick={handleClose} >
                                <CloseIcon style={{ border: '2px solid black', fontSize: '30px' }} />
                            </IconButton>
                        </Grid>
                    </DialogTitle>

                    {
                        (currentUser == 'admin' && (modified.add || modified.edit)) ? <>
                            <div className="col-md-9 text-left  col-9 m-auto">
                                <div className="form-group mt-4   p-20">
                                    <input type="text" className="form-control" value={admin.fName} placeholder="First Name"
                                        onChange={(e) => setAdmin({ ...admin, fName: e.target.value })}
                                        onBlur={e => { setAdminTouched({ ...adminTouched, fName: true }); setAdmin({ ...admin, fName: e.target.value.trim() }) }} />
                                    {(admin.fName.trim() == '' && adminTouched.fName) ? <span style={{ color: 'red' }}>FirstName is required</span> : null}
                                </div>
                                <div className="form-group mt-4   p-20">
                                    <input type="text" className="form-control" value={admin.lName} placeholder="Last Name"
                                        onChange={(e) => setAdmin({ ...admin, lName: e.target.value })}
                                        onBlur={e => { setAdminTouched({ ...adminTouched, lName: true }); setAdmin({ ...admin, lName: e.target.value.trim() }) }} />
                                    {(admin.lName.trim() == '' && adminTouched.lName) ? <span style={{ color: 'red' }}>LastName is required</span> : null}
                                </div>
                                <div className="form-group mt-4   p-20">
                                    <input type="text" className="form-control" value={admin.email} placeholder="Email" onPaste={(e) => setAdmin({ ...admin, email: e.target.value.trim() })}
                                        onChange={(e) => setAdmin({ ...admin, email: e.target.value })}
                                        onBlur={e => { setAdminTouched({ ...adminTouched, email: true }); setAdmin({ ...admin, email: e.target.value.trim() }) }} />
                                    {(admin.email.trim() == '' && adminTouched.email) ? <span style={{ color: 'red' }}>Email is required</span> :
                                        (!emailRegex.test(admin.email) && admin.email.trim() != '' && adminTouched.email) ?
                                            <span style={{ color: 'red' }}>Email pattern is required</span> : null}
                                </div>
                                {(modified.add) ? <>
                                    <div className="form-group mt-4   p-20">
                                        <input type="text" className="form-control" value={admin.password} placeholder="Password"
                                            onChange={(e) => setAdmin({ ...admin, password: e.target.value })}
                                            onBlur={e => { setAdminTouched({ ...adminTouched, password: true }); setAdmin({ ...admin, password: e.target.value.trim() }) }} />
                                        {(admin.password.trim() == '' && adminTouched.password) ? <span style={{ color: 'red' }}>Password is required</span> : null}
                                        <br />
                                        <small style={{ color: "red" }}>Password should contain upto 8 characters</small>
                                    </div></> : null}
                                <br />
                            </div></> : (currentUser == 'district' && (modified.add || modified.edit)) ? <>
                                <div className="col-md-9 text-left  col-9 m-auto">
                                    {(modified.add) ? <>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">District</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selectDistrictId}
                                                    label="Districts"
                                                    // sx={{borderRadius:'23px'}}
                                                    onChange={handleChangeDistrict}
                                                >
                                                    {allDistrict.map((item, index) => {
                                                        return (
                                                            <MenuItem value={item._id}>{item.name}</MenuItem>
                                                        )
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Box></> : null}
                                    <div className="form-group mt-4   p-20">
                                        <input type="text" className="form-control" value={district.fName} placeholder="First Name"
                                            onChange={(e) => setDistrict({ ...district, fName: e.target.value })}
                                            onBlur={e => { setDistrictTouched({ ...districtTouched, fName: true }); setDistrict({ ...district, fName: e.target.value.trim() }) }} />
                                        {(district.fName.trim() == '' && districtTouched.fName) ? <span style={{ color: 'red' }}>FirstName is required</span> : null}
                                    </div>
                                    <div className="form-group mt-4   p-20">
                                        <input type="text" className="form-control" value={district.lName} placeholder="Last Name"
                                            onChange={(e) => setDistrict({ ...district, lName: e.target.value })}
                                            onBlur={e => { setDistrictTouched({ ...districtTouched, lName: true }); setDistrict({ ...district, lName: e.target.value.trim() }) }} />
                                        {(district.lName.trim() == '' && districtTouched.lName) ? <span style={{ color: 'red' }}>LastName is required</span> : null}
                                    </div>
                                    <div className="form-group mt-4   p-20">
                                        <input type="text" className="form-control" value={district.email} placeholder="Email" onPaste={(e) => setDistrict({ ...district, email: e.target.value.trim() })}
                                            onChange={(e) => setDistrict({ ...district, email: e.target.value })}
                                            onBlur={e => { setDistrictTouched({ ...districtTouched, email: true }); setDistrict({ ...district, email: e.target.value.trim() }) }}
                                        />
                                        {(district.email.trim() == '' && districtTouched.email) ? <span style={{ color: 'red' }}>Email is required</span> :
                                            (!emailRegex.test(district.email) && district.email.trim() != '' && districtTouched.email) ?
                                                <span style={{ color: 'red' }}>Email pattern is required</span> : null}
                                    </div>
                                    {(modified.add) ? <>
                                        <div className="form-group mt-4   p-20">
                                            <input type="text" className="form-control" value={district.password} placeholder="Password"
                                                onChange={(e) => setDistrict({ ...district, password: e.target.value })}
                                                onBlur={e => { setDistrictTouched({ ...districtTouched, password: true }); setDistrict({ ...district, password: e.target.value.trim() }) }} />
                                            {(district.password.trim() == '' && districtTouched.password) ? <span style={{ color: 'red' }}>Password is required</span> : null}
                                            <br />
                                            <small style={{ color: "red" }}>Password should contain upto 8 characters</small>
                                        </div>
                                    </> : null}
                                    <div className="form-group mt-4   p-20">
                                        <input type="text" className="form-control" maxLength={16} value={district.mobile} placeholder="Mobile"
                                            onChange={(e) => setDistrict({ ...district, mobile: e.target.value })}
                                            onBlur={e => { setDistrictTouched({ ...districtTouched, mobile: true }); setDistrict({ ...district, mobile: e.target.value.trim() }) }} />
                                        {(district.mobile.trim() == '' && districtTouched.mobile) ? <span style={{ color: 'red' }}>Mobile is required</span> : null}
                                    </div>
                                </div>
                            </> : (currentUser == 'teacher' && (modified.add || modified.edit)) ? <>
                                <div className="col-md-9 text-left  col-9 m-auto">
                                    {modified.add ? <>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">District</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selectDistrictId}
                                                    label="Districts"
                                                    // sx={{borderRadius:'23px'}}
                                                    onChange={handleChangeDistrict}
                                                >
                                                    {allDistrict.map((item, index) => {
                                                        return (
                                                            <MenuItem value={item._id}>{item.name}</MenuItem>
                                                        )
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Box>
                                        {(selectDistrictId != '') ? <>
                                            <br />
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">School</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={selectSchoolId}
                                                        label="Districts"
                                                        // sx={{borderRadius:'23px'}}
                                                        onChange={handleChangeSchool}
                                                    >
                                                        {allSchools.map((item, index) => {
                                                            return (
                                                                <MenuItem value={item._id}>{item.name}</MenuItem>
                                                            )
                                                        })}

                                                    </Select>
                                                </FormControl>
                                            </Box></> : null}
                                    </> : null}
                                    <div className="form-group mt-4   p-20">
                                        <input type="text" className="form-control" value={teacher.fName} placeholder="First Name"
                                            onChange={(e) => setTeacher({ ...teacher, fName: e.target.value })}
                                            onBlur={e => { setTeacherTouched({ ...teacherTouched, fName: true }); setTeacher({ ...teacher, fName: e.target.value.trim() }) }} />
                                        {(teacher.fName.trim() == '' && teacherTouched.fName) ? <span style={{ color: 'red' }}>FirstName is required</span> : null}
                                    </div>

                                    <div className="form-group mt-4   p-20">
                                        <input type="text" className="form-control" value={teacher.lName} placeholder="Last Name"
                                            onChange={(e) => setTeacher({ ...teacher, lName: e.target.value })}
                                            onBlur={e => { setTeacherTouched({ ...teacherTouched, lName: true }); setTeacher({ ...teacher, lName: e.target.value.trim() }) }} />
                                        {(teacher.lName.trim() == '' && teacherTouched.lName) ? <span style={{ color: 'red' }}>LastName is required</span> : null}
                                    </div>
                                    <div className="form-group mt-4   p-20">
                                        <input type="text" className="form-control" value={teacher.email} placeholder="Email" onPaste={(e) => setTeacher({ ...teacher, email: e.target.value.trim() })}
                                            onChange={(e) => setTeacher({ ...teacher, email: e.target.value })}
                                            onBlur={e => { setTeacherTouched({ ...teacherTouched, email: true }); e.target.value.trim() }} />
                                        {(teacher.email.trim() == '' && teacherTouched.email) ? <span style={{ color: 'red' }}>Email is required</span> :
                                            (!emailRegex.test(teacher.email) && teacher.email.trim() != '' && teacherTouched.email) ?
                                                <span style={{ color: 'red' }}>Email pattern is required</span> : null}
                                    </div>
                                    {modified.add ? <>
                                        <div className="form-group mt-4   p-20">
                                            <input type="text" className="form-control" value={teacher.password} placeholder="Password"
                                                onChange={(e) => setTeacher({ ...teacher, password: e.target.value })}
                                                onBlur={e => { setTeacherTouched({ ...teacherTouched, password: true }); setTeacher({ ...teacher, password: e.target.value.trim() }) }} />
                                            {(teacher.password.trim() == '' && teacherTouched.password) ? <span style={{ color: 'red' }}>Password is required</span> : null}
                                            <br />
                                            <small style={{ color: "red" }}>Password should contain upto 8 characters</small>
                                        </div>
                                    </> : null}
                                </div>
                            </> : null}
                    {
                        (modified.delete) ? <>
                            <div className="col-md-9 text-left  col-9 m-auto">
                                <div className="form-group mt-4   p-20">
                                    <h6>
                                        {`Are you sure to delete this ${(currentUser == 'admin') ? 'Super Admin' : (currentUser == 'district') ? 'District/School Admin' : 'Teacher'} `}
                                    </h6>
                                    <br />
                                    <input type="text" readOnly value={(currentUser == 'admin') ? `${admin.fName} ${admin.lName}` :
                                        (currentUser == 'district') ? `${district.fName} ${district.lName}` : `${teacher.fName} ${teacher.lName}`} className="form-control" placeholder="School Destrict Name" />
                                </div>
                            </div>

                        </> : null
                    }
                    <div className="col-md-9 text-left  col-9 m-auto">
                        <div className="form-group p-20">
                            {(modified.add || modified.edit) ? <>
                                <button type="button" className="cta-btn" onClick={() => {
                                    if (currentUser == 'admin') add_edit_delete_admin()
                                    else if (currentUser == 'district') add_edit_delete_District()
                                    else add_edit_delete_Teacher()
                                }} disabled={(modified.add) ? ValidationForm('add') : ValidationForm('edit')}>
                                    {(modified.add) ? 'Add' : 'Edit'}
                                </button>
                            </> : (modified.delete) ? <>
                                <button type="button" className="cta-btn-red" onClick={() => {
                                    if (currentUser == 'admin') add_edit_delete_admin()
                                    else if (currentUser == 'district') add_edit_delete_District()
                                    else add_edit_delete_Teacher()
                                }}>
                                    Delete
                                </button>
                            </> : null
                            }
                        </div>
                    </div>

                    {/* ----- {`${modify.admin.add}`} */}
                </section>
            </Dialog>


        </section>
    )

}


function TabPanel(props) {
    const { children, value, index, user, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}





