import axios from "axios";
import { baseUrl } from "../Base_url";



const userData = JSON.parse(localStorage.getItem("recoil-persist"));


let token = "";

if (userData) {
  if (userData.teacher_v1) {
    token = userData.teacher_v1.token;
  }else{
    // alert('You need to login first')
  }
}



export const getAllMyDevice = async (token,pages,limits) => {
    try {
      const res = await axios.get(`${baseUrl}/teacher/teacher/device/all?page=${pages}&limit=${limits}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      // console.log(e)
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };

  export const getMyDeviceInfo = async (token) => {
    try {
      const res = await axios.get(`${baseUrl}/teacher/teacher/device/info`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      // console.log(e)
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };









  export const createDevice = async (data) => {
    try {
      const res = await axios.post(`${baseUrl}/admin/device/create`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  
  export const editDevice = async (id,data) => {
    try {
      const res = await axios.put(`${baseUrl}/admin/device/${id}`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  export const getDeviceDetails = async (id) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/device/${id}`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };
  
  
  