import axios from "axios";
import { baseUrl } from "../Base_url";


const userData = JSON.parse(localStorage.getItem("recoil-persist-rocket-app-012034220"));


let token = "";

function getToken(){
if (userData) {
  if (userData.KT_app_School_Admin) {
    token = userData.KT_app_School_Admin.token;
  }else{
    // alert('You need to login first')
  }
}
}



export const getAllSchoolDistricts = async () => {
  try {
    getToken();
    const res = await axios.get(`${baseUrl}/school-admin/school-district/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const deleteSchoolDistrictsAdmin = async (id) => {
  try {
    getToken();
    const res = await axios.delete(
      `${baseUrl}/school-admin/school-district/${id}/admin`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const getSchoolDistrictsById = async (id) => {
  try {
    getToken();
    const res = await axios.get(
      `${baseUrl}/school-admin/school-district/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const getSchoolByDistrict = async (id) => {
  try {
    getToken();
    const res = await axios.get(
      `${baseUrl}/school-admin/school-district/${id}/school/all`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const getSchoolById = async (did, sid) => {
  try {
    getToken();
    const res = await axios.get(
      `${baseUrl}/school-admin/school-district/${did}/school/${sid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const getAllTeachers = async (token) => {
  // console.log(token);
  try {
    // getToken();
    const res = await axios.get(`${baseUrl}/school-admin/school/teacher/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};



export const addSchoolDestrict = async (data) => {
  try {
    getToken();
    const res = await axios.post(
      `${baseUrl}/school-admin/school-district/create`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const getSchoolDestrictAdmin = async (data) => {
  try {
    getToken();
    const res = await axios.get(
      `${baseUrl}/school-admin/school-district/${data}/admin`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const createSchoolDestrictAdmin = async (data) => {
  try {
    getToken();
    const res = await axios.post(
      `${baseUrl}/school-admin/school-district/${data.id}/admin`,
      data.data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const InviteSchoolTeacher = async (id, data, token) => {
  try {
    // getToken();
    const res = await axios.post(
      `${baseUrl}/school-admin/school/teacher/create`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const getTeacherDetails = async (id,token) => {
  try {
    
    const res = await axios.get(
      `${baseUrl}/school-admin/school/teacher/${id.teacherid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const editTeacherDetails = async (id,data,token) => {
  try {
    // getToken();
    const res = await axios.put(
      // `${baseUrl}/school-admin/school-district/${did}/school/${sid}/teacher/${id}`,
      `${baseUrl}/school-admin/school/teacher/${id}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const deleteTeacherDetails = async (id, token) => {
  try {
    // getToken();
    const res = await axios.delete(
      // `${baseUrl}/school-admin/school-district/${did}/school/${sid}/teacher/${id}`,
      `${baseUrl}/school-admin/school/teacher/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};


export const passwordChangeTeacher=async (teacherId,data, token)=>{
  try {
    const res = await axios.post(`${baseUrl}/school-admin/school/teacher/${teacherId}/changePassword`,data,{
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
}

export const createSchool = async (id, data) => {
  try {
    getToken();
    const res = await axios.post(
      `${baseUrl}/school-admin/school-district/${id}/school/create`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const getAllSchoolAdmins = async (id) => {
  try {
    getToken();
    const res = await axios.get(
      `${baseUrl}/school-admin/school-district/${id.did}/school/${id.sid}/school-admin/all`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const InviteAdminToSchool = async (id, data) => {
  try {
    getToken();
    const res = await axios.post(
      `${baseUrl}/school-admin/school-district/${id.did}/school/${id.sid}/admin`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const EditSchoolAdmin = async (id, data) => {
  try {
    getToken();
    const res = await axios.put(
      `${baseUrl}/school-admin/school-district/${id.did}/school/${id.sid}/school-admin/${id.adminId}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const getSchoolAdminDetails = async (id) => {
  try {
    getToken();
    const res = await axios.get(
      `${baseUrl}/school-admin/school-district/${id.did}/school/${id.sid}/school-admin/${id.adminId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const getAllAssignedDevicesForSchoolDistrict = async (id) => {
  try {
    getToken();
    const res = await axios.get(
      `${baseUrl}/school-admin/school-district/${id}/device/all`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};




/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis
/// Student Apis

export const getAllStudentbyTeacher = async (id) => {
  try {
    getToken();
    const res = await axios.get(
      `${baseUrl}/school-admin/school/teacher/${id}/student/all`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};


export const getStudentDetails = async (id) => {
  try {
    getToken();
    const res = await axios.get(
      `${baseUrl}/school-admin/school-district/${id.did}/school/${id.sid}/teacher/${id.tid}/student/${id.studentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};



export const deleteStudent = async (teacherId,studentId) => {
  try {
    getToken();
    const res = await axios.delete(
      `${baseUrl}/school-admin/school/teacher/${teacherId}/student/${studentId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};


export const editStudentDetails = async (id, data) => {
  try {
    getToken();
    const res = await axios.put(
      `${baseUrl}/school-admin/school-district/${id.did}/school/${id.sid}/teacher/${id.tid}/student/${id.studentId}`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};



export const createStudent = async (teacherId, data) => {
  try {
    getToken();
    const res = await axios.post(
      `${baseUrl}/school-admin/school/teacher/${teacherId}/student/create`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
