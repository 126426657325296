import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'



const { persistAtom } = recoilPersist()

const userState = atom({
  key: "KT_app",
  default: {
    fName: null,
    userId: null,
    token: null,
    lName: null,
  },
  
  effects_UNSTABLE: [persistAtom]
});

const setAdminCookie = (obj) => {
  let ___cookies = getCookie('recoil-persist')
  let _cookies = JSON.parse(___cookies)
  var exdate=new Date();
  exdate.setDate(exdate.getDate() + 7);
  if (_cookies) {
    _cookies['KT_app'] = obj
    document.cookie = `recoil-persist=${JSON.stringify(_cookies)};path=/;expires=${exdate.toUTCString()}`
  }
  else {
    document.cookie = `recoil-persist=${JSON.stringify({ KT_app: obj })};path=/;expires=${exdate.toUTCString()}`
  }
  // console.log(document.cookie)
}


function getCookie(name) {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}


export default getCookie;




export {userState, getCookie ,  setAdminCookie};
