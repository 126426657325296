import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

import SuperAdminRouter from "./SuperAdminRouter";
import TeacherRouter from "./teacher-admin-builds/TeacherRouter";
import SchoolRouter from './SchoolRouter'
import SchoolDistrictRouter from './district-admin-build/SchoolDistrictRouter'
import StudentRouter from './StudentRouter'
import Home from "./student-builds/components/Home/Home";
import Invitation from "./student-builds/components/Invitations/Invitation";
import ResetPasswordForm from "./student-builds/components/Invitations/ResetPasswordForm"

import { useRecoilState, useRecoilValue } from 'recoil';
import { studentState } from "./student-builds/Atom";
import Analysis from "./student-builds/components/Analysis/Analysis";
import Experiment from "./student-builds/components/Experiments/Experiment";
import getCookie from "./super-admin-build/Atom";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  // const {token} = useRecoilValue(studentState)
  // const [myUser, setmyUser] = useRecoilState(studentState);

  const _cookie = getCookie('recoil-persist')
  const user = JSON.parse(_cookie)?.student
  const [myUser, setmyUser] =  useState(user)

  useEffect(()=>{
    localStorage.clear();
  })

  function loginOpen(){
    // console.log('This was called')
    const _cookie = getCookie('recoil-persist')
    const user = JSON.parse(_cookie)['student']
    setmyUser(user)
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          {!myUser?.token && <>
            <Route path="/" exact 
            // component ={Home} 
            render={props => (<Home location={loginOpen} />)}
            />
            <Route path="/invite" component ={Invitation} />
            <Route path="/reset" component ={ResetPasswordForm} />
            <Route path="/super-admin" component ={SuperAdminRouter} />
            <Route path="/school-district" component ={SchoolDistrictRouter} />
            <Route path="/teachers" component ={TeacherRouter} />
            <Route path="/schools" component ={SchoolRouter} />
          </>}
          {myUser?.token && <>
            <Route path="/" exact component={Experiment} />
            <Route path="/experiments/:id" exact component={Analysis} />
            <Route path="/invite" component ={Invitation} />
            <Route path="/reset" component ={ResetPasswordForm} />
            <Route path="/super-admin" component ={SuperAdminRouter} />
            <Route path="/school-district" component ={SchoolDistrictRouter} />
            <Route path="/teachers" component ={TeacherRouter} />
            <Route path="/schools" component ={SchoolRouter} />
          </>}
        
        </Switch>
        
      </Router>
    </div>
  );
}

export default App;
