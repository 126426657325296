import LoginForm from "./LoginForm"
import { setTeacherCookie, teacherState } from "../../../Atom";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import '../Auth.css'
import './Login.css'
import { useState } from "react";
import logo from '../../../assets/images/logo.png';
import Sign_in_image from '../../../assets/images/Sign_in_image.jpg';
import Group_290 from '../../../assets/images/Group_290.png';
import testRocketLogo from '../../../assets/images/testrocketsLogo.png'
// import { getAllClassesBySchool, getAllSchoolsForStudent, loginThisStudent } from './Api';
import { loginTeacher } from "./Api";
import { PropaneSharp } from "@mui/icons-material";

const classNamees = {
  input: "text-in",
  select: "text-in",
}

export default function LoginScreen(props) {

  const [myUser, setmyUser] = useState(teacherState);
  const [teacherId, setTeacherId] = useState('');
  const [password, setPassword] = useState('');
  const [clicked, setClicked] = useState(false)


  const handleSubmit = async (e) => {
    setClicked(true);
    e.preventDefault();
    
      let data = { email: teacherId.trim(), password: password.trim() };
      let res = await loginTeacher(data);
      if (res.error) {
        // setShow(true);
        // showModal(true);

        // setLoading(false);
        // setModalMessage("Check Email and Password");
        alert('Invalid Credentials');
        // console.log(res);
      } else {
        // console.log(res);
        // setLoading(false);

        let { fName, lName, token, userId, email, schoolId, districtId, teacherId } = res.res.data.data;

        // setmyUser({
        //   fName: fName,
        //   userId: userId,
        //   token: token,
        //   lName: lName,
        //   email: email,
        //   schoolId: schoolId,
        //   districtId: districtId,
        // });
		setTeacherCookie({
			fName: fName,
			userId: userId,
			token: token,
			lName: lName,
			email: email,
			schoolId: schoolId,
			districtId: districtId,
		  })
		  console.log('Set Teach')
		  props.location()
        // window.location.reload('/teachers/')

      }
    setClicked(false);
  };

  return (
    <div className="index-bg">
      <img className="class1 fit-cover" src={Sign_in_image} />
	<div className="login main" >
	<header style={{position:"absolute",width:"100%"}}>
		<div className="container px-4 py-4 mx1-3" >
			<div className="logo px-1 py-1"><a style={{position:"absolute"}}><img src={Group_290}/></a></div>
		</div>
	</header>
	<main className="indexc">
		<div className="container px-0 pt-4 abs" >
			<div className="row login-form">
				<div className="col-lg-71 d-flex align-items-end justify-content-end ">
					<div className="">
						{/* className="pb-5" */}
						<div className="heading text-end border-end border-2 pe-3 border-white d-flex">
						{/* <h2 className=" main-text">Test engines like</h2>
						<h2 className=" main-text">real rocket scientists</h2> */}
						<div className="text-end1">
                			<h2 className="h2 main-text loginMessage-font">Test engines like</h2>
						    <h2 className="h2 main-text loginMessage-font">real rocket scientists</h2>
              			</div>

              <div style={{borderRight:'3px solid white',marginLeft:'20px'}}>
                
              </div>
						</div>
					</div>
				</div>
				<div className="col-lg-51 d-flex align-items-center justify-content-end pe-5">
					<div className="form1 p-5 mt-0 bg-gray me-5" style={{borderRadius:'48px'}}>
						<div className="">
						
						<form>
						  <div className="form-group">
						    <h2 className="form-title">Teacher login</h2>
						    <input type="text" className="form-control mt-3 border border-1 border-white" value={teacherId} onChange={e=>setTeacherId(e.target.value.trim())} id="email" placeholder="Teacher ID"/>
						  </div>
							<div className="form-group">
							    <input type="password" className="form-control mt-3 border border-1 border-white" value={password} id="pwd" placeholder="Password" onChange={e=>setPassword(e.target.value.trim())}/>
							 </div>
						  
						  	<div className="btn btn1 mt-60 px-0 text-center">
							  	<a onClick={(e)=>handleSubmit(e)} type="submit" className="btn btn-default sign-in">Sign in</a><br/>
							  	<a type="forgot" className="btn btn-default forgot"><Link to="/forgot-password" style={{color:'black'}}>Forgot-Password?</Link></a>
							</div>
						</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
    </div>
    </div>
    
  )
}

{/* <div className="index-bg fonted">
      <img className="className" src={Sign_in_image} />
	<div className="login main" >
	<header style={{position:"absolute",width:"100%"}}>
		<div className="container-teacher px-4 py-4 mx1-3" >
			<div className="logo px-1 py-1"><a className="a" style={{position:"absolute"}}><img src={Group_290}/></a></div>
		</div>
	</header>
	<main className="indexc">
		<div className="container-teacher px-0 pt-4 abs">
			<div className="row login-form">
				<div className="col-lg-71 d-flex align-items-end justify-content-end ">
					<div className="pb-5">
						<div className="heading text-end text-end1 border-end border-2 pe-3 border-white d-flex">
              <div>
                <h2 className=" main-text h2">Test engines like</h2>
						    <h2 className=" main-text h2">real rocket scientists</h2>
              </div>

              <div style={{borderRight:'3px solid white',marginLeft:'10px'}}>
                
              </div>
            
					</div>
					</div>
				</div>
				<div className="col-lg-51 d-flex align-items-center justify-content-end pe-5">
					<div className="log-Form p-5 mt-0 bg-gray me-5">
						<div className="">
						
						<form >
						  <div className="form-group">
						    <h2 className="form-title h2">Teacher login</h2>
						    <input type="text" className="_form-control text-f-20 form-placeHolder form-controlz mt-3 border border-1 border-white" value={teacherId} onChange={e=>setTeacherId(e.target.value.trim())} id="email" placeholder="Teacher ID"/>
						  </div>
							<div className="form-group">
							    <input type="password" className="_form-control text-f-20 form-placeHolder form-controlz mt-3 border border-1 border-white" value={password} id="pwd" placeholder="Password" onChange={e=>setPassword(e.target.value.trim())} />
							 </div>
						  
						  	<div className="btn mt-60 login-BTN px-0 text-center">
							  	<button  onClick={(e)=>handleSubmit(e)} type="submit" className="btn btn-default sign-in">Sign in</button><br/>
							  	<button type="forgot" className="btn btn-default forgot"><Link to="/forgot-password" style={{color:'black'}}>Forgot-Password?</Link></button>
							</div>
						</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
    
    </div>
    </div> */}
