import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'



const { persistAtom } = recoilPersist(
  {
    key: "recoil-persist-rocket-app-012034220", // this key is using to store data in local storage
    storage: localStorage, // configurate which stroage will be used to store the data
  }
);

const schoolAdminState = atom({
  key: "KT_app_School_Admin",
  default: {
    fName: null,
    userId: null,
    email: null,
    token: null,
    lName: null,
    schoolId:null,
    districtId:null,
  },
  effects_UNSTABLE: [persistAtom]
});






export {schoolAdminState};
