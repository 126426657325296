import axios from "axios";
import { baseUrl } from "../Base_url";




const userData = JSON.parse(localStorage.getItem("recoil-persist"));


let token = "";

if (userData) {
  if (userData.teacher_v1) {
    token = userData.teacher_v1.token;
  }else{
    // alert('You need to login first')
  }
}



export const editTeacherDetails=async (data,token)=>{
  try {
    const res = await axios.put(`${baseUrl}/teacher/teacher/passwordChange`,data,{
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
}