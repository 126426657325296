import axios from "axios";
import { baseUrl } from "../Base_url";

export const getAllSchoolsForStudent = async () => {
  try {
    const res = await axios.get(`${baseUrl}/student/data/no-auth/all-schools`);
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

export const getAllClassesBySchool = async (sClassId) => {
  try {
    const res = await axios.get(`${baseUrl}/student/data/no-auth/sclasses-by-school/${sClassId}`);
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
}
export const loginThisStudent = async (data) => {
  try {
    const res = await axios.post(`${baseUrl}/student/auth/login`, data);
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};