

import { Chip, CircularProgress } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { useEffect, useState } from "react";

import { AssignDevice, DeleteAssignedDevice, getAllAssignedDevicesForTeacher, SpareAssignedDevicesSchool, SpareAssignedDevicesForTeacher } from "./api";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from '@material-ui/icons/Delete';
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock';
import Button from '@material-ui/core/Button'
import dayjs from 'dayjs';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import '../../../device.css';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";


export default function ManageTeacherDevice({ destrictId, schoolId,teacherId, showManageDevice, token }) {
 
 
 
  const [loading, setLoading] = useState(false)
  const [sparedevices, setSpareDevices] = useState([])
  const [devices, setDevices] = useState([])
  const [showModal, setShowModal] = useState(true)
  const [showUnassignedDevicesTab, setshowUnassignedDevicesTab] = useState(false);
  



  const handleClose = () => {
    setShowModal(false)
    showManageDevice()
  };



  useEffect(() => {
    getAssignedDevicesForSchool()
  }, [0])



  const getAssignedDevicesForSchool = async () => {
    let res = await getAllAssignedDevicesForTeacher(destrictId, schoolId,teacherId, token)
    // let res1 = await SpareAssignedDevicesSchool(destrictId, schoolId)
    let res1 = await SpareAssignedDevicesForTeacher(destrictId, schoolId,teacherId, token)
    console.log(res)
    setDevices(res.res.data.data.devices)
    setSpareDevices(res1.res.data.data.devices)
  }


  const handleAssign = async (did) => {
    let res = await AssignDevice(destrictId, schoolId,teacherId, did, token)
    // console.log(res)
    alert("Assignment Successfull.")
    getAssignedDevicesForSchool()
  }

  const handleDelete = async (did) => {
    let deviceHistoryId;let found=false
    let items = did.assigneeDetails.teacher;
    for(let i=0;i<items.length && !found;i++){
      if(items[i].teacherId==teacherId){
        deviceHistoryId = items[i].historyId;found=true;
      }
    }
    let res = await DeleteAssignedDevice(destrictId, schoolId,teacherId, did._id, deviceHistoryId, token)
    getAssignedDevicesForSchool()
  };






  return (
  
    <>
    <Dialog open={showModal} handleClose={handleClose} maxWidth='md' fullWidth>
      
   <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h6" style={{fontWeight:'600'}}>Manage Devices</Typography>
            <IconButton onClick={handleClose} >
              <CloseIcon style={{border:'2px solid black',fontSize:'30px'}}/>
            </IconButton>
          </Grid>
        </DialogTitle>
         
        <div className="flex-container">
          
          <div className="flex-header">
              <button className="btn btn-add-device mt-4 mb-3 float-left" onClick={() => setshowUnassignedDevicesTab(false)}>
                  <i className="fas fa-plus-circle"></i>&nbsp;<span>Assigned Device</span>
                </button>

                <button className="btn  btn-add-device mt-4 mb-3  btn-available-device" onClick={() => setshowUnassignedDevicesTab(true)}>
                 <PhonelinkLockIcon fontSize="small"/>&nbsp;<span>Available Devices</span>
                </button>
              </div>

          
    { showUnassignedDevicesTab &&  <>
        <TableContainer className="container table">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Serial Id</TableCell>
                <TableCell>Device Name</TableCell>
                <TableCell>Assign</TableCell>
              </TableRow>
            </TableHead>
        

          {loading && sparedevices.length<1 && <CircularProgress/>}
          {!loading && sparedevices.length<1 && <p>No Spare Device Available !</p>}

            <TableBody>
              {sparedevices && sparedevices.map((item, index) => {
                return (
                  <TableRow hover key={index} role="checkbox" tabIndex={-1}>
                    <TableCell className="table-data">{item._id}</TableCell>
                    <TableCell className="table-data">
                      {item.name}
                    </TableCell>
                    <TableCell className="table-data">
                    
                      <span >
                        <Button variant="outlined" onClick={()=>handleAssign(item._id)}>Assign</Button>
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
        
          </Table>
        </TableContainer>
      </> }


    { !showUnassignedDevicesTab &&  <>
        <TableContainer className="container table">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Serial Id</TableCell>
                <TableCell>Device Name</TableCell>
                <TableCell>Assigned On</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
            </TableHead>
        

          {loading && !devices.length && <CircularProgress/>}
          {!loading && !devices.length && <p>No Device Allotted</p>}

            <TableBody>
              {devices.length>0 && devices.map((item, index) => {
                return (
                  <TableRow hover key={index} role="checkbox" tabIndex={-1}>
                    <TableCell className="table-data">{item._id}</TableCell>
                    <TableCell className="table-data">
                      {item.name}
                    </TableCell>{" "}
                    <TableCell className="table-data">{ dayjs(item.lastUpdated).format('DD/MM/YYYY  hh:mm:A') }</TableCell>
                    <TableCell className="table-data">
                    
                      <span >
                        <DeleteIcon onClick={()=>handleDelete(item)} color="secondary"/>
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
        
          </Table>
        </TableContainer>
      </> }
      </div>
    </Dialog>
  </>
  
  
    )}


    // <CustomModal show={showModal} handleClose={handleClose}>
    //   <section id="hero-Msd">
    
    //  <div className="row main-div-modal">
    //       <div className="col-md-6 m-auto center-div text-center">
    //         <div className="p-2 center-div">
    //           <h2>
    //             <button
    //               onClick={handleClose}
    //               className="btn close-btn  rounded-circle float-right"
    //             >
    //               <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                 <circle cx="20" cy="20" r="20" fill="#011627"/>
    //                 <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    //                 <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    //               </svg>
    //             </button>
    //           </h2>
    //           <div className="mt-3">
    //             <h6 className="mtd-heading">
    //               Manage Devices 
    //             </h6>
    //           </div>


    //           <div className="d-flex justify-content-between">

    //           <button className="btn btn-add-device mt-4 mb-3 float-left" onClick={() => setshowUnassignedDevicesTab(false)}>
    //               <i className="fas fa-plus-circle"></i>&nbsp;<span>Assigned Device</span>
    //             </button>

    //             <button className="btn  btn-add-device mt-4 mb-3  btn-available-device" onClick={() => setshowUnassignedDevicesTab(true)}>
    //              <PhonelinkLockIcon fontSize="small"/>&nbsp;<span>Available Devices</span>
    //             </button>
    //           </div>
            
            



    // { showUnassignedDevicesTab &&  <>
    //     <TableContainer className="container table">
    //       <Table stickyHeader aria-label="sticky table">
    //         <TableHead>
    //           <TableRow>
    //             <TableCell>Device Id</TableCell>
    //             <TableCell>Device Name</TableCell>
    //             <TableCell>Assign</TableCell>
    //           </TableRow>
    //         </TableHead>
        

    //       {loading && sparedevices.length<1 && <CircularProgress/>}
    //       {!loading && sparedevices.length<1 && <p>No Spare Device Available !</p>}

    //         <TableBody>
    //           {sparedevices && sparedevices.map((item, index) => {
    //             return (
    //               <TableRow hover key={index} role="checkbox" tabIndex={-1}>
    //                 <TableCell className="table-data">{item._id}</TableCell>
    //                 <TableCell className="table-data">
    //                   {item.name}
    //                 </TableCell>
    //                 <TableCell className="table-data">
                    
    //                   <span >
    //                     <Button variant="outlined" onClick={()=>handleAssign(item._id)}>Assign</Button>
    //                   </span>
    //                 </TableCell>
    //               </TableRow>
    //             );
    //           })}
    //         </TableBody>
        
    //       </Table>
    //     </TableContainer>
    //   </> }


    // { !showUnassignedDevicesTab &&  <>
    //     <TableContainer className="container table">
    //       <Table stickyHeader aria-label="sticky table">
    //         <TableHead>
    //           <TableRow>
    //             <TableCell>Device Id</TableCell>
    //             <TableCell>Device Name</TableCell>
    //             <TableCell>Assigned On</TableCell>
    //             <TableCell>Remove</TableCell>
    //           </TableRow>
    //         </TableHead>
        

    //       {loading && !devices.length && <CircularProgress/>}
    //       {!loading && !devices.length && <p>No Device Allotted</p>}

    //         <TableBody>
    //           {devices.length>0 && devices.map((item, index) => {
    //             return (
    //               <TableRow hover key={index} role="checkbox" tabIndex={-1}>
    //                 <TableCell className="table-data">{item._id}</TableCell>
    //                 <TableCell className="table-data">
    //                   {item.name}
    //                 </TableCell>{" "}
    //                 <TableCell className="table-data">{ dayjs(item.lastUpdated).format('DD/MM/YYYY  hh:mm:A') }</TableCell>
    //                 <TableCell className="table-data">
                    
    //                   <span >
    //                     <DeleteIcon onClick={()=>handleDelete(item._id)} color="secondary"/>
    //                   </span>
    //                 </TableCell>
    //               </TableRow>
    //             );
    //           })}
    //         </TableBody>
        
    //       </Table>
    //     </TableContainer>
    //   </> }

    //         </div>
    //       </div>
    //     </div>

    
    //   </section>
    // </CustomModal>