
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import { PermIdentity } from "@material-ui/icons";

export default function ManageSchoolAdmin() {
  const [loading, setLoading] = useState(false);

  return (
    <section id="hero-Msd">
      <div className="row main-div">
        <div className="col-md-9 m-auto  text-center">
          <div className='p-2 center-div'>
            <h2>
              <button className="btn close-btn  rounded-circle float-right">
                <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#011627"/>
                      <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
              </button>
            </h2>
            <div
            className="dashboard-heading ml-3 p-3 text-left"
            style={{ marginRight: "20px" }}
          >
            <h6>
              Manage School Admins
              <button className="btn btn-add-device float-right ">
                <i className="fas fa-plus-circle"></i> {"  "}
                <span>Add New</span>
              </button>
             
            </h6>
          </div>

            <div>
            <TableContainer className="container table">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell> Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell className="table-data">1</TableCell>
                    <TableCell className="table-data">
                      _______
                    </TableCell>{" "}
                    <TableCell className="table-data">Yes</TableCell>{" "}
                    <TableCell className="table-data">
                 
                      <i className="far fa-eye icon-size-20"></i> {"  "}
                      <i className="fas fa-edit icon-size-20"></i>
                    </TableCell>
                  </TableRow>
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell className="table-data">1</TableCell>
                    <TableCell className="table-data">
                      _______
                    </TableCell>{" "}
                    <TableCell className="table-data">Yes</TableCell>{" "}
                    <TableCell className="table-data">
                 
                      <i className="far fa-eye icon-size-20"></i> {"  "}
                      <i className="fas fa-edit icon-size-20"></i>
                    </TableCell>
                  </TableRow>
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell className="table-data">1</TableCell>
                    <TableCell className="table-data">
                      _______
                    </TableCell>{" "}
                    <TableCell className="table-data">Yes</TableCell>{" "}
                    <TableCell className="table-data">
                 
                      <i className="far fa-eye icon-size-20"></i> {"  "}
                      <i className="fas fa-edit icon-size-20"></i>
                    </TableCell>
                  </TableRow>
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell className="table-data">1</TableCell>
                    <TableCell className="table-data">
                      _______
                    </TableCell>{" "}
                    <TableCell className="table-data">Yes</TableCell>{" "}
                    <TableCell className="table-data">
                 
                      <i className="far fa-eye icon-size-20"></i> {"  "}
                      <i className="fas fa-edit icon-size-20"></i>
                    </TableCell>
                  </TableRow>
               
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}
