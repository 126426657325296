import React, { useState } from 'react'
import { createSuper } from './api'
import { useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useRecoilState, useRecoilValue } from "recoil";
import getCookie, { setAdminCookie, userState } from "../../Atom";
import { useEffect } from 'react';


const styles = {
    formBody: {
        backgroundColor: '#fff',
        padding: '10px',
        borderRadius: '10px'
    }
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CreateSuperAdmin() {
    const [myUser, setmyUser] = useRecoilState(userState);
    const [snackBarShow, setSnackBarShow] = useState('');

    useEffect(()=>{
        const _cookie = getCookie('recoil-persist')
        if(!_cookie){
          logoutHandler()
        }else{
            const user = JSON.parse(_cookie)['KT_app']
            setmyUser(user)
    }
    })

    const logoutHandler=async()=>{
        setTimeout(()=>{
        //   setmyUser({
        //     fName: null,
        //     userId: null,
        //     token: null,
        //     lName: null,
        //   })
          setAdminCookie({
            fName:null,
            lName:null,
            token:null,
            userId:null
          })
          let hostname= window.location.host;
        //   console.log(hostname,window.location.protocol)
          window.location.assign(`${window.location.protocol}//${hostname}/super-admin`)   
        })  
      }

    const history = useHistory()
    const [user, setuser] = useState({
        "fName": "",
        "lName": "",
        "email": "",
        "password": ""
    })
    const [loading, setloading] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true)
        const res = await createSuper(user,myUser.token);
        setloading(false)
        if (res.error) {
            alert("Admin Creation Error!")
        } else {
            setSnackBarShow(true)
            setTimeout(() => {
                history.push('/users')
            }, 2000);
            setuser({
                "fName": "",
                "lName": "",
                "email": "",
                "password": ""
            })
        }
        
    }

    const SnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarShow(false);
    }


    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <form className="form" onSubmit={e => handleSubmit(e)} style={styles.formBody}>
                <h5>Create Super Admin</h5>
                <input type="text" className='form-control' placeholder="First Name" onChange={(e) => { setuser({ ...user, 'fName': e.target.value }) }} required /><br />
                <input type="text" className='form-control' placeholder="Last Name" onChange={(e) => { setuser({ ...user, 'lName': e.target.value }) }} required /><br />
                <input type="email" className='form-control' placeholder="Email" onChange={(e) => { setuser({ ...user, 'email': e.target.value }) }} required /><br />
                <input type="password" className='form-control' placeholder="Password" onChange={(e) => { setuser({ ...user, 'password': e.target.value }) }} required /><br />
                {!loading && <input type="submit" className='form-control btn btn-warning' value="Create" />}
                {loading && <input type="submit" className='form-control btn btn-warning' value="Creating" disabled />}
            </form>
            <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                <Alert onClose={SnackClose} severity={'success'} sx={{ width: '100%' }}>
                    {'Super admin created!'}
                </Alert>
            </Snackbar>
        </div>
    )
}

