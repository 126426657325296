import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import getCookie, { setTeacherCookie, teacherState } from "../../Atom";
import { useRecoilState, useRecoilValue } from "recoil";
import Tooltip from '@mui/material/Tooltip';
import ReactPaginate from 'react-paginate';
import { useHistory } from "react-router-dom";
import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUsersforStudent } from '../School-Districts/api';
import Group_299 from "../../assets/images/Group_299.png";
import Group_332 from "../../assets/images/Group_332.png";
import Group_305 from "../../assets/images/Group_305.png";
import key from "../../assets/images/pass2.png";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { getAllClassby, createStudent, deleteStudent, editStudent } from '../School-Districts/api';
import './userCss.css'
// import Tooltip from '@mui/material/Tooltip';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function User(props) {

  const history = useHistory();
  let search = window.location.search;
  let paramse = new URLSearchParams(search);
  let params = useParams();
  const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
  const [snackBarShow, setSnackBarShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [currPage, setCurrPage] = useState(0);
  const [paged, setPaged] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [noOfPages, setNoOfPages] = useState(0);
  const [query, setQuery] = useState("");
  const [pageLimit, setPageLimit] = useState(25);
  const [open, setOpen] = useState(false);
  const [myUser, setmyUser] = useState(teacherState);
  const [allStudent, setAllStudent] = useState([]);
  const [teacherId, setTeacherId] = useState();
  const [studentId, setStudentId] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [upPwd, setUpPwd] = useState(false);
  const [loginId, setloginId] = useState("");
  const [password, setPassword] = useState("");
  const [classId, setClassId] = useState('');
  const [allClasses, setAllClasses] = useState([]);
  const [selectStudent, setSelectStudent] = useState(null);
  const [classID, setClassID] = useState('');
  const [editLoginIdState, setEditLoginIdState] = useState('');

    const [oldName, setoldName] = useState(false);
    const [newID, setNewID] = useState('');
    const [successOpen, setSuccessOpen] = useState(false);
    const [successMode,setSuccessMode] = useState(0);
    const [className,setClassName] = useState('');


  const [dataNewStudent, setDataNewStudent] = useState({
    "fName": "",
    "lName": "",
    "email": "",
    "password": ""
  });

  const [studentTouched, setStudentTouched] = useState({
    "loginId": false,
    "password": false,
  });

  const logoutHandler = async () => {
    setTimeout(() => {
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })
      setTeacherCookie({
        fName: null,
        userId: null,
        token: null,
        lName: null,
      })
      let hostname = window.location.host;
      // console.log(hostname, window.location.protocol)
      window.location.assign(`${window.location.protocol}//${hostname}/teachers`)
    })
  }

  const ValidationForm = (type) => {
    if (type == 'add') {
      if (
        (loginId.trim() == '' || loginId == null) ||
        (password.trim() == '' || password == null || password.length < 8) ||
        classId.length < 1) {
        return true;
      } else {
        return false;
      }
    } else {
      if ((loginId.trim() == '' || loginId == null) ||
        classId.length < 1) {
        return true;
      } else {
        return false;
      }
    }
  }

  const editingStudent = async () => {
    let res;
    if (editLoginIdState) {
      if (loginId && loginId.length >= 3) {res = await editStudent(classId, studentId, { loginId: loginId }, myUser.token);setSuccessMode(2);setNewID(loginId.trim())}
      else feedBackMsg(true, 'error', 'Login ID should be atleast 3 characters long!')
    }
    else {
      if (!upPwd && loginId && loginId.length < 4) return feedBackMsg(true, 'error', 'Login ID should be atleast 3 characters long!')
      else {res = await editStudent(classId, studentId, { loginId: loginId }, myUser.token);setSuccessMode(2);setNewID(loginId.trim())};
      if (upPwd && password && password.length < 8) return feedBackMsg(true, 'error', 'Password should be atleast 8 characters long!')
      else {res = await editStudent(classId, studentId, { password: password }, myUser.token);setSuccessMode(3)}
    }
    if (res) {
      if (res.error) {
        if (res.data.statusCode == 401) {
          logoutHandler();
        }
        else {
          feedBackMsg(true, 'error', res.data.msg);
        }
      } else {
        feedBackMsg(true, 'success', res.res.data.msg);
        // setSuccessOpen(true)
        getUsers(paged, pageLimit, query)
      }
      handleClose();
    }
  }


  const feedBackMsg = (snackShow, snackSeverity, snackMessage) => {
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const handleClose = () => {
    setOpen(false);
    setAddMode(false);
    setEditMode(false);
    setDeleteMode(false);
    setSelectStudent(null);
    setloginId('');
    setClassId('');
    setPassword('');
    setClassID('');
    setClassId('');
    setEditLoginIdState(false)
    setUpPwd(false)
  }

  const SnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    feedBackMsg(false, '', '');
  }

  useEffect(() => {
    const _cookie = getCookie('recoil-persist')
    const user = _cookie ? JSON.parse(_cookie)['teacher_v1'] : {}
    setmyUser({ ...user, 'page': "users" });
    props.page('users')
    setTeacherId(user?.userId)
    // setmyUser({ ...myUser, 'page': "users" });
    let urlPage = parseInt(paramse.get('page')) || 0;
    let urlLimit = parseInt(paramse.get('limit')) || 25;
    let urlSearch = parseInt(paramse.get('query')) || '';

    history.push({
      pathname: `/users/`,
      search: '?page=' + urlPage + `&limit=${urlLimit}&query=${query}`
    })
    setPaged(urlPage)
    getUsers(urlPage, urlLimit, urlSearch,user);
    GetallClass(user)
  }, [0]);

  const handleChangeClass = (event) => {
    // console.log(event.target.value)
    setClassId(event.target.value);
    let selectedClass = allClasses.filter(e => e._id == event.target.value)[0];
    // console.log(selectedClass);
    setClassID(selectedClass.classID);
    setClassName(selectedClass.name);
  }

  const getUsers = async (pages, limits, query,user=myUser) => {
    let res = await getUsersforStudent(user?.userId, pages, limits, query, user.token);
    if (res.error) {
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {
      // console.log(res.res.data.data);
      setAllStudent(res.res.data.data.student)
      let divisor = parseInt(res.res.data.data.studentcount / limits);
      let remainder = (res.res.data.data.studentcount % limits == 0) ? 0 : 1;
      setNoOfPages(divisor + remainder)
      setCurrPage(pages);

    }
  }

  const addStudent = async () => {
    setoldName(loginId.trim());
    let data = { loginId: loginId.trim(), password: password.trim(), classID: classID };
    // console.log(data);
    let res = await createStudent(classId, data, myUser.token);
    // console.log(res);
    if (res.error) {
      // alert((res.data.msg!=undefined)?res.data.msg:'Error adding Student. Try Again');
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
      // handleCloseAddModal();
      handleClose();
    } else {
      feedBackMsg(true, 'success', res.res.data.msg);
      // setSuccessMode(1);
      // setSuccessOpen(true);
      // handleCloseAddModal();
      getUsers(paged, pageLimit, query);
      handleClose();
    }
    // handleClose();

  };

  const deleteHandler = async (studentId) => {
    // console.log(studentId)
    let res = await deleteStudent(classId, studentId, myUser.token);
    if (res.error) {
      // alert("Error deleting student. Try again");
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
      handleClose();
    } else {
      feedBackMsg(true, 'success', res.res.data.msg);
      // setSuccessMode(4);
      // setSuccessOpen(true);
      getUsers(paged, pageLimit, query);
      handleClose();
    }

  };

  const GetallClass = async (user) => {
    let res = await getAllClassby(user.token);
    if (res.error) {
      if (res.data.statusCode == 401) {
        logoutHandler();
      }
      else {
        feedBackMsg(true, 'error', res.data.msg);
      }
    } else {
      // console.log(res.res.data.data);
      setAllClasses(res.res.data.data.sclass);
    }

  }

  const handleChangePage = (event, newPage) => {
    setPaged(event.selected);

    getUsers(event.selected, pageLimit, query);
    history.push({
      pathname: `/users`,
      search: '?page=' + event.selected + `&limit=${pageLimit}&query=${query}`
    })
  }

  const closeDialog = ()=>{
    setoldName('');
    setSuccessOpen(false);
    setSuccessMode(0);
  }

  return (
    <main style={{ position: "absolute", width: "100%", top: "20%" }} >
      <div className="container px-4 pt-3">
        <div className="upper-section px-60">
          <div className="breadcrumb-menu py-2">
            <Link to={'/'}>Teacher Panel </Link><i className="fas fa-angle-double-right"></i><a className='a'>User</a>
          </div>
          <div className="text-blue maintext"><h2 className='h2'>User List</h2></div>
          <div className="row">
            <div className="col-md-9">
              <div className="card-header px-0"><h3 className='h3'>Manage Users here</h3></div>
            </div>
            <div className="col-md-3 text-end d-flex justify-content-end">
              <button type="button" onClick={() => {
                setOpen(true); setAddMode(true);
              }} className="btn btn-primary add-btn px-0 btn-add-size fonted fontAdjust-500" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">Add New Student &nbsp;&nbsp;<img style={{ height: "25px", width: "auto" }} src={Group_305} /></button>

            </div>
          </div>
          <br />
          <div className="d-flex justify-content-end align-items-end">

            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={noOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handleChangePage}
              containerClassName="pagination"
              activeClassName="activePage active"
              forcePage={currPage} />

          </div>
        </div>

        <div className=" ps-3 pb-5 class-table class-table1" >

          <div className="table-section student-tab" >
            <div className=" lower-section p-5" >
              <table className="table w-100" style={{ backgroundColor: 'transparent' }}>
                <thead className="mb-5 fonted">
                  <tr>
                    <th scope="col" className="col1 pb-4 fonted" style={{ backgroundColor: 'transparent' }}>UserType</th>
                    <th scope="col" className="col1 pb-4 fonted" style={{ backgroundColor: 'transparent' }}>Login ID</th>
                    <th scope="col" className="col1 pb-4 fonted" style={{ backgroundColor: 'transparent' }}>Created On</th>
                    <th scope="col" className="col1 pb-4 fonted" style={{ backgroundColor: 'transparent' }}>Actions</th>
                  </tr>
                </thead>
                <tbody className="table-body" style={{ backgroundColor: 'transparent' }}>
                  {allStudent.length > 0 && allStudent.map((item, index) => {
                    return (
                      <tr className="noBord">
                        <td className="text-end fonted">{'Student'}</td>
                        <td className="class-btn fonted text-purple"> {item.loginId}</td>
                        <td className="class-btn fonted text-purple"> {new Date(item.createdAt).toLocaleDateString()} {new Date(item.createdAt).toLocaleTimeString()}</td>
                        <td className='class-btn fonted d-flex justify-content-center'>
                          
                            <div className="px-3">
                              <Tooltip title="Edit LoginId" placement="top">
                                <Link onClick={() => {
                                  setOpen(true); setEditLoginIdState(true); setStudentId(item._id); setloginId(item.loginId);
                                  setClassId(item.sclass._id);;setoldName(item?.loginId)
                                }}>
                                  <img src={Group_332} className="ps-3" />
                                </Link>
                              </Tooltip>
                            </div>
                            <div className="px-3">
                              <Tooltip title="Change Password" placement="top">
                                <Link onClick={() => { setOpen(true); setUpPwd(true); setEditMode(true); setStudentId(item._id); setloginId(item.loginId); setClassId(item.sclass._id);setoldName(item?.loginId) }}>
                                  <img src={key} className="ps-3" />
                                </Link>
                              </Tooltip>
                            </div>
                            <div className="px-3">
                              <Tooltip title="Delete" placement="top">
                                <Link onClick={() => { setOpen(true); setDeleteMode(true);;setoldName(item?.loginId); setStudentId(item._id); setloginId(item.loginId); setClassId(item.sclass._id); }}>
                                  <img src={Group_299} className="ps-3" />
                                </Link>
                              </Tooltip>
                            </div>
                          
                        </td>
                      </tr>

                    )
                  })}
                </tbody>
                {/* <tbody className="table-body" style={{backgroundColor:'transparent'}}>
                          {allStudent.length > 0 && allStudent.map((item, index) => {
                                return (
                                    <tr>
						      	<td className="text-end d-flex justify-content-end">{'Student'}</td>
                                  <td><div className="d-flex className-btn justify-content-center ps-5 text-purple">{item.loginId}</div></td>
						      	<td><div className="d-flex className-btn justify-content-center ps-5 text-purple">{new Date(item.createdAt).toLocaleDateString()} {new Date(item.createdAt).toLocaleTimeString()}</div></td>
						      	<td>
						      	<div className="d-flex className-btn justify-content-end">
							      	<div className="px-4r">
                          <Link >
                          
                          <img src={Group_332} className="ps-3"/></Link>
							      	</div>
                      <div className="px-4r"> 
                          <Link >
                          
                          <img src={key} className="ps-3"/></Link>
							      	</div>
							      	<div className="px-4r">
							      		<Link>
                      
                        <img src={Group_299} className="ps-3"/></Link>
							      	</div>
							    </div>
                  
						      	</td>
						    </tr>
                                )})}
						    
                           
					  </tbody> */}
              </table>
            </div>
            <div className="col-lg-3c">
            </div>

          </div>
        </div>
      </div>

      <Dialog handleClose={handleClose} open={open} PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none"
        },
      }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable teacher-div">
        <div className="modal-content" style={{ width: '400px' }}>
          <div className="modal-header-n d-flex justify-content-end">
            <button type="button" onClick={handleClose} class="btn-close" data-bs-dismiss="modal" aria-label="Close"><CloseIcon /></button>
          </div>
          <form>
            <div className="modal-body px-5">
              <div className="mb-0">
                <label
                  for="recipient-name"
                  className="col-form-label">
                  {upPwd ? 'Change Password' : (addMode) ? 'Add Student' : (editMode || editLoginIdState) ? 'Edit Student' : 'Delete Student'}
                </label>
                {addMode ? <>
                  <br />
                  {/* <br/> */}
                  <Box sx={{ minWidth: 100, border: 'none' }}>
                    <FormControl fullWidth >
                      <InputLabel id="demo-simple-select-label">Class</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={classId}
                        label="Class"
                        sx={{ height: '50px' }}
                        onChange={handleChangeClass}
                      >
                        {allClasses.map((item, index) => {
                          return (
                            <MenuItem className='xyz' value={item._id}>{item.name}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    {allClasses.length == 0 ? <span style={{ color: 'red' }}>No Class is added</span> : null}
                  </Box>
                  <br />
                  <input type="text" className="form-control1 pt-3" value={loginId}
                    placeholder="Login Id"
                    onChange={(e) => setloginId(e.target.value)}
                    onBlur={e => { setStudentTouched({ ...studentTouched, loginId: true }); setloginId(e.target.value.trim()) }} />
                  <br />
                  <br />
                  <input type="text" className="form-control1 pt-3 "
                    value={password}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={(e) => setPassword(e.target.value.trim())}
                  />
                  {/* <br /> */}
                  <small style={{ color: 'red' }}>Password should be upto 8 characters or more</small>
                </> : (editLoginIdState) ? <>
                  <input type="text" className="form-control1 pt-3" value={loginId}
                    placeholder="Login Id"
                    onChange={(e) => setloginId(e.target.value)}
                    onBlur={e => { setStudentTouched({ ...studentTouched, loginId: true }); setloginId(e.target.value.trim()) }} />
                </> : (editMode) ? <>
                  <input type="text" className="form-control1 pt-3 w-100"
                    value={password}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={(e) => setPassword(e.target.value.trim())} style={{ width: "80%", color: "#262526" }}
                  />
                  <br />
                  <small style={{ color: 'red' }}>Password should be upto 8 characters or more</small>
                </> : (deleteMode) ? <>
                  {/* <h5 class="modal-title1" id="exampleModalLabel">Delete Student</h5> */}
                  <p className='p'></p>
                  <label for="recipient-name" class="col-form-label1">Are you sure you want to delete {loginId}?</label>
                </> : null}
              </div>
            </div>
            <div class="modal-footer px-4">
              {/* <button type="button"  class="bntn btn-pitch">Create Student</button> */}
              {(addMode || editMode || editLoginIdState) ? <>
                <button type="button" className="btn btn-pitch"
                  onClick={() => {
                    if (upPwd) {
                      if (password.length < 8) {
                        feedBackMsg(true, 'error', 'Password should be upto 8 or more characters');
                      }
                      else {
                        editingStudent()
                      }
                    }
                    else {
                      if (addMode) addStudent()
                      else editingStudent()
                    }
                  }}
                  disabled={(addMode) ? ValidationForm('add') : ValidationForm('edit')}>
                  {(addMode) ? 'Create' : 'Update'}
                </button>
              </> : (deleteMode) ? <>
                <button type="button" className="btn btn-pitch" onClick={() => deleteHandler(studentId)}>
                  Delete
                </button>
              </> : null}
            </div>
          </form>
        </div>
      </Dialog>

      <Dialog
        onClose={closeDialog}
        open={successOpen}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none"
          },
        }}
        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="modal-dialog modal-dialog-centered teacher-div">
        
        <div class="modal-content" style={{width:'400px'}}>
            <div class="modal-header-n ms-auto pt-3 pb-0 d-flex justify-content-end">
            <button type="button" class="btn-close" onClick={closeDialog} data-bs-dismiss="modal" aria-label="Close"><CloseIcon /></button>
            </div>
            <div class="modal-body px-5">
              <div class="mb-0">
                {(successMode==1)?<>
                  <label for="recipient-name" class="col-form-label"><span>{oldName}</span>: Add Student ID <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                  <p class="submitted py-3 p">Student ID {oldName} successfully added to class {className}</p>
                </>
                :
                (successMode==2)?<>
                <label for="recipient-name" class="col-form-label"><span>{oldName}</span>: Edit Student ID <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                <p class="submitted py-3 p">Student ID successfully changed from "{oldName}" to "{newID}"</p>
                </>:(successMode==3)?<>
                  <label for="recipient-name" class="col-form-label"><span>{oldName}</span>: Edit Student Password <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                  <p class="submitted py-3 p">Student {oldName} password successfully changed</p>
                </>:<>
                <label for="recipient-name" class="col-form-label"><span>{oldName}</span>: Delete <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                <p class="submitted py-3 p">{oldName} successfully deleted</p>
                </>}
              </div>
            </div>
          </div>
      </Dialog>

      {/*<Dialog open={open} onClose={handleClose} PaperProps={{
    style: {
      backgroundColor: "transparent",
      boxShadow: "none"
    },
  }}
 aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">

    <div class="modal-content">
      <div class="modal-header-n d-flex justify-content-end">
        
        <button type="button" class="btn-close" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close"><CloseIcon/></button>
      </div>
      {editState?<>
      
        <form onSubmit={e => editSubmit(e)}>
      <div class="modal-body px-4 pb-2">
       
          <div class="">
            <label for="recipient-name" class="col-form-label ps-4 fonted pad-">Edit Class Name</label>
            <input type="text" class="modal-form-control px-4" id="recipient-name" required placeholder="Test_Class" value={newEditName} onChange={e => { setnewEditName(e.target.value) }} 
                        onBlur={e => { setnewEditName(e.target.value.trim()) }}/>
          </div>
          
         
        
      </div>
      <div class="modal-footer px-4">
       
        <button type="submit" class="bntn btn-pitch fonted" name="btn" value="Submit" id="submitBtn" data-bs-toggle="modal" data-bs-target="#confirm-submit">Update</button>
      </div>
      </form>
      
      </>:<>
       <form onSubmit={e => deleteHandler(e)}>
      <div class="modal-body px-5 py-0">
       
          <div class="mb-0">
            <h5 class="modal-title1 font-WeightAdjust" id="exampleModalLabel">Delete Message</h5>
            <label for="recipient-name" class="col-form-label1 fonted">Are you sure you want to delete {currentEdit?.name}?</label>
            
          </div>
          
         
        
      </div>
      <div class="modal-footer1 py-4 st-btn px-5">
        <button type="submit" class="btn btn-pitch fonted" name="btn" value="Submit" id="submitBtn" data-bs-toggle="modal" data-bs-target="#confirm-submit">Delete</button>
        <button type="button" class="btn text-purple fonted" onClick={handleClose} data-bs-dismiss="modal"><span class="text-purple">Cancel</span></button>
      </div>
      </form>
      </>}
  
</div>
</Dialog> */}

      <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form action="action.php" id="my-updateForm" method="post" enctype="multipart/form-data" onsubmit="openModal()">
              <div class="modal-body px-4 pb-2">

                <div class="">
                  <label for="recipient-name" class="col-form-label ps-4">Edit Class Name</label>
                  <input type="text" class="modal-form-control px-4" id="recipient-name" placeholder="Test_Class" />
                </div>



              </div>
              <div class="modal-footer px-4">

                <button type="submit" class="btn btn-pitch" name="btn" value="Submit" id="submitBtn" data-bs-toggle="modal" data-bs-target="#confirm-submit">Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal fade bottom" tabindex="-1" role="dialog" id="my-deletedModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header ms-auto pt-3 pb-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body px-5">

              <div class="mb-0">
                <label for="recipient-name" class="col-form-label"><span>Test_Class 1</span>: Delete <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                <p class="submitted py-3 p">Test_Class 1 successfully deleted</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="modal fade bottom" tabindex="-1" role="dialog" id="my-updateModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header ms-auto pt-3 pb-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body px-5">

              <div class="mb-0">
                <label for="recipient-name" class="col-form-label"><span>Test_Class 1</span>: Edit Class Name <i class="fas fa-check check ms-3" aria-hidden="true"></i></label>
                <p class="submitted py-3 p">Class successfully changed from ------ to ——</p>
              </div>



            </div>
          </div>
        </div>
      </div>
      <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
        <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>

      {/* <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
        <Alert onClose={SnackClose} sx={{ width: '100%', backgroundColor: snackBarSeverity.toLowerCase() == 'success' ? 'white' : 'red', color: snackBarSeverity.toLowerCase() == 'success' ? '#6f42c1' : 'white' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar> */}
    </main>

  )


}


{/* <section id="hero-school ">
      <br />
      <div className="d-flex justify-content-end align-items-end">

        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={noOfPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handleChangePage}
          containerClassName="pagination"
          activeClassName="activePage active"
          forcePage={currPage} />

      </div>
      <div className="col-lg-8 col-md-7 col-sm-12">

        <TextField sx={{ minWidth: 250, width: 600, background: 'white' }} value={query} id="outlined-search"
          label="Search" type="search"
          onChange={(e) => {
            setQuery(e.target.value);
            getUsers(0, pageLimit, e.target.value)
          }}
          onBlur={(e) => { setQuery(e.target.value.trim()) }} />
      </div>
      <div className="d-flex justify-content-end align-items-end">
        <button className="btn btn-add float-right" onClick={() => {
          setOpen(true); setAddMode(true);
        }}>
          <i className="fas fa-plus-circle"></i> {"  "}
          <span>Add Student</span>
        </button>
      </div>
      <div style={{ width: '100%' }} className="scrollLimits">
        <table className="table">
          <thead>
            <tr>
              <th>UserType</th>
              <th>Login ID</th>
              <th>Created On</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allStudent.map((item, index) => {
              return (
                <tr key={index} hover role="checkbox" tabIndex={-1}>
                  <td className="">
                    {'Student'}
                  </td>
                  <td>
                    {item.loginId}
                  </td>{" "}
                  <td className="">
                    {new Date(item.createdAt).toLocaleDateString()} {new Date(item.createdAt).toLocaleTimeString()}
                  </td>
                  <td className="">
                    <Tooltip title="Edit" placement="top">
                      <span style={{ cursor: "pointer" }} onClick={() => {

                        setOpen(true); setEditLoginIdState(true); setStudentId(item._id); setloginId(item.loginId);
                        setClassId(item.sclass._id);
                      }}>
                        <i className="fas fa-edit icon-size-30" ></i>
                      </span>
                    </Tooltip>
                    &nbsp;

                    <Tooltip title="Edit" placement="top">
                      <span style={{ cursor: "pointer" }} onClick={() => {

                        setOpen(true); setEditMode(true); setStudentId(item._id); setloginId(item.loginId);
                        setClassId(item.sclass._id);
                      }}>
                        <i className="fas fa-key icon-size-30" ></i>
                      </span>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title="Delete" placement="top">
                      <span style={{ cursor: "pointer" }} onClick={() => {

                        setOpen(true); setDeleteMode(true); setStudentId(item._id);
                        setloginId(item.loginId); setClassId(item.sclass._id);
                      }}>

                        <i className="fas fa-trash-alt icon-size-30" ></i>
                      </span>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Dialog handleClose={handleClose} open={open} fullWidth>
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h6" style={{ fontWeight: '600' }}>
              {(addMode) ? 'Add' : (editMode || editLoginIdState) ? 'Edit' : 'Delete'} Student
            </Typography>
            <IconButton onClick={handleClose} >
              <CloseIcon style={{ border: '2px solid black', fontSize: '30px' }} />
            </IconButton>
          </Grid>
        </DialogTitle>
        {addMode ? <>
          <div className="col-md-9 text-left col-9 m-auto">
            <Box sx={{ minWidth: 100 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Class</InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={classId}
                  label="Class"

                  onChange={handleChangeClass}
                >

                  {allClasses.map((item, index) => {
                    return (
                      
                      <MenuItem className='xyz' value={item._id}>{item.name}</MenuItem>
                  
                    )
                  })}


                </Select>

              </FormControl>
              {allClasses.length == 0 ? <span style={{ color: 'red' }}>No Class is added</span> : null}
            </Box>

            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control" value={loginId}
                placeholder="Login Id"
                onChange={(e) => setloginId(e.target.value)}
                onBlur={e => { setStudentTouched({ ...studentTouched, loginId: true }); setloginId(e.target.value.trim()) }}
              />
              {(loginId.trim() == '' && loginId) ? <span style={{ color: 'red' }}>LoginId is required</span> : null}
            </div>
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control" value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onBlur={e => { setStudentTouched({ ...studentTouched, password: true }); setPassword(e.target.value.trim()) }}
              />
              {(password.trim() == '' && password) ? <span style={{ color: 'red' }}>Password is required</span> : null}
            </div>
          </div>
        </> : (editLoginIdState) ? <>
          <div className="col-md-9 text-left  col-9 m-auto">
            <div className="form-group mt-4   p-20">
              <input
                type="text"
                className="form-control" value={loginId}
                placeholder="Login Id"
                onChange={(e) => setloginId(e.target.value)}
                onBlur={(e) => setloginId(e.target.value.trim())}
              />
            </div>
          </div>
        </> : (editMode) ?
          <>
            <div className="col-md-9 text-left  col-9 m-auto">
              <div className="form-group mt-4   p-20">
                <h6>
                  Change Password for {loginId}
                </h6>
                <input
                  value={password}
                  type="text"
                  className="form-control"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={(e) => setPassword(e.target.value.trim())}
                />
              </div>
            </div>
          </> :
          (deleteMode) ? <>
            <div className="col-md-9 text-left  col-9 m-auto">

              <div className="form-group mt-4   p-20">
                <input readOnly
                  value={loginId}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          </> : null}

        <div className="col-md-9 text-left  col-9 m-auto">
          <div className="form-group p-20">
            {(addMode || editMode || editLoginIdState) ? <>
              <button type="button" className="cta-btn" onClick={() => {
                if (addMode) addStudent()
                else editingStudent()
              }} disabled={(addMode) ? ValidationForm('add') : ValidationForm('edit')}>
                {(addMode) ? 'Add' : 'Edit'}
              </button>
            </> : (deleteMode) ? <>
              <button type="button" className="cta-btn-red" onClick={() => deleteHandler(studentId)}>
                Delete
              </button>
            </> : null}
          </div>
        </div>
      </Dialog>


      <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
        <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    

    </section> */}