

import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getInvitationDetails, postAdminThroughInvitation } from "./api";
import notFound from './2953962.jpg'
import { useHistory } from "react-router-dom";
export default function AddSchoolDestrictAdmin() {
  const [loading,setLoading]=useState(false)
  const history=useHistory()
  const [valid,setValid]=useState(false)
  const [fromemail,setFromEmail]=useState("")
  const [email,setEmail]=useState("")
  const [fname,setfName]=useState("")
  const [lname,setlName]=useState("")
  const [password,setPassword]=useState("")
  const [invitationDetails,setInvitationDetails]=useState({})
const location=useLocation()
// console.log(location)
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const query=useQuery()
// console.log(query.get('token'))
useEffect(()=>{
getInvitation()
},[0])
const getInvitation=async()=>{
  let res=await getInvitationDetails(location.search)
if(res.error){
  // console.log(res)
}
else{
  setValid(res.res.data.data.inviteValid)
  if(res.res.data.data.inviteValid){
    setInvitationDetails(res.res.data.data.invite)
    setFromEmail(res.res.data.data.invite.from.email)
    setEmail(res.res.data.data.invite.toEmail)
  }
 // setSchoolDetails(res.res.data.data.school)
}
}
const submitHandler=async()=>{
  if(email && fname && lname && password){
    setLoading(true)
    let data={
      email:email,
      fName:fname,
      lName:lname,
      password:password
    }
let res=await postAdminThroughInvitation(location.search,data)
if(res.error){
  setLoading(false)

  alert("Can't Add Admin")

}
else{
  setLoading(false)

  alert('Success')
  history.push('/')
}
  }
  else{
    alert('Fill Up Details')
  }
}
  return (
    <section id="hero-Msd">
      <div className="row main-div">
        <div className="col-md-6 m-auto center-div text-center">
          {valid?
        <div className='p-2 center-div'>
            <h2>
              <button className="btn close-btn  rounded-circle float-right">
                <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#011627"/>
                      <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
              </button>
            </h2>
            <div className="mt-3 text-left">
              <h6>Invitation for {invitationDetails.forUserType}</h6>
              <br/>
              <h6>From {fromemail}</h6>
              <br/>
              <h6>To {invitationDetails.toEmail}</h6>
            </div>
            <div className="col-md-7 text-left col-9 m-auto">
              <div className="form-group mt-4   p-20">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                    onChange={(e)=>setfName(e.target.value)}
                />
              </div>

              <div className="form-group mt-4   p-20">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                   onChange={(e)=>setlName(e.target.value)}
                />
              </div>
              <div className="form-group mt-4   p-20">
                <input
                defaultValue={email}
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  onChange={(e)=>setEmail(e.target.value)}

                />
              </div>
              <div className="form-group mt-4   p-20">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Password"
                    onChange={(e)=>setPassword(e.target.value)}
                />
              </div>
            
              <div className='form-group'>
      <button type='submit'  className='cta-btn' onClick={submitHandler}>
  {loading?<CircularProgress/> : "Add" }     
      </button>
    </div>
            </div>
          </div>
    :
    <div className='p-2 center-div text-center '>
    <img src={notFound} alt='notfound' className='notfound-img'/>
    <h1>Invitation InValid</h1></div>}    </div>
      </div>
    </section>
  );
}
