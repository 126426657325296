
import { CircularProgress } from "@material-ui/core";
import { useState } from "react";

export default function UploadDevice() {
  const [loading, setLoading] = useState(false);

  return (
    <section id="hero-Msd">
      <div className="row main-div">
        <div className="col-md-6 m-auto center-div text-center">
          <div className='p-2'>
            <h2>
              <button className="btn close-btn  rounded-circle float-right">
                <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#011627"/>
                      <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
              </button>
            </h2>
            <div className="mt-3">
              <h6>Add Device</h6>
            </div>
            <div className="col-md-9 text-left  col-9 m-auto">
              <div onClick={()=>document.getElementById('file-device').click()} className="form-group mt-4   p-20">
                <span >
                <input
                  type="text"
                  disabled
                  className="form-control"
                  placeholder="Select A File"
                  //  onChange={(e)=>setEmail(e.target.value)}
                  />
                  </span>
                  <input
                  type="file"
                  id='file-device'
                  className="form-control d-none"
                  placeholder="Select A File"
                  //  onChange={(e)=>setEmail(e.target.value)}
                />

              </div>
           
              <div className="form-group p-20">
                <button type="submit" className="cta-btn">
                  {loading ? <CircularProgress /> : "Add"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
