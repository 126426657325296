import axios from "axios";
import { baseUrl } from "../Base_url";



const userData = JSON.parse(localStorage.getItem('recoil-persist'))
let token = ""



if (userData && userData.KT_app) {
    token = userData.KT_app.token;
  }





export const getAllAssignedDevicesForTeacher = async (id, sid,tid, token) => {
  try {
    const res = await axios.get(`${baseUrl}/admin/school-district/${id}/school/${sid}/teacher/${tid}/device/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
}

export const SpareAssignedDevicesForTeacher = async (id, sid, tid,token) => {
  try {
    const res = await axios.get(`${baseUrl}/admin/school-district/${id}/school/${sid}/teacher/${tid}/device/spare`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};




export const SpareAssignedDevicesTeacher = async (id, sid,tid) => {
  try {
    const res = await axios.get(`${baseUrl}/admin/school-district/${id}/school/${sid}/teacher/${tid}/device/spare`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const SpareAssignedDevicesSchool = async (id, sid) => {
  try {
    const res = await axios.get(`${baseUrl}/admin/school-district/${id}/school/${sid}/device/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const AssignDevice = async (id, sid,tid, deviceId, token) => {
  try {
    const res = await axios.post(`${baseUrl}/admin/school-district/${id}/school/${sid}/teacher/${tid}/device/assign?deviceId=${deviceId}`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const DeleteAssignedDevice = async (id, sid,tid, deviceId,history, token) => {
  try {
    const res = await axios.delete(`${baseUrl}/admin/school-district/${id}/school/${sid}/teacher/${tid}/device/assign?deviceId=${deviceId}&historyId=${history}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

