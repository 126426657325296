import { useState } from "react";
import "./Navbar.css";
import MenuSharpIcon from "@material-ui/icons/MenuSharp";
import { Link, useHistory } from "react-router-dom";
import { schoolAdminState } from "../../Atom";
import { useRecoilState } from "recoil";
export default function NavBar() {


  let [toggle, setToggle] = useState(false);

  const [myUser, setmyUser] = useRecoilState(schoolAdminState);

  let history = useHistory();

  const logoutHandler = () => {
    setmyUser(null);
window.location.reload('/schools')
  };

  const openDropdown = () => {
    setToggle(!toggle);
    setTimeout(() => {
      setToggle(false);
    }, 3000);
  };

  return (
    <section id="hero-navbar">
      <div></div>
      <div className="navbar-div row">
        <div className="col-2 m-2 d-flex">
          <button className="btn-bell-icon nav-bars mr-2 p-2 ">
            <MenuSharpIcon
              onClick={() => {
                let navmenu = document.getElementById("navmenu");
                navmenu.classList.toggle("open");
              }}
            />
          </button>
          <button className="btn-bell-icon">
            <i className="far fa-bell"></i>
          </button>
        </div>
        <div className="col-md-5"></div>

        {/* If user is logged in show this data  */}
        {myUser && (
          <div className="col-5 ml-5 pr-0 text-right col-md-4 user-responive">
            <div className="mb-2">
              <h6 className="mr-5 textCap">
                {myUser.fName} {myUser.lName}
              </h6>
              <span>{myUser.email}</span>
              <button
                className="btn btn-dropdown dropleft"
                onClick={() => openDropdown()}
              >
                {toggle ? (
                  <i className="fas fa-caret-up"></i>
                ) : (
                  <i className="fas fa-caret-down"></i>
                )}
              </button>
            </div>

            {toggle && (
              <ul className="ul-for-submenu">
                <Link className="dropdown-item" to="/profile">
                  Profile
                </Link>
                <a className="dropdown-item"  onClick={logoutHandler}>
                  Logout
                </a>
              </ul>
            )}
          </div>
        )}
      </div>
      <div className="nav-menu" id="navmenu">
        <ul className="list-group m-2 text-left mt-1 ">
          <li
            className="list-group-item "
            id="dashboard"
            style={{ border: "none" }}
          >
            <Link to="/">
              {" "}
              <i className="fas fa-laptop p-1"></i>
              <span> DashBoard</span>
            </Link>
          </li>
          <li
            className="list-group-item "
            id="school-District"
            style={{ border: "none" }}
          >
            <Link to="/school-Districts">
              <i className="fas fa-map-marker-alt p-1"></i>{" "}
              <span> School Districts</span>
            </Link>
          </li>
          <li
            className="list-group-item "
            id="devices"
            style={{ border: "none" }}
          >
            <Link to="/devices">
              <i className="fas fa-mobile-alt p-1"></i>
              <span> Devices</span>
            </Link>
          </li>
          <li
            className="list-group-item "
            id="devices"
            style={{ border: "none" }}
          >
            <Link to="/#">
              <i className="fas fa-user p-1"></i>
              <span> profile</span>
            </Link>
          </li>
          <li
            className="list-group-item "
            id="devices"
            style={{ border: "none" }}
          >
            <Link to="/#">
              <i className="fas fa-user p-1"></i>
              <span> logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
}
