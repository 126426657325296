import NavBar from "../Navbar/Navbar";
import SideBar from "../Sidebar/SideBar";
import "./DashBoard.css";
// import "../../../index.css";
import getCookie, {setAdminCookie, userState} from '../../Atom'
import { useRecoilState } from "recoil";
import { getAnalytics } from "./api";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function DashBoard() {
  const [ myUser , setmyUser ] = useState(userState)
  const [ dashboardAnalytics , setdashboardAnalytics ] = useState({});
  const [snackBarShow, setSnackBarShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [cirloading, setcirLoading] = useState(true);

  const logoutHandler=async()=>{
    console.log('This called')
    setTimeout(()=>{
      setAdminCookie({
        fName:null,
        lName:null,
        token:null,
        userId:null
      })
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })
     
      let hostname= window.location.host;
      // console.log(hostname,window.location.protocol)
      window.location.assign(`${window.location.protocol}//${hostname}/super-admin`)   
    })  
  }

  const feedBackMsg =(snackShow,snackSeverity,snackMessage)=>{
    setSnackBarShow(snackShow);
    setSnackBarSeverity(snackSeverity);
    setSnackBarMessage(snackMessage)
  }

  const SnackClose = (event, reason) =>{
    if (reason === 'clickaway') {
      return;
    }
    
    feedBackMsg(false,'','');
  }


  useEffect(()=>{
    const _cookie = getCookie('recoil-persist')
    if(!_cookie){
      logoutHandler()
    }else{
    const user = JSON.parse(_cookie)['KT_app']
    setmyUser(user)
    getAnalytic(user);
    }
      },[0])
      const getAnalytic=async(user)=>{
        let res=await getAnalytics(user?.token)
        if(res.error){
          if(res.data.statusCode==401){
          
            logoutHandler();
          }
          else{
            feedBackMsg(true,'error',res.data.msg);
          }
        }
        else{
          // console.log(res.res.data.data)
          // setSnackBarShow(true);
          // setSnackBarSeverity('success')
          // setSnackBarMessage(res.res.data.msg);
          setdashboardAnalytics(res.res.data.data);
          setcirLoading(false);
      //    setDevices(res.res.data.data)
        }
      }
  return (
    
    <section id="admin-dashboard" >
      
        <div >
          <NavBar />


          <div className="dashboard-heading">
            <h1 className="span-h1">Good Morning, Admin</h1>
            <span>Manage Your DashBoard here</span>
          </div>

        {!cirloading?<>
          
          <div className="dashboard-notification-cards">
            <div className="notification-card-admin p-3">
              <h2>{dashboardAnalytics.schoolDistrict}</h2>

              <h6 className="ml-2 notification-subHeading span-h4 ">
                <Link to="/school-districts">School Districts</Link>
              </h6>
            </div>
            <div className="notification-card-admin p-3">
            <h2>{dashboardAnalytics.school}</h2>


              <h6 className="ml-2 notification-subHeading span-h4">Schools</h6>
            </div>
            <div className="notification-card-admin p-3">
            <h2>{dashboardAnalytics.teacher}</h2>


              <h6 className="ml-2 notification-subHeading span-h4">Teachers</h6>
            </div>
            <div className="notification-card-admin p-3">
            <h2>{dashboardAnalytics.student}</h2>


              <h6 className="ml-2 notification-subHeading span-h4">Students</h6>
            </div>
            <div className="notification-card-admin p-3">
            <h2>{dashboardAnalytics.device}</h2>


              <h6 className="ml-2 notification-subHeading span-h4">Devices</h6>
            </div>
            <div className="notification-card-admin p-3">
            <h2>{dashboardAnalytics.experiment}</h2>


              <h6 className="ml-2 notification-subHeading span-h4">
                Experiments
              </h6>
            </div>
          </div>
        </>:<>
            <Box className="circularProgress">
              <CircularProgress />
            </Box>
          </>}
        </div>

                  <Snackbar open={snackBarShow} autoHideDuration={3000} onClose={SnackClose}>
                    <Alert onClose={SnackClose} severity={snackBarSeverity} sx={{ width: '100%' }}>
                        {snackBarMessage}
                    </Alert>
                  </Snackbar>
  
    </section>
  );
}
