import axios from "axios";
import { baseUrl } from "../Base_url";

export const changePass = async (data, token) => {
    try {
      const res = await axios.put(`${baseUrl}/teacher/accounts/change-password`,data,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res.data };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };