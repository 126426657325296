import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { inviteDetails, registerInvite } from './api'
import './style.css'
function Invitation({ location }) {
    const history = useHistory();

    const [emailToken, setEmailToken] = useState(null)
    const [valid, setValid] = useState(true)
    const [info, setinfo] = useState(null);
    const [userType, setUserType] = useState('');
    const [userRegistered, setUserRegistered] = useState(false)

    const [clicked, setClicked] = useState(false)
    const [user, setUser] = useState({ lName: "", fName: "", password: "",mobile:"" })
    useEffect(() => {
        setEmailToken(location.search.slice(7))
        getUserInvitationDetails(location.search.slice(7))
    }, [])
    const getUserInvitationDetails = async (emailToken) => {
        const res = await inviteDetails(emailToken)
        if (res.error) {
            setValid(false)
        } else {
            setinfo(res.res.data.data.invite)
            setUserType(res.res.data.data.invite.forUserType)
            // console.log(res.res.data.data.invite)
            // setinfo(res.res.data.data.invite)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setClicked(true);
        setUser({ ...user, fName:user.fName.trim()});
        setUser({ ...user, lName:user.lName.trim()});
        setUser({ ...user, mobile:user.mobile.trim()});
        setUser({ ...user, password:user.password.trim()});
        // setUser({ ...user, fName:user.fName.trim()});
        const res = await registerInvite(emailToken, user)
        // console.log(res);
        setClicked(false)
        if (res.error) {
            alert(res.data.msg)
        } else {
            setUserRegistered(true)
        }
    }

    const validInvite =() =>{
        if(userType=='SchoolDistrictAdmin'){
            if(user.fName.trim()=='' || user.lName.trim()=='' || user.mobile.trim()=='' || user.password.trim()=='') return true; else return false;
        }else{
            if(user.fName.trim()=='' || user.lName.trim()=='' || user.password.trim()=='') return true; else return false;
        }
    }

    const manageRedirect = () => {
        switch(info.forUserType){
            case 'Admin':
                history.push("/super-admin")
                break;
            case 'SchoolDistrictAdmin': 
                history.push("/school-district")
                break;
            case 'SchoolAdmin': 
                history.push("/schools")
                break;
            case 'Teacher': 
                history.push("/teachers")
                break;
        }
    }
    return (
        <div className="invitation-body">
            {!userRegistered && <>
                {valid && <form className="form" onSubmit={e => handleSubmit(e)}>
                    {info && <div className="welcom-msg">
                        <p>You are invited to <span>{info.forBody.name}</span> for the role of <span>{info.forUserType}</span>.</p>
                    </div>}
                    <div className="form-group">
                        <label>First Name</label>
                        <input type="text" className="form-control" value={user.fName} required
                            onBlur={(e) => { setUser({ ...user, fName: e.target.value.trim() }) }}
                            onChange={(e) => { setUser({ ...user, fName: e.target.value }) }} />
                    </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input type="text" className="form-control" value={user.lName} required 
                            onBlur={(e) => { setUser({ ...user, lName: e.target.value.trim() }) }}
                            onChange={(e) => { setUser({ ...user, lName: e.target.value}) }} />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" value={user.password} required
                            onBlur={(e) => { setUser({ ...user, password: e.target.value.trim() }) }}
                            onChange={(e) => { setUser({ ...user, password: e.target.value}) }} />
                    </div>
                    {(userType=='SchoolDistrictAdmin')?
                    <>
                    <div className="form-group">
                        <label>Mobile</label>
                        <input type="text" className="form-control" value={user.mobile} maxLength="16" required
                            onBlur={e=>setUser({ ...user, mobile: e.target.value.trim()})}
                            onChange={(e) => { setUser({ ...user, mobile: e.target.value}) }} />
                        <small style={{color:'red'}}>Enter Mobile Number with country Code</small>
                    </div>
                    
                    </>
                    :null}
                    <input type="submit" className="form-control btn" value="Register"  />
                </form>}
                {!valid && <div className="error-box">
                    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.60253 15.1367C9.41053 15.1367 9.21853 15.0637 9.07253 14.9167C8.77953 14.6237 8.77953 14.1497 9.07253 13.8567L13.8645 9.06472C14.1575 8.77172 14.6315 8.77172 14.9245 9.06472C15.2175 9.35772 15.2175 9.83172 14.9245 10.1247L10.1325 14.9167C9.98653 15.0637 9.79453 15.1367 9.60253 15.1367Z" fill="currentColor" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3965 15.1396C14.2045 15.1396 14.0125 15.0666 13.8665 14.9196L9.07046 10.1226C8.77746 9.82965 8.77746 9.35565 9.07046 9.06265C9.36446 8.76965 9.83846 8.76965 10.1305 9.06265L14.9265 13.8596C15.2195 14.1526 15.2195 14.6266 14.9265 14.9196C14.7805 15.0666 14.5875 15.1396 14.3965 15.1396Z" fill="currentColor" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.665 3.5C5.135 3.5 3.5 5.233 3.5 7.916V16.084C3.5 18.767 5.135 20.5 7.665 20.5H16.333C18.864 20.5 20.5 18.767 20.5 16.084V7.916C20.5 5.233 18.864 3.5 16.334 3.5H7.665ZM16.333 22H7.665C4.276 22 2 19.622 2 16.084V7.916C2 4.378 4.276 2 7.665 2H16.334C19.723 2 22 4.378 22 7.916V16.084C22 19.622 19.723 22 16.333 22Z" fill="currentColor" />
                    </svg>
                    <p>Invitation Expired!</p>
                </div>}
            </>}
            {userRegistered && <div className="success-box">
                    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.44019 12L10.8142 14.373L15.5602 9.62695" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.74976 12C2.74976 18.937 5.06276 21.25 11.9998 21.25C18.9368 21.25 21.2498 18.937 21.2498 12C21.2498 5.063 18.9368 2.75 11.9998 2.75C5.06276 2.75 2.74976 5.063 2.74976 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <p>Registration Successfull</p>
                    <button className="btn btn-success" onClick={manageRedirect}>Login</button>
                </div>}
        </div>
    )
}

export default Invitation
