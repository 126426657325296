import { useState } from "react";
import "./Navbar.css";
import MenuSharpIcon from "@material-ui/icons/MenuSharp";
import { Link, useHistory} from "react-router-dom";
import getCookie, {setAdminCookie, userState} from '../../Atom';
// import {passwordChangeSuperAdmin} from './School-Districts/api';
import { useRecoilState } from "recoil";
import Dialog from '@mui/material/Dialog';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

export default function NavBar() {
  let [toggle, setToggle] = useState(true);
  const [ myUser , setmyUser ] = useState(userState);
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  let history=useHistory();

  useEffect(()=>{
    const _cookie = getCookie('recoil-persist')
    if(!_cookie){
      logoutHandler()
    }else{
    const user = JSON.parse(_cookie)['KT_app']
    setmyUser(user)
    }
      },[0])
  
const logoutHandler=async()=>{
  setTimeout(()=>{
    // setmyUser({
    //   fName: null,
    //   userId: null,
    //   token: null,
    //   lName: null,
    // })
    setAdminCookie({
      fName:null,
      lName:null,
      token:null,
      userId:null
    })
    let hostname= window.location.host;
    console.log(hostname,window.location.protocol)
    window.location.assign(`${window.location.protocol}//${hostname}/super-admin`)   
  })  

//   const ChangePasswordForm = async()=>{
//     let res;
//     if(password.trim()==confirmpassword.trim()){
//         let req = {password:password,email:myUser.email};
//         res = await passwordChangeSuperAdmin(req,myUser.token);
//         if(res.error){
//             alert('Failed to update password');
//             handleEditClose();
//             handleClose()
//         }else{
//             alert('Password successfully changed');
//             handleEditClose()
//             // handleClose();
//             logoutHandler();
//         }
//     }else{
//         alert('Password does not match');
//     }
// }

const handleClose = ()=>{
  setEditOpen(false);
}
const handleEditClose = ()=>{
  setEditOpen(false);
  setPassword('');
  setConfirmPassword('');
  // handleEditClose()
}
const validateForm=()=>{
  if(password.trim().length==0 || confirmpassword.trim().length==0){
      return true;
  }else{
      return false;
  }
  }

}

  return (
    <section id="hero-navbar">
      <div></div>
      <div className="navbar-div">
        <div className="d-flex">
          <button className="btn-bell-icon nav-bars mr-2 p-2 ">
            <MenuSharpIcon
              onClick={() => {
                let navmenu=document.getElementById('navmenu')
                navmenu.classList.toggle('open') ;
                
              }}
            />
          </button>
          <button className="btn-bell-icon">
            <i className="far fa-bell"></i>
          </button>
        </div>
        <div className="text-right user-responive">
          <h6 className='mr-5 textCap'>{myUser.fName} {myUser.lName}</h6>
          <span>{myUser.email}</span>
          <button
            className="btn btn-dropdown dropleft"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {" "}
            <i className="fas fa-caret-down"></i>
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <Link className="dropdown-item" to="/create-super">
              Create New Super
            </Link>
            {/* <div className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>setEditOpen(true)}>
              Create New Super
            </div> */}
            <Link className="dropdown-item" to="/profile">
              Profile
            </Link>
            <Link className="dropdown-item" onClick={logoutHandler}>
              Logout
            </Link>
          </div>
        </div>
      </div>
      <div className="nav-menu" id="navmenu">

    
        <ul  className="list-group m-2 text-left mt-1 ">
          <li
            className="list-group-item "
            id="dashboard"
            style={{ border: "none" }}
          >
            <Link to="/">
              {" "}
              <i className="fas fa-laptop p-1"></i>
              <span> DashBoard</span>
            </Link>
          </li>
          <li
            className="list-group-item "
            id="school-District"
            style={{ border: "none" }}
          >
            <Link to="/school-Districts">
              <i className="fas fa-map-marker-alt p-1"></i>{" "}
              <span> School Districts</span>
            </Link>
          </li>
          <li className="list-group-item " id="devices" style={{ border: "none" }}>
            <Link to="/devices">
              <i className="fas fa-mobile-alt p-1"></i>
              <span> Devices</span>
            </Link>
          </li>
          <li className="list-group-item " id="devices" style={{ border: "none" }}>
            <Link to="/#">
              <i className="fas fa-user p-1"></i>
              <span> profile</span>
            </Link>
          </li>
          <li className="list-group-item " id="devices" style={{ border: "none" }}>
            <Link to="/#">
              <i className="fas fa-user p-1"></i>
              <span> logout</span>
            </Link>
          </li>
        </ul>
        </div>

        {/* <Dialog handleClose={handleClose} open={editOpen} >
            <section id="hero-Msd">
        
          
                  <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                      <Typography variant="h6" style={{fontWeight:'600'}}>Add School</Typography>
                      <IconButton onClick={handleClose} >
                        <CloseIcon style={{border:'2px solid black',fontSize:'30px'}}/>
                      </IconButton>
                    </Grid>
                  </DialogTitle>
                  <div className="col-md-9 text-left  col-9 m-auto">
                    <div className="form-group mt-4   p-20">
                      <input
                        type="password"
                        value={password} placeholder="Password"
                        onChange={(e)=>{setPassword(e.target.value)}}
                        onBlur={(e)=>{setPassword(e.target.value.trim())}}
                      />
                    </div>
                    <div className="form-group mt-4   p-20">
                      <input type="password"
                        value={confirmpassword} placeholder=" Confirm Password"
                        onChange={(e)=>{setConfirmPassword(e.target.value)}}
                        onBlur={(e)=>{setConfirmPassword(e.target.value.trim())}}
                      />
                    </div>
                    <div className="form-group p-20">
                      <button onClick={ChangePasswordForm} disabled={validateForm()} type="button" className="cta-btn">
                        Change Password
                      </button>
                    </div>
                  </div>

          </section>
          </Dialog>         */}
    </section>
  );
}
