import axios from "axios";
import { baseUrl } from "../Base_url";

export const getAllAssignedDevicesForSchool = async (id, sid, token) => {
  try {
    const res = await axios.get(`${baseUrl}/admin/school-district/${id}/school/${sid}/device/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
}


export const SpareAssignedDevicesDistrict = async (id, token) => {
  try {
    const res = await axios.get(`${baseUrl}/admin/school-district/${id}/device/spare`,{
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const AssignDevice = async (id, sid, deviceId, token) => {
  try {
    const res = await axios.post(`${baseUrl}/admin/school-district/${id}/school/${sid}/device/assign?deviceId=${deviceId}`, null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};
export const DeleteAssignedDevice = async (id, sid, deviceId, token) => {
  try {
    const res = await axios.delete(`${baseUrl}/admin/school-district/${id}/school/${sid}/device/assign?deviceId=${deviceId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { error: false, res: res };
  } catch (e) {
    const err = { error: true, data: e.response.data.data };
    return err;
  }
};

