import { Button } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { useEffect, useState } from "react"
import showModal from "./showModal"


export default function Modal({message,color='red'}) {
    const [show,setShow]=useState(true)
    const handleClose=()=>{
        showModal(false)
}

  return (
  
  <div id='cusmodal'  className='cusmodal__wrap'>
                
                <div className='cusmodal text-center'>
                <Button
                    onClick={handleClose}
                    style={{ width: 60, height: 40, position: 'absolute', top: 0, right: 0 }}
                    className='close__btn'
                >
                    <Close height="20px" width="20px" className='close__icon' />
                </Button>
                  <br/>
                  <br/>
                  <br/>
                  <h4 className={color==='red'?'text-danger':'text-success'}>
                  {color==='red'?'Error':'Success'}
                  </h4>
                   <br/>
                  <span className={color==='red'?'text-danger':'text-success'}>
                      {message}
                  </span>
                </div> </div> 
                  )
}
