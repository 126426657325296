import axios from "axios";
import { baseUrl } from "../Base_url";

export const getAnalytics = async (token) => {
    try {
      const res = await axios.get(`${baseUrl}/admin/dashboard/entities-count`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      return { error: false, res: res };
    } catch (e) {
      const err = { error: true, data: e.response.data.data };
      return err;
    }
  };