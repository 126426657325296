import React, { useEffect, useState, useRef } from 'react'
import { getAnalysedData, getThisExperimentDetails } from './api'
import './style.css'
import { useRecoilState, useRecoilValue } from 'recoil'
import getCookie, { studentState } from '../../Atom'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, AreaChart, Area, Label, ResponsiveContainer } from 'recharts';
import NavBar from '../Navbar/Navbar'
import { useHistory } from "react-router-dom";
import Sign_in_image from '../../../teacher-admin-builds/assets/images/Sign_in_image.jpg'
import RangeCustom from '../../../school-admin-build/components/Analysis/Range';
import logo from '../../assets/images/logo.png';
import { Link, useParams } from 'react-router-dom'
import CanvasJSReact from '../../../assets/js/canvasjs-commercial-3.4/canvasjs.react'
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Analysis({ match }) {
    let history = useHistory();
    const range_slide_custom = useRef(null)
    const massQ = useRef(null)
    const paraQ = useRef(null)

    // const { token } = useRecoilValue(studentState)
    const [myUser, setmyUser] = useState(studentState)
    const [angle, setAngle] = useState(0)
    const [mass, setMass] = useState(300)
    const [para_area, setPara_area] = useState(0)

    const [experimentData, setExperimentData] = useState(null)
    const [calculatedData, setCalculatedData] = useState(null)

    const [thrustModified, setThrustModified] = useState([])
    const [thrustFiltered, setThrustFiltered] = useState([])
    const [bottle_pressure, setBottle_pressure] = useState([])
    const [exhaust_velocity, setExhaust_velocity] = useState([])
    const [massChange, setMassChange] = useState([])
    const [acceleration, setAcceleration] = useState([])
    const [velocity, setVelocity] = useState([])
    const [distance, setDistance] = useState([])
    const [height, setHeight] = useState([])
    const deviceWidth = window.innerWidth;
    const [clicked, setClicked] = useState(false)
    useEffect(() => {
        const _cookie = getCookie('recoil-persist')

        const user = JSON.parse(_cookie)['student']
        setmyUser(user)
        getBasicDetails(user)

    }, [])
    const getBasicDetails = async (user = myUser) => {

        const res = await getThisExperimentDetails(user?.token, match.params.id)
        if (res.error) {
            alert(`Something Went Wrong. Code: exp_geterr_${match.params.id}`)
        } else {
            // console.log(res.res.data.data.experiment)
            setExperimentData(res.res.data.data.experiment)
            await getPredictedData(res.res.data.data.experiment)
        }

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        getPredictedData()
    }
    const getPredictedData = async (expData = experimentData) => {
        setClicked(true)
        // const res = await getAnalysedData(range_slide_custom.current.state.values[0], massQ.current.value, paraQ.current.value, expData)
        const res = await getAnalysedData(angle, String(mass), String(para_area), expData)
        setClicked(false)
        if (res.error) {
            alert("Experiment Failed. Contact Admin.")
        } else {
            // console.log(res.res.data)
            setCalculatedData(res.res.data)
            setThrustFiltered(res.res.data.thrust)
            setBottle_pressure(res.res.data.bottle_pressure)
            setExhaust_velocity(res.res.data.exhaust_velocity)
            setMassChange(res.res.data.mass_of_rocket)
            setAcceleration(res.res.data.acceleration)
            setVelocity(res.res.data.velocity)
            setDistance(res.res.data.distance)
            setHeight(res.res.data.height)
        }
    }
    return (
        <div className='teacher-div'>
            <div className="index-bg fonted">
                <img className="class fit-cover" src={Sign_in_image} />

                <div className="login">
                    <NavBar onlyLogout={false} />
                    <main style={{ position: "absolute", width: "100%", top: "20%" }}>
                        <div class="container px-4 pt-3">
                            <div class="upper-section px-60">
                                <div class="breadcrumb-menu py-2">
                                    <Link to={'/'} >Students Panel </Link><i class="fas fa-angle-double-right"></i><a className='disable'>Analysis</a>
                                </div>

                            </div>
                            <div class="w-100"><div class=" pe-1">
                                <div class="lower-section1 pt-1 pb-5  mx-1">
                                    <div class="px-3">
                                        <div class="scroll-div-upper">
                                            <p><span class="main-text">Experiment data</span >&nbsp;<span class="sub-text ps-2">Manage and analyze experiments here</span></p>
                                        </div>
                                        <div class="row ps-2 pe-3">
                                            <div class="col-20 name-qunt text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title text-dark1">Name</h4>
                                                    <h2 class="quantity text-purple mb-0">{experimentData?.studentName}</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 name-qunt text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Bottle Volume</h4>
                                                    <h2 class="quantity mb-0">{experimentData?.bottleVolume} ml</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 name-qunt text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Fuel Volume</h4>
                                                    <h2 class="quantity mb-0">{experimentData?.fuelVolume} ml</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 name-qunt text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Nozzle Diameter</h4>
                                                    <h2 class="quantity mb-0">{experimentData?.nozzleDia} mm</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 name-qunt text-center ps-0 pe-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Pressure (st launch)</h4>
                                                    <h2 class="quantity mb-0">{experimentData?.pressure.toFixed(0)} psi</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div><br />
                                    <div class="calculator-section">
                                        <div class="px-3">
                                            <form onSubmit={e => handleSubmit(e)} id="form1">

                                                <div class="row">
                                                    <div class="col-4 py-2">
                                                        <label class="cal-titl form-label" for="amount">Angle of launch</label>


                                                        <div class="range">
                                                            <input id="rangeInput" type="range" value={angle} min="0" max="45" oninput="amount.value=rangeInput.value" onChange={e => setAngle(e.target.value)} required="true" />
                                                            <input class="ms-1" id="amount" type="number" value={angle} min="0" max="45" oninput="rangeInput.value=amount.value" required="true" onChange={e => setAngle(e.target.value)} />
                                                        </div>

                                                    </div>
                                                    <div class="col-8">
                                                        <div class="row">
                                                            <div class="col-4 py-2">
                                                                <label class="cal-titl" for="quantity">Dry Mass Of Rocket (gms)</label>
                                                                <input type="number" step="0.01" name="quantity" className='form-controlnum' value={mass} onChange={e => setMass(e.target.value)} id="quantity" required="true" />
                                                            </div>
                                                            <div class="col-4 py-2">
                                                                <label class="cal-titl" for="area">Parachute Area (m2)</label><input type="number" className='form-controlnum' step="0.01" id="area" name="area" value={para_area} onChange={e => setPara_area(e.target.value)} required="true" />
                                                            </div>
                                                            <div class="col-4 d-flex align-items-end">
                                                                <button type="submit" value="Submit" class="btn-submit mb-2">Calculate</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>


                                    <div class="px-3 mt-4">
                                        <span class="main-text">Analysis</span>
                                        <div class="row ps-2 pe-3">
                                            <div class="col-20 text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Total Thrust Time</h4>
                                                    <h2 class="quantity">{calculatedData?.total_thrust_time} sec</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Water Phase Length</h4>
                                                    <h2 class="quantity">{calculatedData?.water_phase_length} sec</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Total Impulse</h4>
                                                    <h2 class="quantity">{calculatedData?.total_impulse} N-sec</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Peak Thrust</h4>
                                                    <h2 class="quantity">{calculatedData?.peak_thrust} N</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 text-center ps-0 pe-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Apogee to Ground</h4>
                                                    <h2 class="quantity">{calculatedData?.apogee_to_ground} sec</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row pt-2 ps-2 pe-3">
                                            <div class="col-20 text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Peak Acceleration</h4>
                                                    <h2 class="quantity">{calculatedData?.peak_acceleration} m/s^2</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Peak Velocity</h4>
                                                    <h2 class="quantity">{calculatedData?.peak_velocity} m/s</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Max. Altitude</h4>
                                                    <h2 class="quantity">{calculatedData?.max_altitude} m</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 text-center pe-2 ps-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Distance Travelled</h4>
                                                    <h2 class="quantity">{calculatedData?.distance_travelled} m</h2>
                                                </div>
                                            </div>
                                            <div class="col-20 text-center ps-0 pe-0">
                                                <div class="bg-light1 py-1">
                                                    <h4 class="title">Total Flight</h4>
                                                    <h2 class="quantity">{calculatedData?.total_flight_time} sec</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {deviceWidth > 1110 ?
                                        <div className="desktop-view">
                                            {clicked ? <>
                                                <div className='analyse-blur'>
                                                    Calculating
                                                </div>
                                            </> : <>
                                                <div className="d-flex flex-wrap justify-content-between align-items-start ">
                                                    <div className="individual-graph thrust-chart-area-left-student">
                                                        <CanvasJSChart options={{
                                                            animationEnabled: false,
                                                            exportEnabled: true,
                                                            theme: "light1", // "light1", "dark1", "dark2"
                                                            height: 750,
                                                            title: {
                                                                text: "Thrust vs Time",
                                                                fontSize: 25,
                                                                fontWeight: "normal"
                                                            },
                                                            axisY: {
                                                                title: "Thrust (N)",
                                                                suffix: "N",
                                                                labelFontSize: 15,
                                                                titleFontSize: 18,

                                                            },
                                                            axisX: {
                                                                title: "Time",
                                                                suffix: "sec",
                                                                interval: 0.1,
                                                                labelFontSize: 15,
                                                                titleFontSize: 18,
                                                                gridThickness: 1,
                                                                gridColor: "#dbdbdb"
                                                            },
                                                            data: [{
                                                                type: "area",
                                                                toolTipContent: "{x} sec: {y} N",
                                                                dataPoints: thrustFiltered
                                                            }],
                                                            legend: {
                                                                fontSize: 10,
                                                            },
                                                        }}
                                                        />
                                                        {/* <ResponsiveContainer width="100%" height="100%" aspect="1.47">
                                <AreaChart width={300} height={240} data={thrustFiltered}
                                    margin={{ top: 5, right: 20, left: 1, bottom: 0 }}
                                >
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={1} />
                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={1} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="time" >
                                        <Label value="time (s)" offset={0} position="insideBottom" />
                                    </XAxis>
                                    <YAxis label={{ value: 'Thrust (N)', angle: -90, position: 'insideLeft' }} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="Thrust" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                </AreaChart>
                            </ResponsiveContainer> */}
                                                    </div>
                                                    <div className="thrust-chart-area-right-student d-flex flex-wrap">
                                                        <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                                            <CanvasJSChart options={{
                                                                animationEnabled: false,
                                                                exportEnabled: true,
                                                                theme: "light1", // "light1", "dark1", "dark2"
                                                                height: 227,
                                                                title: {
                                                                    text: "Exhaust Velocity vs Time",
                                                                    fontSize: 18,
                                                                    fontWeight: "normal"
                                                                },
                                                                axisY: {
                                                                    title: "Velocity (m/s)",
                                                                    suffix: "m/s"
                                                                },
                                                                axisX: {
                                                                    title: "Time",
                                                                    suffix: "sec",
                                                                    interval: 0.1,
                                                                    gridThickness: 1,
                                                                    gridColor: "#dbdbdb"
                                                                },
                                                                data: [{
                                                                    type: "line",
                                                                    toolTipContent: "{x} sec: {y} m/s",
                                                                    dataPoints: exhaust_velocity
                                                                }]
                                                            }}
                                                            />
                                                        </div>
                                                        <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                                            <CanvasJSChart options={{
                                                                animationEnabled: false,
                                                                exportEnabled: true,
                                                                theme: "light1", // "light1", "dark1", "dark2"
                                                                height: 227,
                                                                title: {
                                                                    text: "Pressure vs Time",
                                                                    fontSize: 18,
                                                                    fontWeight: "normal"
                                                                },
                                                                axisY: {
                                                                    title: "Pressure (kPa)",
                                                                    suffix: "kPa"
                                                                },
                                                                axisX: {
                                                                    title: "Time",
                                                                    suffix: "sec",
                                                                    interval: 0.1,
                                                                    gridThickness: 1,
                                                                    gridColor: "#dbdbdb"
                                                                },
                                                                data: [{
                                                                    type: "spline",
                                                                    toolTipContent: "{x} sec: {y} kPa",
                                                                    dataPoints: bottle_pressure
                                                                }]
                                                            }}
                                                            />
                                                        </div>
                                                        <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                                            <CanvasJSChart options={{
                                                                animationEnabled: false,
                                                                exportEnabled: true,
                                                                theme: "light1", // "light1", "dark1", "dark2"
                                                                height: 227,
                                                                title: {
                                                                    text: "Mass vs Time",
                                                                    fontSize: 18,
                                                                    fontWeight: "normal"
                                                                },
                                                                axisY: {
                                                                    title: "Mass (kg)",
                                                                    suffix: "kg"
                                                                },
                                                                axisX: {
                                                                    title: "Time",
                                                                    suffix: "sec",
                                                                    interval: 0.1,
                                                                    gridThickness: 1,
                                                                    gridColor: "#dbdbdb"
                                                                },
                                                                data: [{
                                                                    type: "area",
                                                                    toolTipContent: "{x} sec: {y} kg",
                                                                    dataPoints: massChange
                                                                }]
                                                            }}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="individual-graph mini-graph" style={{ flexBasis: '100%' }}>
                                                        <CanvasJSChart options={{
                                                            animationEnabled: false,
                                                            exportEnabled: true,
                                                            theme: "light1", // "light1", "dark1", "dark2"
                                                            title: {
                                                                text: "Acceleration vs Time",
                                                                fontSize: 18,
                                                                fontWeight: "normal"
                                                            },
                                                            axisY: {
                                                                title: "Acceleration (m/s^2)",
                                                                suffix: "m/s^2"
                                                            },
                                                            axisX: {
                                                                title: "Time",
                                                                suffix: "sec",
                                                                interval: 0.1,
                                                                gridThickness: 1,
                                                                gridColor: "#dbdbdb"
                                                            },
                                                            data: [{
                                                                type: "spline",
                                                                toolTipContent: "{x} sec: {y} m/s^2",
                                                                dataPoints: acceleration
                                                            }]
                                                        }}
                                                        />
                                                    </div>
                                                    <div className="individual-graph mini-graph" style={{ flexBasis: '49%' }}>
                                                        <CanvasJSChart options={{
                                                            animationEnabled: false,
                                                            exportEnabled: true,
                                                            theme: "light1", // "light1", "dark1", "dark2"
                                                            title: {
                                                                text: "Velocity vs Time",
                                                                fontSize: 18,
                                                                fontWeight: "normal"
                                                            },
                                                            axisY: {
                                                                title: "Velocity (m/s)",
                                                                suffix: "m/s"
                                                            },
                                                            axisX: {
                                                                title: "Time",
                                                                suffix: "sec",
                                                                interval: 1,
                                                                gridThickness: 1,
                                                                gridColor: "#dbdbdb"
                                                            },
                                                            data: [{
                                                                type: "spline",
                                                                toolTipContent: "{x} sec: {y} m/s",
                                                                dataPoints: velocity
                                                            }]
                                                        }}
                                                        />
                                                    </div>
                                                    <div className="individual-graph mini-graph" style={{ flexBasis: '49%' }}>
                                                        <CanvasJSChart options={{
                                                            animationEnabled: false,
                                                            exportEnabled: true,
                                                            theme: "light1", // "light1", "dark1", "dark2"
                                                            title: {
                                                                text: "Height vs Time",
                                                                fontSize: 18,
                                                                fontWeight: "normal"
                                                            },
                                                            axisY: {
                                                                title: "Height (m)",
                                                                suffix: "m"
                                                            },
                                                            axisX: {
                                                                title: "Time",
                                                                suffix: "sec",
                                                                interval: 1,
                                                                gridThickness: 1,
                                                                gridColor: "#dbdbdb"
                                                            },
                                                            data: [{
                                                                type: "spline",
                                                                toolTipContent: "{x} sec: {y} m",
                                                                dataPoints: height
                                                            }]
                                                        }}
                                                        />
                                                    </div>
                                                    {/* <div className="individual-graph mini-graph" style={{ flexBasis: '32.5%' }}>
                            <CanvasJSChart options={{
                                animationEnabled: false,
                                exportEnabled: true,
                                theme: "light1", // "light1", "dark1", "dark2"
                                title: {
                                    text: "Distance vs Time",
                                    fontSize: 18,
                                    fontWeight: "normal"
                                },
                                axisY: {
                                    title: "Distance (m)",
                                    suffix: "m"
                                },
                                axisX: {
                                    title: "Time",
                                    suffix: "sec",
                                    interval: 1,
                                    gridThickness: 1,
                                    gridColor: "#dbdbdb"
                                },
                                data: [{
                                    type: "spline",
                                    toolTipContent: "{x} sec: {y} m",
                                    dataPoints: distance
                                }]
                            }}
                            />
                        </div> */}

                                                </div>
                                            </>}
                                        </div>
                                        : deviceWidth <= 1110 && deviceWidth > 771 ?
                                            <div className="mac-view">
                                                {clicked ? <>
                                                    <div className='analyse-blur'>
                                                        Calculating
                                                    </div>
                                                </> : <>
                                                    <div className="d-flex flex-wrap justify-content-between align-items-start ">
                                                        <div className="individual-graph thrust-chart-area-left-student">
                                                            <CanvasJSChart options={{
                                                                animationEnabled: false,
                                                                exportEnabled: true,
                                                                theme: "light1", // "light1", "dark1", "dark2"
                                                                height: 750,
                                                                title: {
                                                                    text: "Thrust vs Time",
                                                                    fontSize: 25,
                                                                    fontWeight: "normal"
                                                                },
                                                                axisY: {
                                                                    title: "Thrust (N)",
                                                                    suffix: "N",
                                                                    labelFontSize: 15,
                                                                    titleFontSize: 18,

                                                                },
                                                                axisX: {
                                                                    title: "Time",
                                                                    suffix: "sec",
                                                                    interval: 0.1,
                                                                    labelFontSize: 15,
                                                                    titleFontSize: 18,
                                                                    gridThickness: 1,
                                                                    gridColor: "#dbdbdb"
                                                                },
                                                                data: [{
                                                                    type: "area",
                                                                    toolTipContent: "{x} sec: {y} N",
                                                                    dataPoints: thrustFiltered
                                                                }],
                                                                legend: {
                                                                    fontSize: 10,
                                                                },
                                                            }}
                                                            />
                                                        </div>

                                                        <div className="thrust-chart-area-right-student d-flex flex-wrap">
                                                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                                                <CanvasJSChart options={{
                                                                    animationEnabled: false,
                                                                    exportEnabled: true,
                                                                    theme: "light1", // "light1", "dark1", "dark2"
                                                                    height: 227,
                                                                    title: {
                                                                        text: "Exhaust Velocity vs Time",
                                                                        fontSize: 18,
                                                                        fontWeight: "normal"
                                                                    },
                                                                    axisY: {
                                                                        title: "Velocity (m/s)",
                                                                        suffix: "m/s"
                                                                    },
                                                                    axisX: {
                                                                        title: "Time",
                                                                        suffix: "sec",
                                                                        interval: 0.1,
                                                                        gridThickness: 1,
                                                                        gridColor: "#dbdbdb"
                                                                    },
                                                                    data: [{
                                                                        type: "line",
                                                                        toolTipContent: "{x} sec: {y} m/s",
                                                                        dataPoints: exhaust_velocity
                                                                    }]
                                                                }}
                                                                />
                                                            </div>
                                                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                                                <CanvasJSChart options={{
                                                                    animationEnabled: false,
                                                                    exportEnabled: true,
                                                                    theme: "light1", // "light1", "dark1", "dark2"
                                                                    height: 227,
                                                                    title: {
                                                                        text: "Pressure vs Time",
                                                                        fontSize: 18,
                                                                        fontWeight: "normal"
                                                                    },
                                                                    axisY: {
                                                                        title: "Pressure (kPa)",
                                                                        suffix: "kPa"
                                                                    },
                                                                    axisX: {
                                                                        title: "Time",
                                                                        suffix: "sec",
                                                                        interval: 0.1,
                                                                        gridThickness: 1,
                                                                        gridColor: "#dbdbdb"
                                                                    },
                                                                    data: [{
                                                                        type: "spline",
                                                                        toolTipContent: "{x} sec: {y} kPa",
                                                                        dataPoints: bottle_pressure
                                                                    }]
                                                                }}
                                                                />
                                                            </div>
                                                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                                                <CanvasJSChart options={{
                                                                    animationEnabled: false,
                                                                    exportEnabled: true,
                                                                    theme: "light1", // "light1", "dark1", "dark2"
                                                                    height: 227,
                                                                    title: {
                                                                        text: "Mass vs Time",
                                                                        fontSize: 18,
                                                                        fontWeight: "normal"
                                                                    },
                                                                    axisY: {
                                                                        title: "Mass (kg)",
                                                                        suffix: "kg"
                                                                    },
                                                                    axisX: {
                                                                        title: "Time",
                                                                        suffix: "sec",
                                                                        interval: 0.1,
                                                                        gridThickness: 1,
                                                                        gridColor: "#dbdbdb"
                                                                    },
                                                                    data: [{
                                                                        type: "area",
                                                                        toolTipContent: "{x} sec: {y} kg",
                                                                        dataPoints: massChange
                                                                    }]
                                                                }}
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                                            <CanvasJSChart options={{
                                                                animationEnabled: false,
                                                                exportEnabled: true,
                                                                theme: "light1", // "light1", "dark1", "dark2"
                                                                title: {
                                                                    text: "Acceleration vs Time",
                                                                    fontSize: 18,
                                                                    fontWeight: "normal"
                                                                },
                                                                axisY: {
                                                                    title: "Acceleration (m/s^2)",
                                                                    suffix: "m/s^2"
                                                                },
                                                                axisX: {
                                                                    title: "Time",
                                                                    suffix: "sec",
                                                                    interval: 0.1,
                                                                    gridThickness: 1,
                                                                    gridColor: "#dbdbdb"
                                                                },
                                                                data: [{
                                                                    type: "spline",
                                                                    toolTipContent: "{x} sec: {y} m/s^2",
                                                                    dataPoints: acceleration
                                                                }]
                                                            }}
                                                            />
                                                        </div>
                                                        <div className="individual-graph" style={{ flexBasis: '49%' }}>
                                                            <CanvasJSChart options={{
                                                                animationEnabled: false,
                                                                exportEnabled: true,
                                                                theme: "light1", // "light1", "dark1", "dark2"
                                                                title: {
                                                                    text: "Velocity vs Time",
                                                                    fontSize: 18,
                                                                    fontWeight: "normal"
                                                                },
                                                                axisY: {
                                                                    title: "Velocity (m/s)",
                                                                    suffix: "m/s"
                                                                },
                                                                axisX: {
                                                                    title: "Time",
                                                                    suffix: "sec",
                                                                    interval: 1,
                                                                    gridThickness: 1,
                                                                    gridColor: "#dbdbdb"
                                                                },
                                                                data: [{
                                                                    type: "spline",
                                                                    toolTipContent: "{x} sec: {y} m/s",
                                                                    dataPoints: velocity
                                                                }]
                                                            }}
                                                            />
                                                        </div>
                                                        <div className="individual-graph" style={{ flexBasis: '49%' }}>
                                                            <CanvasJSChart options={{
                                                                animationEnabled: false,
                                                                exportEnabled: true,
                                                                theme: "light1", // "light1", "dark1", "dark2"
                                                                title: {
                                                                    text: "Height vs Time",
                                                                    fontSize: 18,
                                                                    fontWeight: "normal"
                                                                },
                                                                axisY: {
                                                                    title: "Height (m)",
                                                                    suffix: "m"
                                                                },
                                                                axisX: {
                                                                    title: "Time",
                                                                    suffix: "sec",
                                                                    interval: 1,
                                                                    gridThickness: 1,
                                                                    gridColor: "#dbdbdb"
                                                                },
                                                                data: [{
                                                                    type: "spline",
                                                                    toolTipContent: "{x} sec: {y} m",
                                                                    dataPoints: height
                                                                }]
                                                            }}
                                                            />
                                                        </div>
                                                        {/* <div className="individual-graph" style={{ flexBasis: '32.5%' }}>
                        <CanvasJSChart options={{
                            animationEnabled: false,
                            exportEnabled: true,
                            theme: "light1", // "light1", "dark1", "dark2"
                            title: {
                                text: "Distance vs Time",
                                fontSize: 18,
                                fontWeight: "normal"
                            },
                            axisY: {
                                title: "Distance (m)",
                                suffix: "m"
                            },
                            axisX: {
                                title: "Time",
                                suffix: "sec",
                                interval: 1,
                                gridThickness: 1,
                                gridColor: "#dbdbdb"
                            },
                            data: [{
                                type: "spline",
                                toolTipContent: "{x} sec: {y} m",
                                dataPoints: distance
                            }]
                        }}
                        />
                    </div> */}
                                                    </div>
                                                </>}
                                            </div>
                                            :
                                            <div className="mobile-view">
                                                {clicked ? <>
                                                    <div className='analyse-blur'>
                                                        Calculating
                                                    </div>
                                                </> : <>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="individual-graph">
                                                                <CanvasJSChart options={{
                                                                    animationEnabled: false,
                                                                    exportEnabled: true,
                                                                    theme: "light1", // "light1", "dark1", "dark2"
                                                                    // height: 800,
                                                                    title: {
                                                                        text: "Thrust vs Time",
                                                                        fontSize: 25,
                                                                        fontWeight: "normal"
                                                                    },
                                                                    axisY: {
                                                                        title: "Thrust (N)",
                                                                        suffix: "N",
                                                                        labelFontSize: 15,
                                                                        titleFontSize: 18,

                                                                    },
                                                                    axisX: {
                                                                        title: "Time",
                                                                        suffix: "sec",
                                                                        interval: 0.1,
                                                                        labelFontSize: 15,
                                                                        titleFontSize: 18,
                                                                        gridThickness: 1,
                                                                        gridColor: "#dbdbdb"
                                                                    },
                                                                    data: [{
                                                                        type: "area",
                                                                        toolTipContent: "{x} sec: {y} N",
                                                                        dataPoints: thrustFiltered
                                                                    }],
                                                                    legend: {
                                                                        fontSize: 10,
                                                                    },
                                                                }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="individual-graph">
                                                                <CanvasJSChart options={{
                                                                    animationEnabled: false,
                                                                    exportEnabled: true,
                                                                    theme: "light1", // "light1", "dark1", "dark2"
                                                                    // height: 245,
                                                                    title: {
                                                                        text: "Exhaust Velocity vs Time",
                                                                        fontSize: 18,
                                                                        fontWeight: "normal"
                                                                    },
                                                                    axisY: {
                                                                        title: "Velocity (m/s)",
                                                                        suffix: "m/s"
                                                                    },
                                                                    axisX: {
                                                                        title: "Time",
                                                                        suffix: "sec",
                                                                        interval: 0.1,
                                                                        gridThickness: 1,
                                                                        gridColor: "#dbdbdb"
                                                                    },
                                                                    data: [{
                                                                        type: "line",
                                                                        toolTipContent: "{x} sec: {y} m/s",
                                                                        dataPoints: exhaust_velocity
                                                                    }]
                                                                }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="individual-graph" style={{ flexBasis: '100%' }}>
                                                                <CanvasJSChart options={{
                                                                    animationEnabled: false,
                                                                    exportEnabled: true,
                                                                    theme: "light1", // "light1", "dark1", "dark2"
                                                                    // height: 245,
                                                                    title: {
                                                                        text: "Pressure vs Time",
                                                                        fontSize: 18,
                                                                        fontWeight: "normal"
                                                                    },
                                                                    axisY: {
                                                                        title: "Pressure (kPa)",
                                                                        suffix: "kPa"
                                                                    },
                                                                    axisX: {
                                                                        title: "Time",
                                                                        suffix: "sec",
                                                                        interval: 0.1,
                                                                        gridThickness: 1,
                                                                        gridColor: "#dbdbdb"
                                                                    },
                                                                    data: [{
                                                                        type: "spline",
                                                                        toolTipContent: "{x} sec: {y} kPa",
                                                                        dataPoints: bottle_pressure
                                                                    }]
                                                                }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="individual-graph">
                                                                <CanvasJSChart options={{
                                                                    animationEnabled: false,
                                                                    exportEnabled: true,
                                                                    theme: "light1", // "light1", "dark1", "dark2"
                                                                    // height: 245,
                                                                    title: {
                                                                        text: "Mass vs Time",
                                                                        fontSize: 18,
                                                                        fontWeight: "normal"
                                                                    },
                                                                    axisY: {
                                                                        title: "Mass (kg)",
                                                                        suffix: "kg"
                                                                    },
                                                                    axisX: {
                                                                        title: "Time",
                                                                        suffix: "sec",
                                                                        interval: 0.1,
                                                                        gridThickness: 1,
                                                                        gridColor: "#dbdbdb"
                                                                    },
                                                                    data: [{
                                                                        type: "area",
                                                                        toolTipContent: "{x} sec: {y} kg",
                                                                        dataPoints: massChange
                                                                    }]
                                                                }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="individual-graph">
                                                                <CanvasJSChart options={{
                                                                    animationEnabled: false,
                                                                    exportEnabled: true,
                                                                    theme: "light1", // "light1", "dark1", "dark2"
                                                                    title: {
                                                                        text: "Acceleration vs Time",
                                                                        fontSize: 18,
                                                                        fontWeight: "normal"
                                                                    },
                                                                    axisY: {
                                                                        title: "Acceleration (m/s^2)",
                                                                        suffix: "m/s^2"
                                                                    },
                                                                    axisX: {
                                                                        title: "Time",
                                                                        suffix: "sec",
                                                                        interval: 0.1,
                                                                        gridThickness: 1,
                                                                        gridColor: "#dbdbdb"
                                                                    },
                                                                    data: [{
                                                                        type: "spline",
                                                                        toolTipContent: "{x} sec: {y} m/s^2",
                                                                        dataPoints: acceleration
                                                                    }]
                                                                }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="individual-graph">
                                                                <CanvasJSChart options={{
                                                                    animationEnabled: false,
                                                                    exportEnabled: true,
                                                                    theme: "light1", // "light1", "dark1", "dark2"
                                                                    title: {
                                                                        text: "Velocity vs Time",
                                                                        fontSize: 18,
                                                                        fontWeight: "normal"
                                                                    },
                                                                    axisY: {
                                                                        title: "Velocity (m/s)",
                                                                        suffix: "m/s"
                                                                    },
                                                                    axisX: {
                                                                        title: "Time",
                                                                        suffix: "sec",
                                                                        interval: 1,
                                                                        gridThickness: 1,
                                                                        gridColor: "#dbdbdb"
                                                                    },
                                                                    data: [{
                                                                        type: "spline",
                                                                        toolTipContent: "{x} sec: {y} m/s",
                                                                        dataPoints: velocity
                                                                    }]
                                                                }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="individual-graph">
                                                                <CanvasJSChart options={{
                                                                    animationEnabled: false,
                                                                    exportEnabled: true,
                                                                    theme: "light1", // "light1", "dark1", "dark2"
                                                                    title: {
                                                                        text: "Height vs Time",
                                                                        fontSize: 18,
                                                                        fontWeight: "normal"
                                                                    },
                                                                    axisY: {
                                                                        title: "Height (m)",
                                                                        suffix: "m"
                                                                    },
                                                                    axisX: {
                                                                        title: "Time",
                                                                        suffix: "sec",
                                                                        interval: 1,
                                                                        gridThickness: 1,
                                                                        gridColor: "#dbdbdb"
                                                                    },
                                                                    data: [{
                                                                        type: "spline",
                                                                        toolTipContent: "{x} sec: {y} m",
                                                                        dataPoints: height
                                                                    }]
                                                                }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-12">
                    <div className="individual-graph">
                        <CanvasJSChart options={{
                            animationEnabled: false,
                            exportEnabled: true,
                            theme: "light1", // "light1", "dark1", "dark2"
                            title: {
                                text: "Distance vs Time",
                                fontSize: 18,
                                fontWeight: "normal"
                            },
                            axisY: {
                                title: "Distance (m)",
                                suffix: "m"
                            },
                            axisX: {
                                title: "Time",
                                suffix: "sec",
                                interval: 1,
                                gridThickness: 1,
                                gridColor: "#dbdbdb"
                            },
                            data: [{
                                type: "spline",
                                toolTipContent: "{x} sec: {y} m",
                                dataPoints: distance
                            }]
                        }}
                        />
                    </div>
                </div> */}





                                                    </div>
                                                </>}
                                            </div>
                                    }




                                </div>

                            </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default Analysis
