import { Chip, CircularProgress } from "@material-ui/core";
import "./mtd-styles.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomModal from "../Modal/CusModal";
import {
  AssignDevice,
  DeleteAssignedDevice,
  getAllAssignedDevicesForTeacher,
  SpareDevices
} from "./api";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from '@material-ui/icons/Delete';
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock';
import Button from '@material-ui/core/Button'
import dayjs from 'dayjs'



export default function ManageTeacherDevice({
  destrictId,
  schoolId,
  teacherId,
  showManageDevice,
}) {


  const [loading, setLoading] = useState(false);
  const [sparedevices, setSpareDevices] = useState([]);
  const [devices, setDevices] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [showUnassignedDevicesTab, setshowUnassignedDevicesTab] = useState(false);



  const handleClose = () => {
    setShowModal(false);
    showManageDevice();
  };

 


  useEffect(() => {
    getAssignedDevices();
    getSpareDevices();
  }, [1]);


  const getAssignedDevices = async () => {
    setLoading(true)
    let res = await getAllAssignedDevicesForTeacher(teacherId._id);
    setDevices(res.res.data.data.devices)
    setLoading(false)
  };

  const getSpareDevices = async () => {
    setLoading(true)
    let res = await SpareDevices();
    setSpareDevices(res.res.data.data.devices)
    // console.log('Spare devices are ',res.res.data.data.devices)
    setLoading(false)
  };


  const RemoveDevice=async(deviceId)=>{
    setLoading(true)
    let res = await DeleteAssignedDevice(teacherId._id,deviceId);
    setSpareDevices(res.res.data.data.devices)
    setLoading(false)
    getAssignedDevices() ;
    getSpareDevices();
  }


  
  const assignDeviceToTeacher=async(deviceId)=>{
    setLoading(true)
    let res = await AssignDevice(teacherId._id,deviceId);
    setLoading(false)
    getAssignedDevices() ;
    getSpareDevices();
  }



  return (
    <>
      <CustomModal show={showModal} handleClose={handleClose}>
        <section id="hero-Msd">
      
       <div className="row main-div-modal">
            <div className="col-md-6 m-auto center-div text-center">
              <div className="p-2 center-div">
                <h2>
                  <button
                    onClick={handleClose}
                    className="btn close-btn  rounded-circle float-right"
                  >
                    <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#011627"/>
                      <path d="M27.0693 13.1005L12.9272 27.2426" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M27.0693 27.2426L12.9272 13.1005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </h2>
                <div className="mt-3">
                  <h6 className="mtd-heading">
                    Manage Devices for {`${teacherId.fName} ${teacherId.lName}`}
                  </h6>
                </div>


                <div className="d-flex justify-content-between">

                <button className="btn btn-add-device mt-4 mb-3 float-left" onClick={() => setshowUnassignedDevicesTab(false)}>
                    <i className="fas fa-plus-circle"></i>&nbsp;<span>Assigned Device</span>
                  </button>

                  <button className="btn  btn-add-device mt-4 mb-3  btn-available-device" onClick={() => setshowUnassignedDevicesTab(true)}>
                   <PhonelinkLockIcon fontSize="small"/>&nbsp;<span>Available Devices</span>
                  </button>
                </div>
              
              


      { !showUnassignedDevicesTab &&  <>
          <TableContainer className="container table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Device Id</TableCell>
                  <TableCell>Device Name</TableCell>
                  <TableCell>Assigned On</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>
          

            {loading && !devices.length && <CircularProgress/>}
            {!loading && !devices.length && <p>No Device Allotted</p>}

              <TableBody>
                {devices.length>0 && devices.map((item, index) => {
                  return (
                    <TableRow hover key={index} role="checkbox" tabIndex={-1}>
                      <TableCell className="table-data">{item._id}</TableCell>
                      <TableCell className="table-data">
                        {item.name}
                      </TableCell>{" "}
                      <TableCell className="table-data">{ dayjs(item.lastUpdated).format('DD/MM/YYYY  hh:mm:A') }</TableCell>
                      <TableCell className="table-data">
                      
                        <span >
                          <DeleteIcon onClick={()=>RemoveDevice(item._id)} color="secondary"/>
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
          
            </Table>
          </TableContainer>
        </> }




        { showUnassignedDevicesTab &&  <>
          <TableContainer className="container table">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Device Id</TableCell>
                  <TableCell>Device Name</TableCell>
                  <TableCell>Assign</TableCell>
                </TableRow>
              </TableHead>
          

            {loading && sparedevices.length<1 && <CircularProgress/>}
            {!loading && sparedevices.length<1 && <p>No Spare Device Available !</p>}

              <TableBody>
                {sparedevices && sparedevices.map((item, index) => {
                  return (
                    <TableRow hover key={index} role="checkbox" tabIndex={-1}>
                      <TableCell className="table-data">{item._id}</TableCell>
                      <TableCell className="table-data">
                        {item.name}
                      </TableCell>
                      <TableCell className="table-data">
                      
                        <span >
                          <Button variant="outlined" onClick={()=>assignDeviceToTeacher(item._id)}>Assign</Button>
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
          
            </Table>
          </TableContainer>
        </> }



              
              </div>
            </div>
          </div>
 
      
        </section>
      </CustomModal>
    </>
  );
}
