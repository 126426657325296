import { useState } from "react";
import "./Navbar.css";
import MenuSharpIcon from "@material-ui/icons/MenuSharp";
import { Link, useHistory } from "react-router-dom";
import getCookie, { districtAdminState, setDistrictCookie } from '../../Atom'
import { useRecoilState } from "recoil";
import { useEffect } from "react";
export default function NavBar() {
  let [toggle, setToggle] = useState(true);
  const [myUser, setmyUser] = useState()
  let history = useHistory()
  const logoutHandler = () => {
    setTimeout(()=>{
      // setmyUser({
      //   fName: null,
      //   userId: null,
      //   token: null,
      //   lName: null,
      // })
      setDistrictCookie({
        fName:null,
        lName:null,
        token:null,
        userId:null
      })
      let hostname= window.location.host;
      // console.log(hostname,window.location.protocol)
      window.location.assign(`${window.location.protocol}//${hostname}/school-district`)
    })
  }

  useEffect(() => {
    const _cookie = getCookie('recoil-persist')
    if(!_cookie){
      logoutHandler()
    }else{
    const user = JSON.parse(_cookie)['district_admin_v1']
    setmyUser(user)
    }
  }, [0])

  return (
    <section id="hero-navbar" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div className="navbar-div d-flex">
        <button className="btn-bell-icon nav-bars mr-2 p-2 ">
          <MenuSharpIcon
            onClick={() => {
              let navmenu = document.getElementById('navmenu')
              navmenu.classList.toggle('open');

            }}
          />
        </button>
        <button className="btn-bell-icon">
          <i className="far fa-bell"></i>
        </button>
      </div>
      <div>
        <h6 className='mr-5 textCap'>{myUser?.fName} {myUser?.lName}</h6>
        <span>{myUser?.email}</span>
        <button
          className="btn btn-dropdown dropleft"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {" "}
          <i className="fas fa-caret-down"></i>
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {/* <Link className="dropdown-item" to="/profile">
            Profile
          </Link> */}
          <a className="dropdown-item" href="#" onClick={logoutHandler}>
            Logout
          </a>
        </div>
      </div>
      <div className="nav-menu" id="navmenu">


        <ul className="list-group m-2 text-left mt-1 ">
          <li
            className="list-group-item "
            id="dashboard"
            style={{ border: "none" }}
          >
            <Link to="/">
              {" "}
              <i className="fas fa-laptop p-1"></i>
              <span> DashBoard</span>
            </Link>
          </li>
          <li
            className="list-group-item "
            id="school-District"
            style={{ border: "none" }}
          >
            <Link to="/school-Districts">
              <i className="fas fa-map-marker-alt p-1"></i>{" "}
              <span> School Districts</span>
            </Link>
          </li>
          <li className="list-group-item " id="devices" style={{ border: "none" }}>
            <Link to="/devices">
              <i className="fas fa-mobile-alt p-1"></i>
              <span> Devices</span>
            </Link>
          </li>
          <li className="list-group-item " id="devices" style={{ border: "none" }}>
            <Link to="/#">
              <i className="fas fa-user p-1"></i>
              <span> profile</span>
            </Link>
          </li>
          <li className="list-group-item " id="devices" style={{ border: "none" }}>
            <Link to="/#">
              <i className="fas fa-user p-1"></i>
              <span> logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
}
