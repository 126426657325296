import { useState } from "react"

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useHistory } from "react-router";
import Sign_in_image from '../../../assets/images/Sign_in_image.jpg';
import Group_290 from '../../../assets/images/Group_290.png';
import "./ForgotPassword.css";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import logo from "../../../assets/images/logo.png";
import { forgotPassword } from "../LoginPage/Api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
   
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
export default function ResetForm(props) {
  const [email,setEmail]=useState("");
  const [otp,setOtp]=useState("");
  const [clicked, setClicked] = useState(false);
  const [loading,setLoading]=useState(false)
  const [open, setOpen] = useState(false);
  const history=useHistory()
  
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async(e) =>{
    e.preventDefault();
    let res = await forgotPassword({email:email.trim()});
    if(res.error){
      alert(res.data.msg);
    }else{
        alert(res.res.data.msg);
        let hostname= window.location.host;
        window.location.assign(`${window.location.protocol}//${hostname}/teachers`);
    }
  }

  return ( 

    <div className="index-bg">
      <img className="class" src={Sign_in_image} />
	<div className="login main" >
	<header style={{position:"absolute",width:"100%"}}>
		<div className="container px-4 py-4 mx1-3" >
			<div className="logo px-1 py-1"><a style={{position:"absolute"}}><img src={Group_290}/></a></div>
		</div>
	</header>
	<main className="indexc">
		<div className="container px-0 pt-4 abs" >
			<div className="row login-form">
				<div className="col-lg-71 d-flex align-items-end justify-content-end ">
					<div className="pb-5">
						<div className="heading text-end1 border-end border-2 pe-3 border-white d-flex">
						{/* <h2 className=" main-text">Test engines like</h2>
						<h2 className=" main-text">real rocket scientists</h2> */}
            <div className="text-end1">
                <h2 className="h2 main-text">Test engines like</h2>
						    <h2 className="h2 main-text">real rocket scientists</h2>
              </div>

              <div style={{borderRight:'3px solid white',marginLeft:'20px'}}>
                
              </div>
					</div>
					</div>
				</div>
				<div className="col-lg-51 d-flex align-items-center justify-content-end pe-5">
					<div className="form1 p-5 mt-0 bg-gray me-5" style={{borderRadius:'48px'}}>
						<div className="">
						
						<form>
						  <div className="form-group">
						    <h2 className="form-title">Forgot Password</h2>
						    <input type="text" className="form-control mt-3 border border-1 border-white" placeholder="Teacher Email" value={email} onBlur={e=>setEmail(e.target.value.trim())} required onChange={e=>setEmail(e.target.value)}/>
						  </div>
							{/* <div className="form-group">
							    <input type="password" className="form-control mt-3 border border-1 border-white" value={password} id="pwd" placeholder="Password" onChange={e=>setPassword(e.target.value.trim())}/>
							 </div> */}
						  
						  	<div className="btn mt-60 px-0 text-center">
							  	<a onClick={(e)=>handleSubmit(e)} type="submit" className="btn btn-default sign-in">Change Password</a><br/>
							  	{/* <a type="forgot" className="btn btn-default forgot"><Link to="/forgot-password" style={{color:'black'}}>Forgot-Password?</Link></a> */}
							</div>
						</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
    </div>
    </div>

  )
}

{/* <div className="index-bg fonted">
      <img className="class" src={Sign_in_image} />
	<div className="login main" >
	<header style={{position:"absolute",width:"100%"}}>
		<div className="container px-4 py-4 mx1-3" >
			<div className="logo px-1 py-1"><a className="a" style={{position:"absolute"}}><img src={Group_290}/></a></div>
		</div>
	</header>
	<main className="indexc">
		<div className="container-teacher px-0 pt-4 abs">
			<div className="row login-form">
				<div className="col-lg-71 d-flex align-items-end justify-content-end ">
					<div className="pb-5">
						<div className="heading text-end text-end1 border-end border-2 pe-3 border-white d-flex">
              <div>
                <h2 className="h2 main-text">Test engines like</h2>
						    <h2 className="h2 main-text">real rocket scientists</h2>
              </div>

              <div style={{borderRight:'3px solid white',marginLeft:'10px'}}>
                
              </div>
            
					</div>
					</div>
				</div>
				<div className="col-lg-51 d-flex align-items-center justify-content-end pe-5">
					<div className="log-Form p-5 mt-0 bg-gray me-5">
						<div className="">
						
						<form >
						  <div className="form-group">
						    <h2 className="form-title h2">Forgot Password</h2>
						  
                <input type="text" placeholder="Teacher Email" className="_form-control text-f-20 form-placeHolder form-controlz mt-3 border border-1 border-white" value={email} onBlur={e=>setEmail(e.target.value.trim())} required onChange={e=>setEmail(e.target.value)} />
						  </div>
						
						  <br/>
						  	<div className="btn mt-60 login-BTN px-0 text-center">
							  	<button  onClick={(e)=>handleSubmit(e)} type="submit" className="btn btn-default sign-in">Change Password</button><br/>
							  
							</div>
						</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
    
    </div>
    </div> */}